<template>
    <section v-if="displayedEvents.length > 0">
        <div class="flex justify-content-between">
            <div class="d-flex align-items-center flex-wrap">
                <h3 class="me-3">Activities Registered In
                </h3>
            </div>
        </div>
        <div class="row flex-wrap row-gap-3">
            <div v-for="(event, index) in displayedEvents" :key="index" class="col-lg-6 col-md-6 col-sm-12 d-flex">
                <div class="card bg-white rounded p-3 d-flex flex-row flex-wrap row-gap-2">
                    <div class="col-lg-2 col-md-12 col-sm-12 px-0 d-flex justify-content-center flex-grow-1">
                        <img v-if="!event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                            class="activity-img-block img-fluid" />
                        <Image v-else class="border-radius img-fluid"
                            :src="this.imageurl + '/' + event.thumbnail_picture" :alt="event.name" preview />
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 fs-7 px-3 d-flex flex-column row-gap-1">
                        <h3 title="" class="mb-0 mt-1 text-truncate-1 fw-bold fs-6">
                            <a :href="baseUrl + '/activities/' + event.slug" class=" w-100
                             py-2" v-tooltip.top="event.name">
                                {{ event.name }}
                            </a>
                        </h3>
                        <div class="m-0 d-flex flex-wrap align-items-start gap-2 fs-8">
                            <span class="d-flex flex-column">
                                <span class="fw-bold">
                                    {{ event.formattedStartDate }} - {{ event.formattedEndDate }}
                                </span>
                            </span>
                        </div>
                        <p class="d-flex flex-row text-truncate-2 mb-0">
                            {{ event.event_type.name }} by
                            <a v-if="event.volunteer_id" :href="baseUrl + '/volunteers/' + event.OwenerDetail[0].slug"
                                class="text-primary text-capitalize" v-tooltip.bottom="event.OwenerDetail[0].name">
                                {{ event.OwenerDetail[0].name }}
                            </a>
                            <a v-if="event.organization_id"
                                :href="baseUrl + '/organizations/' + event.OwenerDetail[0].slug"
                                class="text-primary text-capitalize" v-tooltip.bottom="event.OwenerDetail[0].name">
                                {{ event.OwenerDetail[0].name }}
                            </a>
                        </p>
                    </div>
                    <div
                        class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card py-2 flex-grow-1">
                        <div class="ratinng-box d-flex justify-content-start">
                            <div v-if="event.ratings.avgRating > 0"
                                class="d-flex align-items-center justify-content-center flex-grow-1 gap-1 flex-nowrap flex-sm-wrap">
                                <div class="d-flex justify-content-center text-primary fs-8">
                                    <i v-for="(rating, index) in event.ratings.fullRating" class="fas fa-star rating"
                                        :key="index"></i>
                                    <i v-if="event.ratings.halfRating" class="fas fa-star-half-alt rating"></i>
                                    <i v-for="(rating, index) in event.ratings.zeroRating" class="fa-regular fa-star"
                                        :key="index"></i>
                                </div>
                                <span class="text-secondary fs-9">
                                    ({{ event.ratings.avgRating }} out of 5.0)
                                </span>
                            </div>
                            <div v-else
                                class="d-flex align-items-center justify-content-center flex-grow-1 gap-1 flex-wrap">
                                <div class="d-flex align-items-start text-muted opacity-50 fs-8">
                                    <span class="fa-regular fa-star"></span>
                                    <span class="fa-regular fa-star"></span>
                                    <span class="fa-regular fa-star"></span>
                                    <span class="fa-regular fa-star"></span>
                                    <span class="fa-regular fa-star"></span>
                                </div>
                                <span class="text-muted opacity-50 fs-9">
                                    (0 out of 5.0)
                                </span>
                            </div>
                        </div>
                        <a title="View Activity" :href="baseUrl + '/activities/' + event.slug"
                            class="btn btn-primary w-100 px-1 py-2 fs-8">
                            View
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Paginator v-model:first="first" :rows="4" :totalRecords="totalRecords"
            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            :currentPageReportTemplate="currentPageReportTemplate" />
    </section>
</template>
<script>
import { getVolunteerRegistered } from "@/api";
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Paginator from 'primevue/paginator';

export default {
    components: {
        Button,
        Image,
        Paginator,
    },
    data() {
        return {
            imageurl: null,
            registeredEventsData: Array,
            registeredEvents: [],
            loading: false,
            avgRating: 0,
            fullRating: 0,
            halfRating: 0,
            remainingStars: 0,
            first: 0,
        };
    },
    props: {
        volunteerSlug: {
            type: String,
            required: true
        },
    },
    computed: {
        totalRecords() {
            return this.registeredEvents.length;
        },
        displayedEvents() {
            const startIndex = this.first;
            const endIndex = startIndex + 4; // Display 8 records per page
            return this.registeredEvents.slice(startIndex, endIndex);
        },
        currentPageReportTemplate() {
            const startIndex = this.first + 1;
            const endIndex = this.first + this.displayedEvents.length;
            return `Showing ${startIndex} to ${endIndex} of ${this.totalRecords}`;
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const params = {
                slug: this.volunteerSlug
            };
            const res = await getVolunteerRegistered(params);
            // console.log(res);
            this.registeredEventsData = res;
            this.registeredEvents = this.registeredEventsData;
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {


        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        }
    }
};
</script>

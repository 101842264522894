<template>
    <section class="dashboard container-fluid py-3">
        <div class="container-xxxl">
            <div class="row mx-0 text-center py-4">
                <h1 class="text-capitalize text-primary fs-2">Contact Us</h1>
                <p>Have Questions? Feel Free To Write To Us.</p>
            </div>
            <div class="card w-100">
                <div class="card-body p-4 row mx-0">
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-4">
                        <div class="contact-banner py-5 px-5 text-white">
                            <div class="position-reletive">
                                <h3 class="text-white">Contact Information</h3>
                                <p>For inquiries please contact us, we will get back to you as soon as we can.</p>
                                <div class="d-flex align-items-center mb-3">
                                    <i class="fa-solid fa-phone-volume fa-flip-vertical me-2"></i>
                                    <span>+92 310 4444210</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="fa-solid fa-envelope me-2"></i>
                                    <span>contact@milkar.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 row mx-0">
                        <div class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0">
                            <label>First Name</label>
                            <InputText v-model="firstName" placeholder="First Name" />
                            <span v-if="this.validationErrors?.firstName" class="text-danger empty-1 text-validation">
                                {{this.validationErrors.firstName }}
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0">
                            <label>Last Name</label>
                            <InputText v-model="lastName" placeholder="Last Name" />
                            <span v-if="this.validationErrors?.lastName" class="text-danger empty-1 text-validation">
                                {{this.validationErrors.firstName }}
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0">
                            <label>Phone Number</label>
                            <InputMask id="basic" v-model="phoneNumber" variant="filled" mask="99999999999" placeholder="Phone (0300xxxxxxx)" />
                            <InputNumber v-model="phoneNumber" placeholder="Phone (0300xxxxxxx)" />
                            <span v-if="this.validationErrors?.phoneNumber"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.phoneNumber }}
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0">
                            <label>Email Address</label>
                            <InputText v-model="emailAddress" placeholder="email@example.com" />
                            <span v-if="this.validationErrors?.emailAddress"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.emailAddress }}
                            </span>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group row mx-0">
                            <label>Your Message</label>
                            <Textarea v-model="message" rows="5" cols="30" />
                            <span v-if="this.validationErrors?.message" class="text-danger empty-1 text-validation">
                                {{this.validationErrors.message }}
                            </span>
                        </div>
                        <div class="col-lg-12 text-right">
                            <div class="letter-send">
                                <Button class="btn btn-primary" label="Send Message" @click="submit"
                                    :disabled="isLoading" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { contactUs } from "@/api";
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeflex/primeflex.css';

export default {
    components: {
        Image,
        InputText,
        Button,
        Textarea,
        InputMask,
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            phoneNumber: null,
            emailAddress: null,
            message: null,
            isLoading: false,
            validationErrors: [],
        }
    },
    methods: {
        async submit() {
            try {
                if (!this.validateData()) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = true;
                const params = {
                    name: this.firstName + ' ' + this.lastName,
                    phone: this.phoneNumber,
                    email: this.emailAddress,
                    message: this.message,
                };
                const res = await contactUs(params);
                // Handle the success response
                if (res.status == 200 || res.status == 201) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your Message has been sent to admin.',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            
                        }
                    });
                }
                this.isLoading = false;
            } catch (error) {
                console.error('Error submitting form:', error);
                this.$swal.fire({
                    icon: 'error',
                    text: 'Something Went Wrong....',
                    confirmButtonText: 'OK',
                    showConfirmButton: true,
                });
                this.isLoading = false;
            }
        },
        validateData() {
            this.validationErrors = {};

            if (!this.firstName || this.firstName.trim() === "") {
                this.validationErrors.firstName = 'First Name is required';
            }

            if (!this.lastName || this.lastName.trim() === "") {
                this.validationErrors.lastName = 'Last Name is required';
            }

            if (!this.emailAddress || this.emailAddress.trim() === "") {
                this.validationErrors.emailAddress = 'Email is required';
            } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.emailAddress)) {
                this.validationErrors.emailAddress = 'Please enter valid email';
            }

            if (!this.phoneNumber || this.phoneNumber.trim() === "") {
                this.validationErrors.phoneNumber = 'Phone is required';
            } else {
                const phoneNumber = this.phoneNumber.replace(/\D/g, '');
                if (phoneNumber.length !== 11) {
                    this.validationErrors.phoneNumber = 'Phone number must be 11 digits long';
                }
            }

            if (!this.message || this.message.trim() === "") {
                this.validationErrors.message = 'Message is required';
            }
            
            return Object.keys(this.validationErrors).length === 0;
        },
    },
}
</script>
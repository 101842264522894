<template>
    <section class="volunteerdetail container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card w-100">
                        <div class="card-body">
                            <div class="col-lg-12 px-0 text-left d-flex flex-column row-gap-2">
                                <div class="text-center mb-3">
                                    <img v-if="!organization.logo" src="@/assets/img/org_placeholder.png"
                                        class="border-radius img-fluid" />
                                    <Image v-else class="border-radius img-fluid" preview
                                        :src="this.imageurl + '/' + organization.logo" :alt="organization.user.name" />
                                </div>
                                <div class="">
                                    <a href="">
                                        <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1"> {{ this.organizationName }}
                                        </h3>
                                    </a>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Type</label>
                                        <p class="fs-bold mb-0">{{ this.organizationType }}</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">City</label>
                                        <p class="fs-bold mb-0">{{ this.organizationCity }}</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Address</label>
                                        <p class="fs-bold mb-0">{{ organization.address }}</p>
                                    </div>
                                </div>
                                <div
                                    class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right mb-2">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <span class="badge-counter-text">
                                            Students:
                                            <span v-if="organization.studentsCount > 0" class="counter">
                                                {{ organization.studentsCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>

                                        <span class="badge-counter-text">
                                            Activity Organized:
                                            <span v-if="organization.eventsCount > 0" class="counter">
                                                {{ organization.eventsCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">
                                            Volunteers Engaged:
                                            <span v-if="organization.volunteersEngagedCount > 0" class="counter">
                                                {{ organization.volunteersEngagedCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
                            <div class="row mx-0 flex justify-content-between p-dataview-header px-0">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 me-3">About</h3>
                                </div>
                            </div>
                            <section>
                                <div class="d-flex flex-column row-gap-3">
                                    <div class="row mx-0">
                                        <p class="mb-0" v-html="organization.about"></p>
                                    </div>
                                    <div class="row mx-0">
                                        <label class="text-muted fs-8 text-capitalize">Type of activities</label>
                                        <div class="d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                            <span v-for="(type, index) in typeOfActivities" :key="index"
                                                class="badge-custom">{{ type }}</span>
                                        </div>
                                    </div>
                                    <div class="row mx-0">
                                        <label class="text-muted fs-8">Socials</label>
                                        <p v-if="organization.facebook" class="mb-0">Facebook:
                                            <strong>
                                                <a :href="organization.facebook" class="text-primary">{{
                                        organization.facebook }}</a>
                                            </strong>
                                        </p>
                                        <p v-if="organization.twitter" class="mb-0">Twitter:
                                            <strong>
                                                <a :href="organization.twitter" class="text-primary">{{ organization.twitter
                                                }}</a>
                                            </strong>
                                        </p>
                                        <p v-if="organization.linkedin" class="mb-0">Linkedin:
                                            <strong>
                                                <a :href="organization.linkedin" class="text-primary">{{
                                        organization.linkedin }}</a>
                                            </strong>
                                        </p>
                                        <p v-if="organization.instagram" class="mb-0">Instagram:
                                            <strong>
                                                <a :href="organization.instagram" class="text-primary">{{
                                        organization.instagram }}</a>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </section>
                            <ActivitiesOrganized :slug="this.slug"></ActivitiesOrganized>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getOrganization } from "@/api";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import { Pagination, Navigation } from 'vue3-carousel';
import ActivitiesOrganized from "../components/Organizations/ActivitiesOrganized";
export default {
    name: "OrganizationDetail",
    components: {
        DataView,
        DataViewLayoutOptions,
        Button,
        Image,
        Divider,
        Card,
        Pagination,
        Navigation,
        ActivitiesOrganized,

    },
    data() {
        return {
            imageurl: null,
            organization: [],
            typeOfActivities: [],
            organizationName: null,
            organizationType: null,
            organizationCity: null,
            organizationAddress: null,
            organizationAddress: null,
            loading: false,
        };
    },
    props: ['slug'], // Declare the 'slug' prop
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const res = await getOrganization(this.slug);
            this.organization = res.organization;
            this.typeOfActivities = res.typeOfActivities;
            this.organizationName = this.organization.user.name;
            this.organizationType = this.organization.type.name;
            this.organizationCity = this.organization.city.name;
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {

    }
};
</script>
<template>
    <div class="container-xxxl px-0">
        <DetailPageSekeleton v-if="pageLoading"></DetailPageSekeleton>
        <div v-else class="row row-gap-3">
            <Sidebar v-model:visible="visible">
                <div class="card-body">
                    <h3 class="">Filter Options</h3>
                    <div class="funkyradio mb-2 flex-wrap flex-sm-nowrap gap-2">
                        <div class="funkyradio-primary w-100">
                            <input type="radio" name="radio" id="radio2" value="Virtual"
                                v-model="CheckedInTypeOption" />
                            <label for="radio2">Virtual</label>
                        </div>
                        <div class="funkyradio-success w-100">
                            <input type="radio" name="radio" id="radio3" value="Onsite" v-model="CheckedInTypeOption" />
                            <label for="radio3">Onsite</label>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-start gap-2 mb-2">
                        <InputText v-model="activityName" type="text" placeholder="Name" class="w-100" />
                        <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                            placeholder="Province" class="w-100" :disabled="isVirtualSelected" />
                        <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                            class="w-100" :disabled="!selectedProvince" />
                        <MultiSelect v-model="selectedEventTypes" display="chip" :options="eventTypes"
                            optionLabel="name" placeholder="Select Event Types" :maxSelectedLabels="100"
                            class="w-100" />
                        <MultiSelect v-model="selectedSkills" display="chip" :options="skills" optionLabel="name"
                            placeholder="Select Skills" :maxSelectedLabels="100" class="w-100" />
                        <MultiSelect v-model="selectedCauses" display="chip" :options="causes" optionLabel="name"
                            placeholder="Select Causes" :maxSelectedLabels="100" class="w-100" />
                        <span class="p-float-label w-100">
                            <Calendar class="w-100" v-model="dates" selectionMode="range" :manualInput="false"
                                placeholder="Range Date" />

                        </span>
                    </div>
                    <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                        @click="searchActivities" />

                    <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                        @click="resetFilters" />
                </div>
            </Sidebar>
            <DataView :value="events" :layout="layout">
                <template #header>
                    <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                        <div
                            class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                            <h3 class="mb-0 me-3">Explore Registered Events</h3>
                            <Chip class="pl-0 pr-3">
                                <span class="ml-2 font-medium small fw-bold">
                                    {{ activitiesCount }}
                                    Found
                                </span>
                            </Chip>
                        </div>
                        <div
                            class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap row-gap-3">
                            <div
                                class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                <Button type="button" class="btn btn-primary-sm" label="Mark Completed All" />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-nowrap flex-wrap">
                                <div class="p-float-label sortable-select w-100">
                                    <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                        placeholder="Sort Activities" @change="onSortChange($event)" class="w-100" />
                                </div>
                                <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                <Button class="filter-opt" icon="pi pi-filter-fill" @click="visible = true" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #list="slotProps">
                    <div v-for="(event, index) in slotProps.items" :key="index">
                        <div class="card-body row mx-0 row-gap-3 pb-0">
                            <div class="col-lg-2 col-md-12 text-center px-0">
                                <img v-if="!event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                                    class="activity-img-block border-radius img-fluid" />
                                <Image v-else class="border-radius img-fluid"
                                    :src="this.imageurl + '/' + event.thumbnail_picture" :alt="event.OwenerDetail.name"
                                    preview />
                            </div>
                            <div
                                class="col-lg-7 col-md-7 col-sm-12 text-truncate fs-7 px-lg-3 px-0 d-flex flex-column row-gap-2">
                                <a :href="baseUrl + '/activities/' + event.slug">
                                    <h3 title="" class="mb-0 mt-1 text-truncate-2 fw-bold">
                                        {{ event.name }}
                                    </h3>
                                </a>
                                <div class="m-0 d-flex flex-wrap align-items-start row-gap-3 gap-3">
                                    <span class="d-flex flex-column">
                                        <label class="fs-8">Date</label>
                                        <span v-if="event.formattedStartDate && event.formattedEndDate" class="fw-bold">
                                            {{ event.formattedStartDate }} - {{
            event.formattedEndDate
        }}
                                        </span>
                                        <span v-else class="fw-bold" v-tooltip.bottom="'Not Added'">
                                            N/A
                                        </span>
                                    </span>
                                    <span class="d-flex flex-column">
                                        <label class="fs-8">Time</label>
                                        <span v-if="event.formattedStartTime && event.formattedEndTime" class="fw-bold">
                                            {{ event.formattedStartTime }} - {{
            event.formattedEndTime
        }}
                                        </span>
                                        <span v-else class="fw-bold" v-tooltip.bottom="'Not Added'">
                                            N/A
                                        </span>
                                    </span>
                                    <span class="d-flex flex-column" v-if="event.min_hours > 0 && event.max_hours > 0">
                                        <label class="fs-8">Duration</label>
                                        <span class="fw-bold">
                                            {{ event.min_hours }} - {{ event.max_hours }} Hours
                                        </span>
                                    </span>
                                    <span class="" v-else-if="event.min_hours > 0 && !event.max_hours > 0">
                                        <label class="fs-8">Duration</label>
                                        <span class="fw-bold">
                                            {{ event.min_hours }} Hours
                                        </span>

                                    </span>
                                    <span class="d-flex flex-column"
                                        v-else-if="!event.min_hours > 0 && event.max_hours > 0">
                                        <label class="fs-8">Max Hours</label>
                                        <span class="fw-bold">
                                            {{ event.max_hours }}
                                        </span>
                                    </span>
                                    <span class="d-flex flex-column">
                                        <label class="fs-8">City</label>
                                        <span v-if="event.city" class="fw-bold">
                                            {{ event.city.name }}
                                        </span>
                                        <span v-else class="fs-7 text-muted fw-bold" v-tooltip.bottom="'Not Added'">
                                            N/A
                                        </span>
                                    </span>
                                </div>
                                <p class="d-flex flex-row text-truncate-2 mb-0">
                                    {{ event.event_type.name }} by
                                    <a v-if="event.organization_id && event.OwenerDetail && event.OwenerDetail[0] && event.OwenerDetail[0].slug"
                                        :href="baseUrl + '/organizations/' + event.OwenerDetail[0].slug"
                                        class="text-primary">
                                        {{ event.OwenerDetail[0].name }}
                                    </a>
                                    <a v-if="event.volunteer_id && event.OwenerDetail && event.OwenerDetail[0] && event.OwenerDetail[0].slug"
                                        :href="baseUrl + '/volunteers/' + event.OwenerDetail[0].slug"
                                        class="text-primary">
                                        {{ event.OwenerDetail[0].name }}
                                    </a>
                                </p>
                                <div class="col-lg-12 px-0">
                                    <div v-if="event.EventLocations.length > 0">
                                        <Button title="View more locations"
                                            class="btn btn-secondary-sm align-items-baseline"
                                            @click="locationDailogVisible = true, handleViewLocationClick(event.EventLocations, event.name)">
                                            <span class="badge bg-primary font-5 me-1">
                                                <i class="fa-solid fa-location-dot me-1"></i>{{
            event.locationCount
        }}
                                            </span>
                                            Location
                                        </Button>
                                    </div>
                                    <div v-else class="btn btn-primary-sm disabled opacity-25"
                                        v-tooltip.bottom="'Venue Not Added'">
                                        Venue:
                                        <span class="fs-7 fw-bold">
                                            N/A
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                <div class="row mx-0 flex-wrap w-100 justify-content-center row-gap-1">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <span class="badge-counter-text">Volunteers Engaged:
                                            <span v-if="event.registeredVolunteersCount > 0" class="counter">
                                                {{ event.registeredVolunteersCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>

                                    </div>
                                    <div class="ratinng-box d-flex justify-content-center row-gap-2">
                                        <div v-if="event.ratings.avgRating > 0"
                                            class="d-flex align-items-center justify-content-center gap-2 flex-nowrap">
                                            <div
                                                class="d-flex gap-1 align-items-center text-primary fs-8 flex-nowrap w-100">
                                                <span v-for="v in Math.floor(event.ratings.avgRating)" :key="v"
                                                    class="fas fa-star filled"></span>
                                                <span v-if="event.ratings.halfRating % 1 !== 0"
                                                    class="fas fa-star-half-alt filled"></span>
                                                <span v-if="event.ratings.avgRating < 5"
                                                    class="d-flex align-items-center gap-1"><span
                                                        v-for="v in Math.ceil(event.ratings.zeroRating)"
                                                        :key="'empty-' + v" class="fa-regular fa-star"></span>
                                                </span>
                                            </div>
                                            <span class="text-secondary w-100 fs-9">
                                                ({{ calculateRatingOutOf5(event.ratings.avgRating,
            event.ratings.fullRating) }} out of 5.0)
                                            </span>
                                        </div>
                                        <div v-else
                                            class="d-flex align-items-center justify-content-center gap-2 flex-nowrap">
                                            <div class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                <span class="fa-regular fa-star"></span>
                                                <span class="fa-regular fa-star"></span>
                                                <span class="fa-regular fa-star"></span>
                                                <span class="fa-regular fa-star"></span>
                                                <span class="fa-regular fa-star"></span>
                                            </div>
                                            <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-wrap px-0">
                                        <a v-if="this.$checkEndDate(event.end_date) && this.$checkStartDate(event.start_date)"
                                            class="btn btn-primary-sm mx-0 w-100"
                                            @click="this.statursChange(event.id, 10)" :disabled="isButtonLoading">Mark
                                            as Complete
                                            <span class="promise-btn__spinner-wrapper" v-if="isButtonLoading">
                                                <span class="spinner"
                                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s;"></span>
                                            </span>
                                        </a>
                                        <div v-else class="btn btn-primary-sm disabled opacity-25 w-100"
                                            v-tooltip.bottom="!this.$checkStartDate(event.start_date) ? 'Activity not started' : 'Mark as Completed Not Yet'">
                                            Mark
                                            as Complete
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider></Divider>
                        </div>
                    </div>
                </template>

                <template #grid="slotProps">
                    <div class="grid grid-nogutter">
                        <div v-for="(event, index) in slotProps.items" :key="index"
                            class="sm:col-12 lg:col-3 xl:col-3 p-2">
                            <div
                                class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                    <div class="col-lg-4 px-1">
                                        <Skeleton class="w-100 border-round h-100" />
                                    </div>
                                    <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                        <Skeleton class="w-100 border-round h-2rem" />
                                        <Skeleton class="w-100 border-round h-2rem" />
                                    </div>
                                    <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                        <Skeleton class="w-100 border-round h-2rem" />
                                        <Skeleton class="w-100 border-round h-2rem" />
                                        <div class="row px-2 row-gap-2">
                                            <div class="col-lg-7 px-1">
                                                <Skeleton class="w-60 border-round h-2rem" />
                                            </div>
                                            <div class="col-lg-5 px-1">
                                                <Skeleton class="w-40 border-round h-2rem" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row mx-0">
                                    <div class="row mx-0 align-items-start flex-wrap px-0 row-gap-2">
                                        <div class="col-lg-4 px-0">
                                            <img v-if="!event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.thumbnail_picture"
                                                :alt="event.OwenerDetail.name" preview />
                                        </div>
                                        <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2 flex-grow-1">
                                            <a class="px-0" :href="baseUrl + '/activities/' + event.slug">
                                                <h3 v-tooltip.top="event.name"
                                                    class="mb-1 mt-0 text-truncate-2 fw-bold text-capitalize fs-7">
                                                    {{ event.name }}
                                                </h3>
                                            </a>
                                            <p class="d-flex flex-row text-truncate-2 mb-0 fs-8"
                                                v-tooltip.bottom="event.OwenerDetail[0] ? event.OwenerDetail[0].name : ''">
                                                {{ event.event_type.name }} by
                                                <a v-if="event.organization_id && event.OwenerDetail && event.OwenerDetail[0] && event.OwenerDetail[0].slug"
                                                    :href="baseUrl + '/organizations/' + event.OwenerDetail[0].slug"
                                                    class="text-primary">
                                                    {{ event.OwenerDetail[0].name }}
                                                </a>
                                                <a v-if="event.volunteer_id && event.OwenerDetail && event.OwenerDetail[0] && event.OwenerDetail[0].slug"
                                                    :href="baseUrl + '/volunteers/' + event.OwenerDetail[0].slug"
                                                    class="text-primary">
                                                    {{ event.OwenerDetail[0].name }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 d-flex align-items-top flex-column text-left px-0 fs-8 mt-2">
                                        <div class="d-flex flex-column">
                                            <label class="fs-8">Date</label>
                                            <span v-if="event.formattedStartDate && event.formattedEndDate"
                                                class="fw-bold">
                                                {{ event.formattedStartDate }} - {{
            event.formattedEndDate }}
                                            </span>
                                            <span v-else class="fw-bold" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </div>

                                    </div>
                                    <div
                                        class="col-lg-12 card-section-right-sm d-flex align-items-center justify-content-center card mt-2 shadow-none">
                                        <div class="row mx-0 flex-wrap w-100 justify-content-center">
                                            <div class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                                <span class="badge-counter-text fs-8">Volunteers Engaged:
                                                    <span v-if="event.registeredVolunteersCount > 0" class="counter">
                                                        {{ event.registeredVolunteersCount }}
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 px-0 d-flex align-items-center gap-0 flex-column">
                                        <Button v-if="event.EventLocations.length > 0" title="View more locations"
                                            class="btn btn-secondary-sm align-items-baseline w-100"
                                            @click="locationDailogVisible = true, handleViewLocationClick(event.EventLocations, event.name)">
                                            <span class="badge bg-primary font-5 me-1">
                                                <i class="fa-solid fa-location-dot me-1"></i>{{
            event.locationCount
        }}
                                            </span>
                                            Location
                                        </Button>
                                        <div v-else class="btn btn-primary-sm w-100 disabled opacity-25"
                                            v-tooltip.bottom="'Venue Not Added'">
                                            Venue:
                                            <span class="fs-7 fw-bold">
                                                N/A
                                            </span>
                                        </div>
                                        <div class="px-0 d-flex flex-column w-100">
                                            <a v-if="this.$checkEndDate(event.end_date) && this.$checkStartDate(event.start_date)"
                                                class="btn btn-primary-sm mx-0"
                                                @click="this.statursChange(event.id, 10)" :disabled="isButtonLoading">
                                                Mark as Complete
                                                <span class="promise-btn__spinner-wrapper" v-if="isButtonLoading">
                                                    <span class="spinner"
                                                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s;"></span>
                                                </span>
                                            </a>
                                            <div v-else class="btn btn-primary-sm disabled opacity-25"
                                                v-tooltip.bottom="!this.$checkStartDate(event.start_date) ? 'Activity not started' : 'Mark as Completed Not Yet'">
                                                Mark
                                                as Complete</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #footer v-if="hasMoreActivities">
                    <div class="p-4">
                        <div v-if="hasMoreActivities" class="text-center">
                            <button @click="loadMoreActivities" class="btn btn-primary" :disabled="showSpinner">
                                View More
                                <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                                    fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                            </button>
                        </div>
                    </div>
                </template>
                <template #empty v-if="!loading">
                    <div class="text-center no-data-image">
                        <img src="@/assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                    </div>
                </template>
            </DataView>
            <Dialog v-model:visible="locationDailogVisible" modal :header="this.dailogHrader"
                :style="{ width: '50vw' }">
                <div id="mycustommodelcontenet" class="scroll list-group">
                    <div class="border-bottom pb-2 mb-2">
                        <a v-for="(location, index) in eventLocationData" :key="index" target="_blank"
                            class="custommlocation-icon d-flex list-group-item list-group-item-action"
                            :href="'https://www.google.com/maps/search/?api=1&query=' + location.latitude + ',' + location.longitude">
                            <img src="https://i.ibb.co/HFYVTtw/map.png" alt="milkar.com">{{ location.address }}
                        </a>
                    </div>
                </div>
            </Dialog>
        </div>
    </div>
</template>
<script>
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import 'primeicons/primeicons.css';
import Divider from 'primevue/divider';
import Image from 'primevue/image';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import { getActivitiesFilters, getActivitiesRegistered } from "@/api";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import Sidebar from 'primevue/sidebar';
import Menu from 'primevue/menu';
import Dialog from 'primevue/dialog';
import DetailPageSekeleton from "@/components/Skeletons/DetailPageSekeleton";
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    components: {
        AutoComplete,
        MultiSelect,
        Dropdown,
        InputText,
        MultiSelect,
        Calendar,
        Button,
        Tag,
        Chip,
        Divider,
        Image,
        DataView,
        DataViewLayoutOptions,
        Sidebar,
        Menu,
        DetailPageSekeleton,
        Dialog,
        Checkbox,
        ProgressSpinner,
    },
    name: "Activities",
    data() {
        return {
            baseUrl: null,
            loading: false,
            pageLoading: true,
            selectedProvince: null,
            selectedCity: null,
            selectedEventTypes: [],
            selectedSkills: [],
            selectedCauses: [],
            selectedCities: [],
            events: [],
            causes: [],
            cities: [],
            dailogHrader: null,
            eventTypes: [],
            provinves: [],
            skills: [],
            activitiesCount: 0,
            eventLocationData: [],
            visible: false,
            pageNo: 1,
            perPage: 12,
            dates: null,
            imageurl: null,
            activityName: null,
            CheckedInTypeOption: null,
            sortKey: '',
            sortvalue: null,
            sortType: null,
            layout: 'list',
            locationDailogVisible: false,
            allRegistered: null,
            sortOptions: [
                { label: 'Created Date ASC', value: 'created_at', type: 'asc' },
                { label: 'Created Date DESC', value: 'created_at', type: 'desc' },
                { label: 'Start Date ASC', value: 'start_date', type: 'asc' },
                { label: 'Start Date DESC', value: 'start_date', type: 'desc' },
                { label: 'End Date ASC', value: 'end_date', type: 'asc' },
                { label: 'End Date DESC', value: 'end_date', type: 'desc' },
                { label: 'Name ASC', value: 'name', type: 'asc' },
                { label: 'Name DESC', value: 'name', type: 'desc' },
            ],
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            showSpinner: false,
        };
    },
    computed: {
        hasMoreActivities() {
            return this.activitiesCount > this.perPage * this.pageNo;
        },
        isVirtualSelected() {
            return this.CheckedInTypeOption === 'Virtual';
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            // this.loading = true;
            this.pageLoading = true;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getActivitiesRegistered(params);
            const res2 = await getActivitiesFilters();
            this.events = res.data.registeredEvents.data;
            this.causes = res2.causes;
            this.allCities = res2.cities;
            this.eventTypes = res2.eventTypes;
            this.provinves = res2.provinves;
            this.skills = res2.skills;
            this.activitiesCount = res.data.registeredEventsCount;
            // this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        selectedProvince(newProvince) {
            // Filter cities based on the selected province and update the cities options
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                // If no province is selected, reset the cities options to empty
                this.cities = [];
            }
        },
    },
    methods: {
        async searchActivities() {
            try {
                this.loading = true;
                var check_in_from_anywhere = null;
                if (this.CheckedInTypeOption == 'Virtual') {
                    check_in_from_anywhere = 1
                }
                if (this.CheckedInTypeOption == 'Onsite') {
                    check_in_from_anywhere = 0
                }
                const params = {
                    pageno: 1, // Reset the page number to 1 for a new search
                    check_in_from_anywhere: check_in_from_anywhere,
                    perpage: this.perPage,
                    name: this.activityName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    event_type_ids: this.selectedEventTypes.map((type) => type.id),
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    cause_ids: this.selectedCauses.map((cause) => cause.id),
                    date_range: this.dates,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };
                const res = await getActivitiesRegistered(params);
                // console.log(res);
                this.events = res.data.registeredEvents.data;
                this.activitiesCount = res.data.registeredEventsCount;
                this.loading = false;
                this.visible = false;
            } catch (error) {
                console.error(error);
            }
        },
        async loadMoreActivities() {
            this.showSpinner = true;
            try {
                this.loading = true;
                var check_in_from_anywhere = null;
                if (this.CheckedInTypeOption == 'Virtual') {
                    check_in_from_anywhere = 1
                }
                if (this.CheckedInTypeOption == 'Onsite') {
                    check_in_from_anywhere = 0
                }
                const params = {
                    pageno: this.pageNo + 1,
                    check_in_from_anywhere: check_in_from_anywhere,
                    perpage: this.perPage,
                    name: this.activityName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    event_type_ids: this.selectedEventTypes.map((type) => type.id),
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    cause_ids: this.selectedCauses.map((cause) => cause.id),
                    date_range: this.dates,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };
                const res = await getActivitiesRegistered(params);
                const newActivities = res.data.registeredEvents.data;
                this.events = [...this.events, ...newActivities];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.CheckedInTypeOption = null;
            this.activityName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedEventTypes = [];
            this.selectedSkills = [];
            this.selectedCauses = [];
            this.dates = null;
            this.searchActivities();
            this.loading = false;
        },
        async onSortChange(event) {
            this.loading = true;
            this.sortvalue = event.value.value;
            this.sortType = event.value.type;
            var check_in_from_anywhere = null;
            if (this.CheckedInTypeOption == 'Virtual') {
                check_in_from_anywhere = 1
            }
            if (this.CheckedInTypeOption == 'Onsite') {
                check_in_from_anywhere = 0
            }
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage,
                check_in_from_anywhere: check_in_from_anywhere,
                name: this.activityName,
                province_id: this.selectedProvince ? this.selectedProvince.id : null,
                city_id: this.selectedCity ? this.selectedCity.id : null,
                event_type_ids: this.selectedEventTypes.map((type) => type.id),
                skill_ids: this.selectedSkills.map((skill) => skill.id),
                cause_ids: this.selectedCauses.map((cause) => cause.id),
                date_range: this.dates,
                sortBy: this.sortvalue,
                sortType: this.sortType,
            };
            const res = await getActivitiesRegistered(params);
            this.events = res.data.registeredEvents.data;
            this.activitiesCount = res.data.registeredEventsCount
            this.loading = false;
        },
        handleViewLocationClick(eventLocations, name) {
            this.eventLocationData = eventLocations;
            this.dailogHrader = name;
        },
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        },
        statursChange(id, status) {
            this.isButtonLoading = true;
            const res = this.$statusClicked(id, status);
            if (res && status == 10) {
                this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Activity completed successfully.',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#46B849",
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.searchActivities();
                    }
                });
            }
        }
    },
}
</script>

<template>
    <section class="dashboard container-fluid py-3">
        <div class="container-xxxl">
            <Toast ref="toast" position="top-right"></Toast>
            <div class="card w-100">
                <div class="card-body p-4">
                    <div class="row row-gap-3 mb-3">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <img v-if="this.imgDataUrl" :src="this.imgDataUrl" class="img-fluid rounded" />
                            <img v-else src="@/assets/img/org-default.png" class="img-fluid rounded" />
                            <i class="fa fa-check-circle text-success font-20 fa-check-circl d-none"></i>
                            <button for="upload_images" class="btn btn-primary w-100" @click="toggleShow">
                                <i class="fa-solid fa-upload pr-1"></i>
                                Upload Profile Picture
                            </button>
                        </div>
                        <div class="col-lg-9 col-md-6 col-sm-12">
                            <h3>Activity Basic Information</h3>
                            <Divider></Divider>
                            <div class="row mx-0 mt-4">
                                <div
                                    class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
                                    <label>Activity Name<span class="text-danger ml-1">*</span></label>
                                    <InputText v-model="activityName" placeholder="Activity Title" ref="activityName" />
                                    <span v-if="this.validationErrors.activityName"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.activityName }}
                                    </span>
                                </div>
                                <div
                                    class="col-lg-6 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
                                    <label>Activity Type<span class="text-danger ml-1">*</span></label>
                                    <Dropdown v-model="selectedActivityType" :options="eventTypes" filter
                                        optionLabel="name" placeholder="Select Activity Type" class="w-100"
                                        ref="selectedActivityType">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value" class="flex align-items-center">
                                                <div>{{ slotProps.value.name }}</div>
                                            </div>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <span v-if="this.validationErrors.selectedActivityType"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.selectedActivityType }}
                                    </span>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 form-group row mx-0">
                                    <label class="d-flex align-items-center flex-wrap gap-1 row-gap-0">Describe Your
                                        Activity<span class="text-danger ml-1">*</span>
                                        <div v-if="remainingWords" class="word-limit fs-8 text-muted">({{remainingWords }} words remaining)</div>
                                        <div v-if="isWordLimitReached" class="word-limit fs-8 text-danger">(Word limit exceeded)</div>
                                    </label>

                                    <Editor v-model="describeYourActivity" editorStyle="height: 150px"
                                        placeholder="Describe Your Activity (Limit: 500 Words)" ref="describeYourActivity">
                                        <template v-slot:toolbar>
                                            <span class="ql-formats">
                                                <select class="ql-font"></select>
                                                <select class="ql-size"></select>
                                            </span>
                                            <span class="ql-formats">
                                                <button class="ql-bold"></button>
                                                <button class="ql-italic"></button>
                                                <button class="ql-underline"></button>
                                            </span>
                                        </template>
                                    </Editor>
                                    <span v-if="this.validationErrors.describeYourActivity"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.describeYourActivity }}
                                    </span>
                                </div>
                                <div v-if="this.educationalInstitute">
                                    <label for="">Recommendation</label>
                                    <div class="flex align-items-center">
                                        <RadioButton v-model="recommendationType" inputId="myVolunteer"
                                            name="myVolunteer" value="myVolunteer" />
                                        <label for="myVolunteer" class="ml-2">My Volunteer</label>
                                    </div>
                                    <div class="flex align-items-center">
                                        <RadioButton v-model="recommendationType" inputId="allvolunteer"
                                            name="allvolunteer" value="allvolunteer" />
                                        <label for="allvolunteer" class="ml-2">All Volunteer</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Accordion :multiple="true" class="d-flex flex-column row-gap-2">
                        <AccordionTab header="Let's Add Location">
                            <div class="row mx-0">
                                <div class="form-group col-lg-12 col-md-12 col-sm-12 row mx-0 row-gap-2 gap-2">
                                    <div class="d-flex flex-wrap gap-3 mt-3 px-0">
                                        <div class="d-flex align-items-center gap-3 row-gap-2 flex-wrap">
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="venueType" inputId="onField" name="onField"
                                                    value="onField" />
                                                <label for="onField" class="ml-2">On Field</label>
                                            </div>
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="venueType" inputId="virtual" name="virtual"
                                                    value="virtual" />
                                                <label for="virtual" class="ml-2">Virtual</label>
                                            </div>
                                            <div class="flex align-items-center">
                                                <RadioButton v-model="venueType" inputId="other" name="other"
                                                    value="other" />
                                                <label for="other" class="ml-2">Other</label>
                                            </div>
                                        </div>
                                        <label class="fs-6 text-muted">(Now let's specify more about venue of your
                                            Cause)</label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 form-group row mx-0">
                                    <label>Province<span class="text-danger ml-1">*</span></label>
                                    <Dropdown :disabled="this.venueType === 'virtual'" v-model="selectedProvince"
                                        :options="provinces" filter optionLabel="name" placeholder="Province"
                                        class="w-100" ref="selectedProvince">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value" class="flex align-items-center">
                                                <div>{{ slotProps.value.name }}</div>
                                            </div>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <span v-if="this.validationErrors.selectedProvince"
                                        class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.selectedProvince }} </span>
                                </div>
                                <div class="col-md-6 col-sm-12 form-group row mx-0">
                                    <label>City <small>(not required if virtual)</small><span
                                            class="text-danger ml-1">*</span></label>
                                    <Dropdown v-model="selectedCity" :options="provinceCities"
                                        :disabled="!selectedProvince" filter optionLabel="name" placeholder="City"
                                        class="w-100" ref="selectedCity">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value" class="flex align-items-center">
                                                <div>{{ slotProps.value.name }}</div>
                                            </div>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <span v-if="this.validationErrors.selectedCity"
                                        class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedCity
                                        }}</span>
                                </div>
                                <div class="col-md-12 col-sm-12 form-group row mx-0">
                                    <label>Activity Venue<span class="text-danger ml-1">*</span></label>
                                    <!-- <vue-google-autocomplete v-model="venueAddress" id="map" classname="form-control"
                                        :disabled="this.venueType === 'virtual' || !selectedProvince || !selectedCity"
                                        placeholder="Enter Activity Venue" :value="!venueAddress ? null : venueAddress"
                                        @placechanged="onPlaceChanged" :country="['pk']" ref="venueAddress">
                                    </vue-google-autocomplete> -->
                                    <VueGoogleAutocomplete class="p-0" id="map"
                                        v-model="venueAddress"
                                        :disabled="this.venueType === 'virtual' || !selectedProvince || !selectedCity"
                                        placeholder="Enter Cause Venue"
                                        :userAddress="venueAddress"
                                        @location-sent="onLocationSent"></VueGoogleAutocomplete>
                                    <span v-if="this.validationErrors.venue"
                                        class="text-danger empty-1 text-validation">{{
                                this.validationErrors.venue }}</span>
                                </div>
                                <div class="col-md-12 col-sm-12 form-group row mx-0">
                                    <GoogleMap class="px-0" api-key="YOUR_GOOGLE_MAPS_API_KEY"
                                        style="width: 100%; height: 250px" :center="center" :zoom="zoom">
                                        <Marker v-if="venueAddress" :options="{ position: center }" />
                                    </GoogleMap>
                                </div>
                                <div class="d-flex flex-wrap gap-2 mb-3">
                                    <Chip v-for="(location, index) in selectedLocations" :key="location"
                                        :label="location.venue" icon="fa-solid fa-location-dot" :removable="true"
                                        @remove="removeLocation(index)" />
                                </div>
                                <div class="col-lg-12 text-right">
                                    <Button class="btn btn-primary" icon="pi pi-plus" label="Add Location"
                                        @click="addLocation" />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Contact Person Information">
                            <checkbox v-model="contactDetails" inputId="sameContact" name="sameContact"
                                value="sameContact" />
                            <label for="sameContact" class="ml-2"> Same As Organization's Contact Person </label>
                            <div class="row mx-0 row-gap-4 mt-4">
                                <div
                                    class="col-lg-4 col-md-4 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
                                    <label>Name<span class="text-danger ml-1">*</span></label>
                                    <InputText v-model="contactPersonName" placeholder="Contact Person Name"
                                        ref="contactPersonName" :disabled="contactDetails.includes('sameContact')" />
                                    <span v-if="this.validationErrors.contactPersonName"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.contactPersonName }}
                                    </span>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
                                    <label>Email<span class="text-danger ml-1">*</span></label>
                                    <InputText v-model="contactPersonEmail" placeholder="Contact Person Email"
                                        ref="contactPersonEmail" :disabled="contactDetails.includes('sameContact')" />
                                    <span v-if="this.validationErrors.contactPersonEmail"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.contactPersonEmail }}
                                    </span>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
                                    <label>Phone<span class="text-danger ml-1">*</span></label>
                                    <InputText v-model="contactPersonPhone" placeholder="Contact Person Phone"
                                        ref="contactPersonPhone" :disabled="contactDetails.includes('sameContact')"
                                        maxlength="11"
                                        @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()" />
                                    <span v-if="this.validationErrors.contactPersonPhone"
                                        class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.contactPersonPhone }}
                                    </span>
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Add More Details">
                            <div class="row mx-0 row-gap-4">
                                <div class="col-md-6 col-sm-12 form-group flex-grow-1 mb-0">
                                    <label class="text-capitalize">Please specify your Activity Hashtag</label>
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">
                                            <i class="pi pi-hashtag text-primary"></i>
                                        </span>
                                        <InputText v-model="hashtag" placeholder="Activity Hashtag" ref="hashtag" />
                                        <span v-if="this.validationErrors.hashtag"
                                            class="text-danger empty-1 text-validation">{{ this.validationErrors.hashtag
                                            }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 form-group flex-grow-1 mb-0">
                                    <label class="text-capitalize">Additional Activity Hashtags</label>
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">
                                            <i class="pi pi-hashtag text-primary"></i>
                                        </span>
                                        <vue3-tags-input :tags="tags" display="chip" placeholder="Enter Activity Tags"
                                            class="p-inputtext p-component" @on-tags-changed="handleChangeTag" />
                                    </div>
                                </div>
                                <MultipleImagesUpload :key="componentKey"
                                    @uploaded-files-updated="handleUploadedFilesUpdated" @limit-exceeded="limitCheck">
                                </MultipleImagesUpload>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h3 class="fw-bold">Please mention the schedule of Activity</h3>
                                    <div class="row mx-0 row-gap-2">
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1 px-1">
                                            <label for="startDate">Start Date</label>
                                            <Calendar id="startDate" v-model="startDate" showIcon
                                                placeholder="MM-DD-YYYY" class="w-100" :minDate="minStartDate"
                                                :manualInput="false" ref="startDate" />
                                            <span v-if="this.validationErrors.startDate"
                                                class="text-danger empty-1 text-validation">{{
                                this.validationErrors.startDate }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1 px-1">
                                            <label for="endDate">End Date</label>
                                            <Calendar id="endDate" v-model="endDate" showIcon placeholder="MM-DD-YYYY"
                                                class="w-100" :minDate="startDate" :manualInput="false"
                                                :disabled="!startDate" ref="endDate" />
                                            <span v-if="this.validationErrors.endDate"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.endDate
                                                }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1 px-1">
                                            <label for="startTime">Start Time</label>
                                            <Calendar id="startTime" v-model="startTime" timeOnly hourFormat="12"
                                                placeholder="00:00 am" class="w-100" :manualInput="false"
                                                ref="startTime" />
                                            <span v-if="this.validationErrors.startTime"
                                                class="text-danger empty-1 text-validation">{{
                                this.validationErrors.startTime }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1 px-1">
                                            <label for="endTime">End Time</label>
                                            <Calendar id="endTime" v-model="endTime" timeOnly timeNow hourFormat="12"
                                                placeholder="00:00 am" class="w-100" :manualInput="false"
                                                ref="endTime" />
                                            <span v-if="this.validationErrors.endTime"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.endTime
                                                }}</span>
                                        </div>
                                        <div class="col-lg-4 col-md-12 col-sm-12 px-0">
                                            <div class="d-flex align-items-center flex-nowrap flex-grow-1 gap-2">
                                                <div class="flex-auto">
                                                    <label for="minHour">Min Hours<span
                                                            class="text-danger ml-1">*</span></label>
                                                    <InputNumber id="minHour" v-model="minHours" timeOnly
                                                        hourFormat="12" placeholder="Min Hours" class="w-100"
                                                        @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                        ref="minHours" />

                                                </div>
                                                <div class="flex-auto">
                                                    <label for="maxHour">Max Hours<span
                                                            class="text-danger ml-1">*</span></label>
                                                    <InputNumber id="maxHour" v-model="maxHours" timeOnly
                                                        hourFormat="12" placeholder="Max Hours" class="w-100"
                                                        @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                        ref="maxHours" />

                                                </div>
                                            </div>
                                            <span v-if="this.validationErrors.minHours"
                                                class="text-danger empty-1 text-validation">{{
                                this.validationErrors.minHours }}
                                            </span>
                                            <span v-if="this.validationErrors.maxHours"
                                                class="text-danger empty-1 text-validation">{{
                                this.validationErrors.maxHours }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.recommendationType == 'allvolunteer'"
                                    class="col-lg-12 col-md-12 col-sm-12">
                                    <h3 class="fw-bold mb-1">Tell us about required volunteers for the Activity</h3>
                                    <p class="fs-8">We will suggest you potential volunteers according to your
                                        requirements
                                    </p>
                                    <div class="d-flex align-items-center flex-wrap gap-2 row-gap-3">
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="totalVolunteers">Total No. of Volunteers</label>
                                            <InputText id="totalVolunteers" v-model="totalVolunteers"
                                                placeholder="Total No. of Volunteers" class="w-100" disabled />
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="maleVolunteers">Male Volunteers</label>
                                            <InputText id="maleVolunteers" v-model="maleVolunteers" placeholder="0"
                                                class="w-100" @change="totalVolunteersNumbers"
                                                @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                ref="maleVolunteers" />
                                            <span v-if="this.validationErrors.maleVolunteers"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.maleVolunteers
                                                }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="femaleVolunteers">Female Volunteers</label>
                                            <InputText id="femaleVolunteers" v-model="femaleVolunteers" placeholder="0"
                                                class="w-100" @change="totalVolunteersNumbers"
                                                @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                ref="femaleVolunteers" />
                                            <span v-if="this.validationErrors.femaleVolunteers"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.femaleVolunteers
                                                }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="minAge">Minimum Age</label>
                                            <InputText id="minAge" v-model="minAge" placeholder="0" class="w-100"
                                                @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                ref="minAge" />
                                            <span v-if="this.validationErrors.minAge"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.minAge
                                                }}</span>
                                        </div>
                                        <div class="col-lg-2 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="maxAge">Maximum Age</label>
                                            <InputText id="maxAge" v-model="maxAge" placeholder="0" class="w-100"
                                                @keypress="$event.key.match(/^[\d\.]$/) ? '' : $event.preventDefault()"
                                                ref="maxAge" />
                                            <span v-if="this.validationErrors.maxAge"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.maxAge
                                                }}</span>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 flex-grow-1">
                                            <label for="education">Education</label>
                                            <Dropdown v-model="selectedEducation" :options="education" filter
                                                optionLabel="name" placeholder="Education Level" class="w-100"
                                                ref="selectedEducation">
                                                <template #value="slotProps">
                                                    <div v-if="slotProps.value" class="flex align-items-center">
                                                        <div>{{ slotProps.value.name }}</div>
                                                    </div>
                                                    <span v-else>
                                                        {{ slotProps.placeholder }}
                                                    </span>
                                                </template>
                                                <template #option="slotProps">
                                                    <div class="flex align-items-center">
                                                        <div>{{ slotProps.option.name }}</div>
                                                    </div>
                                                </template>
                                            </Dropdown>
                                            <span v-if="this.validationErrors.selectedEducation"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEducation
                                                }}</span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label for="employment">Employment Status</label>
                                            <Dropdown v-model="selectedEmploymentStatus" :options="employmentStatuses"
                                                filter optionLabel="name" placeholder="Employment Status" class="w-100"
                                                ref="selectedEmploymentStatus">
                                                <template #value="slotProps">
                                                    <div v-if="slotProps.value" class="flex align-items-center">
                                                        <div>{{ slotProps.value.name }}</div>
                                                    </div>
                                                    <span v-else>
                                                        {{ slotProps.placeholder }}
                                                    </span>
                                                </template>
                                                <template #option="slotProps">
                                                    <div class="flex align-items-center">
                                                        <div>{{ slotProps.option.name }}</div>
                                                    </div>
                                                </template>
                                            </Dropdown>
                                            <span v-if="this.validationErrors.selectedEmploymentStatus"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEmploymentStatus
                                                }}</span>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 flex-grow-1">
                                            <label for="causesList">Causes</label>
                                            <MultiSelect v-model="selectedCauses" display="chip" :options="causes"
                                                optionLabel="name" placeholder="Select Causes" :maxSelectedLabels="4"
                                                :maxSelectedSize="4" class="w-100" :selectionLimit="4"
                                                ref="selectedCauses" />
                                            <span v-if="this.validationErrors.selectedCauses"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedCauses
                                                    }}</span>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 flex-grow-1">
                                            <label for="skillsList">Skills</label>
                                            <MultiSelect v-model="selectedSkills" display="chip" :options="skills"
                                                optionLabel="name" placeholder="Select Skills" :maxSelectedLabels="4"
                                                class="w-100" :selectionLimit="4" ref="selectedSkills" />
                                            <span v-if="this.validationErrors.selectedSkills"
                                                class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedSkills
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <div class="col-lg-12 text-right">
                        <button class="btn btn-primary-sm d-inline-flex align-items-center gap-1"
                            :disabled="showSpinner" @click="createActivity()">Save
                            <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                                fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <my-upload field="img" @crop-success="cropSuccess" @crop-upload-fail="cropUploadFail" v-model="show"
        img-format="png" lang-type="en" @change="handleFileSelected"></my-upload>
</template>
<script>
import { getVolunteerDetail, createActivity, getOrganization } from "@/api";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import VueGoogleAutocomplete from "@/components/AutoComplete.vue";
import { GoogleMap, Marker } from "vue3-google-map";
import Image from 'primevue/image';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import Chip from 'primevue/chip';
import FileUpload from 'primevue/fileupload';
import MultiSelect from 'primevue/multiselect';
import MultipleImagesUpload from "@/components/MultipleImagesUpload.vue";
import Vue3TagsInput from 'vue3-tags-input';
import Editor from 'primevue/editor';
import myUpload from 'vue-image-crop-upload';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';

export default {
    components: {
        GoogleMap,
        Marker,
        Image,
        Button,
        Textarea,
        ListingSkeleton,
        Accordion,
        AccordionTab,
        Divider,
        InputText,
        Calendar,
        RadioButton,
        Dropdown,
        Chip,
        FileUpload,
        MultiSelect,
        VueGoogleAutocomplete,
        MultipleImagesUpload,
        Vue3TagsInput,
        Editor,
        myUpload,
        ProgressSpinner,
        Toast,
        Checkbox,
        InputNumber,
    },
    data() {
        return {
            contactDetails: ['sameContact'],
            baseUrl: null,
            activityName: null,
            venueType: 'onField',
            venueAddress: null,
            isLocationAccordionEnabled: false,
            selectedActivityType: null,
            describeYourActivity: '',
            maxWordLimit: 500,
            selectedProvince: null,
            enterActivityVenue: null,
            selectedCity: null,
            selectedEducation: null,
            selectedCauses: null,
            selectedSkills: null,
            startDate: null,
            endDate: null,
            minStartDate: new Date(),
            startTime: null,
            endTime: null,
            minHours: null,
            maxHours: null,
            maleVolunteers: null,
            femaleVolunteers: null,
            totalVolunteers: null,
            minAge: null,
            maxAge: null,
            employmentStatuses: [],
            selectedEmploymentStatus: null,
            eventTypes: [],
            skills: [],
            causes: [],
            provinceCities: [],
            universities: [],
            education: [],
            educationField: [],
            gender: null,
            provinces: [],
            cities: [],
            parentUploadedFiles: [],
            zoom: 10,
            center: { lat: 31.5204, lng: 74.3587 },
            selectedLocationsArray: [],
            selectedLocations: [],
            selectedLocation: {
                lat: null,
                lang: null,
                city_id: null,
                province_id: null,
                venue: null
            },
            validationErrors: [],
            hashtag: null,
            orgiginalIamge: null,
            tags: [],
            show: false,
            filename: null,
            imageType: null,
            file: null,
            showSpinner: false,
            contactPersonName: null,
            contactPersonEmail: null,
            contactPersonPhone: null,
            recommendationType: 'allvolunteer',
            educationalInstitute: null,
            componentKey: 0
        }
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        if (this.venueType == 'onField') {
            this.isLocationAccordionEnabled = true;
        }
        else {
            false;
        }
        try {
            const res = await getVolunteerDetail();
            const myUser = localStorage.getItem("organization");
            const parsedUser = JSON.parse(myUser);
            const organization = await getOrganization(parsedUser.slug);
            const org = organization.organization;
            this.organization = org;
            this.contactPersonName = this.organization.contact_person_name;
            this.contactPersonEmail = this.organization.contact_person_email;
            this.contactPersonPhone = this.organization.contact_person_phone;
            this.educationalInstitute = this.organization ? this.organization.university_id : null;
            this.skills = res.skills;
            this.eventTypes = res.eventType;
            this.causes = res.causes;
            this.universities = res.universities;
            this.provinces = res.provinces;
            this.cities = res.cities;
            this.education = res.education;
            this.educationField = res.educationField;
            this.employmentStatuses = res.employmentStatus;
        }
        catch (error) {
            console.error(error);
            this.errored = true;
        }
        finally {
            this.loading = false;
        }
        if (this.educationalInstitute) {
            this.recommendationType = 'myVolunteer';
        }
    },
    watch: {
        selectedProvince(newProvince) {
            if (newProvince) {
                this.provinceCities = this.cities.filter((city) => city.province_id === newProvince.id);
            }
            // this.selectedCity = null;
        },
        venueType(newValue) {
            if (newValue === 'virtual') {
                // If "Virtual" is selected, clear the selectedLocations array
                this.selectedLocations = [];
                this.selectedCity = null;
                //this.selectedProvince = null;
            }
        },
    },
    computed: {
        isWordLimitReached() {
            return this.countWords(this.describeYourActivity) >= this.maxWordLimit;
        },
        remainingWords() {
            const words = this.countWords(this.describeYourActivity);
            const remaining = this.maxWordLimit - words;
            return Math.max(remaining, 0);
        },
    },
    methods: {
        reloadComponent() {
            this.componentKey++;
        },
        limitCheck(limitExceeded) {
            if (limitExceeded) {
                this.reloadComponent();
                this.$swal.fire({
                    icon: 'error',
                    title: 'Limit Exceeded..',
                    text: 'Maximum 5 images selection allowed.',
                    confirmButtonText: 'OK',
                    showConfirmButton: false,
                });
            }

        },
        countWords(text) {
            const words = text.trim().split(/\s+/).filter(Boolean);
            return words.length;
        },
        handleChangeTag(tags) {
            this.tags = tags;
        },
        // onPlaceChanged(place, addressData) {
        //     this.venueAddress = addressData.formatted_address;
        //     const cityselected = this.cities.find(city => city.name === place.locality);
        //     if (cityselected) {
        //         this.selectedCity = null;
        //         this.provinceselected = null;
        //         const provinceselected = this.provinces.find(province => province.id === cityselected.province_id);
        //         this.selectedProvince = provinceselected;
        //         this.selectedCity = cityselected;
        //     }
        //     if (!cityselected && place.administrative_area_level_1) {
        //         this.selectedCity = null;
        //         this.provinceselected = null;
        //         this.selectedProvince = this.provinces.find(province => province.name === place.administrative_area_level_1);
        //     }
        //     this.zoom = 15;
        //     this.center = {
        //         lat: addressData.geometry.location.lat(),
        //         lng: addressData.geometry.location.lng(),
        //     };
        //     this.selectedLocation.lat = addressData.geometry.location.lat();
        //     this.selectedLocation.lang = addressData.geometry.location.lng();
        //     this.selectedLocation.city_id = this.selectedCity ? this.selectedCity.id : null;
        //     this.selectedLocation.province_id = this.selectedProvince ? this.selectedProvince.id : null;
        //     this.selectedLocation.venue = this.venueAddress;
        // },
        onLocationSent(data) {
            this.venueAddress = data.address;
            const cityselected = this.cities.find(city => city.name === data.city);
            if (cityselected) {
                this.selectedCity = null;
                this.provinceselected = null;
                const provinceselected = this.provinces.find(province => province.id === cityselected.province_id);
                this.selectedProvince = provinceselected;
                this.selectedCity = cityselected;
            }
            if (!cityselected && data.province) {
                this.selectedCity = null;
                this.provinceselected = null;
                this.selectedProvince = this.provinces.find(province => province.name === data.province);
            }
            this.zoom = 15;
            this.center = {
                lat:  data.latitude,
                lng:  data.longitude,
            };
            this.selectedLocation.lat = data.latitude;
            this.selectedLocation.lang = data.longitude;
            this.selectedLocation.city_id = this.selectedCity ? this.selectedCity.id : null;
            this.selectedLocation.province_id = this.selectedProvince ? this.selectedProvince.id : null;
            this.selectedLocation.venue = this.venueAddress;
        },
        addLocation() {
            if (this.venueType == 'onField' || this.venueType == 'other') {
                if (!this.selectedProvince) {
                    this.validationErrors.selectedProvince = 'Province is required';
                } else {
                    this.validationErrors.selectedProvince = '';
                }

                if (!this.selectedCity) {
                    this.validationErrors.selectedCity = 'City is required';
                } else {
                    this.validationErrors.selectedCity = '';
                }

                if (!this.venueAddress) {
                    this.validationErrors.venue = 'Venue is required';
                } else {
                    this.validationErrors.venue = '';
                }
            }
            if (this.selectedCity) {
                this.selectedLocation.city_id = this.selectedCity.id;
            }
            if (this.selectedLocation.venue && this.venueAddress && this.selectedProvince && this.selectedCity) {
                this.selectedLocations.push({ ...this.selectedLocation });
                this.venueAddress = null;
                this.center = { lat: 31.5204, lng: 74.3587 };
                this.zoom = 10;
            }
            // if (this.venueType == 'onField') {
            //     if (!this.selectedLocation.province_id) {
            //         this.validationErrors.selectedProvince = 'Province is required';                    
            //     }
            //     if (!this.selectedLocation.city_id) {
            //         this.validationErrors.selectedCity = 'City is required';                    
            //     }
            //     if(!this.selectedLocation.venue) {
            //         this.validationErrors.venue = 'Venue is required';                    
            //     }
            // }
            // if (this.venueAddress) {

            //     this.selectedLocations.push({ ...this.selectedLocation });
            //     this.venueAddress = null;
            //     this.center = { lat: 31.5204, lng: 74.3587 };
            //     this.zoom = 10;
            // }
        },
        removeLocation: function (index) {
            this.selectedLocations.splice(index, 1);
        },
        handleUploadedFilesUpdated(updatedUploadedFiles) {
            this.parentUploadedFiles = updatedUploadedFiles;
        },
        validateData() {
            this.validationErrors = {};
            if (!this.activityName || this.activityName.trim() === "") {
                this.scrollToField('activityName');
                this.validationErrors.activityName = 'Activity name is required';
            } else if (this.activityName.length > 75) {
                this.scrollToField('activityName');
                this.validationErrors.activityName = 'Activity name should not exceed 75 characters';
            }

            if (!this.selectedActivityType) {
                this.scrollToField('selectedActivityType');
                this.validationErrors.selectedActivityType = 'Activity type is required';
            }
            if (!this.describeYourActivity || this.describeYourActivity.trim() === "") {
                this.scrollToField('describeYourActivity');
                this.validationErrors.describeYourActivity = 'Describe your activity is required';
            } else if(this.isWordLimitReached) {
                this.scrollToField('describeYourActivity');
                this.validationErrors.describeYourActivity = 'Word limit exceeded';                
            }
            if (this.venueType == 'onField') {
                if (!this.selectedProvince) {
                    this.scrollToField('selectedProvince');
                    this.validationErrors.selectedProvince = 'Province is required';
                }
                if (!this.selectedCity) {
                    this.scrollToField('selectedCity');
                    this.validationErrors.selectedCity = 'City is required';
                }
                if (!this.selectedLocation.venue) {
                    this.scrollToField('venueAddress');
                    this.validationErrors.venue = 'Venue is required';
                }
            }
            if (!this.hashtag || this.hashtag.trim() === "") {
                this.scrollToField('hashtag');
                this.validationErrors.hashtag = 'Hashtag is required';
            }
            if (!this.startDate) {
                this.scrollToField('startDate');
                this.validationErrors.startDate = 'Start date is required';
            } else if (!this.endDate) {
                this.scrollToField('endDate');
                this.validationErrors.endDate = 'End date is required';
            }
            if (this.endDate < this.startDate) {
                this.validationErrors.endDate = 'End date must be greater than the start date';
            }
            if (!this.startTime) {
                this.scrollToField('startTime');
                this.validationErrors.startTime = 'Start time is required';
            }
            if (!this.endTime) {
                this.scrollToField('endTime');
                this.validationErrors.endTime = 'End time is required';
            }
            if ((!this.minHours && !this.maxHours) || !this.minHours || !this.maxHours) {
                this.scrollToField('maxHours');
                this.validationErrors.maxHours = 'Min and Max hours required';
            } else if (!this.$isNumeric(this.minHours)) {
                this.scrollToField('minHours');
                this.validationErrors.minHours = 'Just numeric value is allowed in min hours';
            } else if (!this.$isNumeric(this.maxHours)) {
                this.scrollToField('minHours');
                this.validationErrors.minHours = 'Just numeric value is allowed in max hours';
            } else if (Number(this.minHours) > Number(this.maxHours)) {
                this.scrollToField('maxHours');
                this.validationErrors.maxHours = 'Max hours should be greater than Min hours';
            } else if (Number(this.minHours) <= 0 || Number(this.maxHours) <= 0) {
                this.scrollToField('maxHours');
                this.validationErrors.maxHours = 'Min and Max hours must be greater than 0';
            }
            if (this.recommendationType == 'allvolunteer') {
                if (!this.maleVolunteers) {
                    this.scrollToField('maleVolunteers');
                    this.validationErrors.maleVolunteers = 'Male volunteer is required';
                } else if (!this.$isNumeric(this.maleVolunteers)) {
                    this.scrollToField('maleVolunteers');
                    this.validationErrors.maleVolunteers = 'Just numeric value is allowed in male volunteer';
                }
                if (!this.femaleVolunteers) {
                    this.scrollToField('femaleVolunteers');
                    this.validationErrors.femaleVolunteers = 'Female volunteers is required';
                } else if (!this.$isNumeric(this.femaleVolunteers)) {
                    this.scrollToField('femaleVolunteers');
                    this.validationErrors.femaleVolunteers = 'Just numeric value is allowed in female volunteer';
                }
                if (!this.minAge) {
                    this.scrollToField('minAge');
                    this.validationErrors.minAge = 'Min age is required';
                } else if (!this.$isNumeric(this.minAge)) {
                    this.scrollToField('minAge');
                    this.validationErrors.minAge = 'Just numeric value is allowed in min age';
                } else if (this.minAge < 10) {
                    this.scrollToField('minAge');
                    this.validationErrors.minAge = 'Min age should be at least 10';
                }
                if (!this.maxAge) {
                    this.scrollToField('maxAge');
                    this.validationErrors.maxAge = 'Max age is required';
                } else if (!this.$isNumeric(this.maxAge)) {
                    this.scrollToField('maxAge');
                    this.validationErrors.maxAge = 'Just numeric value is allowed in max age';
                } else if (this.maxAge <= this.minAge) {
                    this.scrollToField('maxAge');
                    this.validationErrors.maxAge = 'Max age should be greater than Min age';
                } else if (this.maxAge > 70) {
                    this.scrollToField('maxAge');
                    this.validationErrors.maxAge = 'Max age should be less than 70';
                }
                if (!this.selectedEducation) {
                    this.scrollToField('selectedEducation');
                    this.validationErrors.selectedEducation = 'Education is required';
                }
                if (!this.selectedEmploymentStatus) {
                    this.scrollToField('selectedEmploymentStatus');
                    this.validationErrors.selectedEmploymentStatus = 'Employment status is required';
                }
                if (!this.selectedCauses) {
                    this.scrollToField('selectedCauses');
                    this.validationErrors.selectedCauses = 'Causes is required';
                }
                if (!this.selectedSkills) {
                    this.scrollToField('selectedSkills');
                    this.validationErrors.selectedSkills = 'Skills is required';
                }
            }
            if (this.contactDetails !== 'sameContact') {
                if (!this.contactPersonName || this.contactPersonName.trim() === "") {
                    this.validationErrors.contactPersonName = 'Contact person name is required';
                }
                if (!this.contactPersonEmail || this.contactPersonEmail.trim() === "") {
                    this.validationErrors.contactPersonEmail = 'Contact person email is required';
                }
                if (!this.contactPersonPhone || this.contactPersonPhone.trim() === "") {
                    this.validationErrors.contactPersonPhone = 'Contact person phone is required';
                }
            }
            return Object.keys(this.validationErrors).length === 0;
        },
        scrollToField(refName) {
            const element = this.$refs[refName];
            if (element && element.$el) {
                element.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
        async createActivity() {
            this.showSpinner = true;
            if (!this.validateData()) {
                this.showSpinner = false;
                this.$refs.toast.add({ severity: 'error', summary: 'Oops...', detail: 'Something went wrong', life: 3000 });
                return;
            }
            var venueTypeID = 0;
            if (this.venueType == 'onField') {
                venueTypeID = 0;
            }
            else if (this.venueType == 'virtual') {
                venueTypeID = 1;
            }
            else if (this.venueType == 'other') {
                venueTypeID = 2;
            }

            var contactDetailsType = 0;
            if (this.contactDetails == 'sameContact') {
                contactDetailsType = 0;
            } else if (this.contactDetails !== 'sameContact') {
                contactDetailsType = 1;
            }

            var volunteer_type;
            if (this.recommendationType == 'myVolunteer') {
                volunteer_type = 'myvolunteer';
            } else if (this.recommendationType == 'allvolunteer') {
                volunteer_type = 'allvolunteer';
            }

            const params = {
                volunteer_type: volunteer_type,
                contactDetail: contactDetailsType,
                check_in_from_anywhere: venueTypeID,
                title: this.activityName,
                event_type_id: this.selectedActivityType ? this.selectedActivityType.id : null,
                about: this.describeYourActivity,
                province_id: this.selectedLocation.province_id,
                city_id: this.selectedLocation.city_id,
                contact_person_name: this.contactPersonName,
                email: this.contactPersonEmail,
                phone: this.contactPersonPhone,
                venue: this.selectedLocation.venue,
                hashtag: this.hashtag,
                tags: this.tags,
                start_date: this.startDate ? this.$formatDate(this.startDate) : null,
                end_date: this.endDate ? this.$formatDate(this.endDate) : null,
                start_time: this.startTime ? this.$getOnlyTimeFromDate(this.startTime) : null,
                end_time: this.endTime ? this.$getOnlyTimeFromDate(this.endTime) : null,
                min_hours: this.minHours,
                max_hours: this.maxHours,
                no_of_male_volunteer: this.maleVolunteers,
                no_of_female_volunteer: this.femaleVolunteers,
                min_volunteer_age_range: this.minAge,
                max_volunteer_age_range: this.maxAge,
                volunteer_education_id: this.selectedEducation ? this.selectedEducation.id : null,
                causes: this.selectedCauses ? this.selectedCauses.map((edu) => edu.id) : null,
                skills: this.selectedSkills ? this.selectedSkills.map((skill) => skill.id) : null,
                volunteer_employment_id: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.id : null,
                locationsarray: this.selectedLocations,
                imageList: this.parentUploadedFiles,
            };
            let image = '';
            if (this.file) {
                try {
                    const image = await this.$uploadSingleImage(this.file, this.orgiginalIamge);
                    params.picture = image[0];
                    params.thumbnail_picture = image[1];
                } catch (error) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });
                    return;
                }
            }

            try {
                const res = await createActivity(params);
                if (res.status == 200) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Activity has been created',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = this.baseUrl + '/activities/' + res.data;
                        }
                    });
                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });
                }
            }
            catch (error) {
                // console.error(error);
                if (error.response && error.response.data && error.response.data.errors) {
                    // Backend validation errors are present
                    this.validationErrors = error.response.data.errors;
                } else {
                    this.errored = true;
                }
            } finally {
                setTimeout(() => {
                    this.showSpinner = false;
                }, 1500);
            }

        },
        toggleShow() {
            this.show = !this.show;
        },
        cropSuccess(dataUrl) {
            this.imgDataUrl = dataUrl;
            const result = this.$getFileFormat(dataUrl, this.filename, this.imageType).then(file => {
                this.file = file;
            })

        },
        handleFileSelected(event) {
            const selectedFile = event.target.files[0];
            this.orgiginalIamge = selectedFile;
            if (selectedFile) {
                const fileName = selectedFile.name;
                const fileType = selectedFile.type;
                this.filename = fileName
                this.imageType = fileType;
            }
        },
        totalVolunteersNumbers() {
            // this.totalVolunteers = parseInt(this.maleVolunteers) + parseInt(this.femaleVolunteers);
            const maleVolunteersValue = parseInt(this.maleVolunteers ? this.maleVolunteers : 0);
            const femaleVolunteersValue = parseInt(this.femaleVolunteers ? this.femaleVolunteers : 0);
            if (!isNaN(maleVolunteersValue) && !isNaN(femaleVolunteersValue)) {
                this.totalVolunteers = maleVolunteersValue + femaleVolunteersValue;
            }
        }
    }
}
</script>
<template>
    <section class="activities container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card">
                        <Sidebar v-model:visible="visible">
                            <div class="card-body">
                                <h3>Categories</h3>
                                <div class="d-flex flex-column align-items-start gap-2 mb-2 p-listbox border-0">
                                    <ul class="p-listbox-list">
                                        <li v-for="category in categories" :key="category.id"
                                            @click="selectCategory(category)" class="p-listbox-item">
                                            <router-link :to="'/blogs/category/' + category.slug">
                                                {{ category.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Sidebar>
                    </div>
                    <div class="card act-filter-options">
                        <div class="card-body">
                            <div class="blog-sidebar-content"
                                style="padding-top: 0px; padding-bottom: 1px; position: static; top: 80px; left: 1004.6px;">
                                <!-- <div id="search-2" class="widget widget_search">
                                    <form role="search" method="get" class="pc-searchform"
                                        action="https://adsterra.com/blog/">
                                        <div class="pc-searchform-inner">
                                            <input type="text" class="search-input p-inputtext p-component w-100"
                                                placeholder="Type and hit enter..." name="s">
                                            <i class="pi pi-search" style="font-size: 1rem"></i>
                                            <input type="submit" class="searchsubmit" value="Search">
                                        </div>
                                    </form>
                                </div> -->
                                <div class="widget blog_latest_news_widget">
                                    <div class="widget-title blog-border-arrow">
                                        <span class="inner-arrow">
                                            Latest Blogs
                                        </span>
                                    </div>
                                    <ul id="blog-latestwg-9898" class="side-newsfeed">
                                        <li class="blog-feed" v-if="latestBlog">
                                            <a :href="'/blogs/' + latestBlog.slug" rel="bookmark"
                                                title="{{ latestBlog.title }}">
                                                {{ latestBlog.title }}
                                            </a>
                                        </li>
                                        <li class="blog-feed" v-else>
                                            No latest blog found.
                                        </li>
                                    </ul>

                                </div>
                                <div id="categories-3" class="widget widget_categories">
                                    <div class="widget-title blog-border-arrow">
                                        <span class="inner-arrow">
                                            Categories
                                        </span>
                                    </div>
                                    <ul>
                                        <li v-for="category in categories" :key="category.id"
                                            @click="selectCategory(category)" class="cat-item cat-item-141 current-cat">
                                            <router-link :to="'/blogs/category/' + category.slug">
                                                {{ category.name }}
                                                <!-- <span class="category-item-count">(21)</span> -->
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 d-flex flex-column align-items-start gap-2">
                    <div class="card w-100">
                        <DataView :value="blogs" :layout="layout">
                            <template #header>
                                <div class="flex justify-content-between">
                                    <div class="d-flex align-items-center flex-wrap">
                                        <h3 class="mb-0 me-3">Our Blogs</h3>
                                        <Chip class="pl-0 pr-3">
                                            <span class="ml-2 font-medium small">
                                                <b>{{ blogs.length }}</b>
                                                Found
                                            </span>
                                        </Chip>
                                    </div>
                                    <div class="d-flex align-content-center gap-2">
                                        <Button class="mv-filter" icon="pi pi-th-large" @click="visible = true" />
                                    </div>
                                </div>
                            </template>

                            <template #list="slotProps">
                                <div v-for="(event, index) in slotProps.items" :key="index">
                                    <div v-if="loading" class="row w-100 mx-0 p-4 pb-0" style="z-index: 99;">
                                        <div class="d-flex mb-4">
                                            <div class="col-lg-5 px-0">
                                                <Skeleton class="w-100 border-round h-9rem" />
                                            </div>
                                            <div class="col-lg-7 px-0 gap-1">
                                                <Skeleton class="w-20rem border-round h-2rem my-1" />
                                                <div class="row flex-column mx-0 gap-2">
                                                    <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                    <Skeleton class="w-8rem border-round h-2rem" />
                                                </div>
                                            </div>
                                        </div>

                                        <Divider></Divider>
                                    </div>
                                    <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                        <div class="col-lg-4 col-md-12 text-center blog-image-box">
                                            <!-- <div v-if="event.banner || event.thumbnail_picture"
                                                class="blog-image preview"
                                                :style="{ 'background-image': 'url(' + this.imageurl + '/' + event.banner + ')' }">
                                            </div> -->
                                            <Image v-if="event.banner || event.banner" class="border-radius img-fluid"
                                                :style="{ 'background-image': 'url(' + this.imageurl + '/' + event.banner + ')' }"
                                                :src="this.imageurl + '/' + event.banner" preview />
                                            <!-- <Image v-if=" event.banner || event.thumbnail_picture "
                                                class="border-radius img-fluid"
                                                :src=" this.imageurl + '/' + event.banner " preview /> -->

                                            <img v-else src="@/assets/img/org_placeholder.png" class="img-fluid rounded"
                                                width="100" shape="circle" />
                                        </div>
                                        <div
                                            class="col-lg-8 col-md-8 col-sm-12 text-truncate fs-7 px-3 d-flex flex-column row-gap-2">

                                            <div class="content-list-right content-list-center">
                                                <div class="header-list-style">
                                                    <h3 title="" class="mb-0 mt-1 text-truncate-2 fw-bold">
                                                        <a :href="baseUrl + '/blogs/' + event.slug">
                                                            {{ event.title }}
                                                        </a>
                                                    </h3>
                                                    <div class="grid-post-box-meta">
                                                        <span class="divider">by
                                                            <router-link :to="'/blogs/category/' + event.category.slug"
                                                                class="text-primary font-12-mb"
                                                                @click="selectCategory(event.category)">
                                                                {{ event.category.name }}
                                                            </router-link>
                                                        </span>
                                                        <span class="contact-info">
                                                            Published on {{ dateTime(event.publish_at) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="item-content entry-content">
                                                    <p class="text-truncate-4 text-break">
                                                        {{ this.$stripHTML(event.description) }}
                                                    </p>
                                                </div>
                                                <a class="btn btn-primary-sm" :href="baseUrl + '/blogs/' + event.slug">
                                                    Read more
                                                    <i class="fa fa-angle-double-right"></i>
                                                </a>
                                                <!-- <div class="blog-box-grid">
                                                    <div class="blog-share-box">
                                                        <a class="post-share-facebook" aria-label="Share on Facebook"
                                                            target="_blank" rel="noreferrer" href="#">
                                                            <i class="fa-brands fa-facebook-f"></i>
                                                        </a>
                                                        <a class="post-share-twitter" aria-label="Share on Twitter"
                                                            target="_blank" rel="noreferrer" href="#">
                                                            <i class="fa-brands fa-twitter"></i>
                                                        </a>
                                                        <a class="post-share-linkedin" aria-label="Share on LinkedIn"
                                                            target="_blank" rel="noreferrer" href="#">
                                                            <i class="fa-brands fa-linkedin-in"></i>
                                                        </a>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <Divider class="mb-0"></Divider>
                                    </div>
                                </div>
                            </template>

                            <template #empty v-if="loading">
                                <div class="row w-100 mx-0 p-4 pb-0 mb-2" style="z-index: 99;">
                                    <div class="row mx-0 px-0 mb-4">
                                        <div class="col-lg-4">
                                            <Skeleton class="w-100 border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-8 gap-1">
                                            <Skeleton class="w-20rem border-round h-2rem my-1" />
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mx-0 px-0 mb-4">
                                        <div class="col-lg-4">
                                            <Skeleton class="w-100 border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-8 gap-1">
                                            <Skeleton class="w-20rem border-round h-2rem my-1" />
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mx-0 px-0 mb-4">
                                        <div class="col-lg-4">
                                            <Skeleton class="w-100 border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-8 gap-1">
                                            <Skeleton class="w-20rem border-round h-2rem my-1" />
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </template>
                        </DataView>
                        <div v-if="!loading && blogs.length <= 0" class="text-center">
                            <img src="../assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getBlogsPosts, getBlogsPostsByCategory } from "@/api";
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Image from 'primevue/image';
import Sidebar from 'primevue/sidebar';
import Menu from 'primevue/menu';
import Skeleton from 'primevue/skeleton';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import moment from "moment";


export default {
    components: {
        Button,
        Tag,
        Chip,
        Divider,
        Image,
        Sidebar,
        Menu,
        Skeleton,
        DataView,
        DataViewLayoutOptions,
    },
    data() {
        return {
            imageurl: null,
            baseUrl: null,
            blogs: [],
            categories: [],
            loading: true,
            visible: false,
            latestBlog: '',
        };
    },
    async mounted() {
        this.loading = true;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        this.baseUrl = window.location.origin;
        const slug = this.$route.params.slug;
        try {
            if (slug) {
                const res = await getBlogsPostsByCategory(slug);
                this.blogs = res.blogs;
                this.categories = res.categories;
            }
            else {
                const params = {
                    pageno: this.pageNo,
                    perpage: this.perPage
                };
                const res = await getBlogsPosts(params);
                this.blogs = res.blogs;
                this.categories = res.categories;
                if (this.blogs && this.blogs.length > 0) {
                    // Assuming created_at is a field in each blog post object
                    const latestBlog = this.blogs.reduce((prev, current) => {
                        return (new Date(current.publish_at) > new Date(prev.publish_at)) ? current : prev;
                    });
                    this.latestBlog = latestBlog;
                }
            }
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        async selectCategory(category) {
            this.loading = true;
            const res = await getBlogsPostsByCategory(category.slug);
            this.blogs = res.blogs;
            this.categories = res.categories;
            this.loading = false;
        },
        dateTime(value) {
            return moment(value).format("DD-MMM-YYYY");
        },
    }
}
</script>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import store from "./store";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "primeicons/primeicons.css";
import "./assets/css/utilities-media.css";
import PrimeVue from "primevue/config";
import LoadScript from "vue-plugin-load-script";
import { globalMethods } from "./assets/utilities";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import Tooltip from "primevue/tooltip";

// import VueObserveVisibility from 'vue3-observe-visibility';
// import firebaseMessaging from "../firebase";

const vueApp = createApp(App);
// vueApp.config.globalProperties.$messaging = firebaseMessaging;

vueApp.directive("tooltip", Tooltip);

Object.keys(globalMethods).forEach((methodName) => {
  vueApp.config.globalProperties["$" + methodName] = globalMethods[methodName];
});

vueApp
  .use(store)
  .use(router)
  .use(Vuex)
  .use(PrimeVue)
  .use(LoadScript)
  .use(VueSweetalert2)
  .use(ToastService)
  .use(Toast)
  .use(ToastPlugin)
  .use(Tooltip)

  // .use(VueObserveVisibility)
  .mount("#app");

<template>
    <div class="container-fluid p-3 text-center">

        <div class="row">
            <div class="col-12">
                <div class="alert alert-success" role="alert">
                    A fresh verification link has been sent to your email address.
                </div>

                <div class="verify-img pb-3">
                    <img src="asset('img/verify-email.png')" class="img-fluid" alt="verify email">
                </div>
                <h4 class="pb-3 font-weight-bold">Verify Your Email Address!</h4>
                <p class="mb-2">Before proceeding, please check your email for a verification link.</p>
                <p class="mb-2 pb-4">If you did not receive the email.</p>

                <button type="submit" class="btn btn-primary">Click here to request another</button>
            </div>
        </div>
    </div>
</template>

<script>
  import axios from "axios";
export default {
    data() {
      return {
        token: '$2y$10$y7Y5HOV01rZTWigtLf6E6eHb/vzEWmwloI8RcGEVdZK1UtN3YLfk6',
      };
    },
    async mounted() {
        this.verifyEmail();

    },
    methods: {
        async verifyEmail() {
            axios.post(process.env.VUE_APP_BASE_URL +'/api/password/verify-email', { token: this.token }).then(response => {
                console.log(response);
            }).catch(error => {
                console.error(error.response);
            });
        },
    },
};
</script>
<template>
    <div class="text-center">
      <img src="../assets/img/404.png" alt="Milkar.com">
      <h1>It seems you are offline.</h1>
      <p>Please make sure you have an active internet connection.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Offline'
};
  </script>  
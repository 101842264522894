<template>
    <section>
        <h3>Volunteer Reviews</h3>
        <div class="d-flex flex-column row-gap-5">
            <div class="d-flex flex-column row-gap-5">
                <div class="row justify-content-center d-flex mt-3 row-gap-3">
                    <div class="col-lg-4 col-md-6 col-sm-12 d-flex flex-column row-gap-2">
                        <div class="rating-box d-flex flex-column rounded">
                            <span class="fs-4 fw-bold">{{ avgRating ? avgRating.toFixed(1) : 0 }}</span>
                            <p class="mb-0"> Out of 5.0</p>
                        </div>
                        <div class="d-flex gap-1 justify-content-center text-primary">
                            <div v-if="reviews.length > 0" class="d-flex gap-1 justify-content-center text-primary">
                                <!-- Display filled stars -->
                                <span v-for="n in Math.floor(avgRating)" :key="n" class="fas fa-star filled"></span>
                                <!-- Display half-filled star if the fractional part is greater than 0 -->
                                <span v-if="avgRating % 1 !== 0" class="fas fa-star-half-alt filled"></span>
                                <!-- Display empty stars -->
                                <span v-for="n in 5 - Math.ceil(avgRating)" :key="'empty-' + n"
                                    class="fa-regular fa-star"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div v-for="star in reverseKeys(5)" :key="star"
                            class="d-flex align-items-center gap-2 rating-bar">
                            <div class="ratinng-box d-flex gap-1 justify-content-center text-primary">
                                <span v-for="n in star" :key="n" class="fas fa-star filled"></span>
                                <span v-for="n in 5 - star" :key="`empty-${n}`" class="fa-regular fa-star"></span>
                            </div>
                            <div class="w-100 ml-4">
                                <ProgressBar class="" :value="(starDistribution[star] / reviews.length) * 100">
                                </ProgressBar>
                            </div>
                            <div class="">{{ starDistribution[star] }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex-wrap row-gap-3 " v-if="volunteerReviewsData.length > 0">
                <div class="col-lg-6 col-md-6 col-sm-12 d-flex" v-for="review in displayedReviews" :key="review.id">
                    <div class="card bg-white rounded p-3 d-flex min-h-230">
                        <div class="d-flex align-items-start justify-content-between">
                            <div class="col-md-10 mb-2">
                                <p v-if="review.expanded" class="mb-0 custom-scroll" style="max-height: 75px;">
                                    {{ truncateText(this.$stripHTML(review.comment)) }}
                                </p>
                                <p v-else class="text-truncate-1 mb-0">
                                    {{ this.$stripHTML(review.comment) }}
                                </p>
                                <a v-if="isTruncated(review)" @click="toggleExpansion(review)"
                                    class="text-primary cursor-pointer fs-9 badge-custom">
                                    {{ review.expanded ? 'view less' : 'view more' }}
                                </a>
                            </div>
                            <div class="col-md-2 text-right">
                                <div class="ratinng-box d-flex flex-column text-primary row-gap-1 fs-8">
                                    <div class="d-flex justify-content-end text-primary">
                                        <span v-for="v in Math.floor(review.ratings.avgRating)" :key="v"
                                            class="fas fa-star filled"></span>
                                        <span v-if="review.ratings.halfRating % 1 !== 0"
                                            class="fas fa-star-half-alt filled"></span>
                                        <span v-for="v in Math.ceil(5 - review.ratings.avgRating)" :key="'empty-' + v"
                                            class="fa-regular fa-star"></span>
                                    </div>
                                    <span class="text-primary w-100">
                                        {{ review.createdDate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-section-right d-flex flex-wrap rounded shadow-sm p-2 row-gap-2 mt-auto">
                            <div class="col-lg-2 col-md-12 col-sm-12 px-0 d-flex justify-content-center flex-grow-1">
                                <img v-if="!review.events.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                                    class="activity-img-block img-fluid" />
                                <Image v-else class="border-radius img-fluid"
                                    :src="this.imageurl + '/' + review.events.thumbnail_picture"
                                    :alt="review.events.name" preview />
                            </div>
                            <div class="col-lg-10 col-md-10 col-sm-12 fs-7 px-3 d-flex flex-column row-gap-1">
                                <a :href="baseUrl + '/activities/' + review.events.slug"
                                    v-tooltip.top="review.events.name">
                                    <p class="fs-6 mb-0 fw-bold text-truncate-2 text-capitalize">
                                        {{ review.events.name }}
                                    </p>
                                </a>
                                <p class="mb-0 fs-7 text-truncate-3" v-tooltip.top="review.events.OwenerDetail[0].name">
                                    <span>
                                        {{ review.events.event_type.name }} by
                                    </span>
                                    <a v-if="review.events.OwenerDetail[0].isOrganization"
                                        :href="baseUrl + '/organizations/' + review.events.OwenerDetail[0].slug"
                                        class="text-primary ml-1"> {{ review.events.OwenerDetail[0].name }}
                                    </a>
                                    <a v-else :href="baseUrl + '/volunteers/' + review.events.OwenerDetail[0].slug"
                                        class="text-primary ml-1"> {{ review.events.OwenerDetail[0].name }}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <a v-promise-btn @click="viewMore" v-bind:href="`?page=${page+1}`"  class="btn btn-secondary disable_btn">
                    View More
                </a> -->
                <div class="row mx-0 w-100 text-center gap-2 justify-content-center">
                    <Button v-if="pageNo > 1" @click="loadPre" icon="pi pi-angle-left" aria-label="Submit"
                        class="btn btn-primary-sm" />
                    <Button v-else icon="pi pi-angle-left" aria-label="Submit" disabled class="btn btn-primary-sm" />
                    <Button v-if="hasMorePages" @click="loadMore" icon="pi pi-angle-right" aria-label="Submit"
                        class="btn btn-primary-sm" />
                    <Button v-else icon="pi pi-angle-right" aria-label="Submit" disabled class="btn btn-primary-sm" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getVolunteerReviews } from "@/api";
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import ProgressBar from 'primevue/progressbar';

export default {
    components: {
        Button,
        Image,
        ProgressBar,
    },
    data() {
        return {
            imageurl: null,
            reviews: [],
            loading: false,
            volunteerReviewsData: Array,
            avgRating: 0,
            fullRating: 0,
            halfRating: 0,
            remainingStars: 0,
            pageNo: 1,
            perPage: 2,
            isNextVisible: true,
        };
    },
    props: {
        volunteerSlug: {
            type: String,
            required: true
        },
    },
    computed: {
        starDistribution() {
            const distribution = {
                5: 0,
                4: 0,
                3: 0,
                2: 0,
                1: 0,
            };
            for (const review of this.reviews) {
                distribution[review.rating]++;
            }
            return distribution;
        },
        displayedReviews() {
            const startIndex = (this.pageNo - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return this.reviews.slice(startIndex, endIndex);
        },
        hasMorePages() {
            return this.pageNo * this.perPage < this.reviews.length;
        }
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const params = {
                slug: this.volunteerSlug
            };
            const res = await getVolunteerReviews(params);
            this.volunteerReviewsData = res;
            this.reviews = this.volunteerReviewsData;
            this.calculateRatings();
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {

        calculateRatings() {
            const totalRatings = this.reviews.reduce((total, review) => total + review.rating, 0);
            this.avgRating = totalRatings / this.reviews.length;
            this.fullRating = Math.floor(this.avgRating);
            this.halfRating = this.avgRating - this.fullRating;
            this.avgRating = Math.round(this.avgRating * 10) / 10;
            this.remainingStars = Math.floor(5 - this.fullRating - this.halfRating);
        },
        reverseKeys(n) {
            return Array.from({ length: n }, (_, i) => n - i);
        },
        loadMore() {
            this.pageNo++;
        },
        loadPre() {
            this.pageNo--;
        },
        truncateText(text) {
            const maxChars = 1000; // Maximum number of characters to show before truncating
            return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
        },
        isTruncated(review) {
            const maxChars = 150; // Maximum number of characters to show before truncating
            return review.comment.length > maxChars;
        },
        toggleExpansion(review) {
            review.expanded = !review.expanded;
        }
    }
};
</script>

<template>
    <div class="col-lg-12 px-0 text-left d-flex flex-column row-gap-2">
        <div class="text-right box mb-3">
            <img v-if="!volunteer.profile_picture_thumb" :src="this.$volunteerNoImage(this.volunteer.gender)"
                class="activity-img-block img-fluid rounded" />
            <Image v-else class="activity-img-block img-fluid rounded"
                :src="this.$volunteerImageUrl(volunteer.profile_picture_thumb)" :alt="volunteer.slug" preview />
            <span v-if="volunteer.is_completed" class="ribbon ribbon-top-left" title="">
                <span class="bg-label-success">Verified</span>
            </span>
            <span v-else class="ribbon ribbon-top-left" title="">
                <span class="bg-label-warning">Unverified</span>
            </span>


        </div>
        <div class="">
            <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-2">
                {{ userName }}
            </h3>
            <div class="d-flex align-items-start flex-column justify-content-between row-gap-2">
                <div class="d-flex align-items-center justify-content-start gap-1 text-capitalize text-muted fs-7">
                    <span>{{ this.volunteer.genderStr }}</span>
                    <span v-if="this.volunteer.dob"><span>| </span>{{ this.$getAge(this.volunteer.dob) }}</span>
                    <span v-if="this.cityName"><span>| </span>{{ this.cityName }}</span>
                </div>
                {{ this.avgRating }}

                <div class="ratinng-box d-flex justify-content-start">
                    <div v-if="this.rating > 0"
                        class="ratinng-box d-flex flex-row align-items-start text-primary gap-1 fs-8">
                        <div class="d-flex justify-content-end text-primary">
                            <i v-for="(rating, index) in this.volunteer.ratings.fullRating" class="fas fa-star rating"
                                :key="index"></i>
                            <i v-if="this.volunteer.ratings.halfRating" class="fas fa-star-half-alt rating"></i>
                            <i v-for="(rating, index) in this.volunteer.ratings.zeroRating" class="fa-regular fa-star"
                                :key="index"></i>
                        </div>
                        <span class="text-secondary fs-9">
                            ({{ this.rating.toFixed(1) }} out of 5.0)
                        </span>
                    </div>
                    <div v-else class="d-flex align-items-start justify-content-start flex-grow-1 gap-1">
                        <div class="d-flex align-items-start text-muted opacity-50 fs-8">
                            <span class="fa-regular fa-star"></span>
                            <span class="fa-regular fa-star"></span>
                            <span class="fa-regular fa-star"></span>
                            <span class="fa-regular fa-star"></span>
                            <span class="fa-regular fa-star"></span>
                        </div>
                        <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-gap-0 mx-0">
            <div class="d-flex mx-0 gap-2 flex-nowrap px-0">
                <Button type="button" @click="downloadPdf"
                    class="btn btn-primary-sm w-100 d-inline-flex align-items-center gap-1" :disabled="showSpinner"><i
                        v-if="!showSpinner" class="fa fa-solid fa-file-arrow-down me-1"></i>CV
                    <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8" fill="transparent"
                        animationDuration="2s" aria-label="Custom ProgressSpinner" />
                </Button>
                <a v-if="this.volunteer.is_activity_creator" :href="baseUrl + '/volunteer-dashboard/create-activity'"
                    class="btn btn-secondary-sm w-100 d-flex align-items-center gap-2 flex-wrap">
                    <i class="pi pi-plus"></i> Cause
                </a>

                <Button class="btn btn-primary-sm w-50" type="button" icon="pi pi-share-alt" @click="toggle"
                    aria-haspopup="true" aria-controls="overlay_menu" />
                <Toast />
                <Menu ref="menu" id="overlay_menu" class="p-menu p-component" :model="socialitems" :popup="true">
                    <template #item="{ label, item, props }">
                        <a @click="dynamicURL(item)" target="_blank" class="p-menuitem-link">
                            <span v-bind="props.icon" /> {{ label }}
                        </a>
                    </template>
                </Menu>
            </div>
        </div>
        <div class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right mb-2">
            <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                <span class="badge-counter-text">
                    Activities Volunteered:
                    <span v-if="this.volunteer.totalActivities > 0" class="counter">
                        {{ this.volunteer.totalActivities }}
                    </span>
                    <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                        N/A
                    </span>
                </span>
                <span class="badge-counter-text">
                    Reviews Received:
                    <span v-if="this.volunteer.totalReviewsReceived > 0" class="counter">
                        {{ this.volunteer.totalReviewsReceived }}
                    </span>
                    <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                        N/A
                    </span>
                </span>
                <span class="badge-counter-text">
                    Referral Count:
                    <span v-if="this.volunteer.referralsCount > 0" class="counter">
                        {{ this.volunteer.referralsCount }}
                    </span>
                    <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                        N/A
                    </span>
                </span>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-lg-12 px-0">
                <label class="fs-8 text-muted">Degree</label>
                <p v-if="field" class="fs-bold mb-0">{{ field }}</p>
                <p v-else class="fs-bold mb-0"> N/A </p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-lg-12 px-0">
                <label class="fs-8 text-muted">Field</label>
                <p v-if="degree" class="fs-bold mb-0">{{ degree }}</p>
                <p v-else class="fs-bold mb-0"> N/A </p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-lg-12 px-0">
                <label class="fs-8 text-muted">University</label>
                <p v-if="universityName" class="fs-bold mb-0">{{ universityName }}</p>
                <p v-else class="fs-bold mb-0"> N/A </p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-lg-12 px-0">
                <label class="fs-8 text-muted">Employement Status</label>
                <p v-if="employmentStatus" class="fs-bold mb-0">{{ employmentStatus }}</p>
                <p v-else class="fs-bold mb-0"> N/A </p>
            </div>
        </div>
        <div class="row fs-7">
            <label class="text-muted fs-8">Socials</label>
            <div class="d-flex align-items-center social-icon"
                v-if="this.volunteer.facebook || this.volunteer.twitter || this.volunteer.linkedin || this.volunteer.instagram || this.volunteer.snapchat">
                <a v-if="this.volunteer.facebook" :href="this.volunteer.facebook">
                    <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a v-if="this.volunteer.twitter" :href="this.volunteer.twitter">
                    <i class="fa-brands fa-twitter"></i>
                </a>
                <a v-if="this.volunteer.linkedin" :href="this.volunteer.linkedin">
                    <i class="fa-brands fa-linkedin-in"></i>
                </a>
                <a v-if="this.volunteer.instagram" :href="this.volunteer.instagram">
                    <i class="fa-brands fa-instagram"></i>
                </a>
                <a v-if="this.volunteer.snapchat" :href="this.volunteer.snapchat">
                    <i class="fa-brands fa-snapchat"></i>
                </a>
            </div>
            <div v-else class="d-flex align-items-center social-icon">
                <p class="fs-bold mb-0"> N/A </p>
            </div>
        </div>

    </div>
</template>
<script>
import { getVolunteer, downloadVolunteerCv } from "@/api";
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Menu from "primevue/menu";
import { useToast } from 'primevue/usetoast';
import ProgressSpinner from 'primevue/progressspinner';



export default {
    name: "VolunteerInfo",
    components: {
        // HomeComponent,
        Button,
        Image,
        Menu,
        useToast,
        ProgressSpinner,

    },
    props: ['slug'],
    data() {
        const vvolunteer = localStorage.getItem("volunteer");
        const parsedVolunteer = JSON.parse(vvolunteer);
        return {
            showSpinner: false,
            baseUrl: null,
            volunteer: parsedVolunteer ? parsedVolunteer : [],
            loading: false,
            userName: '',
            universityName: '',
            cityName: '',
            degree: '',
            employmentStatus: '',
            field: '',
            rating: 0,
            user: null,
            loading: true, // To show loading message
            errored: false, // To show error message
            socialitems: [
                {
                    label: 'Invite Friend',
                    icon: 'pi pi-whatsapp',
                },
                {
                    label: 'Facebook',
                    icon: 'pi pi-facebook',
                },
                {
                    label: 'Twitter',
                    icon: 'pi pi-twitter',

                },
                {
                    label: 'Copy Link',
                    icon: 'pi pi-copy',

                },
                {
                    label: 'Copy Referral Link',
                    icon: 'pi pi-copy',

                }
            ],
        };
    },

    async mounted() {
        this.baseUrl = window.location.origin;
        try {
            this.loading = true;
            const res = await getVolunteer(this.volunteer.slug);
            this.volunteer = res;
            if (this.volunteer.university) {
                this.universityName = this.volunteer.university.name;
            }

            if (this.volunteer.user) {
                this.userName = this.volunteer.user.name;
            }
            if (this.volunteer.ratings) {
                this.rating = this.volunteer.ratings.avgRating;
            }
            if (this.volunteer.city) {
                this.cityName = this.volunteer.city.name;
            }
            if (this.volunteer.education_field) {
                this.degree = this.volunteer.education_field.name;
            }
            if (this.volunteer.education) {
                this.field = this.volunteer.education.name;
            }
            if (this.volunteer.employment) {
                this.employmentStatus = this.volunteer.employment.name;
            }
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        toastShow() {
            this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
        },
        dynamicURL(item) {
            if (item.label == 'Invite Friend') {
                const urlParam = 'https://api.whatsapp.com/send?text=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                window.open(urlParam, '_blank');
            }
            if (item.label == 'Facebook') {
                const urlParam = 'https://www.facebook.com/sharer/sharer.php?u=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                window.open(urlParam, '_blank');
            }
            if (item.label == 'Twitter') {
                const urlParam = 'https://twitter.com/intent/tweet?text=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                window.open(urlParam, '_blank');
            }
            if (item.label == 'Copy Link') {
                const copyurl = this.baseUrl + '/volunteers/' + this.volunteer.slug;
                const textarea = document.createElement('textarea');
                textarea.value = copyurl;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            }
            if (item.label == 'Copy Referral Link') {
                const copyrefurl = this.baseUrl + '/r/' + this.volunteer.rcode;
                const textarea2 = document.createElement('textarea');
                textarea2.value = copyrefurl;
                document.body.appendChild(textarea2);
                textarea2.select();
                document.execCommand('copy');
                document.body.removeChild(textarea2);
            }

        },
        async downloadPdf() {
            this.showSpinner = true;
            try {
                const res = await downloadVolunteerCv();
                const blob = new Blob([res], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = this.volunteer.slug + '-cv.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading PDF', error);
            } finally {
                setTimeout(() => {
                    this.showSpinner = false;
                }, 1500);
            }
        },
    }
};
</script>
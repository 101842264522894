<template>
    <section class="container-fluid mt-4 mb-3">
        <div class="row mx-0 flex justify-content-between my-3">
            <div class="col-lg-12 d-flex align-items-center flex-wrap justify-content-center">
                <h3 class="">
                    Our Press
                </h3>
            </div>
        </div>

        <div class="press-boxed team-fixed-size">
            <div class="item d-flex card m-2 rounded overflow-hidden" v-for="(pr, index) in press" :key="index">
                <div class="p-2 flex-column justify-content-start align-items-start">
                    <div v-if="loading" class="row">
                        <div class="col-lg-12 px-0">
                            <div class="card-body d-flex flex-column row-gap-2">
                                <Skeleton class="w-100 border-round h-3rem" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="row mx-0">
                        <div class="p-2">
                            <Image v-if="pr.banner" class="border-radius img-fluid"
                                :style="{ 'background-image': 'url(' + this.imageurl + '/' + pr.banner + ')' }"
                                :src="this.imageurl + '/' + pr.banner" preview />
                            <img v-else src="@/assets/img/org_placeholder.png" class="img-fluid rounded" width="100"
                                shape="circle" />

                            <h3 title="" class="mb-0 mt-1 text-truncate-1 fw-bold fs-6">
                                <a title="" :href="pr.link" v-tooltip.top="pr.title">
                                    {{ pr.title }}
                                </a>
                            </h3>
                            <p class="fs-8 text-muted mt-1 mb-2">
                                <span class="contact-info">
                                    Published on {{ dateTime(pr.publish_at) }}
                                </span>
                                <!-- <span class="fs-9 mr-1">Publish at</span> {{ pr.publish_at }} -->
                            </p>
                            <div class="text-box-height-50 mt-2">
                                <p class="text-truncate-2 mb-2">
                                    {{ this.$stripHTML(pr.description) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 ml-auto mr-auto">
                        <a title="View Activity" :href="pr.link"
                            class="btn btn-primary-sm fs-8 d-flex align-items-center flex-auto">
                            <i class="fa-solid fa-eye pr-1"></i>View
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getPress } from "@/api";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Skeleton from 'primevue/skeleton';
import moment from "moment";


export default {
    components: {
        Button,
        Image,
        Skeleton,
    },
    data() {
        return {
            imageurl: null,
            press: [],
            loading: false,
        };
    },
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = false;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getPress(params);
            this.press = res.data;
            // console.log(this.press);
            this.loading = false;
        } catch (error) {
            zl
            console.error(error);
        }
    },
    methods: {
        dateTime(value) {
            return moment(value).format("DD-MMM-YYYY");
        },
    }
}
</script>
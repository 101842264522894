<template>
    <section class="activities container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3" style="z-index: 99;">
                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-2">
                            <div class="row px-2 row-gap-2">
                                <div class="col-lg-6 px-1">
                                    <Skeleton class="w-100 border-round h-3rem" />
                                </div>
                                <div class="col-lg-6 px-1">
                                    <Skeleton class="w-100 border-round h-3rem" />
                                </div>
                            </div>
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-1">
                            <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
                                <Skeleton class="w-20rem border-round h-3rem" />
                                <Skeleton class="w-6rem border-round h-3rem" />
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2 my-2">
                                <div class="col-lg-2 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2 my-2">
                                <div class="col-lg-2 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2 my-2">
                                <div class="col-lg-2 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import Divider from 'primevue/divider';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeflex/primeflex.css';

export default {
    components: {
        Skeleton,
        Divider,
    },
    name: "ListingSkeleton",
    data() {
        return {
        }
    },
    computed: {

    },
    async mounted() {

    },
    watch: {

    },
    methods: {

    },
}
</script>

<template>
    <section id="vueContent" class="institute container-fluid py-3">

        <div class="container-xxxl">
            <div class="alert alert-success" style="display: none">
                <strong>Success</strong>
            </div>
            <div class="card col-lg-12 pb-4">
                <div v-if="loadingOurProjects" class="row row-gap-3 p-4" style="z-index: 99;">
                    <div class="col-lg-12">
                        <Skeleton class="w-100 border-round h-100" />
                    </div>
                    <div class="col-lg-12 px-1 d-flex flex-column row-gap-3">
                        <Skeleton class="w-100 border-round h-2rem" />
                        <Skeleton class="w-100 border-round h-2rem" />
                    </div>
                    <div class="col-lg-12 px-1 d-flex flex-column row-gap-3">
                        <Skeleton class="w-100 border-round h-3rem" />
                        <Skeleton class="w-100 border-round h-2rem" />
                    </div>
                </div>
                <div v-else class="card-body row mx-0 row-gap-5">
                    <div v-for="(project, index) in OurProjectsData" :key="index">
                        <div class="col-lg-12 border-bottom">
                            <div class="text-center py-3 mb-3">
                                <h3 class="mb-0 me-3">
                                    {{ project.title }}
                                </h3>
                            </div>
                            <p class="our-Project text-center">
                                {{ this.$stripHTML(project.description) }}
                            </p>
                        </div>
                    </div>
                    <div v-for="(content, index) in projectContent" :key="index">
                        <div class="col-lg-12 border-bottom">
                            <div class="text-center py-3 mb-3">
                                <h3 class="mb-0 me-3">
                                    {{ content.title }}
                                </h3>
                            </div>
                            <p class="our-Project text-center">
                                {{ this.$stripHTML(content.description) }}
                            </p>
                            <div class="bg-black">
                                <iframe width="100%" height="315" :src="content.video_url" title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="hasMoreVolunteers" class="text-center">
                    <button @click="loadMoreVolunteers" class="btn btn-primary" :disabled="showSpinner">
                        View More
                        <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                            fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getOurProjects } from "@/api";
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';

export default {
    name: "OurProjects",
    components: {
        ProgressSpinner,
        Skeleton,
    },
    props: {},
    data() {
        return {
            imageurl: null,
            OurProjectsData: [],
            projectContent: [],
            loadingOurProjects: true, // To show loading message
            errored: false, // To show error message
            projectContentCount: 0,
            pageNo: 1,
            perPage: 12,
            showSpinner: false,
        };
    },
    computed: {
        hasMoreVolunteers() {
            return this.projectContentCount > this.perPage * this.pageNo;
        },
    },
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getOurProjects(params);
            this.OurProjectsData = res.ourProjects.data;
            this.projectContent = res.projectContent.data;
            this.projectContentCount = res.projectContentCount;
            this.loadingOurProjects = false;
        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loadingOurProjects = false;
        }
    },
    methods: {
        async loadMoreVolunteers() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    pageno: this.pageNo + 1,
                    perpage: this.perPage,
                };
                const res = await getOurProjects(params);
                console.log(res);
                const newProjectContent = res.projectContent.data;
                this.projectContent = [...this.projectContent, ...newProjectContent];
                this.pageNo++;
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
    },
}
</script>
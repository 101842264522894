<template>
    <section class=" container-fluid py-3">
        <div class="container-xxxl">
            <!-- <Breadcrumb :home="home" :model="items" class="text-capitalize mb-3">
                <template #item="{ label, item, props }">
                    <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
                        <a :href="routerProps.href" v-bind="props.action">
                            <span v-bind="props.icon" class="text-primary" />
                            <span v-bind="props.label">{{ label }}</span>
                        </a>
                    </router-link>
                    <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                        <span v-if="item.icon" v-bind="props.icon" />
                        <span v-bind="props.label">{{ label }}</span>
                    </a>
                </template>
</Breadcrumb> -->
            <div v-if="pageLoading" class="row row-gap-3" style="z-index: 99;">
                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-2">
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-1">
                            <div class="d-flex align-items-center justify-content-between gap-2">
                                <Skeleton class="w-20rem border-round h-3rem" />
                                <Skeleton class="w-6rem border-round h-3rem" />
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2">
                                <div class="col-lg-2">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2">
                                <div class="col-lg-2">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card">
                        <Sidebar v-model:visible="visible">
                            <div class="card-body">
                                <h3 class="">Filter Options</h3>
                                <div class="d-flex flex-column align-items-start gap-2 mb-2">
                                    <InputText v-model="OrganizationName" type="text" placeholder="Name"
                                        class="w-100" />
                                    <Dropdown v-model="selectedProvince" :options="provinces" optionLabel="name"
                                        placeholder="Province" class="w-100" />
                                    <Dropdown v-model="selectedCity" :options="cities" optionLabel="name"
                                        placeholder="City" class="w-100" :disabled="!selectedProvince" />
                                    <Dropdown v-model="selectedOrganizationType" :options="organizationTypes"
                                        optionLabel="name" placeholder="Organization Type" class="w-100" />
                                </div>
                                <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                                    @click="searchOrganizations" />
                                <Button type="button" class="btn btn-secondary w-100" label="Reset Filter"
                                    icon="pi pi-times" @click="resetFilters" />
                            </div>
                        </Sidebar>
                    </div>
                    <div class="card act-filter-options">
                        <div class="card-body">
                            <h3 class="">Filter Options</h3>
                            <div class="d-flex flex-column align-items-start gap-2 mb-2">
                                <InputText v-model="OrganizationName" type="text" placeholder="Name" class="w-100" />
                                <Dropdown v-model="selectedProvince" :options="provinces" optionLabel="name"
                                    placeholder="Province" class="w-100" />
                                <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                                    class="w-100" :disabled="!selectedProvince" />
                                <Dropdown v-model="selectedOrganizationType" :options="organizationTypes"
                                    optionLabel="name" placeholder="Organization Type" class="w-100" />
                            </div>
                            <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                                @click="searchOrganizations" />
                            <Button type="button" class="btn btn-secondary w-100" label="Reset Filter"
                                icon="pi pi-times" @click="resetFilters" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 d-flex flex-column align-items-start gap-2">
                    <div class="card w-100">
                        <DataView :value="organizations" :layout="layout">

                            <template #header>
                                <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                                    <div
                                        class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                        <h3 class="mb-0 me-3">Partner Organizations</h3>
                                        <Chip class="pl-0 pr-3">
                                            <span class="ml-2 font-medium small">
                                                <b>{{ organizationsCount }}</b>
                                                Found
                                            </span>
                                        </Chip>
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap">
                                        <div
                                            class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                            <Button type="button" class="btn btn-primary-sm"
                                                label="Mark Completed All" />
                                        </div>
                                        <div
                                            class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-grow-0 flex-sm-nowrap flex-wrap">
                                            <!-- <div class="p-float-label sortable-select w-100">
                                                <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                                    placeholder="Sort Activities" @change="onSortChange($event)"
                                                    class="w-100" />
                                            </div> -->
                                            <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                            <Button class="mv-filter" icon="pi pi-filter-fill"
                                                @click="visible = true" />
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #list="slotProps">
                                <div v-for="(event, index) in slotProps.items" :key="index">
                                    <div v-if="loading" class="row w-100 mx-0 p-4" style="z-index: 99;">
                                        <div class="col-lg-2 px-0">
                                            <Skeleton class="w-8rem border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-7 px-0 gap-1">
                                            <Skeleton class="w-8rem border-round h-2rem my-1" />
                                            <div class="row mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                                <Skeleton class="w-4rem border-round h-2rem" />
                                            </div>
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-20rem border-round h-2rem" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3 px-0">
                                            <Skeleton class="w-100 border-round h-9rem" />
                                        </div>
                                    </div>
                                    <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                        <div class="col-lg-2 col-md-12 text-center px-0">
                                            <img v-if="!event.logo_thumb" src="@/assets/img/org_placeholder.png"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.logo_thumb" :alt="event.user_v2?.name"
                                                preview />
                                        </div>
                                        <div class="col-lg-7 col-md-7 col-sm-12 text-truncate fs-7 px-3 mb-2 text-left">
                                            <a :href="baseUrl + '/organizations/' + event.slug">
                                                <h3 title="" class="mb-2 mt-1 text-truncate-1 fw-bold"
                                                    v-tooltip.top="event.user_v2?.name">
                                                    {{ event.user_v2?.name }}
                                                </h3>
                                            </a>
                                            <div class="m-0 d-flex flex-column align-items-start row-gap-1">
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">Type</label>
                                                    <span class="fw-bold">
                                                        {{ event.type?.name }}
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">City</label>
                                                    <span class="fw-bold">
                                                        {{ event.city?.name }}
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">Address</label>
                                                    <span class="fw-bold text-truncate-1" v-tooltip.top="event.address">
                                                        {{ event.address }}
                                                    </span>
                                                </span>
                                            </div>

                                        </div>
                                        <div
                                            class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                            <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                                <span class="badge-counter-text">
                                                    Activities Organized:
                                                    <span v-if="event.eventsCount > 0" class="counter">
                                                        {{ event.eventsCount }}
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                                <span class="badge-counter-text">
                                                    Volunteers Engaged:
                                                    <span v-if="event.volunteersEngagedCount > 0" class="counter">
                                                        {{ event.volunteersEngagedCount }}
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                            </div>
                                            <a title="View Activity" :href="baseUrl + '/organizations/' + event.slug"
                                                class="btn btn-primary w-100 px-1">
                                                <i class="fa-solid fa-eye pr-1"></i>Visit Profile
                                            </a>
                                        </div>
                                        <Divider class="mb-0"></Divider>
                                    </div>
                                </div>
                            </template>

                            <template #grid="slotProps">
                                <div class="grid grid-nogutter">
                                    <div v-for="(event, index) in slotProps.items" :key="index"
                                        class="sm:col-12 lg:col-4 xl:col-4 p-2">
                                        <div
                                            class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                            <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                                <div class="col-lg-4 px-1">
                                                    <Skeleton class="w-100 border-round h-100" />
                                                </div>
                                                <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                </div>
                                                <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-3rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                </div>
                                            </div>
                                            <div v-else class="row mx-0 align-items-start flex-wrap row-gap-2">
                                                <div class="col-lg-4 px-0">
                                                    <img v-if="!event.logo_thumb" src="@/assets/img/org_placeholder.png"
                                                        class="activity-img-block border-radius img-fluid" />
                                                    <Image v-else class="border-radius img-fluid"
                                                        :src="this.imageurl + '/' + event.logo_thumb"
                                                        :alt="event.user_v2?.name" preview />
                                                </div>
                                                <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2">
                                                    <a :href="baseUrl + '/organizations/' + event.slug">
                                                        <h4 title=""
                                                            class="mb-1 mt-1 text-truncate-2 fw-bold text-capitalize"
                                                            v-tooltip.top="event.user_v2?.name">
                                                            {{ event.user_v2?.name }}
                                                        </h4>
                                                    </a>
                                                    <div class="m-0 d-flex flex-column align-items-start row-gap-1">
                                                        <span class="d-flex align-items-center fs-8">
                                                            <label class="fs-8 mr-1">Type:</label>
                                                            <span class="fw-bold">
                                                                {{ event.type?.name }}
                                                            </span>
                                                        </span>
                                                        <span class="d-flex align-items-center fs-8">
                                                            <label class="fs-8 mr-1">City:</label>
                                                            <span class="fw-bold">
                                                                {{ event.city?.name }}
                                                            </span>
                                                        </span>
                                                        <span class="d-flex align-items-center fs-8">
                                                            <label class="fs-8 mr-1">Address:</label>
                                                            <span class="fw-bold text-truncate-1"
                                                                v-tooltip.top="event.address">
                                                                {{ event.address }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-12 card-section-right-sm d-flex align-items-center justify-content-center card mt-2 shadow-none">
                                                    <div class="row mx-0 flex-wrap w-100 justify-content-center">
                                                        <div
                                                            class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                                            <span class="badge-counter-text fs-8">Activities Organized:
                                                                <span v-if="event.eventsCount > 0" class="counter fs-7">
                                                                    {{ event.eventsCount }}
                                                                </span>
                                                                <span v-else class="counter fs-7 text-muted"
                                                                    v-tooltip.bottom="'Not Added'">
                                                                    N/A
                                                                </span>
                                                            </span>

                                                            <span class="badge-counter-text fs-8">
                                                                Volunteers Engaged:
                                                                <span v-if="event.volunteersEngagedCount > 0"
                                                                    class="counter fs-7">
                                                                    {{ event.volunteersEngagedCount }}
                                                                </span>
                                                                <span v-else class="counter fs-7 text-muted"
                                                                    v-tooltip.bottom="'Not Added'">
                                                                    N/A
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 px-0 d-flex align-items-center gap-2 mt-1">
                                                    <a title="View Activity"
                                                        :href="baseUrl + '/organizations/' + event.slug"
                                                        class="btn btn-primary-sm px-1 fs-7 flex-grow-1">
                                                        <i class="fa-solid fa-eye pr-1"></i> Visit Profile
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #footer v-if="hasMoreOrganizations">
                                <div class="p-4 text-center">
                                    <button @click="loadMoreOrganizations" class="btn btn-primary"
                                        :disabled="showSpinner">
                                        View More
                                        <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px"
                                            strokeWidth="8" fill="transparent" animationDuration="2s"
                                            aria-label="Custom ProgressSpinner" />
                                    </button>
                                </div>
                            </template>

                            <template #empty v-if="!loading">
                                <div class="text-center">
                                    <img src="../assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                                </div>
                            </template>
                        </DataView>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getOrganizationsList } from "@/api";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import Image from 'primevue/image';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Breadcrumb from 'primevue/breadcrumb';
import ProgressSpinner from 'primevue/progressspinner';


export default {
    components: {
        Dropdown,
        InputText,
        Button,
        Image,
        DataView,
        DataViewLayoutOptions,
        Sidebar,
        Skeleton,
        Chip,
        Divider,
        Breadcrumb,
        ProgressSpinner,

    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            pageNo: 1,
            perPage: 12,
            imageurl: null,
            OrganizationName: null,
            baseUrl: null,
            selectedProvince: null,
            selectedOrganizationType: null,
            selectedCity: null,
            organizations: [],
            organizationTypes: [],
            cities: [],
            provinces: [],
            organizationsCount: 0,
            layout: 'list',
            visible: false,
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ],
            showSpinner: false,
        };
    },
    computed: {
        hasMoreOrganizations() {
            return this.organizationsCount > this.perPage * this.pageNo;
        },
    },
    watch: {
        selectedProvince(newProvince) {
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                this.cities = [];
            }
        },
    },
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        this.baseUrl = window.location.origin;
        try {
            //this.loading = true;
            this.pageLoading = true;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getOrganizationsList(params);
            this.organizations = res.organizations.data;            
            this.organizationsCount = res.organizationsCount;
            this.provinces = res.provinves;
            this.allCities = res.cities;
            this.organizationTypes = res.organization_types;
            this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        stripHTML(html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || '';
        },
        async loadMoreOrganizations() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    pageno: this.pageNo + 1,  // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.OrganizationName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    organization_type_id: this.selectedOrganizationType ? this.selectedOrganizationType.id : null,
                };
                const res = await getOrganizationsList(params);
                const newOrganizations = res.organizations.data;
                this.organizations = [...this.organizations, ...newOrganizations];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        async searchOrganizations() {
            try {
                this.loading = true;
                const params = {
                    pageno: 1, // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.OrganizationName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    organization_type_id: this.selectedOrganizationType ? this.selectedOrganizationType.id : null,
                };
                const res = await getOrganizationsList(params);
                this.organizations = res.organizations.data;
                this.organizationsCount = res.organizationsCount;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.OrganizationName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedOrganizationType = null;
            this.searchOrganizations();
            this.loading = false;
        },
    }
}
</script>

// eventBus.js

import { reactive } from 'vue';

const state = reactive({
  lastActivityTime: Date.now(),
});

export function updateActivityTime() {
  state.lastActivityTime = Date.now();
}

export function getLastActivityTime() {
  return state.lastActivityTime;
}


<template>
    <div class="container mt-5">
        <div class="row justify-content-center">

            <div class="col-lg-8 mb-5">
                <h3 class="pb-4 font-weight-bold text-center">Copyright and Terms of Use</h3>

                <p>These are the Copyright and Terms of Use under which you may use the MILKAR MILKAR Volunteering service platform which comprises www.milkar.com (the ”Platform”).
                    Please read this carefully. If you do not accept the Copyright and Terms of Use stated herein, do not use the Platform. By using this Platform, you are indicating your acceptance to be bound by these Copyright and Terms of Use.</p>

                <h4>I. Copyright Notice</h4>
                © Copyright milkar.com. All rights reserved.
                None of the materials provided on this Platform may be used, reproduced or transmitted, in whole or in part, in any form or by any means, electronic or mechanical, including photocopying, recording or by any information storage and retrieval system, without permission in writing from the MILKAR. Requests for such permission and further inquiries should be sent to contact@milkar.com.
                The MILKAR, is the exclusive property of MILKAR. They are protected under international law. Unauthorized use is prohibited. They may not be copied or reproduced in any way without the prior written permission of MILKAR. Requests for permission should be sent to contact@milkar.com.

                <h4>II. Website Usage</h4>
                <h5>A. Definitions</h5>
                “MILKAR Volunteer” is a Registered User who has applied for at least one MILKAR Volunteering opportunity, and whose application has not been marked as rejected.
                “Organization” is an organization that has submitted its application to register with the Platform, whose application has been accepted by the Site Administrator, whose account is publicly viewable on the Platform and can submit MILKAR Volunteering opportunity postings.
                “Registered User” is an individual who has completed the registration process anywhere on the Platform.
                “User” means anyone who chooses to access this Platform.
                <h5>B. General</h5>
                1. Use of this Platform constitutes agreement with the Terms of Use contained herein.
                2. MILKAR maintains the Platform for the benefit of those who may choose to access the Platform ("User"). MILKAR grants permission to Users, when registered, to visit the Platform and upload, download and copy the information, documents and materials (collectively, "Content") from the Platform for the Registered User's personal, non-commercial use, without any right to resell, redistribute, compile or create derivative works therefrom, subject to the Terms of Use outlined below, and also subject to more specific restrictions that may apply to specific material within this Platform. The use of the Content on any other website or in a networked computer environment for any purpose is prohibited.
                3. MILKAR administers this Platform (the “Site Administrator”). All Content on this Platform and all Users to this Platform, whether registered or otherwise, are subject to these Terms of Use.
                4. Unless expressly stated otherwise, the User-submitted Platform content reflects the submissions of the Registered Users and does not necessarily represent the views of MILKAR.
                5. Although legal minors (age depends on country of residence/nationality) are allowed to access the Platform, in order for a User to become registered s/he has to be beyond the age of majority.

                <h5>C. Legal status</h5>

                1. The User, registered or otherwise, the User personnel and sub-Users shall not be considered in any respect as being the employee, staff or agent of MILKAR.
                2. An Milkar Volunteer shall not be deemed in any respect as being a Milkar Volunteer and as such shall not benefit from this status.
                3. An Organization account on the Platform shall neither be construed as an endorsement or recommendation on the part of MILKAR, nor as a partnership with MILKAR.

                <h5>D. Observance of the law</h5>

                The User, registered or otherwise, shall comply with all laws, ordinances, rules, and regulations bearing upon the performance of its obligations under the terms of this Agreement.
                <h5>E. Child labour</h5>

                The User, registered or otherwise, represents and warrants that neither s/he, nor any of her/his suppliers is engaged in any practice inconsistent with the rights set forth in the Convention on the Rights of the Child, including Article 32 thereof, which, inter alia, requires that a child shall be protected from performing any work that is likely to be hazardous or to interfere with the child's education, or to be harmful to the child's health or physical mental, spiritual, moral or social development.
                <h5>F. Disclaimers</h5>

                <ol>
                    <li>MILKAR is only providing a venue Platform allowing volunteering activities to be agreed upon between Organizations and Registered Users. This Platform acts as a venue for Organizations to post MILKAR Volunteering opportunities, and for Registered Users seeking MILKAR Volunteering opportunities to post their applications.</li>
                    <li>MILKAR is not involved in the actual arrangements between Registered Users and Organizations and declines all liability regarding the relationship between those entities.
                MILKAR assumes no control or liability over the quality, safety or legality of the MILKAR Volunteering opportunities or applications posted, the truth or accuracy of the listings, the ability of the Organization to offer MILKAR Volunteering opportunities to the individuals submitting applications, or the ability of such individuals to fill the MILKAR Volunteering opportunity openings.
                The details regarding a particular opportunity for which a Registered User applied, regarding, inter alia, deliverables (including copyright), scheduling, collaboration processes and prerequisites are agreed between the Organization and the Registered User. MILKAR is not involved in such arrangements and has no responsibility or liability in connection therewith.
                The Organization shall be responsible for verifying the accuracy and validity of the advice and/or deliverables it receives from MILKAR Volunteers. The MILKAR Volunteer shall not partake in any activities that could be detrimental to the Organization or to its objectives and the advice and/or deliverables the MILKAR Volunteer will provide to the Organization will reflect the full extent of her/his knowledge and experience. The Registered User shall not apply for opportunities where s/he does not have the appropriate skills or qualifications. The MILKAR Volunteer accepts sole responsibility in the case of her/his gross negligence or willful misconduct in connection with any MILKAR Volunteering opportunity.
                The User, registered or otherwise, agrees not to make any financial claims to other Users and/or to MILKAR.
                        In addition, please note that there are risks in using the Platform, and the User, registered or otherwise, assumes all risks associated with dealing with other Users, registered or otherwise, with whom s/he comes in contact through the Platform. MILKAR expects that the User, registered or otherwise, will use caution and common sense when using this Platform.</li>
                    <li>Because User authentication on the Internet is difficult, MILKAR cannot and does not confirm that each User, registered or otherwise, is who s/he claims to be. Because MILKAR does not and cannot be involved in user-to-user dealings or control the behavior of Users on the Platform, in the event that the User, registered or otherwise, has a dispute with one or more Users, registered or otherwise, the User releases MILKAR and its agents and employees from claims, demands, and damages actual and consequential, direct and indirect of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</li>
                    <li>Content may contain inaccuracies or typographical errors. Content provided on this Platform is provided "as is" without warranty of any kind, either express or implied, including without limitation warranties of merchantability, fitness for a particular purpose, and non-infringement. MILKAR specifically does not make any warranties or representations as to the accuracy or completeness of any such Content. MILKAR periodically adds, changes, improves, or updates the Content on this Platform without notice. Organizations are solely responsible for their postings on the Platform. MILKAR shall not be responsible for any decisions relating to engagement of Registered Users, for whatever reason made, by any entity posting MILKAR Volunteering opportunities on this Platform. Under no circumstances shall MILKAR be liable for any loss, damage, liability or expense incurred or suffered which is claimed to result from use of this Platform, including without limitation, any fault, error, omission, interruption or delay with respect thereto. Use of this Platform is at User's sole risk. Under no circumstances, including, but not limited to negligence, shall MILKAR or its affiliates be liable for any direct, indirect, incidental, special or consequential damages, even if MILKAR has been advised of the possibility of such damages.</li>
                    <li>The User, registered or otherwise, is responsible for her/his own communications and is responsible for the consequences of their posting. The User, registered or otherwise, specifically acknowledges and agrees that MILKAR is not liable for any conduct of any User, registered or otherwise.</li>
                    <li>Use of any password-protected area of the Platform is restricted to the Registered User who has registered or otherwise been given permission and a password to enter such area. The password cannot be distributed to others. The Registered User is responsible for maintaining the confidentiality of her/his information and password. The Registered User shall be responsible for all uses of her/his registration, whether or not authorized by him/her. The Registered User agrees to immediately notify MILKAR of any unauthorized use of her/his registration or password.</li>
                    <li>This Platform may contain advice, opinions, and statements, communications of various Users, registered or otherwise. MILKAR does not represent or endorse the accuracy or reliability of any advice, opinion, statement, communication or other information provided by any User, registered or otherwise, or other person or entity. Reliance upon any such advice, opinion, statement, communication or other information shall also be at the User’s, registered or otherwise, own risk. Neither MILKAR nor its affiliates, nor any of their respective agents, employees, information providers or content providers shall be liable to any User, registered or otherwise, or anyone else for any inaccuracy, error, omission, interruption, timeliness, completeness, deletion, defect, failure of performance, computer virus, communication line failure, alteration of, or use of any content herein, regardless of cause, for any damages resulting therefrom.</li>
                    <li>MILKAR acts as a passive conduit for the online distribution and publication of User-submitted information and has no obligation to screen communications or information in advance and is not responsible for screening or monitoring material posted by Users. If notified by a Registered User of communications which allegedly do not conform to these Terms of Use, MILKAR may investigate the allegation and determine in good faith and in its sole discretion whether to remove or request the removal of the communication. MILKAR has no liability to Users, registered or otherwise, for performance or non-performance of such activities. MILKAR reserves the right to expel Users, registered or otherwise, and prevent their further access to the Platform for violating the Terms of Use or applicable law and the right to remove communications, which are abusive, illegal, or disruptive. MILKAR may take any action with respect to User-submitted information that it deems necessary or appropriate in its sole discretion if it believes it may create liability or embarrassment for MILKAR.</li>
                    <li>The User, registered or otherwise shall indemnify, hold and save harmless, and defend, at her/his own expense, MILKAR, its officials, agents, servants and employees from and against all suits, claims, demands, and liability of any nature or kind, including their costs and expenses, arising out of acts or omissions of the User, or the User's employees, officers, agents or sub-Users, in the performance of this Agreement. This provision shall extend, inter alia, to claims and liability in the nature of workmen's compensation, products liability and liability arising out of the use of patented inventions or devices, copyrighted material or other intellectual property by the User, her/his employees, officers, agents, servants or sub-Users. The obligations under this Article do not lapse upon termination of this Agreement.</li>
                    <li> This Platform may contain links and references to third-party websites. The linked sites are not under the control of MILKAR and MILKAR is not responsible for the contents of any linked site or any link contained in a linked site. MILKAR provides these links only as a convenience, and the inclusion of a link or reference does not imply endorsement of the linked site by MILKAR. If the User, registered or otherwise, decides to access linked third party websites, the User does so at her/his own risk.</li>
                    <li> This Platform may be used only for lawful purposes consistent with these Terms of Use. This Platform may contain bulletin boards, blogs, access to mailing lists or other message or communication facilities (collectively, "Forums"). The User, registered or otherwise, agrees to use the Forums only to send and receive messages and material that are proper and related to the particular Forum. By way of example, and not as a limitation, the User, registered or otherwise, agrees not to do any of the following:</li>


                    <ol type="A">
                        <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</li>
                        <li>Publish, post, distribute or disseminate any incomplete, false or inaccurate biographical or organizational information or information which is not the User own accurate resume or organization description or any defamatory, infringing, obscene, indecent or unlawful material or information.</li>
                        <li>Post any franchise, pyramid scheme, club membership, distributorship or sales representative agency arrangement or other business opportunity which requires an up-front or periodic payment, pays commission only, requires recruitment of other members, sub-distributors or sub-agents.</li>
                        <li>Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless User owns or controls the rights thereto or has received all consents thereof as may be required by law.</li>
                        <li>Upload or attach files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer, service to any User, host or network, or use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any activity being conducted on this Platform, including, without limitation, via means of submitting a virus to any website, overloading, flooding, spamming, mail bombing or crashing.</li>
                        <li>Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded, or delete or revise any material posted by any other person or entity.</li>
                        <li>Falsify the origin or source of software or other material contained in a file that is uploaded.</li>
                        <li>Send unsolicited e-mail, advertise or offer to sell any goods or services, or conduct or forward surveys, contests, or chain letters, or download any file posted by another User of a Forum that User knows, or reasonably should know, cannot be legally distributed in such manner.  i. Violate or attempt to violate the security of the Platform, including without limitation (i) accessing data not intended for such User or logging into a server or account which the User is not authorized to access, (ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization, or (iii) forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting.</li>
                    </ol>
                    <li>The User, registered or otherwise, acknowledges that all Forums are public and not private communications. Further, the User, registered or otherwise, acknowledges that chats, postings, conferences, emails and other communications by other Users, registered or otherwise, are not endorsed by MILKAR, and such communications shall not be considered reviewed, screened, or approved by MILKAR. MILKAR reserves the right for any reason to remove without notice any contents of the Forums received from the User, registered or otherwise, including without limitation email and bulletin board postings.</li>
                </ol>
                <h5>G. Country and area nomenclature; mention of companies or products</h5>

                <ol>
                    <li>The use of particular designations of countries or territories does not imply any judgment by MILKAR as to the legal status of such countries or territories, of their authorities and institutions or of the delimitation of their boundaries. The designations "developed" and "developing" regions are intended for statistical and analytical convenience and do not necessarily express a judgment about the stage reached by a particular country or area in the development process.</li>
                    <li>The mention of names of specific companies or products (whether or not indicated as registered) does not imply any intention to infringe proprietary rights, nor should it be construed as an endorsement or recommendation on the part of MILKAR.</li>
                </ol>


                <h5>I. Settlement of disputes</h5>

                It is intended that this Agreement be valid and enforceable under the laws of Pakistan, and that these laws shall govern the agreement’s interpretation.

                <h5>J. Termination</h5>
                <ol>
                    <li>MILKAR reserves its exclusive right in its sole discretion to alter, limit or discontinue the Platform or any Content in any respect. MILKAR shall have no obligation to take the needs of any User, registered or otherwise, into consideration in connection therewith.</li>
                    <li>MILKAR reserves the right to deny in its sole discretion any User, registered or otherwise, access to this Platform or any portion thereof without notice and terminate without cause this Agreement immediately at any time upon without incurring any liability whatsoever.</li>
                    <li>No waiver by MILKAR of any provision of this Agreement shall be binding except as set forth in writing signed by its duly authorized representative.</li>
                </ol>


                <h4>III. Changes to the Copyright and Terms of Use</h4>
                Users should check this page periodically to review these Copyright and Terms of Use. MILKAR may, in its sole discretion, modify or revise these Copyright and Terms of Use at any time by updating this page. The User, registered or otherwise, agrees to be bound by such modifications or revisions.
                Using or accessing this Platform indicates your acceptance of these Copyright and Terms of Use. If you do not accept these Copyright and Terms of Use, do not continue to use this Platform.

            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "TermsView",
    components: {
    },
    props: {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
    async mounted() {},
};
</script>
  
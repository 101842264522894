<template>
  <section class="container-fluid briefoverview">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 register-as-volunteer">
        <div class="col-lg-10 volunteer-content pb-3">
          <h2>Register as a Volunteer</h2>
          <p>Receive activity recommendations, and engage with top organizations. <br class="d-none d-md-block"> Sign up
            now in a few simple steps.
          </p>
          <a :href="baseUrl + '/register' " class="btn btn-secondary">Get Started <i class="fa-solid fa-arrow-right pl-1"></i></a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 py-4 px-4">
        <h3 class="mt-2 text-primary">A Brief Overview</h3>
        <div class="col-lg-12 pl-0 mt-3">
          <div class="volunteers-registered counter-box px-0">
            <div class="color-orange-numbers">
              <h2 class="fw-semibold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='volunteersCount' :duration='3' separator=','
                  :autoinit='true' />
              </h2>
              <label class="">Volunteers Registered</label>
              <p class="">A diverse pool of dedicated and driven volunteers</p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 pl-0">
          <div class="organizations-registered counter-box px-0">
            <div class="color-orange-numbers">
              <h2 class="fw-semibold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='organizationsCount' :duration='3'
                  separator=',' :autoinit='true' />
              </h2>
              <label class="">Organizations Registered</label>
              <p class="">A large network of organizations on a single platform</p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 pl-0">
          <div class="activities-registered counter-box px-0">
            <div class="color-orange-numbers">
              <h2 class="fw-semibold">
                <vue3-autocounter ref='counter' :startAmount='0' :endAmount='eventsCount' :duration='3' separator=','
                  :autoinit='true' />
              </h2>

              <label class="">Activities organized</label>
              <p class="">A wide range of volunteer activities happening nearby</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import { getOverviewCounts } from "@/api";
import Vue3autocounter from 'vue3-autocounter';


export default {
  name: "HomeView",
  components: {
    'vue3-autocounter': Vue3autocounter
  },
  props: {},
  computed: {

  },
  data() {
    return {
      imageurl: null,
      volunteersCount: 0,
      eventsCount: 0,
      organizationsCount: 0,
      overviewCounts: [],
      blogs: [],
      ambassadors: [],
      loading: true,
      loadingPopularEvents: true, // To show loading message
      loadingBlogs: true,
      loadingAmbassadors: true,
      errored: false, // To show error message
      slider: null,
      slider2: null,
      baseUrl: null,
      //heat map option are here 
    };
  },
  methods: {

  },
  async mounted() {
    this.baseUrl = window.location.origin;
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    try {
      /* eslint-disable */
      this.overviewCounts = await getOverviewCounts();
      this.volunteersCount = this.overviewCounts.volunteersCount;
      this.organizationsCount = this.overviewCounts.organizationsCount;
      this.eventsCount = this.overviewCounts.eventsCount;
      this.loadingAmbassadors = false;

    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loadingPopularEvents = false;
    }
  },
};
</script>
  
<template>
        <div class="container-fluid text-center mt-5">
        <div class="row">
            <div class="col-12">
                <div class="welcome-img pb-3">
                    <img src="../assets/img/header-img.png" class="img-fluid w-25" alt="verify email">
                </div>
                <h4 class="pb-2 font-weight-bold">Welcome To Milkar.com!</h4>
                <p class="mb-2 pb-2">We believe in connecting people, skills and good causes...<br/>kyun ke jo tanhaa nahi wo mil kar mumkin hai.</p>
                <div class="pb-4">
                    <img src="../assets/img/welcome-img-2.png" class="img-fluid w-25" alt="milkar.com">
                </div>
                <div v-if="this.user">
                    <a v-if="this.user.role === 0" href="/volunteer-dashboard/update-settings"
                        id="complete-your-profile"
                        class="btn btn-primary mb-4">Complete Your Profile</a>
                    <a v-else href="/organization-dashboard/update-settings"
                        id="complete-your-profile"
                        class="btn btn-primary mb-4">Complete Your Profile</a>
                </div>
                <!-- <p><small>This page will automatically redirect in 30 seconds</small></p> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {

  },
  components: {

  },
  data() {
    return {
        user: null,
    };
  },
  async mounted() {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    this.user = parsedUser;
    // console.log(this.user.role);
  },
  methods: {

  },
};
</script>
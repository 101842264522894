<template>
    <section class="volunteerdetail container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card w-100">
                        <div class="card-body">
                            <div class="col-lg-12 px-0 text-left d-flex flex-column row-gap-2">
                                <div class="text-right mb-3">
                                    <img v-if="!volunteer.profile_picture_thumb"
                                        :src="this.$volunteerNoImage(this.volunteer.gender)"
                                        class="activity-img-block img-fluid" />
                                    <Image v-else class="border-radius img-fluid"
                                        :src="this.imageurl + '/' + volunteer.profile_picture_thumb" :alt="volunteer.slug"
                                        preview />

                                </div>
                                <div class="d-flex flex-column">
                                    <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1">
                                        {{ userName }}
                                    </h3>
                                    <div class="d-flex align-items-start flex-column justify-content-between row-gap-1">
                                        <div
                                            class="d-flex align-items-center justify-content-start gap-1 text-capitalize text-muted fs-7">
                                            <span>{{ volunteer.genderStr }}</span>
                                            <span v-if="this.volunteer.dob">
                                                <span>| </span>
                                                {{ this.$getAge(this.volunteer.dob) }}
                                            </span>
                                            <!-- Age -->
                                            <span v-if="this.cityName">
                                                <span>| </span>
                                                {{ this.cityName }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between w-100">
                                            <div v-if="this.rating > 0"
                                                class="ratinng-box d-flex flex-row align-items-center text-primary gap-1 fs-8">
                                                <div class="d-flex justify-content-end text-primary">
                                                    <i v-for="(rating, index) in this.volunteer.ratings.fullRating"
                                                        class="fas fa-star rating" :key="index"></i>
                                                    <i v-if="this.volunteer.ratings.halfRating"
                                                        class="fas fa-star-half-alt rating"></i>
                                                    <i v-for="(rating, index) in this.volunteer.ratings.zeroRating"
                                                        class="fa-regular fa-star" :key="index"></i>
                                                </div>
                                                <span class="text-secondary fs-9">
                                                    ({{ this.rating.toFixed(1) }} out of 5.0)
                                                </span>
                                            </div>
                                            <div v-else
                                                class="d-flex align-items-start justify-content-start flex-grow-1 gap-1">
                                                <div class="d-flex align-items-start text-muted opacity-50 fs-8">
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                </div>
                                                <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                </span>
                                            </div>

                                            <Button class="share-ribbon" type="button" icon="pi pi-share-alt"
                                                @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
                                            <!-- <Menu ref="menu" id="overlay_menu" :model="socialitems" :popup="true" /> -->
                                            <Menu ref="menu" id="overlay_menu" class="p-menu p-component"
                                                :model="socialitems" :popup="true">
                                                <template #item="{ label, item, props }">
                                                    <a  @click="dynamicURL(item)"  target="_blank" class="p-menuitem-link">
                                                        <span v-bind="props.icon" /> {{ label }}
                                                    </a>
                                                </template>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>


                                <div class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right mb-2">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <span class="badge-counter-text">
                                            Activities Volunteered:
                                            <span v-if="volunteer.totalActivities > 0" class="counter">
                                                {{ volunteer.totalActivities ? volunteer.totalActivities : 0 }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">
                                            Reviews Received:
                                            <span v-if="volunteer.totalReviewsReceived > 0" class="counter">
                                                {{ volunteer.totalReviewsReceived ?
                                                    volunteer.totalReviewsReceived : 0 }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>

                                        <span class="badge-counter-text">
                                            Referral Count:
                                            <span v-if="volunteer.referralsCount > 0" class="counter">
                                                {{ volunteer.referralsCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'"> N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">
                                            Hours Completed:
                                            <span v-if="volunteer.totalHours > 0" class="counter">
                                                {{ volunteer.totalHours ? volunteer.totalHours : 0 }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'"> N/A
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Degree</label>
                                        <p v-if="this.degree" class="fs-bold mb-0">{{ this.degree }}</p>
                                        <p v-else class="fs-bold mb-0"> N/A </p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Field</label>
                                        <p v-if="this.field" class="fs-bold mb-0">{{ this.field }}</p>
                                        <p v-else class="fs-bold mb-0"> N/A </p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">University</label>
                                        <p v-if="this.universityName" class="fs-bold mb-0">{{ this.universityName }}</p>
                                        <p v-else class="fs-bold mb-0"> N/A </p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Employement Status</label>
                                        <p v-if="this.employmentStatus" class="fs-bold mb-0">{{ this.employmentStatus }}</p>
                                        <p v-else class="fs-bold mb-0"> N/A </p>
                                    </div>
                                </div>
                                <div class="row fs-7">
                                    <label class="text-muted fs-8">Socials</label>
                                    <div class="d-flex align-items-center social-icon"
                                        v-if="this.volunteer.facebook || this.volunteer.twitter || this.volunteer.linkedin || this.volunteer.instagram || this.volunteer.snapchat">
                                        <a v-if="this.volunteer.facebook" :href="this.volunteer.facebook">
                                            <i class="fa-brands fa-facebook-f"></i>
                                        </a>
                                        <a v-if="this.volunteer.twitter" :href="this.volunteer.twitter">
                                            <i class="fa-brands fa-twitter"></i>
                                        </a>
                                        <a v-if="this.volunteer.linkedin" :href="this.volunteer.linkedin">
                                            <i class="fa-brands fa-linkedin-in"></i>
                                        </a>
                                        <a v-if="this.volunteer.instagram" :href="this.volunteer.instagram">
                                            <i class="fa-brands fa-instagram"></i>
                                        </a>
                                        <a v-if="this.volunteer.snapchat" :href="this.volunteer.snapchat">
                                            <i class="fa-brands fa-snapchat"></i>
                                        </a>
                                    </div>
                                    <div v-else class="d-flex align-items-center social-icon">
                                        <p class="fs-bold mb-0"> N/A </p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <a v-if="isAuthenticated && volunteer.user_id == user"
                                        :href="baseUrl + '/volunteer-dashboard/update-settings'"
                                        class="btn btn-primary-sm w-100"><i class="far fa-edit"></i> Update Profile</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
                            <div class="row mx-0 flex justify-content-between p-dataview-header px-0">
                                <div class="d-flex align-items-center">
                                    <h3 class="mb-0 me-3">About</h3>
                                </div>
                            </div>
                            <VolunteerImages :volunteerSlug="this.slug"></VolunteerImages>
                            <section>
                                <div class="d-flex flex-column row-gap-3">
                                    <div class="row mx-0 align-self-start">
                                        <label class="text-muted fs-8">Affiliated with</label>
                                        <p v-if="this.universityName.length > 0" class="mb-0"> {{ this.universityName }}</p>
                                        <span v-else class="mb-0" v-tooltip.bottom="'Not Added'"> N/A</span>
                                    </div>
                                    <div class="row mx-0 align-self-start">
                                        <label class="text-muted fs-8">Bio</label>
                                        <p v-if="volunteer.about" class="mb-0">{{ volunteer.about }}</p>
                                        <span v-else class="mb-0 not-added" v-tooltip.bottom="'Not Added'"> N/A</span>
                                    </div>
                                    <div class="row mx-0 align-self-start">
                                        <label class="text-muted fs-8">Skills</label>
                                        <div v-if="volunteer.skills && volunteer.skills.length > 0"
                                            class="d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                            <span v-for="(skill, index) in volunteer.skills" :key="index"
                                                class="badge-custom">
                                                {{ skill.name }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span class="badge-custom" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mx-0 align-self-start">
                                        <label class="text-muted fs-8">Causes interested in</label>
                                        <div v-if="volunteer.causes && volunteer.causes.length > 0"
                                            class="d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                            <span v-for="(cause, index) in volunteer.causes" :key="index"
                                                class="badge-custom">
                                                {{ cause.name }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span class="badge-custom" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </section>
                            <ActivitiesRegistered :volunteerSlug="this.slug"></ActivitiesRegistered>
                            <VolunteerReviews :volunteerSlug="this.slug"></VolunteerReviews>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getVolunteerV2 } from "@/api";
import { mapGetters } from 'vuex';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Galleria from 'primevue/galleria';
import ProgressBar from 'primevue/progressbar';
import Paginator from 'primevue/paginator';
import VolunteerReviews from "@/components/Volunteers/VolunteerReviews.vue";
import ActivitiesRegistered from "@/components/Volunteers/ActivitiesRegistered.vue";
import VolunteerImages from "@/components/Volunteers/VolunteerImages.vue";
import Menu from "primevue/menu";
import Breadcrumb from 'primevue/breadcrumb';

export default {
    components: {
        DataView,
        DataViewLayoutOptions,
        Button,
        Image,
        Tag,
        Chip,
        Divider,
        Card,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Avatar,
        AvatarGroup,
        Galleria,
        ProgressBar,
        Paginator,
        VolunteerReviews,
        ActivitiesRegistered,
        VolunteerImages,
        Menu,
        Breadcrumb,
    },
    data() {
        return {
            imageurl: null,
            volunteer: [],
            loading: false,
            userName: '',
            universityName: '',
            employmentStatus: '',
            cityName: '',
            degree: '',
            field: '',
            rating: 0,
            user: null,
            socialitems: [
                {
                    label: 'Invite Friend',
                    icon: 'pi pi-whatsapp',
                },
                {
                    label: 'Facebook',
                    icon: 'pi pi-facebook',
                },
                {
                    label: 'Twitter',
                    icon: 'pi pi-twitter',

                },
                {
                    label: 'Copy Link',
                    icon: 'pi pi-copy',

                },
                {
                    label: 'Copy Referral Link',
                    icon: 'pi pi-copy',

                }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            breadcrumb: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ]
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
    props: ['slug'], // Declare the 'slug' prop
    async mounted() {
        if (this.isAuthenticated) {
            const userdata = localStorage.getItem("user");
            this.user = JSON.parse(userdata);
            this.user = this.user.id
        }
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const res = await getVolunteerV2(this.slug);
            this.volunteer = res;
            // console.log(this.volunteer);
            if (this.volunteer.university) {
                this.universityName = this.volunteer.university.name;
            }
            if (this.volunteer.user_v2) {
                this.userName = this.volunteer.user_v2.name;
            }
            if (this.volunteer.ratings) {
                this.rating = this.volunteer.ratings.avgRating;
            }
            if (this.volunteer.city) {
                this.cityName = this.volunteer.city.name;
            }
            if (this.volunteer.education_field) {
                this.degree = this.volunteer.education_field.name;
            }
            if (this.volunteer.education) {
                this.field = this.volunteer.education.name;
            }
            if (this.volunteer.employment) {
                this.employmentStatus = this.volunteer.employment.name;
            }

            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        dynamicURL(item) {
            if (item.label == 'Invite Friend') {
                const urlParam = 'https://api.whatsapp.com/send?text=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                return urlParam;
            }
            if (item.label == 'Facebook') {
                const urlParam = 'https://www.facebook.com/sharer/sharer.php?u=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                return urlParam;
            }
            if (item.label == 'Twitter') {
                const urlParam = 'https://twitter.com/intent/tweet?text=' + this.baseUrl + '/r/' + this.volunteer.rcode;
                return urlParam;
            }
            if (item.label == 'Copy Link') {
                const copyurl = this.baseUrl + '/volunteers/' + this.volunteer.slug;
                const textarea = document.createElement('textarea');
                textarea.value = copyurl;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            }
            if (item.label == 'Copy Referral Link') {
                const copyrefurl = this.baseUrl + '/r/' + this.volunteer.rcode;
                const textarea2 = document.createElement('textarea');
                textarea2.value = copyrefurl;
                document.body.appendChild(textarea2);
                textarea2.select();
                document.execCommand('copy');
                document.body.removeChild(textarea2);
            }

        },
    }
};
</script>

<template>
  <section>
    <div class="container-fluid px-0">
      <section class="login-reg-sec">
        <div class="box">
          <div class="square" style="--i:0;"></div>
          <div class="square" style="--i:1;"></div>
          <div class="square" style="--i:2;"></div>
          <div class="square" style="--i:3;"></div>
          <div class="square" style="--i:4;"></div>
          <div class="square" style="--i:5;"></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center mb-2">
                <a href="/" class="text-center">
                  <img src="../assets/img/logo.png" alt="logo" class="img-fluid w-50 my-3" />
                </a>
              </div>
              <div class="row mx-0 text-center">
                <h1 class="mb-3">Sign in</h1>
              </div>
              <form @submit="handleLogin" id="form-signin">
                <div class="form-group">
                  <input id="email" type="email" class="form-control" name="email" autocomplete="email"
                    placeholder="Email Address" autofocus="" v-model="email" required />
                </div>
                <div class="form-group">
                  <input id="password" type="password" class="form-control" name="password"
                    autocomplete="current-password" placeholder="Password" v-model="password" required />
                </div>
                <div class="checkbox clearfix">
                  <div class="form-check checkbox-theme float-left">
                    <input class="form-check-input" type="checkbox" name="remember" id="remember" />
                    <label class="form-check-label" for="remember" style="cursor: pointer">
                      Remember Me
                    </label>
                  </div>
                  <a class="font-12-mb" href="/password/forgot">Forgot Your Password?</a>
                </div>
                <div class="form-group mb-0">
                  <button id="login-btn" class="btn btn-primary w-100" type="submit" tabindex="12" data-style="zoom-in">
                    <i class="fa-solid fa-arrow-right-to-bracket pr-1"></i>
                    Login
                  </button>
                </div>
                <div v-if="loginError" class="alert alert-danger mt-3">
                  {{ loginError }}
                </div>
              </form>
              <div class="d-flex align-items-center gap-2 flex-sm-nowrap flex-wrap">
                <button @click="socialLogin('google')" class=" flex-grow-1 btn btn-secondary">
                  <span class="google_wait"></span>
                  <i class="fab fa-google pr-1"></i> Google
                </button>
                <form action="https://stage.milkar.com/social-login/facebook" id="form-fb-signin" class="flex-grow-1">
                  <button @click="socialLogin('facebook')" class="btn btn-secondary w-100">
                    <span class="fb_wait"></span>
                    <i class="fab fa-facebook-f pr-1"></i>
                    Facebook
                  </button>
                </form>
              </div>
              <div class="footer mt-3 text-center">
                <span class="font-12-mb">Don't have an account?
                  <a href="/register" class="text-primary">Register here</a></span>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </section>
</template>
<script>
import { socialLogins } from "@/api";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: null,
    };
  },
  mounted(){
    this.baseUrl = window.location.origin;
    const userIsActive = this.$route.params.isActive;
    if(userIsActive) {
      this.loginError = 'User is not active';
    }

    if (localStorage.getItem("user"))
    {
      var user = localStorage.getItem("user");
      const parsedUser = JSON.parse(user);
      if (parsedUser.role === 0) {
        this.$router.push('/volunteer-dashboard');
      }
      else if (parsedUser.role === 1) {
        this.$router.push('/organization-dashboard');
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin(event) {
      event.preventDefault()
      try {
        const credentials = {
          email: this.email,
          password: this.password,
        };
        await this.login(credentials);
        const userRole = this.$store.state.user.role;
        // Redirect to the dashboard or another appropriate route
        if (userRole === 0) {
          this.$router.push('/volunteer-dashboard');
        }
        else if (userRole === 1) {
          this.$router.push('/organization-dashboard');
        }
        else {
          // Handle unknown role or other cases
          console.error('Unknown user role');
        }
      } catch (error) {
        if (error.response) {
          if (Array.isArray(error.response.data.errors)) {
            // Handle an array of errors
            this.loginError = error.response.data.errors.join(', ');
            console.log('array of message ', this.loginError);
          } else if (typeof error.response.data.message === 'string') {
            // Handle a single error
            this.loginError = error.response.data.message;
            console.log('String ', this.loginError);
          } else {
            // Handle other unexpected response structures
            this.loginError = 'An error occurred. Please try again later.';
            console.log('else ', this.loginError);
          }
        } else {
          console.log(error);
          this.loginError = 'An error occurred. Please try again later.';
        }
      }
    },
    async socialLogin(provider) {
      try {
        const response = await socialLogins(provider);
        window.location = response.data;
      } catch (error) {
        console.error('Google login error', error);
      }
    },
  },
};
</script>
<style>
@import "../assets/css/login-register.css";
</style>

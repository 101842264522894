<template>
  <section class="dashboard container-fluid py-3">
    <div class="container-xxxl">
      <Message v-if="$store.state.isAuthenticated && !this.isPermissionAllowed && this.isLocationEnabled"
        severity="warn">
        Allow notification permission in order to receive notification.
      </Message>
      <Message v-if="$store.state.isAuthenticated && !this.isPermissionAllowed && !this.isLocationEnabled"
        severity="warn">
        Allow notification and location permission in order to receive notification and better recommendations.
      </Message>
      <Message v-if="$store.state.isAuthenticated && this.isPermissionAllowed && !this.isLocationEnabled"
        severity="warn">
        Allow location permission in order to receive better recommendations.
      </Message>
      <div v-if="isProfileCompleted != '1'" class="alert alert-danger py-2">Please complete your profile to receive
        better activity recommendations. <a href="volunteer-dashboard/update-settings"
          class="btn btn-primary-sm my-0">Complete
          Profile</a> </div>
      <div class="row row-gap-3">
        <div class="col-lg-3">
          <div class="card w-100">
            <div class="card-body">
              <VolunteerInfo :slug="this.volunteer.slug"></VolunteerInfo>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="card w-100">
            <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
              <TabView :scrollable="true" v-model:activeIndex="active">
                <TabPanel id="my-causes">
                  <template #header>
                    <span>My Causes</span>
                    <Badge class="ml-2 bg-primary">{{ this.myCausesCount }}</Badge>
                  </template>
                  <MyCausesComponent v-if="this.active == 0"></MyCausesComponent>
                </TabPanel>
                <TabPanel id="activities">
                  <template #header>
                    <span>Activities</span>
                    <Badge class="ml-2 bg-primary">{{ this.activitiesCount }}</Badge>
                  </template>
                  <Activities v-if="this.active == 1"></Activities>
                </TabPanel>
                <TabPanel id="registered">
                  <template #header>
                    <span>Registered</span>
                    <Badge class="ml-2 bg-primary">{{ this.registerActivityCount }}</Badge>
                  </template>
                  <RegisteredEvents v-if="this.active == 2"></RegisteredEvents>
                </TabPanel>
                <TabPanel id="invitations">
                  <template #header>
                    <span>Invitations</span>
                    <Badge class="ml-2 bg-primary">{{ this.invitationCount }}</Badge>
                  </template>
                  <InvitationEvents v-if="this.active == 3"></InvitationEvents>
                </TabPanel>
                <TabPanel id="recommended">
                  <template #header>
                    <span>Recommended</span>
                    <Badge class="ml-2 bg-primary">{{ this.recommendedCount }}</Badge>
                  </template>
                  <RecommendedEvents v-if="this.active == 4"></RecommendedEvents>
                </TabPanel>
                <TabPanel id="completed">
                  <template #header>
                    <span>Completed</span>
                    <Badge class="ml-2 bg-primary">{{ this.completedCount }}</Badge>
                  </template>
                  <CompletedEvents v-if="this.active == 5"></CompletedEvents>
                </TabPanel>
                <TabPanel id="pending">
                  <template #header>
                    <span>Pending</span>
                    <Badge class="ml-2 bg-primary">{{ this.pendingCount }}</Badge>
                  </template>
                  <PendingEvents v-if="this.active == 6"></PendingEvents>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  volunteerDataApi, getMyCausesCount, getActivitiesCount, getRegisterActivitiesCount, getInvitationActivitiesCount,
  getRecommendedActivityCount, getCompletedActivityCount, getPendingActivityCount
} from "@/api";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabMenu from 'primevue/tabmenu';
import VolunteerInfo from "@/components/Volunteers/Dashboard/VolunteerInfo";
import MyCausesComponent from "@/components/Volunteers/Dashboard/MyCauses";
import Activities from "@/components/Volunteers/Dashboard/Activities";
import InvitationEvents from "@/components/Volunteers/Dashboard/InvitationEvents";
import RegisteredEvents from "@/components/Volunteers/Dashboard/RegisteredEvents";
import RecommendedEvents from "@/components/Volunteers/Dashboard/RecommendedEvents";
import CompletedEvents from "@/components/Volunteers/Dashboard/CompletedEvents";
import PendingEvents from "@/components/Volunteers/Dashboard/PendingEvents";
import DataView from 'primevue/dataview';
import Paginator from 'primevue/paginator';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Button from 'primevue/button';
import Image from 'primevue/image';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Breadcrumb from 'primevue/breadcrumb';
import { saveUserLocation } from "@/api";
import Message from 'primevue/message';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";

export default {
  metaInfo() {
    return {
      title: 'Your Tab Title',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Milkar - Dashboard';
      }
    },
  },
  components: {
    // HomeComponent,
    TabView,
    TabPanel,
    TabMenu,
    VolunteerInfo,
    MyCausesComponent,
    Activities,
    InvitationEvents,
    RegisteredEvents,
    RecommendedEvents,
    CompletedEvents,
    PendingEvents,
    Paginator,
    DataView,
    DataViewLayoutOptions,
    Button,
    Image,
    Tag,
    Chip,
    Divider,
    Card,
    Badge,
    BadgeDirective,
    Breadcrumb,
    Message,
    ListingSkeleton,
  },
  props: {},
  data() {
    return {
      myCausesCount: null,
      activitiesCount: null,
      registerActivityCount: null,
      invitationCount: null,
      recommendedCount: null,
      completedCount: null,
      pendingCount: null,
      volunteer: [],
      user: [],
      active: 0,
      image: '',
      loading: true, // To show loading message
      errored: false, // To show error message
      isPermissionAllowed: false,
      isLocationEnabled: false,
      isProfileCompleted: true,
      home: {
        icon: 'pi pi-home',
        route: '/'
      },
    };
  },
  async mounted() {
    const myUser = localStorage.getItem("user");
    const vvolunteer = localStorage.getItem("volunteer");
    this.volunteer = JSON.parse(vvolunteer);
    this.isProfileCompleted = this.volunteer.is_completed;
    // console.log(this.isProfileCompleted);
    const parsedUser = JSON.parse(myUser);
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.isPermissionAllowed = true;
        }
      });
      navigator.permissions.query({ name: 'geolocation' })
        .then((PermissionStatus) => {
          if (PermissionStatus.state === 'granted') {
            this.isLocationEnabled = true;
          }
        })
      if ('geolocation' in navigator) {
        // Geolocation is supported
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const params = {
              latitude: latitude,
              longitude: longitude
            };
            const response = saveUserLocation(params);
          },
        );
      } else {
        // Geolocation is not supported
        console.error('Geolocation is not supported by this browser.');
      }
    }
    catch (error) {
      console.error(error);
      this.errored = true;
    }
    const userId = this.$route.params.tabSelected;
    if (userId == 'invitations') {
      this.active = 3;
    }
    try {
      const myCausesCount = await getMyCausesCount();
      this.myCausesCount = myCausesCount;
      const activitiesCount = await getActivitiesCount();
      this.activitiesCount = activitiesCount;
      const registerActivityCount = await getRegisterActivitiesCount();
      this.registerActivityCount = registerActivityCount;
      const invitationCount = await getInvitationActivitiesCount();
      this.invitationCount = invitationCount;
      const recommendedCount = await getRecommendedActivityCount();
      this.recommendedCount = recommendedCount;
      const completedCount = await getCompletedActivityCount();
      this.completedCount = completedCount;
      const pendingCount = await getPendingActivityCount();
      this.pendingCount = pendingCount;
      // localStorage.removeItem('token');
      // localStorage.removeItem('user');
      // localStorage.removeItem('volunteer');
      const parsedVolunteer = JSON.parse(vvolunteer);
      this.volunteer = parsedVolunteer;
      this.user = parsedUser;
      this.image = "https://volunteers-pk-live.s3-ap-southeast-1.amazonaws.com/" + parsedVolunteer.profile_picture_thumb;
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
    <section id="vueContent" class="container-fluid t-section px-0">
        <div class="row mx-0 row-gap-4 pb-5">
            <div class="t-header">
                <div class="d-flex align-item=center w-100 justify-content-center mt-5">
                    <h3 class="text-center text-white mt-3">
                        Meet The Milkar Team
                    </h3>
                </div>
            </div>

            <div class="col-lg-10 t-list-main">

                <div class="card">
                    <div v-if="loadingTeam" class="row mx-0 justify-content-center">
                        <div class="col-lg-12 px-0">
                            <div class="card-body d-flex flex-column row-gap-2">
                                <Skeleton class="w-100 border-round h-3rem" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="card-body row mx-0 row-gap-4">
                        <div v-for=" (team, index) in ourTeam" :key="index" class="col-lg-3 col-md-6 col-sm-12">
                            <div class="t-container-layout">
                                <Image class="t-expanded-width t-image" preview
                                    :src="this.imageurl + '/' + team.picture_thumb" width="700" />
                                    <div class="container">
                                        <ul v-if="team.facebook || team.twitter || team.instagram || team.snapchat || team.linkedin || team.website" class="submenu">
                                            <li v-if="team.facebook"><a :href="team.facebook" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                            <li v-if="team.twitter"><a :href="team.twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                            <li v-if="team.instagram"><a :href="team.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li v-if="team.snapchat"><a :href="team.snapchat" target="_blank"><i class="fab fa-snapchat"></i></a></li>
                                            <li v-if="team.linkedin"><a :href="team.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                            <li v-if="team.website"><a :href="team.website" target="_blank"><i class="fab fa-solid fa-globe"></i></a></li>
                                        </ul>                                    
                                    </div>
                                <div class="t-group">
                                    <div class="t-group-layout">
                                        <h5 class="text-primary">{{ team.name }}</h5>
                                        <h6 class="text-muted">{{ team.position }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getTeam } from "@/api";
import Image from 'primevue/image';
import Skeleton from 'primevue/skeleton';

export default {
    name: "HomeView",
    components: {
        Image,
        Skeleton,
    },
    props: {},
    computed: {},
    data() {
        return {
            imageurl: null,
            ourTeam: [],
            loadingTeam: true, // To show loading message
            errored: false, // To show error message
            //heat map option are here 
        };
    },
    methods: {},
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            /* eslint-disable */
            this.ourTeam = await getTeam();
            // console.log(this.ourTeam);
            this.loadingTeam = false;
        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loadingTeam = false;
        }
    },
}
</script>

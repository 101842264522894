<template>
    <section id="vueContent" class="institute container-fluid py-3">
        <div class="container-xxxl">
            <div class="alert alert-success" style="display: none">
                <strong>Success</strong>
            </div>
            <div class="card col-lg-12">
                <div v-if="loadingChampions" class="row row-gap-3 p-4" style="z-index: 99;">
                    <div class="col-lg-12">
                        <Skeleton class="w-100 border-round h-100" />
                    </div>
                    <div class="col-lg-12 px-1 d-flex flex-column row-gap-3">
                        <Skeleton class="w-100 border-round h-2rem" />
                        <Skeleton class="w-100 border-round h-2rem" />
                    </div>
                    <div class="col-lg-12 px-1 d-flex flex-column row-gap-3">
                        <Skeleton class="w-100 border-round h-3rem" />
                        <Skeleton class="w-100 border-round h-2rem" />
                    </div>
                </div>
                <div v-else class="card-body row mx-0 row-gap-5">
                    <div v-for="(champion, index) in   milkarChampions  " :key="index">
                        <div class="col-lg-12">
                            <div class="text-center py-3 mb-3">
                                <h3 class="mb-0 me-3">
                                    {{ champion.title }}
                                </h3>
                            </div>
                            <p class="" v-html="champion.description"></p>
                        </div>
                        <div class="grid-container">
                            <div v-for="(championsList, i) in champion.champions" :key="i" class="">
                                <div v-if="championsList.is_special == 0"
                                    class="bg-white rounded shadow-sm p-2 pb-3 text-center grid-main">

                                    <!-- <Image v-if="championsList.banner"
                                        class="border-radius img-fluid flexible-layout__img"
                                        :style="{ 'background-image': 'url(' + this.imageurl + '/images/champions/' + championsList.banner + ')' }"
                                        :src="this.imageurl + '/images/champions/' + championsList.banner" preview />

                                    <img v-else src="@/assets/img/male_placeholder.png"
                                        class="img-fluid rounded flexible-layout__img" width="100" /> -->


                                    <img v-if="!championsList.banner" src="@/assets/img/male_placeholder.png"
                                        class="border-radius img-fluid" style="width: 150px; height: 100%;" />
                                    <Image v-else :src="this.imageurl + '/images/champions/' + championsList.banner"
                                        alt="" width="100" class="border-radius img-fluid" preview />

                                    <h5 class="mb-0 mt-3">{{ championsList.name }}</h5>
                                    <div v-if="championsList.category_id" class="">
                                        <label class="mb-1 mt-2 badge-custom d-xl-inline-block fs-9">Category</label>
                                        <p class="fs-7  text-capitalize mb-0"
                                            v-tooltip.bottom="championsList.category.name">
                                            {{ championsList.category.name }}
                                        </p>
                                    </div>
                                    <div v-else-if="championsList.university_id" class="">
                                        <label class="mb-1 mt-2 badge-custom d-xl-inline-block fs-8">University</label>
                                        <p class="fs-7 text-capitalize mb-0"
                                            v-tooltip.bottom="championsList.university.name">
                                            {{ championsList.university.name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- <h3 v-if="getSpecialChampions(champion.champions)" class="text-break text-primary"
                                style="text-align: center">Special Mentions</h3> -->
                            <div v-for="(championsList, i) in   getSpecialChampions(champion.champions) " :key="i"
                                class="grid-main">
                                <div v-if="championsList.is_special == 1"
                                    class="w-100 bg-white rounded shadow-sm p-2 pb-3 text-center big">

                                    <!-- <Image v-if="championsList.banner"
                                        class="border-radius img-fluid flexible-layout__img"
                                        :style="{ 'background-image': 'url(' + this.imageurl + '/images/champions/' + championsList.banner + ')' }"
                                        :src="this.imageurl + '/images/champions/' + championsList.banner" preview />

                                    <img v-else src="@/assets/img/male_placeholder.png"
                                        class="img-fluid rounded flexible-layout__img" width="100" /> -->

                                    <div href="#" class="v-badge" v-tooltip.top="'Special Mentions'">
                                        <i class="fs-8 text-white pi pi-star-fill"></i>
                                    </div>
                                    <img v-if="!championsList.banner" src="@/assets/img/male_placeholder.png"
                                        class="border-radius img-fluid" style="width: 150px; height: 100%;" />
                                    <Image v-else :src="this.imageurl + '/images/champions/' + championsList.banner"
                                        alt="" width="100" class="border-radius img-fluid" preview />

                                    <h5 class="mb-0 mt-3">{{ championsList.name }}</h5>
                                    <div v-if="championsList.category_id" class="">
                                        <label class="mb-1 mt-2 badge-custom d-xl-inline-block fs-8">Category</label>
                                        <p class="fs-7 text-capitalize mb-0"
                                            v-tooltip.bottom="championsList.category.name">
                                            {{ championsList.category.name }}
                                        </p>
                                    </div>
                                    <div v-else-if="championsList.university_id" class="">
                                        <label class="mb-1 mt-2 badge-custom d-xl-inline-block fs-8">University</label>
                                        <p class="fs-7 text-capitalize mb-0"
                                            v-tooltip.bottom="championsList.university.name">
                                            {{ championsList.university.name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <h5 v-else>No Event/Champion added</h5> -->
            </div>
        </div>
    </section>
</template>
<script>
import { getMilkarChampions } from "@/api";
import Skeleton from 'primevue/skeleton';
import Divider from 'primevue/divider';
import Image from 'primevue/image';

export default {
    name: "Milkar-Champion",
    components: {
        Image,
        Skeleton,
        Divider
    },
    props: {},
    computed: {},
    data() {
        return {
            imageurl: null,
            milkarChampions: [],
            loadingChampions: true, // To show loading message
            errored: false, // To show error message
            counter: 0,
            isSpecialMentions: true,
        };
    },
    methods: {},
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            /* eslint-disable */
            const res = await getMilkarChampions();
            this.milkarChampions = res;
            this.loadingChampions = false;
        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loadingChampions = false;
        }
    },
    methods: {
        specialMentionsCounter() {
            this.isSpecialMentions = false;
        },
        getSpecialChampions(data) {
            // Filter the champion.champions array to only include items where is_special is 1
            return data.filter(champion => {
                return champion.is_special === 1;
            });
        }

    }
}
</script>

<template>
    <dropdown-menu class="notificationDropdownMenu">
        <template #trigger>
            <button class="notification-btn dropdown-toggle me-2" type="button" id="notificationDropdownMenu"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-handshake"></i>
                <span class="quantity vol-unread-requests" v-if="this.requestsCount">
                    {{ this.requestsCount }}</span>
            </button>
        </template>
        <template #header>
            <div>
                <h4 class="widget-title d-inline-flex align-items-center">
                    Requests
                </h4>
                <a @click="getAllRequests" class="reloadAllVolNotifications ml-2 pointer">
                    <i class="fas fa-sync-alt pe-auto text-primary refresh-icon " aria-hidden="true"></i>
                </a>

            </div>
            <div class="volMarkAllRequestsAsRead">
                <a class="text-muted font-12 pointer" @click="updateLoadRequests(false)">Mark all as read</a>
            </div>
        </template>
        <template #body>
            <ScrollPanel style="width: 100%; max-height: 300px" class="custom-scroll">
                <div v-if="this.requests && isAuthenticated && role == 0">
                    <a v-for="(request, index) in requests" :key="index" :href="this.getURL(request)" class="d-block"
                        @click="updateLoadRequests(request.id)">
                        <div class="row px-0 mx-0">
                            <div class="col-3">
                                <img v-if="!request.event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                                    class="activity-img-block border-radius img-fluid" />
                                <img v-else :src="this.imageurl + '/' + request.event.thumbnail_picture" alt="abc"
                                    class="img-fluid rounded">
                            </div>
                            <div class="col-8 fs-7" v-if="request.type == 4 || request.type == 21">
                                <p class="mb-0 fs-7"><strong>
                                        {{ request.organization ? request.event.organizationsCsv :
                    request.event.ownervolunteer.name }}
                                    </strong> has sent you a volunteer request for their activity <strong>{{
                    request.event.name }}</strong>.
                                </p>
                                <span class="fs-8 text-primary">{{ request.updated_at }}</span>
                            </div>
                            <div class="col-8 fs-7" v-if="request.type == 11">
                                <p class="mb-0 fs-7">Request for review is received from <strong>
                                        {{ request.user.name }}
                                    </strong> for your cause <strong>{{ request.event.name }}</strong>
                                </p>

                                <span class="fs-8 text-primary">{{ request.updated_at }}</span>
                            </div>
                            <div class="col-8 fs-7" v-if="request.type == 5">
                                <p class="mb-0 fs-7"> <strong>
                                        {{ request.user.name }}
                                    </strong> has sent you request for your cause
                                    <strong>{{ request.event.name }}</strong>
                                </p>

                                <span class="fs-8 text-primary">{{ request.updated_at }}</span>
                            </div>
                            <div v-if="!request.is_read" class="col-1 px-0 mt-1">
                                <span class="inactive-available-circle"></span>
                                <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                            </div>

                        </div>
                        <Divider></Divider>
                        <!-- end mess__item -->
                    </a>
                </div>
                <div v-if="this.requests && isAuthenticated && role == 1">
                    <a v-for="(request, index) in requests" :key="index" :href="this.getURL(request)" class="d-block">
                        <div class="row px-0 mx-0">
                            <div class="col-3">
                                <img v-if="!request.profile_pic" src="@/assets/img/org_placeholder.png"
                                    class="activity-img-block border-radius img-fluid" />
                                <img v-else :src="this.imageurl + '/' + request.profile_pic" alt="abc"
                                    class="img-fluid rounded">
                            </div>
                            <div class="col-8 fs-7" v-if="request.type == 11">
                                <!-- <p class="mb-0">You have a new request from <strong>
                                        {{ request.user_name }}
                                    </strong> for your activity <strong>{{ request.event_name }}</strong>.
                                </p> -->
                                <p class="mb-0 fs-7">Request for review is received from <strong>
                                        {{ request.user_name }}
                                    </strong> for your cause <strong>{{ request.event_name }}</strong>
                                </p>
                                <span class="fs-8 text-primary">{{ request.updated_at }}</span>
                            </div>
                            <div class="col-8 fs-7" v-if="request.type == 5">
                                <p class="mb-0 fs-7"> <strong>
                                        {{ request.user_name }}
                                    </strong> has sent you request for your activity
                                    <strong>{{ request.event_name }}</strong>
                                </p>

                                <span class="fs-8 text-primary">{{ request.updated_at }}</span>
                            </div>
                            <div v-if="!request.is_read" class="col-1 px-0 mt-1">
                                <span class="inactive-available-circle"></span>
                                <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                            </div>

                        </div>
                        <Divider></Divider>
                        <!-- end mess__item -->
                    </a>
                </div>
                <div v-if="this.requests <= 0 && isAuthenticated && !isLoading">
                    <a href="#" class="d-block">
                        <div class="mess__item justify-content-center pb-4">
                            <div class="content">
                                <p class="text-muted mb-0 text-center">No information available</p>
                            </div>
                        </div>
                    </a>
                </div>
                <p v-if="isLoading" class="org-notifications-loading text-center mt-2"> <i
                        class="fas fa-circle-notch fa-spin"></i> Loading</p>
                <!-- <p v-if="isLoading" class="org-notifications-loading text-center mt-2"> <i class="fas fa-circle-notch fa-spin"></i> Loading</p>
                <div v-if="!isLoading && this.requests " class="mt-3 text-center">
                    <button @click="loadMore"  class="btn btn-primary">Load More</button>
                </div> -->
            </ScrollPanel>
        </template>
        <template #footer v-if="!isLoading && this.lastPage > this.pageNo">
            <div class="my-3 text-center">
                <a @click="loadMore($event)" class="fs-7 cursor-pointer text-primary">Load More</a>
            </div>
        </template>
    </dropdown-menu>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu';
import { loadRequests, readLoadRequests } from "@/api";
import Divider from 'primevue/divider';
import ScrollPanel from 'primevue/scrollpanel';

export default
    {
        components: {
            DropdownMenu,
            Divider,
            ScrollPanel
        },
        data() {
            return {
                pageNo: 1,
                perPage: 10,
                requests: [],
                requestsCount: null,
                isLoading: false,
                lastPage: 1,
            }
        },
        props: ['isAuthenticated', 'role'],
        async mounted() {
            this.baseUrl = window.location.origin;
            this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
            this.getAllRequests();

        },
        methods: {
            async getAllRequests() {
                this.isLoading = true;
                this.requests = [];
                try {
                    const params = {
                        pageno: this.pageNo,
                        perpage: this.perPage
                    };
                    const responce = await loadRequests(params);
                    this.lastPage = responce.requests.last_page;
                    this.requests = responce.requests.data;
                    this.requestsCount = responce.requestsCount;
                    this.isLoading = false;
                }
                catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            getURL(request) {
                if (request.type == 4 && this.role == 0) {
                    return this.baseUrl + "/volunteer-dashboard/invitations"
                }
                if (request.type == 4 && this.role == 1) {
                    return this.baseUrl + "/organization-dashboard/invitations"
                }
                if (request.type == 5 && this.role == 0) {
                    return this.baseUrl + "/volunteer-dashboard/activity/" + request.event.slug + "/volunteers/requests"
                }
                if (request.type == 5 && this.role == 1) {
                    return this.baseUrl + "/organization-dashboard/activity/" + request.event_slug + "/volunteers/requests"
                }
            },
            async loadMore(event) {
                event.stopPropagation(); // Stop event propagation
                this.pageNo++;
                this.isLoading = true;
                try {
                    const params = {
                        pageno: this.pageNo,
                        perpage: this.perPage
                    };
                    const responce = await loadRequests(params);
                    this.requests = [...this.requests, ...responce.requests.data];
                    this.isLoading = false;
                }
                catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            async updateLoadRequests(id, slug) {
                try {
                    var params = {};
                    if (id) {
                        params = {
                            requestID: id
                        };
                    }
                    const responce = await readLoadRequests(params);
                    if (responce.status == 200 || responce.status == 201) {
                        //    window.location.href=this.baseUrl + "/activities/" + slug
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
    }
</script>
<template>
     <div class="container mt-5">
        <div class="row justify-content-center">

            <div class="col-lg-8 mb-5">
                <h3 class="pb-4 font-weight-bold text-center">Community Guidelines</h3>

                <p>When you join our programs, you’re joining a community. And like any growing community, a few ground rules about expected behavior are good for everyone. These guidelines cover both online (e.g. mailing lists, social channels) and offline (e.g. in-person meetups) behavior.
                Violations of this code of conduct can result in members being removed from the program. Use your best judgement, and if you’d like more clarity or have questions feel free to reach out.
                </p>

                <h3>Be nice</h3>

                <p>We're all part of the same community, so be friendly, welcoming, and generally a nice person. Be someone that other people want to be around.</p>

                <h3>Be respectful and constructive</h3>

                <p>Remember to be respectful and constructive with your communication to fellow members. Don't get into flamewars, make personal attacks, vent, or rant unconstructively. Everyone should take responsibility for the community and take the initiative to diffuse tension and stop a negative thread as early as possible.</p>

                <h3>Be collaborative</h3>
                <p>Work together! We can learn a lot from each other. Share knowledge, and help each other out.</p>

                <h3>Participate</h3>
                <p>Join in on discussions, show up for in-person meetings regularly, offer feedback, and help implement that feedback.</p>

                <h3>Step down considerately</h3>
                <p>If you have some form of responsibility in your community, be aware of your own constraints. If you know that a new job or personal situation will limit your time, find someone who can take over for you and transfer the relevant information (contacts, passwords, etc.) for a smooth transition.</p>

                <h3>Basic etiquette for online discussions</h3>
                <p>Don’t send messages to a big list that only need to go to one person. Keep off topic conversations to a minimum. Don’t be spammy by advertising or promoting personal projects which are off topic.</p>

                <h3>Condemns abusive action or corruption</h3>
                <p>MilKar highly condemns and says No to any kind of corruption and also follows a strict policy against harassment, any such consequence shall have a strict action to it. We believe in equality and any abusive or authoritative behaviour towards fellow colleagues is not entertained.</p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "CommunityView",
    components: {
    },
    props: {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
    async mounted() {},
};
</script>
  
<template>
    <section>
        <div class="flex justify-content-between">
            <div class="d-flex align-items-center flex-wrap">
                <h3 class="me-3">Recent Activities Organized
                </h3>
            </div>
        </div>
        <div class="row flex-wrap row-gap-3 mb-2">
            <div v-for="(event, index) in displayedEvents" :key="index" class="col-lg-6 col-md-6 col-sm-12 d-flex">
                <div class="card bg-white rounded p-3 d-flex flex-row flex-wrap row-gap-2">
                    <div class="col-lg-3 col-md-12 col-sm-12 px-0 d-flex justify-content-center flex-grow-1">
                        <img v-if="!event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                            class="border-radius img-fluid" />
                        <!-- <img v-else class="border-radius img-fluid" :src="this.imageurl + '/' + event.thumbnail_picture"
                            :alt="event.name" /> -->
                        <Image v-else class="border-radius img-fluid"
                            :src="this.imageurl + '/' + event.thumbnail_picture" :alt="event.name" preview />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 fs-7 px-3 d-flex flex-column row-gap-1">
                        <a :href="baseUrl + '/activities/' + event.slug">
                            <h3 title="" class="mb-0 mt-1 text-truncate-1 fw-bold fs-6">
                                {{ event.name }}
                            </h3>
                        </a>
                        <div class="m-0 d-flex flex-wrap align-items-start gap-2 fs-8">
                            <span class="d-flex flex-column">
                                <label class="fs-8">Date</label>
                                <span v-if="event.formattedStartDate && event.formattedEndDate" class="fw-bold">
                                    {{ event.formattedStartDate }} - {{ event.formattedEndDate }}
                                </span>
                            </span>
                        </div>
                        <p class="d-flex flex-row text-truncate-2 mb-0">
                            {{ event.event_type.name }} by
                            <a :href="baseUrl + '/organizations/' + event.OwenerDetail[0].slug" class="text-primary">
                                {{ event.OwenerDetail[0].name }}
                            </a>
                        </p>
                    </div>
                    <div
                        class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card py-2 flex-grow-1">
                        <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                            <span class="badge-counter-text d-flex flex-column row-gap-2 px-0 fs-9">
                                Volunteers Engaged
                                <span v-if="event.registeredVolunteersCount > 0" class="counter mw-100 w-100 ml-0">
                                    {{ event.registeredVolunteersCount }}
                                </span>
                                <span v-else class="counter fs-7 text-muted mw-100 w-100 ml-0"
                                    v-tooltip.bottom="'Not Added'">
                                    N/A
                                </span>
                            </span>
                        </div>
                        <a v-tooltip.bottom="'View Activity'" :href="baseUrl + '/activities/' + event.slug"
                            class="btn btn-primary w-100 px-1 py-2 fs-8">
                            <i class="fa-solid fa-eye pr-1"></i>View
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <Paginator v-model:first="first" :rows="2" :totalRecords="totalRecords"
            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            :currentPageReportTemplate="currentPageReportTemplate" />
    </section>
</template>
<script>
import { getOrganizationEvents } from "@/api";
import Paginator from 'primevue/paginator';
import Image from 'primevue/image';


export default {
    components: {
        Paginator,
        Image,
    },
    data() {
        return {
            imageurl: null,
            organizationEventsData: Array,
            organizationEvents: [],
            loading: false,
            avgRating: 0,
            fullRating: 0,
            halfRating: 0,
            remainingStars: 0,
            first: 0,
            pageNo: 1,
            perPage: 4,
        };
    },
    props: {
        slug: {
            type: String,
            required: true
        },
    },
    computed: {
        totalRecords() {
            return this.organizationEvents.length;
        },
        displayedEvents() {
            const startIndex = this.first;
            const endIndex = startIndex + this.perPage; // Display 8 records per page
            return this.organizationEvents.slice(startIndex, endIndex);
        },
        currentPageReportTemplate() {
            const startIndex = this.first + 1;
            const endIndex = this.first + this.displayedEvents.length;
            return `Showing ${startIndex} to ${endIndex} of ${this.totalRecords}`;
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const params = {
                slug: this.slug,
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getOrganizationEvents(params);
            this.organizationEventsData = res;
            // console.log(res);
            this.organizationEvents = this.organizationEventsData;
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        }
    }
};
</script>

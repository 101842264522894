<template>
   <VolunteerAbout>
    
   </VolunteerAbout>
</template>
<script>
    import VolunteerAbout from "@/components/Volunteers/VolunteerAbout.vue";

    export default {
        components: {
            VolunteerAbout,
        },
    }
</script>

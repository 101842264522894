<template>
  <header class="container-fluid sticky-top p-component p-menubar-mobile-active px-0 pb-0">

    <nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar">
      <a v-if="!isAuthenticated" class="navbar-brand" href="/">
        <img src="../assets/img/logo.png" alt="logo" />
      </a>
      <a v-else-if="isAuthenticated && role == 0" class="navbar-brand" href="/volunteer-dashboard">
        <img src="../assets/img/logo.png" alt="logo" />
      </a>
      <a v-else-if="isAuthenticated && role == 1" class="navbar-brand" href="/organization-dashboard">
        <img src="../assets/img/logo.png" alt="logo" />
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <div class="social-media order-lg-last">
          <span class="mb-0 d-flex justify-content-center">
            <div v-if="isAuthenticated" class="d-flex align-items-center user-section">
              <!-- Request-->
              <div v-if="this.organization && this.organization.is_completed == 1" class="d-flex">
                <Requests :isAuthenticated="this.isAuthenticated" :role="this.role"></Requests>
                <!-- Notification-->
                <Notifications :isAuthenticated="this.isAuthenticated" :role="this.role"></Notifications>
              </div>
              <Requests :isAuthenticated="this.isAuthenticated" :role="this.role" v-if="this.volunteer"></Requests>
              <!-- Notification-->
              <Notifications :isAuthenticated="this.isAuthenticated" :role="this.role" v-if="this.volunteer">
              </Notifications>

              <!-- users-->
              <div class="user-action-wrap">
                <div v-if="this.organization && this.organization.is_completed == 0">
                  <a :href="'/completeprofile'" class="btn btn-secondary"> dashboard </a>
                  <button @click="logout" type="submit" class="btn btn-primary ml-1">Logout</button>
                </div>
                <dropdown-menu v-else>
                  <template #trigger>
                    <button v-if="volunteer"
                      class="dot-status online-status dropdown-toggle text-chrach-limit-20 text-truncate user-img-box"
                      type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true">
                      <img v-if="volunteer && volunteer.profile_picture" :src="this.image">
                      <img v-else :src="this.$volunteerNoImage(this.volunteer ? this.volunteer.gender : '')"
                        class="border-radius img-fluid" width="100" shape="circle" />
                    </button>
                    <button v-if="organization"
                      class="dot-status online-status dropdown-toggle text-chrach-limit-20 text-truncate user-img-box"
                      type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="true">
                      <img v-if="organization && organization.logo" :src="this.image">
                      <img v-else src="@/assets/img/org_placeholder.png" class="border-radius img-fluid" width="100"
                        shape="circle" />
                    </button>
                  </template>
                  <template #header>
                    <div class="mess__title d-flex align-items-center">
                      <div v-if="volunteer" class="image dot-status online-status">
                        <img v-if="volunteer && volunteer.profile_picture" :src="this.image" :alt="this.name">
                        <img v-else :src="this.$volunteerNoImage(this.volunteer ? this.volunteer.gender : '')"
                          :alt="this.name">
                      </div>
                      <div v-if="organization" class="image dot-status online-status">
                        <img v-if="organization && organization.logo" :src="this.image" :alt="this.name">
                        <img v-else src="@/assets/img/org_placeholder.png" :alt="this.name">
                      </div>
                      <div class="content">
                        <h4 class="widget-title font-size-16 text-truncate-1" v-tooltip.top="this.name">
                          <a href="">{{ this.name }}</a>
                        </h4>
                        <span class="email text-truncate-1 text-break"
                          v-tooltip.top="this.email">{{ this.email }}</span>
                      </div>
                    </div><!-- end mess__title -->
                  </template>
                  <template #body>
                    <div class="mess__body" v-if="isAuthenticated && role == 0">
                      <ul class="list-items">
                        <li class="mb-0">
                          <a :href="baseUrl + '/volunteers/' + slug" class="d-block">
                            <i class="far fa-user"></i> My Profile
                          </a>
                        </li>
                        <li class="mb-0">
                          <a href="/volunteer-dashboard/update-settings" class="d-block">
                            <i class="far fa-edit"></i> Update Profile
                          </a>
                        </li>
                        <li class="mb-0">
                          <a href="/volunteer-dashboard" class="d-block">
                            <i class="far fa-address-card"></i> Dashboard
                          </a>
                        </li>

                        <li class="mb-0">
                          <a href="https://stage.milkar.com/volunteer-dashboard/unsubscribe-emails" class="d-block">
                            <i class="far fa-envelope"></i> Unsubscribe Emails
                          </a>
                        </li>
                        <li class="mb-0">
                        </li>
                        <li class="mb-0">
                          <div class="section-block mt-3 mb-3"></div>
                        </li>

                        <li class="mb-0">
                          <button @click="logout" type="submit" class="btn btn-secondary w-100">Logout</button>
                          <a href="/" @click="logout" id="logout-btn" class="d-block pointer d-none">
                            <i class="fas fa-power-off"></i> Logout
                          </a>
                        </li>
                      </ul>
                    </div><!-- end mess__body -->
                    <div class="mess__body" v-if="isAuthenticated && role == 1">
                      <ul class="list-items">
                        <li class="mb-0">
                          <a :href="baseUrl + '/organizations/' + slug" class="d-block">
                            <i class="far fa-user"></i> My Profile
                          </a>
                        </li>
                        <li class="mb-0">
                          <a href="/organization-dashboard/update-settings" class="d-block">
                            <i class="far fa-edit"></i> Update Profile
                          </a>
                        </li>
                        <li class="mb-0">
                          <a href="/volunteer-dashboard" class="d-block">
                            <i class="far fa-address-card"></i> Dashboard
                          </a>
                        </li>
                        <li class="mb-0">
                          <a href="https://stage.milkar.com/volunteer-dashboard/unsubscribe-emails" class="d-block">
                            <i class="far fa-envelope"></i> Unsubscribe Emails
                          </a>
                        </li>
                        <li class="mb-0">
                        </li>
                        <li class="mb-0">
                          <div class="section-block mt-3 mb-3"></div>
                        </li>
                        <li class="mb-0">
                          <button @click="logout" type="submit" class="btn btn-secondary w-100">Logout</button>
                          <a href="/" @click="logout" id="logout-btn" class="d-block pointer d-none">
                            <i class="fas fa-power-off"></i> Logout
                          </a>
                        </li>
                      </ul>
                    </div><!-- end mess__body -->
                  </template>
                </dropdown-menu>
              </div>
            </div>
            <div v-else class="d-lg-flex align-items-center flex-row d-none">
              <div class="">
                <router-link id="login-btn" class="btn btn-secondary me-2 text-center" data-style="zoom-in"
                  to="/login"><i class="fa-solid fa-arrow-right-to-bracket me-2"></i>Login</router-link>
              </div>
              <div class="">
                <router-link id="register-btn" class="btn btn-primary text-center" to="/register"><i
                    class="fa-regular fa-address-card me-2"></i>Register</router-link>
              </div>
            </div>
          </span>
        </div>
        <ul class="navbar-nav mb-2 mb-lg-0 p-menubar-root-list p-menubar-dropdown py-lg-0 mr-3">
          <li class="nav-item p-menuitem">
            <div class="p-menuitem-content">
              <a v-if="!isAuthenticated" class="nav-link mb-close-nav p-menuitem-link" href="/"
                :class="{ 'active': currentPath === '/' }">Home</a>
              <a v-else-if="isAuthenticated && role == 0" class="nav-link mb-close-nav p-menuitem-link"
                href="/volunteer-dashboard" :class="{ 'active': currentPath === '/volunteer-dashboard' }">Home</a>
              <a v-else-if="isAuthenticated && role == 1" class="nav-link mb-close-nav p-menuitem-link"
                href="/organization-dashboard" :class="{ 'active': currentPath === '/organization-dashboard' }">Home</a>
            </div>
          </li>
          <li class="nav-item p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link mb-close-nav p-menuitem-link" href="/map"
                :class="{ 'active': currentPath === '/map' }">Map</a>
            </div>
          </li>
          <li class="nav-item dropdown p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link dropdown-toggle p-menuitem-link" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false"
                :class="{ 'active': currentPath === '/team' || currentPath === '/ambassadors' }">
                About Us
              </a>
              <ul class="dropdown-menu p-submenu-list" aria-labelledby="navbarDropdown">
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/team"
                      :class="{ 'active': currentPath === '/team' }">
                      Our Team
                    </a>
                  </div>
                </li>
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/ambassadors"
                      :class="{ 'active': currentPath === '/ambassadors' }">
                      Our Ambassadors</a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link mb-close-nav p-menuitem-link" href="/activities"
                :class="{ 'active': currentPath === '/activities' }">
                Activities
              </a>
            </div>
          </li>
          <li class="nav-item p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link mb-close-nav p-menuitem-link" href="/volunteers"
                :class="{ 'active': currentPath === '/volunteers' }">
                Volunteers
              </a>
            </div>
          </li>
          <li class="nav-item dropdown p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link dropdown-toggle p-menuitem-link" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false"
                :class="{ 'active': currentPath === '/organizations' || currentPath === '/institutes' }">
                Partners
              </a>
              <ul class="dropdown-menu p-submenu-list" aria-labelledby="navbarDropdown">
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/organizations"
                      :class="{ 'active': currentPath === '/organizations' }">
                      Not For Profit Organizations
                    </a>
                  </div>
                </li>
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/institutes"
                      :class="{ 'active': currentPath === '/institutes' }">
                      Educational Institutes
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item dropdown p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link dropdown-toggle p-menuitem-link" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false"
                :class="{ 'active': currentPath === '/projects' || currentPath === '/milkar-champions' || currentPath === '/press' || currentPath === '/blogs' || currentPath === '/milkar-champions' }">
                Our Work
              </a>
              <ul class="dropdown-menu p-submenu-list" aria-labelledby="navbarDropdown">
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/projects"
                      :class="{ 'active': currentPath === '/projects' }">
                      Our Projects
                    </a>
                  </div>
                </li>
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/milkar-champions"
                      :class="{ 'active': currentPath === '/milkar-champions' }">
                      Milkar Champions
                    </a>
                  </div>
                </li>
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/press"
                      :class="{ 'active': currentPath === '/press' }">
                      Press
                    </a>
                  </div>
                </li>
                <li class="p-menuitem">
                  <div class="p-menuitem-content">
                    <a class="dropdown-item p-menuitem-link" href="/blogs"
                      :class="{ 'active': currentPath === '/blogs' }">
                      Blog
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item p-menuitem">
            <div class="p-menuitem-content">
              <a class="nav-link mb-close-nav p-menuitem-link" href="/contact"
                :class="{ 'active': currentPath === '/contact' }">
                Contact Us
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div v-if="!isAuthenticated" class="fixed-bottom d-flex d-lg-none p-0">
      <div class="flex-grow-1">
        <router-link id="login-btn" class="btn-mobile-login text-center" data-style="zoom-in" to="/login"><i
            class="fa-solid fa-arrow-right-to-bracket me-2"></i>Login</router-link>
      </div>
      <div class="flex-grow-1">
        <router-link id="register-btn" class="btn-mobile-register text-center" to="/register"><i
            class="fa-regular fa-address-card me-2"></i>Register</router-link>
      </div>
      <!-- <div class="mobile-btn text-center d-flex">
        <a href="#" class="btn-mobile-login"><i class="fa-solid fa-arrow-right-to-bracket pr-1"></i> Login</a>
        <a href="#" class="btn-mobile-register"><i class="fa-regular fa-address-card pr-1"></i> Register</a>
      </div> -->
    </div>
  </header>
</template>
<script>
import { getVolunteer } from "@/api";
import DropdownMenu from 'v-dropdown-menu'
import { mapGetters, mapActions } from 'vuex';
import Menubar from 'primevue/menubar';
import Requests from "@/components/Notifications/RequestsComponent";
import Notifications from "@/components/Notifications/NotificationsComponent";


export default
  {
    data() {
      return {
        baseUrl: null,
        imageurl: null,
        email: '',
        name: '',
        image: '',
        currentPath: '',
        role: '',
        slug: '',
        volunteer: null,
        organization: null,
      };
    },
    props: {
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
    },
    components: {
      DropdownMenu,
      Menubar,
      Requests,
      Notifications,
    },
    async mounted() {
      this.baseUrl = window.location.origin;
      this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
      this.currentPath = this.$route.path; // Initialize currentPath with the current route path
      this.$router.afterEach(this.handleRouteChange); // Listen for route changes
      if (this.isAuthenticated) {
        const user = localStorage.getItem("user");
        const parsedUser = JSON.parse(user);

        this.role = parsedUser.role;
        if (this.role == 0) {
          const volunteer = localStorage.getItem("volunteer");
          const parsedVolunteer = JSON.parse(volunteer);
          const resVol = await getVolunteer(parsedVolunteer.slug);
          this.volunteer = resVol;
          this.image = this.imageurl + '/' + this.volunteer.profile_picture_thumb;
          this.slug = parsedVolunteer.slug;
        }
        if (this.role == 1) {
          const organization = localStorage.getItem("organization");
          const parsedOrganization = JSON.parse(organization);
          this.organization = parsedOrganization;
          if (parsedOrganization.logo) {
            this.image = this.imageurl + '/' + parsedOrganization.logo;
          }
          else {
            this.image = require('@/assets/img/org_placeholder.png');
          }
          this.slug = parsedOrganization.slug;
        }
        this.email = parsedUser.email;
        this.name = parsedUser.name;
      }
    },
    methods: {
      ...mapActions(['logout']),
      handleRouteChange(to, from) {
        this.currentPath = to.path; // Update currentPath when the route changes
      },
    },
  }
</script>
<style lang="scss">
@import "../assets/css/header-footer.css";

// Other custom styles
a.nav-link.active {
  // Add your active button styles here...
  // For example:
  background-color: #007bff;
  color: #fff;
}
</style>

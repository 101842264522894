import store from '../store';

export function authGuard(to, from, next) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  // console.log(user);
  const parsedUser = JSON.parse(user);
  const isLoggedIn = token && user ? true : false;
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login');
  } 
  const authenticated  = token ? true : false;
  if (authenticated ) {
    const userRole = parsedUser.role;
    store.commit('setAuthenticated', true);
    if (userRole === 0) {
      if (to.name === "login" || to.name === "register") {
        next("/volunteer-dashboard");
      }
      // User is a volunteer
      if (to.name === "volunteer-dashboard") {
        next(); // Allow access to volunteer dashboard for role 0
      } 
      else if (to.name === "organization-update-profile") 
      {
        next("/volunteer-dashboard");
      }
      else 
      {
        if (to.name === "organization-dashboard" || to.name === "organization-activity-dashboard") {
          next("/volunteer-dashboard");
        }
        else {
          next();
        }
      }
    } 

    
    else if (userRole === 1) {
      // alert('jfg');
      const org = JSON.parse(localStorage.getItem('organization'));
      if (!parsedUser.is_email_verified) {
        next({ name: 'organization-verify' });
      }
      else if(org && !org.is_completed) {
        next({ name: 'completeprofile' });
      }
      else {
          // User is an organization
        if (to.name === "login" || to.name === "register") {
            next("/organization-dashboard");
        }
        if (to.name === "organization-dashboard") {
          next(); // Allow access to organization dashboard for role 1
        }
        else if (to.name === "volunteer-update-profile" || to.name === "volunteer-activity-dashboard" || to.name === "volunteer-activity-update") 
        {
          next("/organization-dashboard");
        } 
        else {
          if (to.name === "volunteer-dashboard") {
            next("/organization-dashboard");
          }
          else {
            next();
          }
        }
      }
      
      
    }
  } else {
    store.commit('setAuthenticated', false);
    next("/");
  }
}

<template>
  <section class="dashboard container-fluid py-3">
    <div class="container-xxxl">
      <Toast ref="toast" position="top-right"></Toast>
      <div class="card w-100">
        <div class="card-body p-4 row mx-0 row-gap-3">
          <div class="row row-gap-3">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <img v-if="this.imgDataUrl" :src="this.imgDataUrl" class="img-fluid rounded" />
              <img v-else-if="!volunteer.profile_picture" :src="this.$volunteerNoImage(this.volunteer.gender)"
                class="img-fluid rounded" />
              <Image v-else class="img-fluid rounded" :src="this.image" :alt="volunteer.slug" preview
                id="uploaded_images" />
              <i class="fa fa-check-circle text-success font-20 fa-check-circl d-none"></i>
              <button for="upload_images" class="btn btn-primary w-100" @click="toggleShow">
                <i class="fa-solid fa-upload pr-1"></i>
                Upload Profile Picture
              </button>
            </div>
            <div class="col-lg-9 col-md-6 col-sm-12">
              <h3>Basic Information</h3>
              <Divider></Divider>
              <div class="row mx-0">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Volunteer Name</label>
                  <input type="text" v-model="volunteerName" id="name" name="name" class="form-control"
                    placeholder="Volunteer Name" :disabled="volunteerName !== ''">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Email Address</label>
                  <input type="email" v-model="emailAdress" id="email" name="email" class="form-control"
                    placeholder="Email Address" :disabled="emailAdress !== ''">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Phone Number <small>(will not be published)</small><span
                      class="text-danger ml-1">*</span></label>
                  <input type="tel" v-model="phoneNumber" id="phone" name="phone" class="form-control "
                    placeholder="Phone (0300xxxxxxx)" maxlength="11" :disabled="phoneNumber !== '' && !isSocialUser">
                  <span v-if="this.validationErrors.phone"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.phone }}</span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Date of birth<span class="text-danger ml-1">(Min age must be 10 years)*</span></label>
                  <Calendar v-model="dateOfBirth" :maxDate="maxSelectableDate" placeholder="Date of Birth"
                    class="w-100" />
                  <span v-if="this.validationErrors.dateOfBirth"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.dateOfBirth }}</span>
                </div>
                <div class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label>Gender<span class="text-danger ml-1">*</span></label>
                  <div class="flex flex-wrap gap-3 my-3">
                    <div class="flex align-items-center">
                      <RadioButton v-model="gender" inputId="male" name="male" value="male"
                        :checked="gender === 'male'" />
                      <label for="male" class="ml-2">Male</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton v-model="gender" inputId="female" name="female" value="female"
                        :checked="gender === 'female'" />
                      <label for="female" class="ml-2">Female</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton v-model="gender" inputId="other" name="other" value="other"
                        :checked="gender === 'other'" />
                      <label for="other" class="ml-2">Other</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab header="More Information">
              <div class="row mx-0">
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Employment Status<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedEmploymentStatus" :options="employmentStatuses" filter optionLabel="name"
                    placeholder="Employment Status" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedEmploymentStatus"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEmploymentStatus }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Education Level<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedEducation" :options="education" filter optionLabel="name"
                    placeholder="Education Level" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedEducation"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEducation }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Subject Area<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedEducationField" :options="educationField" filter optionLabel="name"
                    placeholder="Subject Area" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        {{ slotProps.value.name }}
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        {{ slotProps.option.name }}
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedEducationField"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEducationField }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Educational Institute<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedUniversity" :options="universities" filter optionLabel="name"
                    placeholder="Educational Institute" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        {{ slotProps.value.name }}
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        {{ slotProps.option.name }}
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedUniversity"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedUniversity }}</span>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label class="font-12-mb">Session Start Year</label>
                  <div class="form-group">
                    <Calendar v-model="sessionStart" placeholder="Session Start Date" class="w-100" />
                    <span v-if="this.validationErrors.sessionStart"
                      class="text-danger empty-1 text-validation">{{ this.validationErrors.sessionStart }}</span>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <label class="font-12-mb">Session End Year</label>
                  <div class="form-group">
                    <Calendar v-model="sessionEnd" placeholder="Session End Date" class="w-100" />
                    <span v-if="this.validationErrors.sessionEnd"
                      class="text-danger empty-1 text-validation">{{ this.validationErrors.sessionEnd }}</span>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <label class="font-12-mb">Role Number/ Registation Number</label>
                  <input type="text" v-model="roll_number" id="rollNumber" name="rollNumber" class="form-control"
                    placeholder="Role Number/ Registation Number">
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Event Types<span class="text-danger ml-1">*</span></label>
                  <MultiSelect v-model="selectedEventTypes" display="chip" :options="eventTypes" :selectionLimit="4"
                    optionLabel="name" placeholder="Select Event Types" :maxSelectedLabels="100" class="w-100" />
                  <span v-if="this.validationErrors.selectedEventTypes"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEventTypes }}</span>
                </div>

                <div class="col-md-4 col-sm-12 form-group">
                  <label>Skills<span class="text-danger ml-1">*</span></label>
                  <MultiSelect v-model="selectedSkills" display="chip" :options="skills" :selectionLimit="4"
                    optionLabel="name" placeholder="Select Skills" :maxSelectedLabels="100" class="w-100" />
                  <span v-if="this.validationErrors.selectedSkills"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedSkills }}</span>
                </div>

                <div class="col-md-4 col-sm-12 form-group">
                  <label>Causes<span class="text-danger ml-1">*</span></label>
                  <MultiSelect v-model="selectedCauses" display="chip" :options="causes" optionLabel="name"
                    :selectionLimit="4" placeholder="Select Causes" :maxSelectedLabels="100" class="w-100" />
                  <span v-if="this.validationErrors.selectedCauses"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedCauses }}</span>
                </div>

                <div class="col-md-4 col-sm-12">
                  <label class="font-12-mb">Province<span class="text-danger ml-1">*</span></label>
                  <div class="form-group">
                    <Dropdown v-model="selectedProvince" :options="provinces" filter optionLabel="name"
                      placeholder="Province" class="w-100" @change="changeProvinces()">
                      <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                          <div>{{ slotProps.value.name }}</div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          <div>{{ slotProps.option.name }}</div>
                        </div>
                      </template>
                    </Dropdown>
                    <span v-if="this.validationErrors.selectedProvince"
                      class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedProvince }}</span>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>City<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedCity" :options="provinceCities" :disabled="!selectedProvince" filter
                    optionLabel="name" placeholder="City" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedCity"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedCity }}</span>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Residential Address (will not be published)*</label>
                    <AutoComplete v-model="address" @location-sent="onLocationSent" :userAddress="address"></AutoComplete>
                    <Chip v-model="newAddress" v-if="newAddress" :removable="true" @remove="removeLocation(newAddress)"
                      :label="newAddress" icon="fa-solid fa-location-dot" class="mt-3" />
                    <Chip v-model="selectedLocations" v-if="selectedLocations && !newAddress" :label="selectedLocations"
                      icon="fa-solid fa-location-dot" class="mt-3" />
                    <span v-if="this.validationErrors.address"
                      class="text-danger empty-1 text-validation">{{ this.validationErrors.address }}</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="font-12-mb">About Yourself<span class="text-danger ml-1">*</span></label>
                    <textarea class="form-control" v-model="about" rows="10" style="min-height: 110px"></textarea>
                    <span v-if="this.validationErrors.about"
                      class="text-danger empty-1 text-validation">{{ this.validationErrors.about }}</span>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Social Profiles">
              <p class="text-capitalize">
                Link Your Social Profiles for Better Connectivity
              </p>
              <div class="row">
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername" class="text-capitalize">Facebook</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-facebook text-primary"></i>
                    </span>
                    <InputText v-model="facebook" class="flex-grow-1" placeholder="https://facebook.com/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Twitter</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-twitter"></i>
                    </span>
                    <InputText type="text" v-model="twitter" name="twitter" class="flex-grow-1"
                      placeholder="https://twitter.com/username" />

                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Linkedin</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-linkedin"></i>
                    </span>
                    <InputText type="text" v-model="linkedin" name="linkedin" class="flex-grow-1"
                      placeholder="https://linkedin.com/in/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Instagram</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-instagram"></i>
                    </span>
                    <InputText type="text" v-model="instagram" name="instagram" class="flex-grow-1"
                      placeholder="https://instagram.com/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Snapchat</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-snapchat-ghost"></i>
                    </span>
                    <InputText type="text" v-model="snapchat" name="snapchat" class="flex-grow-1"
                      placeholder="https://snapchat.com/username" />
                  </div>
                </div>
              </div>

            </AccordionTab>

          </Accordion>
          <div class="row">
            <div class="col-md-12 text-center text-md-right">
              <button type="submit" class="btn btn-primary d-inline-flex align-items-center gap-1 save_btn"
                :disabled="showSpinner" @click="updateProfile()"><i v-if="!showSpinner"
                  class="fa-solid fa-floppy-disk pr-1"></i>
                Save Volunteer Profile
                <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8" fill="transparent"
                  animationDuration="2s" aria-label="Custom ProgressSpinner" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <my-upload field="img" @crop-success="cropSuccess" @crop-upload-fail="cropUploadFail" v-model="show" img-format="png"
    lang-type="en" @change="handleFileSelected"></my-upload>
</template>
<script>
import { getVolunteerDetail, updateVolunteerProfile, volunteerDataApi, getVolunteer, getVolunteerUserDeatails } from "@/api";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from "@/components/AutoComplete";
import { GoogleMap } from 'vue3-google-map'
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeflex/primeflex.css';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Chip from 'primevue/chip';
import myUpload from 'vue-image-crop-upload';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';

export default {
  components: {
    // HomeComponent,
    TabView,
    TabPanel,
    Image,
    Calendar,
    RadioButton,
    Dropdown,
    MultiSelect,
    GoogleMap,
    AutoComplete,
    Textarea,
    Button,
    ListingSkeleton,
    Accordion,
    AccordionTab,
    Divider,
    InputText,
    Chip,
    myUpload,
    ProgressSpinner,
    Toast,
  },
  data() {
    // const maxYear = 2012;
    const currentDate = new Date();
    const maxSelectableDate = new Date();
    maxSelectableDate.setFullYear(currentDate.getFullYear() - 10);
    return {
      selectedLocations: null,
      newAddress: null,
      imageurl: null,
      about: null,
      volunteer: [],
      user: [],
      image: '',
      loading: true, // To show loading message
      errored: false, // To show error message
      volunteerName: null,
      emailAdress: null,
      phoneNumber: null,
      // maxSelectableDate: new Date(new Date().getFullYear() - 14, new Date().getMonth(), new Date().getDate()),
      maxSelectableDate,
      dateOfBirth: null,
      selectedEmploymentStatus: null,
      selectedEducation: null,
      selectedCity: null,
      selectedProvince: null,
      selectedEducationField: null,
      selectedUniversity: null,
      employmentStatuses: [],
      eventTypes: [],
      skills: [],
      causes: [],
      universities: [],
      education: [],
      educationField: [],
      gender: null,
      provinces: [],
      cities: [],
      provinceCities: [],
      selectedEventTypes: [],
      selectedSkills: [],
      selectedCauses: [],
      sessionStart: null,
      sessionEnd: null,
      // social models 
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      snapchat: null,
      validationErrors: [],
      address: null,
      show: false,
      imgDataUrl: null,
      orgiginalIamge: null,
      showSpinner: false,
      isSocialUser: null,
    };
  },
  async mounted() {
    this.baseUrl = window.location.origin;
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    try {
      const vvolunteer = localStorage.getItem("volunteer");
      const parsedVolunteer = JSON.parse(vvolunteer);
      const res = await getVolunteerDetail();
      const resVol = await getVolunteer(parsedVolunteer.slug);
      this.volunteer = resVol;
      // this.user = parsedUser;
      this.user = resVol;
      // console.log(this.user.name);

      this.volunteerName = resVol.user.name;
      this.emailAdress = resVol.user.email;
      this.phoneNumber = resVol.user.phone;
      this.isSocialUser = resVol.user.gmail_id;
      if(resVol.gender == 'm') {
        this.gender = 'male';
      } else if(resVol.gender == 'f') {
        this.gender = 'female';
      } else if(resVol.gender == 'o') {
        this.gender = 'other';
      }
      // this.gender = resVol.gender == 'm' ? 'male' : 'female';
      this.about = resVol.about;
      this.roll_number = resVol.roll_number;
      this.sessionStart = resVol.session_start_year;
      this.sessionEnd = resVol.session_end_year;
      this.selectedLocations = resVol.address;
      //asigning  data to socials models 
      this.facebook = resVol.facebook;
      this.twitter = resVol.twitter;
      this.linkedin = resVol.linkedin;
      this.instagram = resVol.instagram;
      this.snapchat = resVol.snapchat;
      //all the dropdowns & multiselect arrays data
      this.employmentStatuses = res.employmentStatus;
      this.skills = res.skills;
      this.eventTypes = res.eventType;
      this.causes = res.causes;
      this.universities = res.universities;
      this.provinces = res.provinces;
      this.cities = res.cities;
      this.education = res.education;
      this.educationField = res.educationField;

      //select dropdowns & multiselect values
      const selectedOption = this.employmentStatuses.find(status => status.id === resVol.employment_status_id);
      if (selectedOption) {
        this.selectedEmploymentStatus = selectedOption;
      }
      const selectedEducationOption = this.education.find(ed => ed.id === resVol.education_id);
      if (selectedEducationOption) {
        this.selectedEducation = selectedEducationOption;
      }
      const selectedEducationFieldOption = this.educationField.find(edf => edf.id === resVol.education_field_id);
      if (selectedEducationFieldOption) {
        this.selectedEducationField = selectedEducationFieldOption;
      }
      const selectedUniversiyOption = this.universities.find(u => u.id === resVol.university_id);
      if (selectedUniversiyOption) {
        this.selectedUniversity = selectedUniversiyOption;
      }
      const selectedCityOption = this.cities.find(c => c.id === resVol.city_id);
      if (selectedCityOption) {
        this.selectedCity = selectedCityOption;
      }
      if (resVol.skills) {
        this.selectedSkills = resVol.skills;
      }
      if (resVol.event_types) {
        this.selectedEventTypes = resVol.event_types
      }

      if (resVol.causes) {
        this.selectedCauses = resVol.causes;
      }
      if (resVol.dob) {
        this.dateOfBirth = new Date(resVol.dob);
      }
      const selectedProvinceOption = this.provinces.find(status => status.id === resVol.province_id);
      if (selectedProvinceOption) {
        this.selectedProvince = selectedProvinceOption;
      }
      this.image = this.imageurl + '/' + resVol.profile_picture;
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loading = false;
    }
  },
  watch: {
    selectedProvince(newProvince) {
      // Filter cities based on the selected province and update the cities options
      if (newProvince) {
        this.provinceCities = this.cities.filter((city) => city.province_id === newProvince.id);
      } else {
        // If no province is selected, reset the cities options to empty
        //added data here 
        this.cities = [];
      }
    },
  },
  methods: {
    changeProvinces() {
      this.selectedCity = null;
    },
    getValue(value) {
      // console.log(value);
    },
    removeLocation(index) {
      const set = new Set([index]);
      const result2 = Array.from(set).slice(1);
      this.address = '';
      this.newAddress = '';
      // this.selectedLocations.splice(index, 1);
    },
    updateAddress(data) {
      this.newAddress = data.address;
      this.address = '';
      // You can also access latitude and longitude if needed: data.latitude, data.Longitude
    },
    onLocationSent(data) {
      this.newAddress = data.address;
    },
    toggleShow() {
      this.show = !this.show;
    },
    validateData() {
      this.validationErrors = {};
      if (!this.dateOfBirth) {
        this.validationErrors.dateOfBirth = 'Date of birth is required';
      }
      if (!this.about) {
        this.validationErrors.about = 'About is required';
      }
      if (!this.selectedEmploymentStatus) {
        this.validationErrors.selectedEmploymentStatus = 'Employment status is required';
      }
      if (!this.selectedEducation) {
        this.validationErrors.selectedEducation = 'Education level is required';
      }
      if (!this.selectedEducationField) {
        this.validationErrors.selectedEducationField = 'Subject area is required';
      }
      if (!this.selectedUniversity) {
        this.validationErrors.selectedUniversity = 'Educational institute is required';
      }
      // if (!this.sessionStart) {
      //   this.validationErrors.sessionStart = 'Session start year is required';
      // }
      // if (!this.sessionEnd) {
      //   this.validationErrors.sessionEnd = 'Session end year is required';
      // }
      if (!this.phoneNumber || this.phoneNumber.trim() === "") {
          this.validationErrors.phone = 'Phone is required';
        }
      const phoneNumber = this.phoneNumber.replace(/\D/g, '');
      if (phoneNumber.length !== 11) {
        this.validationErrors.phone = 'Phone number must be 11 digits long';
      }
      if (!Array.isArray(this.selectedEventTypes) || this.selectedEventTypes.length === 0) {
        this.validationErrors.selectedEventTypes = 'Event types is required';
      }
      if (!Array.isArray(this.selectedSkills) || this.selectedSkills.length === 0) {
        this.validationErrors.selectedSkills = 'Skills is required';
      }
      if (!Array.isArray(this.selectedCauses) || this.selectedCauses.length === 0) {
        this.validationErrors.selectedCauses = 'Causes is required';
      }

      // if(!this.selectedEventTypes) {
      //   this.validationErrors.selectedEventTypes = 'Event types is required';
      // }
      // if(!this.selectedSkills) {
      //   this.validationErrors.selectedSkills = 'Skills is required';
      // }
      // if(!this.selectedCauses) {
      //   this.validationErrors.selectedCauses = 'Causes is required';
      // }
      if (!this.selectedProvince) {
        this.validationErrors.selectedProvince = 'Province is required';
      }
      if (!this.selectedCity) {
        this.validationErrors.selectedCity = 'City is required';
      }
      if (!this.selectedLocations && !this.newAddress) {
        this.validationErrors.address = 'Address is required';
      }

      return Object.keys(this.validationErrors).length === 0;
    },
    async updateProfile() {
      this.showSpinner = true;
      if (!this.validateData()) {
        this.showSpinner = false;
        this.$refs.toast.add({ severity: 'error', summary: 'Oops...', detail: 'Something went wrong', life: 3000 });
        return;
      }
      const params = {
        phone: this.phoneNumber,
        dob: this.dateOfBirth,
        gender: this.gender,
        employment_status_id: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.id : null,
        education_id: this.selectedEducation ? this.selectedEducation.id : null,
        education_field_id: this.selectedEducationField ? this.selectedEducationField.id : null,
        university_id: this.selectedUniversity ? this.selectedUniversity.id : null,
        session_start_year: this.sessionStart,
        session_end_year: this.sessionEnd,
        roll_number: this.roll_number,
        event_types: this.selectedEventTypes ? this.selectedEventTypes.map(type => type.id) : null,
        skills: this.selectedSkills ? this.selectedSkills.map((skill) => skill.id) : null,
        causes: this.selectedCauses ? this.selectedCauses.map((cause) => cause.id) : null,
        province_id: this.selectedProvince ? this.selectedProvince.id : null,
        city_id: this.selectedCity ? this.selectedCity.id : null,
        address: this.newAddress ? this.newAddress : this.selectedLocations,
        about: this.about,
        facebook: this.facebook,
        twitter: this.twitter,
        linkedin: this.linkedin,
        instagram: this.instagram,
        snapchat: this.snapchat,
      };
      if (params.gender === 'male') {
        params.gender = 'm';
      } else if (params.gender === 'female') {
        params.gender = 'f';
      } else if (params.gender === 'other') {
        params.gender = 'o';
      }
      try {
        const res = await updateVolunteerProfile(params);
        if (res.status == 200) {
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Profile has been updated',
            showConfirmButton: false,
            timer: 1500,
            allowOutsideClick: false,
          });
          const userDeatil = await getVolunteerUserDeatails();
          localStorage.setItem('volunteer', JSON.stringify(userDeatil.data.volunteer));
          localStorage.setItem('user', JSON.stringify(userDeatil.data.user));
          window.location.href = this.baseUrl + '/volunteer-dashboard/';
        }
        // console.log(res.data);        
      } catch (error) {
          this.validationErrors = error.response.data.errors;
          let errorMessage = 'Something went wrong!';

          if (this.validationErrors && this.validationErrors.causes) {
              this.validationErrors.selectedCauses = this.validationErrors.causes[0] + 'Causes';
              errorMessage = `${this.validationErrors.selectedCauses}`;
          } else if (this.validationErrors && this.validationErrors.skills) {
              this.validationErrors.selectedSkills = this.validationErrors.skills[0] + 'Skills';
              errorMessage = `${this.validationErrors.selectedSkills}`;
          } else if (this.validationErrors && this.validationErrors.event_types) {
              this.validationErrors.selectedEventTypes = this.validationErrors.event_types[0] + 'Event types';
              errorMessage = `${this.validationErrors.selectedEventTypes}`;
          }

          this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              text: errorMessage,
              showConfirmButton: true,
          });
      }
      finally {
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    },
    cropSuccess(dataUrl) {
      this.imgDataUrl = dataUrl;
      const result = this.$getFileFormat(dataUrl, this.filename, this.imageType).then(file => {
        this.file = file;
        const path = this.$uploadSingleImage(this.file, this.orgiginalIamge).then(path => {
          const params = {
            slug: this.volunteer.slug,
            profile_picture: path[0],
            profile_picture_thumb: path[1]
          };
          this.$updateUserProfileImage(params);
        });

      })
    },
    handleFileSelected(event) {
      const selectedFile = event.target.files[0];
      this.orgiginalIamge = selectedFile;
      if (selectedFile) {
        const fileName = selectedFile.name;
        const fileType = selectedFile.type;
        this.filename = fileName
        this.imageType = fileType;
      }
    },
  }
};
</script>

<template>
    <div style="max-width: 600px; margin: 0 auto;" class="email-container">
        <h1>Verified</h1>
    </div>
</template>
<script>
import { verifyUser } from "@/api";

export default {
  props: {

  },
  components: {

  },
  data() {
    return {
        baseUrl: '',
    };
  },
  async mounted() {
    this.baseUrl = window.location.origin;
      const verifyToken = this.$route.params.token;
      var user = localStorage.getItem("user");
      user = JSON.parse(user);
      if(verifyToken && !user.is_email_verified) {
        try {
        const res = await verifyUser(verifyToken);
        if(res.status == 200) {
            const userData = res.data.user;
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(userData));
            this.$router.push("/completeprofile")
        }
        // console.log(res);
        } catch (error) {
            console.error(error);
        }
      }
      else {
        this.$router.push("/")
      }
  },
  methods: {

  },
};
</script>
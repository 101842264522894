<template>
  <MapComponent></MapComponent>
  <section class="bannersection_carousel">
    <hr class="hr-primary" />
    <div v-if="loadingHomeslider" class="row gap-3 my-2 flex-nowrap">
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
      <Skeleton width="11rem" height="7.5rem"></Skeleton>
    </div>
    <div v-else-if="homeslider.length > 8" ref="slider2" class="keen-slider my-2">
      <div v-for="(image, index) in homeslider" :key="index" class="keen-slider__slide"
        :class="'number-slide' + (index + 1)">
        <img :src="this.imageurl + '/' + image.thumbnail_picture" alt="Slider Image" style="height: 120px;">
      </div>
    </div>
    <div v-else-if="homeslider.length <= 8 && screenWidth < 1000" ref="slider2" class="keen-slider my-2">
      <div v-for="(image, index) in homeslider" :key="index" class="keen-slider__slide"
        :class="'number-slide' + (index + 1)">
        <img :src="this.imageurl + '/' + image.thumbnail_picture" alt="Slider Image" style="height: 120px;">
      </div>
    </div>
    <div v-else-if="homeslider.length <= 8 && screenWidth > 1000"
      style="display: flex; justify-content: center; margin: 10px 10px;">
      <div v-for="(image, index) in homeslider" :key="index" style="margin-right: 10px;">
        <img :src="this.imageurl + '/' + image.thumbnail_picture" alt="Slider Image" style="height: 120px;">
      </div>
    </div>
    <hr class="hr-primary" />
  </section>

  <PopularComponent></PopularComponent>
  <OverviewComponent></OverviewComponent>
  <OrganizationsComponent></OrganizationsComponent>
  <TestimonialComponent></TestimonialComponent>

  <section class="container-fluid py-5 milkar-footer">
    <div class="col-lg-10 milkar-footer-content">
      <h2 class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-0 text-white">Connecting People, Skills & Good Causes</h2>
      <p class="mb-4 py-0 mb-3 px-0">Download the app with everything for free. </p>
      <ul class="nav justify-content-center justify-content-lg-start app-button">
        <li class="me-2"><a href="https://apps.apple.com/pk/app/milkar/id1565517520"><img class="img-fluid lazy"
              src='../assets/img/appstore.png' alt="milkar.com apple store"></a></li>

        <li><a href="https://play.google.com/store/apps/details?id=org.milkar.pwa"><img class="img-fluid lazy"
              src='../assets/img/googlestore.png' alt="milkar.com playstore"></a></li>
      </ul>
    </div>
    <div class="mobile-image">
      <img class="img-fluid lazy" src="../assets/img/download-app-case.png" alt="milkar.com app download">
    </div>
  </section>
</template>

<script>

import { getSliderImages } from "@/api";
import "keen-slider/keen-slider.min.css"
import KeenSlider from "keen-slider"
import MapComponent from "../components/Home/MapComponent";
import PopularComponent from "../components/Home/PopularComponent";
import OverviewComponent from "../components/Home/OverviewComponent";
import OrganizationsComponent from "../components/Home/OrganizationsComponent";
import TestimonialComponent from "../components/Home/TestimonialComponent";
import Skeleton from 'primevue/skeleton';
import { ref } from 'vue'

const screenWidth = ref(screen.width)
const screenHeight = ref(screen.height)


const animation = { duration: 50000, easing: (t) => t }
export default {
  name: "HomeView",
  components: {
    MapComponent,
    PopularComponent,
    OverviewComponent,
    OrganizationsComponent,
    TestimonialComponent,
    Skeleton,
  },
  props: {},
  computed: {

  },
  data() {
    return {
      imageurl: null,
      markers: [],
      volunteersCount: Number,
      provinceWiseEvents: null,
      allEvents: [],
      heatMapinfos: [],
      eventsCount: Number,
      organizationsCount: Number,
      volunteers: [],
      allLocationsList: [],
      poularEvents: [],
      blogs: [],
      ambassadors: [],
      homeslider: [],
      loading: true,
      loadingHomeslider: true, // To show loading message
      errored: false, // To show error message added comments
      slider: null,
      slider2: null,
      screenWidth: null,
      //heat map option are here 
    };
  },
  methods: {

  },
  async mounted() {
    this.screenWidth = screen.width;
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    try {
      /* eslint-disable */

      this.homeslider = await getSliderImages();
      this.loadingHomeslider = false;
      await this.$nextTick();
      this.slider2 = new KeenSlider(this.$refs.slider2, {
        loop: true,
        renderMode: "performance",
        drag: true,
        slides: {
          perView: 8,
          spacing: 6,
        },
        breakpoints: {
          '(min-width: 350px)': {
            slides: { perView: 3, spacing: 6 },
          },
          '(min-width: 1000px)': {
            slides: { perView: 10, spacing: 6 },
          },
        },
        created(s) {
          s.moveToIdx(5, true, animation)
        },
        updated(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
        animationEnded(s) {
          s.moveToIdx(s.track.details.abs + 5, true, animation)
        },
      });
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    if (this.slider2) this.slider2.destroy();
  },
};
</script>

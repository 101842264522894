<template>
    <section class=" container-fluid py-3">
        <div class="container-xxxl">
            <!-- <Breadcrumb :home="home" :model="items" class="text-capitalize mb-3">
                <template #item="{ label, item, props }">
                    <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
                        <a :href="routerProps.href" v-bind="props.action">
                            <span v-bind="props.icon" class="text-primary" />
                            <span v-bind="props.label">{{ label }}</span>
                        </a>
                    </router-link>
                    <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                        <span v-if="item.icon" v-bind="props.icon" />
                        <span v-bind="props.label">{{ label }}</span>
                    </a>
                </template>
</Breadcrumb> -->
            <div v-if="pageLoading" class="row" style="z-index: 99;">
                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-2">
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                            <Skeleton class="w-100 border-round h-3rem" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card">
                        <div class="card-body d-flex flex-column row-gap-1">
                            <div class="d-flex align-items-center justify-content-between gap-2">
                                <Skeleton class="w-20rem border-round h-3rem" />
                                <Skeleton class="w-6rem border-round h-3rem" />
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2">
                                <div class="col-lg-2">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                            <Divider></Divider>
                            <div class="row mx-0 row-gap-2">
                                <div class="col-lg-2">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-lg-3">
                    <div class="card">
                        <Sidebar v-model:visible="visible">
                            <div class="card-body">
                                <h3 class="">Filter Options</h3>
                                <div class="d-flex flex-column align-items-start gap-2 mb-2">
                                    <InputText v-model="volunteerName" type="text" placeholder="Name" class="w-100" />
                                    <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                                        placeholder="Province" class="w-100" />
                                    <Dropdown v-model="selectedCity" :options="cities" optionLabel="name"
                                        placeholder="City" class="w-100" :disabled="!selectedProvince" />
                                    <MultiSelect v-model="selectedSkills" display="chip" :options="skills"
                                        optionLabel="name" placeholder="Select Skills" :maxSelectedLabels="100"
                                        class="w-100" />
                                    <MultiSelect v-model="selectedEducation" display="chip" :options="education"
                                        optionLabel="name" placeholder="Select Causes" :maxSelectedLabels="100"
                                        class="w-100" />
                                </div>
                                <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                                    @click="searchVolunteers" />
                                <Button type="button" class="btn btn-secondary w-100" label="Reset Filter"
                                    icon="pi pi-times" @click="resetFilters" />
                            </div>
                        </Sidebar>
                    </div>
                    <div class="card act-filter-options">
                        <div class="card-body">
                            <h3 class="">Filter Options</h3>
                            <div class="d-flex flex-column align-items-start gap-2 mb-2">
                                <InputText v-model="volunteerName" type="text" placeholder="Name" class="w-100" />
                                <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                                    placeholder="Province" class="w-100" />
                                <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                                    class="w-100" :disabled="!selectedProvince" />
                                <MultiSelect v-model="selectedSkills" display="chip" :options="skills"
                                    optionLabel="name" placeholder="Select Skills" :maxSelectedLabels="100"
                                    class="w-100" />
                                <Dropdown v-model="selectedEducation" display="chip" :options="education"
                                    optionLabel="name" placeholder="Select Education" :maxSelectedLabels="100"
                                    class="w-100" />
                            </div>
                            <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                                @click="searchVolunteers" />
                            <Button type="button" class="btn btn-secondary w-100" label="Reset Filter"
                                icon="pi pi-times" @click="resetFilters" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 d-flex flex-column align-items-start gap-2">
                    <div class="card w-100">
                        <DataView :value="volunteers" :layout="layout">

                            <template #header>
                                <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                                    <div
                                        class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                        <h3 class="mb-0 me-3">Explore Volunteers</h3>
                                        <Chip class="pl-0 pr-3">
                                            <span class="ml-2 font-medium small">
                                                <b>{{ volunteersCount }}</b>
                                                Found
                                            </span>
                                        </Chip>
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap">
                                        <div
                                            class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                            <Button type="button" class="btn btn-primary-sm"
                                                label="Mark Completed All" />
                                        </div>
                                        <div
                                            class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-nowrap flex-wrap">
                                            <div class="p-float-label sortable-select w-100">
                                                <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                                    placeholder="Sort Volunteers" @change="onSortChange($event)"
                                                    class="w-100" />
                                            </div>
                                            <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                            <Button class="mv-filter" icon="pi pi-filter-fill"
                                                @click="visible = true" />
                                        </div>
                                    </div>
                                </div>

                            </template>

                            <template #list="slotProps">
                                <div v-for="(event, index) in slotProps.items" :key="index">
                                    <div v-if="loading" class="row w-100 mx-0 p-4" style="z-index: 99;">
                                        <div class="col-lg-2 px-0">
                                            <Skeleton class="w-8rem border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-7 px-0 gap-1">
                                            <Skeleton class="w-8rem border-round h-2rem my-1" />
                                            <div class="row mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                                <Skeleton class="w-4rem border-round h-2rem" />
                                            </div>
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-20rem border-round h-2rem" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3 px-0">
                                            <Skeleton class="w-100 border-round h-9rem" />
                                        </div>
                                    </div>
                                    <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                        <div class="col-lg-2 col-md-12 col-sm-12 px-0">
                                            <img v-if="!event.profile_picture_thumb"
                                                :src="this.$volunteerNoImage(event.gender)"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.profile_picture_thumb"
                                                :alt="event.user_v2?.name" preview />
                                        </div>
                                        <div
                                            class="col-lg-7 col-md-7 col-sm-12 px-lg-3 px-0 py-0 text-left text-truncate gy-2">
                                            <a :href="baseUrl + '/volunteers/' + event.slug">
                                                <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1"> {{ event.user_v2?.name }}
                                                </h3>
                                            </a>
                                            <p class="mb-2 d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                                <span v-if="event.gender" class="badge-custom d-xl-inline-block">
                                                    {{ getFormattedGender(event.gender) }}
                                                </span>
                                                <span v-if="event.education" class="badge-custom d-xl-inline-block">
                                                    {{ event.education?.name }}
                                                </span>
                                                <span v-if="event.education_field"
                                                    class="badge-custom d-xl-inline-block">
                                                    {{ event.education_field?.name }}
                                                </span>
                                                <span v-if="event.university" class="badge-custom d-xl-inline-block">
                                                    {{ event.university?.name }}
                                                </span>
                                            </p>
                                            <p class="my-1 fs-7"><strong>City:</strong>
                                                {{ event.city?.name }}
                                            </p>
                                            <p v-if="event.about" class="text-truncate-2 w-fit">
                                                {{ event.about }}
                                            </p>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                            <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                                <span class="badge-counter-text">
                                                    Activities Volunteered:
                                                    <span v-if="event.totalActivities > 0" class="counter">
                                                        {{ event.totalActivities }}
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                                <span class="badge-counter-text">
                                                    Hours Completed:
                                                    <span v-if="event.totalHours > 0" class="counter">
                                                        {{ event.totalHours }}
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="ratinng-box d-flex justify-content-center row-gap-2">
                                                <div v-if="event.ratings.avgRating > 0"
                                                    class="d-flex align-items-center justify-content-center gap-2 flex-nowrap">
                                                    <div
                                                        class="d-flex gap-1 align-items-center text-primary fs-8 flex-nowrap w-100">
                                                        <span v-for="v in Math.floor(event.ratings.avgRating)" :key="v"
                                                            class="fas fa-star filled"></span>
                                                        <span v-if="event.ratings.halfRating % 1 !== 0"
                                                            class="fas fa-star-half-alt filled"></span>
                                                        <span v-if="event.ratings.avgRating < 5"
                                                            class="d-flex align-items-center gap-1"><span
                                                                v-for="v in Math.ceil(event.ratings.zeroRating)"
                                                                :key="'empty-' + v" class="fa-regular fa-star"></span>
                                                        </span>
                                                    </div>
                                                    <span class="text-secondary w-100 fs-9">
                                                        ({{
                                                            calculateRatingOutOf5(event.ratings.avgRating,
                                                                event.ratings.fullRating)
                                                        }} out of 5.0)
                                                    </span>
                                                </div>
                                                <div v-else
                                                    class="d-flex align-items-start justify-content-center gap-2 mb-1">
                                                    <div
                                                        class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                    </div>
                                                    <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                    </span>
                                                </div>
                                            </div>
                                            <a :href="baseUrl + '/volunteers/' + event.slug"
                                                class="btn btn-primary-sm w-100"><i class="fa-solid fa-eye pr-1"></i>
                                                Visit
                                                Profile</a>
                                        </div>
                                        <Divider class="mb-0"></Divider>
                                    </div>
                                </div>
                            </template>

                            <template #grid="slotProps">
                                <div class="grid grid-nogutter">
                                    <div v-for="(event, index) in slotProps.items" :key="index"
                                        class="sm:col-12 lg:col-3 xl:col-3 p-2">
                                        <div
                                            class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                            <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                                <div class="col-lg-4 px-1">
                                                    <Skeleton class="w-100 border-round h-100" />
                                                </div>
                                                <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                </div>
                                                <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-3rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                </div>
                                            </div>
                                            <div v-else class="row mx-0 text-center px-0 gap-2">
                                                <div class="row mx-0 px-0 row-gap-2">
                                                    <div class="col-lg-4 px-0">
                                                        <img v-if="!event.profile_picture_thumb"
                                                            src="@/assets/img/org_placeholder.png"
                                                            class="activity-img-block border-radius img-fluid" />
                                                        <Image v-else class="border-radius img-fluid"
                                                            :src="this.imageurl + '/' + event.profile_picture_thumb"
                                                            :alt="event.user_v2?.name" preview />
                                                    </div>
                                                    <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2">
                                                        <a :href="baseUrl + '/volunteers/' + event.slug">
                                                            <h3 v-tooltip.top="event.user_v2?.name"
                                                                class="mb-0 mt-0 text-truncate-2 fw-bold text-capitalize fs-7">
                                                                {{ event.user_v2?.name }}
                                                            </h3>
                                                        </a>
                                                        <div
                                                            class="col-lg-12 d-flex align-items-top flex-wrap text-left px-0 fs-8">
                                                            <p class="my-1 fs-8">
                                                                <strong>City:</strong> {{ event.city?.name }}
                                                            </p>
                                                        </div>
                                                        <div class="ratinng-box fs-9 d-flex justify-content-start">
                                                            <div v-if="event.ratings.avgRating > 0"
                                                                class="d-flex align-items-start text-primary gap-1">
                                                                <div class="d-flex justify-content-center text-primary">
                                                                    <span
                                                                        v-for="v in Math.floor(event.ratings.avgRating)"
                                                                        :key="v" class="fas fa-star filled"></span>
                                                                    <span v-if="event.ratings.halfRating % 1 !== 0"
                                                                        class="fas fa-star-half-alt filled"></span>
                                                                    <span v-if="event.ratings.avgRating < 5"
                                                                        class="d-flex align-items-center"><span
                                                                            v-for="v in Math.ceil(event.ratings.zeroRating)"
                                                                            :key="'empty-' + v"
                                                                            class="fa-regular fa-star"></span>
                                                                    </span>
                                                                </div>
                                                                <span class="text-secondary w-100 fs-9">
                                                                    ({{
                                                                        calculateRatingOutOf5(event.ratings.avgRating,
                                                                            event.ratings.fullRating)
                                                                    }} out of 5.0)
                                                                </span>
                                                            </div>
                                                            <div v-else
                                                                class="d-flex align-items-start text-primary gap-1">
                                                                <div
                                                                    class="d-flex justify-content-center text-muted opacity-50">
                                                                    <span class="fa-regular fa-star"></span>
                                                                    <span class="fa-regular fa-star"></span>
                                                                    <span class="fa-regular fa-star"></span>
                                                                    <span class="fa-regular fa-star"></span>
                                                                    <span class="fa-regular fa-star"></span>
                                                                </div>
                                                                <span class="text-muted opacity-50 fs-9"> (0 out of
                                                                    5.0)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 card-section-right-sm mt-2 shadow-none">
                                                    <div
                                                        class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                                        <span class="badge-counter-text fs-8">
                                                            Activities Volunteered:
                                                            <span v-if="event.totalActivities > 0" class="counter fs-7">
                                                                {{ event.totalActivities }}
                                                            </span>
                                                            <span v-else class="counter fs-7 text-muted"
                                                                v-tooltip.bottom="'Not Added'">
                                                                N/A
                                                            </span>
                                                        </span>
                                                        <span class="badge-counter-text fs-8">
                                                            Hours Completed:
                                                            <span v-if="event.totalHours > 0" class="counter fs-7">
                                                                {{ event.totalHours }}
                                                            </span>
                                                            <span v-else class="counter fs-7 text-muted"
                                                                v-tooltip.bottom="'Not Added'">
                                                                N/A
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 px-0 d-flex align-items-center gap-2">
                                                    <a title="View Activity"
                                                        :href="baseUrl + '/volunteers/' + event.slug"
                                                        class="btn btn-primary-sm px-1 fs-7 flex-grow-1">
                                                        <i class="fa-solid fa-eye pr-1"></i> Visit
                                                        Profile
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #footer v-if="hasMoreVolunteers">
                                <div class="p-4 text-center">
                                    <button @click="loadMoreVolunteers" class="btn btn-primary" :disabled="showSpinner">
                                        View More
                                        <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px"
                                            strokeWidth="8" fill="transparent" animationDuration="2s"
                                            aria-label="Custom ProgressSpinner" />
                                    </button>
                                </div>
                            </template>

                            <template #empty v-if="!loading">
                                <div class="text-center">
                                    <img src="../assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                                </div>
                            </template>
                        </DataView>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Image from 'primevue/image';
import { getVolunteers, getVolunteersFilters } from "@/api";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import Breadcrumb from 'primevue/breadcrumb';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    components: {
        AutoComplete,
        MultiSelect,
        Dropdown,
        InputText,
        MultiSelect,
        Calendar,
        Button,
        Tag,
        Chip,
        Divider,
        Image,
        DataView,
        DataViewLayoutOptions,
        Sidebar,
        Skeleton,
        Breadcrumb,
        ProgressSpinner,
    },
    name: "Volunteers",
    data() {
        return {
            loading: true,
            pageLoading: true,
            selectedProvince: null,
            selectedCity: null,
            selectedEventTypes: [],
            selectedSkills: [],
            selectedCities: [],
            selectedEducation: [],
            volunteers: [],
            cities: [],
            provinves: [],
            skills: [],
            education: [],
            volunteersCount: 0,
            visible: false,
            pageNo: 1,
            perPage: 12,
            sortKey: '',
            sortvalue: null,
            sortType: null,
            imageurl: null,
            volunteerName: null,
            layout: 'list',
            sortOptions: [
                { label: 'Name ASC', value: 'name', type: 'asc' },
                { label: 'Name DESC', value: 'name', type: 'desc' },
                { label: 'Hours Completed DESC', value: 'totalHours', type: 'desc' },
                { label: 'Hours Completed ASC', value: 'totalHours', type: 'asc' },
            ],
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ],
            showSpinner: false,
        };
    },
    computed: {
        hasMoreVolunteers() {
            return this.volunteersCount > this.perPage * this.pageNo;
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            this.pageLoading = true;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const responce = await getVolunteers(params);
            this.volunteers = responce.volunteers.data;
            this.volunteersCount = responce.volunteersCount;
            // console.log(this.volunteers);
            const res2 = await getVolunteersFilters();
            // console.log(res2);
            this.causes = res2.causes;
            this.allCities = res2.cities;
            this.eventTypes = res2.eventTypes;
            this.provinves = res2.provinves;
            this.skills = res2.skills;
            this.education = res2.Education;
            this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        selectedProvince(newProvince) {
            // Filter cities based on the selected province and update the cities options

            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                // If no province is selected, reset the cities options to empty
                this.cities = [];
            }
        },
    },
    methods: {
        getFormattedGender(gender) {
            return gender === 'f' ? 'Female' : gender === 'm' ? 'Male' : 'unknown';
        },
        async searchVolunteers() {
            try {
                this.loading = true;
                const params = {
                    pageno: 1, // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };
                const res = await getVolunteers(params);
                this.volunteers = res.volunteers.data;
                // console.log(res);
                this.volunteersCount = res.volunteersCount;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        async loadMoreVolunteers() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    pageno: this.pageNo + 1,  // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };

                const res = await getVolunteers(params);
                // console.log(res);
                const newVolunteers = res.volunteers.data;
                this.volunteers = [...this.volunteers, ...newVolunteers];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.volunteerName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedSkills = [];
            this.selectedEducation = [];
            this.searchVolunteers();
            this.loading = false;
        },
        async onSortChange(event) {
            this.loading = true;
            this.sortvalue = event.value.value;
            this.sortType = event.value.type;
            this.pageNo = 1;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage,
                sortBy: this.sortvalue,
                sortType: this.sortType,
                name: this.volunteerName,
                province_id: this.selectedProvince ? this.selectedProvince.id : null,
                city_id: this.selectedCity ? this.selectedCity.id : null,
                skill_ids: this.selectedSkills.map((skill) => skill.id),
                education_id: this.selectedEducation ? this.selectedEducation.id : null,
            };

            const res = await getVolunteers(params);
            this.volunteers = res.volunteers.data;
            this.volunteersCount = res.volunteersCount;
            this.loading = false;
        },
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        }
    },
}
</script>

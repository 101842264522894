<template>
  <section class="login-reg-sec">
    <div class="box">
      <div class="square" style="--i:0;"></div>
      <div class="square" style="--i:1;"></div>
      <div class="square" style="--i:2;"></div>
      <div class="square" style="--i:3;"></div>
      <div class="square" style="--i:4;"></div>
      <div class="square" style="--i:5;"></div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-center mb-2">
            <a href="/" class="text-center">
              <img src="../assets/img/logo.png" alt="logo" class="img-fluid w-50 my-3" />
            </a>
          </div>
          <div class="row mx-0 text-center">
            <h1 class="mb-3">Reset Password</h1>
          </div>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <div class="form-group">
                <Password class="d-grid" v-model="password" toggleMask placeholder="Password" />
                <!-- <span v-if="this.validationErrors.password"
                  class="text-danger empty-1 text-validation"> {{
                    this.validationErrors.organizationPassword }}
                </span> -->
              </div>
              <div class="form-group">
                <!-- <Password class="d-grid" v-model="confirmPassword" toggleMask
                  placeholder="Confirm password" /> -->
                <!-- <span v-if="this.validationErrors.organizationConfirmPassword"
                  class="text-danger empty-1 text-validation"> {{
                    this.validationErrors.organizationConfirmPassword }}
                </span> -->
              </div>
              <!-- <input type="email" class="form-control" autocomplete="email" placeholder="Password" v-model="email"
                required /> -->
            </div>
            <div class="form-group mb-0">
              <button id="login-btn" class="btn btn-primary w-100" type="submit" tabindex="12" data-style="zoom-in"
                @click="verifyEmail">
                <i class="fa-solid fa-arrow-right-to-bracket pr-1"></i>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
import Password from 'primevue/password';
import { resetPassword } from "@/api";

export default {
  components: {
    Password,
  },
  data() {
    return {
      // token: '$2y$10$VPX8l1MHTMfO/SPZdRV6BespqNhjYg47Yf5ANGNasJNkG/JEGjuUW',
      baseUrl: null,
      password: null,
      confirmPassword: null,
    };
  },
  async mounted() {
    // this.verifyEmail();
    this.baseUrl = window.location.origin;
    this.token = this.$route.params.token;
    // alert(this.token);
  },
  methods: {
    async verifyEmail() {
      const params = {
        password: this.password,
        token: this.token,
      };
      try {
        // const res = axios.post(process.env.VUE_APP_BASE_URL + '/api/reset-password' , { params} );
        const res = await resetPassword(params);
        if (res.status == 200) {
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Password has been updated',
            confirmButtonText: 'Ok',
            confirmButtonColor: "#46B849",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = this.baseUrl + '/login';
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<template>
  <section class="container-fluid py-5 testimonial-volunteers mt-5">
    <div class="container card">
      <Carousel>
        <Slide v-for="(volunteer, index) in volunteerTestimonial" :key="index">
          <div class="carousel__item row mx-0 w-100">
            <div class="col-lg-7 col-sm-12 milkar-order-2 text-start">
              <div class="testimonial m-5">
                <q class="d-flex">
                  <p class="text-truncate-5 mb-0">
                    {{ volunteer.about }}
                  </p>
                </q>
                <div class="d-flex flex-column mt-3">
                  <span class="fw-bold mb-1">
                    <h1 class="mb-0 text-white">{{ volunteer.user_v2.name }}</h1>
                  </span>
                  <small v-if="volunteer.university">{{ volunteer.university.name }}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12 px-0 milkar-order-1">
              <div class="testimonial-volunteers-img">
                <img :src="this.imageurl + '/' + volunteer.profile_picture" alt="Slider Image" class="h-100">
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>
<script>

import { getTestimonial } from "@/api";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'



const animation = { duration: 50000, easing: (t) => t }
export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {},
  computed: {},
  data() {
    return {
      imageurl: null,
      volunteerTestimonial: [],
      loadingVolunteers: true, // To show loading message
      errored: false, // To show error message
      //heat map option are here 
    };
  },
  methods: {},
  async mounted() {
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    try {
      /* eslint-disable */
      this.volunteerTestimonial = await getTestimonial();
      this.loadingVolunteers = false;
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loadingVolunteers = false;
    }
  },
};
</script>
<style scoped>
/* Add styles based on screen resolution */
@media (max-width: 767px) {

  /* Styles for screens less than 767px in width */
  .testimonial-volunteers .testimonial-volunteers-img img {
    max-width: 300px;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* Styles for screens between 768px and 991px in width */
  .testimonial-volunteers .testimonial-volunteers-img img {
    max-width: 300px;
    height: auto;
  }
}

@media (min-width: 992px) {

  /* Styles for screens larger than 992px in width */
  .testimonial-volunteers .testimonial-volunteers-img img {
    max-width: 400px;
  }

  .testimonial-volunteers {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .testimonial-volunteers>.container {
    padding: 0px !important;
  }
}
</style>

<template>
    <section class="volunteer-dashboard container-fluid py-3">
        <div v-if="organization.is_active === 0" class="alert alert-danger">Your organization profile is pending for
            approval. If it's not activated within 2 working days, then
            <a :href="baseUrl + '/contact'" class="text-primary">contact us</a>
        </div>
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card w-100">
                        <div class="card-body">
                            <div class="col-lg-12 px-0 text-left d-flex flex-column row-gap-2">
                                <div class="text-center mb-3">
                                    <img v-if="!organization.logo" src="@/assets/img/org_placeholder.png"
                                        class="border-radius img-fluid" />
                                    <Image v-else class="border-radius img-fluid" preview
                                        :src="this.imageurl + '/' + organization.logo" :alt="organization.user_v2.name" />
                                </div>
                                <div class="">
                                    <a href="">
                                        <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-2 w-fit"
                                            v-tooltip.top="this.organizationName">
                                            {{ this.organizationName }}
                                        </h3>
                                    </a>
                                </div>
                                <div class="row row-gap-0 mx-0">
                                    <div class="d-flex mx-0 flex-nowrap px-0">
                                        <a class="btn btn-primary-sm w-100 align-items-center d-flex gap-2"
                                            :href="baseUrl + '/organization-dashboard/update-settings'">
                                            <i class="far fa-edit"></i> Profile
                                        </a>
                                        <a v-if="organization.is_active"
                                            :href="baseUrl + '/organization-dashboard/create-activity'"
                                            class="btn btn-secondary-sm w-100 d-flex align-items-center gap-2 flex-wrap mx-2">
                                            <i class="pi pi-plus"></i> Activity
                                        </a>
                                        <a v-else
                                            class="btn btn-secondary-sm w-100 d-flex align-items-center gap-2 flex-wrap mx-2 disabled opacity-25">
                                            <i class="pi pi-plus"></i> Activity
                                        </a>
                                        <Button class="btn btn-primary-sm w-50" type="button" icon="pi pi-share-alt"
                                            @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
                                        <Toast />
                                        <Menu ref="menu" id="overlay_menu" class="p-menu p-component"
                                            :model="socialitems" :popup="true">
                                            <template #item="{ label, item, props }">
                                                <a @click="dynamicURL(item)" target="_blank" class="p-menuitem-link">
                                                    <span v-bind="props.icon" /> {{ label }}
                                                </a>
                                            </template>
                                        </Menu>
                                    </div>
                                    <a :href="baseUrl + '/organization-dashboard/sms-campaign-list'"
                                        v-if="organization.is_sms_allowed"
                                        class="btn btn-primary-sm w-100 d-flex align-items-center gap-2 flex-wrap">
                                        <i class="fa fa-thin fa-comment-sms"></i> SMS Campaigns
                                    </a>
                                </div>
                                <div
                                    class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right mb-2">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <div v-if="organization.university_id">
                                            <span class="badge-counter-text">
                                                Students:
                                                <span v-if="organization.studentsCount > 0"
                                                    class="counter">{{ organization.studentsCount }}</span>
                                                <span v-else class="counter fs-7 text-muted"
                                                    v-tooltip.bottom="'Not Added'">
                                                    N/A
                                                </span>
                                            </span>
                                        </div>
                                        <span class="badge-counter-text">
                                            Activity Organized:
                                            <span v-if="organization.eventsCount > 0" class="counter">
                                                {{ organization.eventsCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">
                                            Volunteers Engaged:
                                            <span v-if="organization.volunteersEngagedCount > 0" class="counter">
                                                {{ organization.volunteersEngagedCount }}
                                            </span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Type</label>
                                        <p v-if="this.organizationType" class="fs-bold mb-0">{{ this.organizationType }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">City</label>
                                        <p v-if="this.organizationCity" class="fs-bold mb-0">{{ this.organizationCity }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Address</label>
                                        <p v-if="organization.address" class="fs-bold mb-0">{{ organization.address }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <Sidebar v-model:visible="visible">
                            <div class="card-body">
                                <h3 class="">Filter Options</h3>
                                <div class="funkyradio mb-2 flex-wrap flex-sm-nowrap gap-2">
                                    <div class="funkyradio-primary w-100">
                                        <input type="radio" name="radio" id="radio2" value="Virtual"
                                            v-model="CheckedInTypeOption" />
                                        <label for="radio2">Virtual</label>
                                    </div>
                                    <div class="funkyradio-success w-100">
                                        <input type="radio" name="radio" id="radio3" value="Onsite"
                                            v-model="CheckedInTypeOption" />
                                        <label for="radio3">Onsite</label>
                                    </div>
                                </div>
                                <div class="d-flex flex-column align-items-start gap-2 mb-2">
                                    <InputText v-model="activityName" type="text" placeholder="Name" class="w-100" />
                                    <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                                        placeholder="Province" class="w-100" :disabled="isVirtualSelected" />
                                    <Dropdown v-model="selectedCity" :options="cities" optionLabel="name"
                                        placeholder="City" class="w-100" :disabled="!selectedProvince" />
                                    <MultiSelect v-model="selectedEventTypes" display="chip" :options="eventTypes"
                                        optionLabel="name" placeholder="Select Event Types" :maxSelectedLabels="100"
                                        class="w-100" />
                                    <MultiSelect v-model="selectedSkills" display="chip" :options="skills"
                                        optionLabel="name" placeholder="Select Skills" :maxSelectedLabels="100"
                                        class="w-100" />
                                    <MultiSelect v-model="selectedCauses" display="chip" :options="causes"
                                        optionLabel="name" placeholder="Select Causes" :maxSelectedLabels="100"
                                        class="w-100" />
                                    <span class="p-float-label w-100">
                                        <Calendar class="w-100" v-model="dates" selectionMode="range"
                                            :manualInput="false" placeholder="Range Date" />

                                    </span>
                                </div>
                                <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                                    @click="searchActivities" />
                                <Button type="button" class="btn btn-secondary w-100" label="Reset Filter"
                                    icon="pi pi-times" @click="resetFilters" />
                            </div>
                        </Sidebar>
                        <DataView :value="events" :layout="layout">

                            <template #header>
                                <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                                    <div
                                        class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                        <h3 class="mb-0 me-3">Explore Activities</h3>
                                        <Chip class="pl-0 pr-3">
                                            <span class="ml-2 font-medium small">
                                                <b>{{ activitiesCount }}</b>
                                                Found
                                            </span>
                                        </Chip>
                                    </div>
                                    <div
                                        class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap">
                                        <div
                                            class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                            <Button type="button" class="btn btn-primary-sm"
                                                label="Mark Completed All" />
                                        </div>
                                        <div
                                            class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-nowrap flex-wrap">
                                            <div class="p-float-label sortable-select w-100">
                                                <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                                    placeholder="Sort Activities" @change="onSortChange($event)"
                                                    class="w-100" />
                                            </div>
                                            <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                            <Button class="filter-opt" icon="pi pi-filter-fill"
                                                @click="visible = true" />
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #list="slotProps">
                                <div v-for="(event, index) in slotProps.items" :key="index">
                                    <div v-if="!pageLoading" class="row w-100 mx-0 p-4" style="z-index: 99;">
                                        <div class="col-lg-2 px-0">
                                            <Skeleton class="w-8rem border-round h-9rem" />
                                        </div>
                                        <div class="col-lg-7 px-0 gap-1">
                                            <Skeleton class="w-8rem border-round h-2rem my-1" />
                                            <div class="row mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem" />
                                                <Skeleton class="w-8rem border-round h-2rem" />
                                                <Skeleton class="w-4rem border-round h-2rem" />
                                            </div>
                                            <div class="row flex-column mx-0 gap-2">
                                                <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                                <Skeleton class="w-20rem border-round h-2rem" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3 px-0">
                                            <Skeleton class="w-14rem border-round h-9rem" />
                                        </div>
                                    </div>
                                    <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                        <div class="col-lg-2 col-md-12 text-center px-0">
                                            <img v-if="!event.thumbnail_picture" src="@/assets/img/org_placeholder.png"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.thumbnail_picture"
                                                :alt="event.OwenerDetail.name" preview />
                                        </div>
                                        <div
                                            class="col-lg-7 col-md-7 col-sm-12 text-truncate fs-7 px-lg-3 px-0 d-flex flex-column row-gap-2">
                                            <a :href="baseUrl + '/activities/' + event.slug">
                                                <h3 title="" class="mb-0 mt-1 text-truncate-2 fw-bold">
                                                    {{ event.name }}
                                                </h3>
                                            </a>
                                            <div class="m-0 d-flex flex-wrap align-items-start row-gap-3 gap-3">
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">Date</label>
                                                    <span class="fw-bold">
                                                        {{ event.formattedStartDate }} - {{
            event.formattedEndDate
        }}
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">Time</label>
                                                    <span class="fw-bold">
                                                        {{ event.formattedStartTime }} - {{
            event.formattedEndTime
        }}
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column"
                                                    v-if="event.min_hours > 0 && event.max_hours > 0">
                                                    <label class="fs-8">Duration</label>
                                                    <span class="fw-bold">
                                                        {{ event.min_hours }} - {{ event.max_hours }}
                                                        Hours
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column"
                                                    v-else-if="event.min_hours > 0 && !event.max_hours > 0">
                                                    <label class="fs-8">Duration</label>
                                                    <span class="fw-bold">
                                                        {{ event.min_hours }} Hours
                                                    </span>

                                                </span>
                                                <span class="d-flex flex-column"
                                                    v-else-if="!event.min_hours > 0 && event.max_hours > 0">
                                                    <label class="fs-8">Max Hours</label>
                                                    <span class="fw-bold">
                                                        {{ event.max_hours }}
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column">
                                                    <label class="fs-8">City</label>
                                                    <span v-if="event.city && event.locationCount < 2" class="fw-bold">
                                                        {{ event.city.name }}
                                                    </span>
                                                    <span v-else-if="event.city && event.locationCount > 1"
                                                        class="fw-bold">
                                                        Pakistan
                                                    </span>
                                                    <span v-else class="counter fs-7 text-muted fw-bold"
                                                        v-tooltip.bottom="'Not Added'">
                                                        N/A
                                                    </span>
                                                </span>
                                            </div>
                                            <p class="d-flex flex-row text-truncate-2 mb-0">
                                                {{ event.event_type.name }} by
                                                <a href="" class="text-primary">
                                                    {{ event.OwenerDetail[0].name }}
                                                </a>
                                            </p>
                                            <div class="col-lg-12 px-0">
                                                <div v-if="event.city && event.EventLocations && event.EventLocations[0] && event.EventLocations[0].address">
                                                    <Button title="View more locations"
                                                        class="btn btn-secondary-sm align-items-baseline"
                                                        @click="locationDailogVisible = true, handleViewLocationClick(event.EventLocations, event.name)">
                                                        <span class="badge bg-primary font-5 me-1">
                                                            <i class="fa-solid fa-location-dot me-1"></i>{{
            event.locationCount
        }}
                                                        </span>
                                                        Location
                                                    </Button>
                                                </div>
                                                <div v-else class="btn btn-primary-sm disabled opacity-25"
                                                    v-tooltip.bottom="'Venue Not Added'">
                                                    Venue:
                                                    <span class="fs-7 fw-bold">
                                                        N/A
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card row-gap-2">
                                            <div class="row mx-0 flex-wrap w-100 justify-content-center row-gap-2">
                                                <div class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                                    <span class="badge-counter-text">
                                                        Volunteers Engaged:
                                                        <span v-if="event.registeredVolunteersCount > 0"
                                                            class="counter">
                                                            {{ event.registeredVolunteersCount }}
                                                        </span>
                                                        <span v-else class="counter fs-7 text-muted"
                                                            v-tooltip.bottom="'Not Added'">
                                                            N/A
                                                        </span>
                                                    </span>
                                                    <span class="badge-counter-text">
                                                        Volunteers Required:
                                                        <span v-if="event.totalVolunteersRequired > 0" class="counter">
                                                            {{ event.totalVolunteersRequired }}
                                                        </span>
                                                        <span v-else class="counter fs-7 text-muted"
                                                            v-tooltip.bottom="'Not Added'">
                                                            N/A
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="d-flex align-items-center flex-wrap px-0">
                                                    <a type="button" class="btn btn-secondary-sm w-100"
                                                        :href="baseUrl + '/activities/' + event.slug">
                                                        <i class="fa fa-eye me-1"></i>View
                                                    </a>
                                                    <a type="button" class="btn btn-primary-sm w-100"
                                                        :href="viewAllVolunteers(event.slug)">
                                                        <i class="fa-solid fa-layer-group me-1"></i>All Volunteer
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-0"></Divider>
                                    </div>
                                    <Divider></Divider>
                                </div>
                            </template>

                            <template #grid="slotProps">
                                <div class="grid grid-nogutter">
                                    <div v-for="(event, index) in slotProps.items" :key="index"
                                        class="sm:col-12 lg:col-3 xl:col-3 p-2">
                                        <div
                                            class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                            <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                                <div class="col-lg-4 px-1">
                                                    <Skeleton class="w-100 border-round h-100" />
                                                </div>
                                                <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                </div>
                                                <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                    <Skeleton class="w-100 border-round h-2rem" />
                                                    <div class="row px-2 row-gap-2">
                                                        <div class="col-lg-7 px-1">
                                                            <Skeleton class="w-60 border-round h-2rem" />
                                                        </div>
                                                        <div class="col-lg-5 px-1">
                                                            <Skeleton class="w-40 border-round h-2rem" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row mx-0 align-items-start flex-wrap row-gap-2">
                                                    <div class="col-lg-4 px-0">
                                                        <img v-if="!event.thumbnail_picture"
                                                            src="@/assets/img/org_placeholder.png"
                                                            class="activity-img-block border-radius img-fluid" />
                                                        <Image v-else class="border-radius img-fluid"
                                                            :src="this.imageurl + '/' + event.thumbnail_picture"
                                                            :alt="event.OwenerDetail.name" preview />
                                                    </div>
                                                    <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2">
                                                        <a class="px-0" :href="baseUrl + '/activities/' + event.slug"
                                                            v-tooltip.top="event.name">
                                                            <h3
                                                                class="mb-1 text-truncate-2 fw-bold text-capitalize fs-7">
                                                                {{ event.name }}
                                                            </h3>
                                                        </a>
                                                        <p class="d-flex flex-row text-truncate-2 mb-0 fs-8"
                                                            v-tooltip.bottom="event.OwenerDetail[0].name">
                                                            {{ event.event_type.name }} by
                                                            <a href="" class="text-primary text-capitalize">
                                                                {{ event.OwenerDetail[0].name }}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-12 d-flex align-items-top flex-column text-left px-0 fs-8 mt-2">
                                                    <div class="d-flex flex-column">
                                                        <label class="fs-8">Date</label>
                                                        <span v-if="event.formattedStartDate && event.formattedEndDate"
                                                            class="fw-bold">
                                                            {{ event.formattedStartDate }} - {{
            event.formattedEndDate }}
                                                        </span>
                                                        <span v-else class="fw-bold" v-tooltip.bottom="'Not Added'">
                                                            N/A
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-lg-12 card-section-right-sm d-flex align-items-center justify-content-center card mt-2 shadow-none">
                                                    <div class="row mx-0 flex-wrap w-100 justify-content-center">
                                                        <div
                                                            class="badge-counter-box d-flex flex-column row-gap-2 mb-0 px-0">
                                                            <span class="badge-counter-text fs-8">Volunteers Engaged:
                                                                <span v-if="event.registeredVolunteersCount > 0"
                                                                    class="counter fs-7">
                                                                    {{ event.registeredVolunteersCount }}
                                                                </span>
                                                                <span v-else class="counter fs-7 text-muted"
                                                                    v-tooltip.bottom="'Not Added'">
                                                                    N/A
                                                                </span>
                                                            </span>
                                                            <span class="badge-counter-text fs-8">Volunteers Required:
                                                                <span v-if="event.totalVolunteersRequired > 0"
                                                                    class="counter fs-7">
                                                                    {{ event.totalVolunteersRequired }}
                                                                </span>
                                                                <span v-else class="counter fs-7 text-muted"
                                                                    v-tooltip.bottom="'Not Added'">
                                                                    N/A
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 px-0">
                                                    <div v-if="event.city && event.EventLocations && event.EventLocations[0] && event.EventLocations[0].address">
                                                        <Button title="View more locations"
                                                            class="btn btn-secondary-sm align-items-baseline w-100"
                                                            @click="locationDailogVisible = true, handleViewLocationClick(event.EventLocations, event.name)">
                                                            <span class="badge bg-primary font-5 me-1">
                                                                <i class="fa-solid fa-location-dot me-1"></i>{{ event.locationCount
                                                                }}
                                                            </span>
                                                            Location
                                                        </Button>
                                                    </div>
                                                    <div v-else class="btn btn-primary-sm w-100 disabled opacity-25"
                                                        v-tooltip.bottom="'Venue Not Added'">
                                                        Venue:
                                                        <span class="fs-7 fw-bold">
                                                            N/A
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center flex-nowrap gap-2 px-0 w-100">
                                                        <a type="button" class="btn btn-primary-sm w-100"
                                                            :href="baseUrl + '/activities/' + event.slug"
                                                            :loading="loading">
                                                            <i class="fa fa-eye me-1"></i>View
                                                        </a>
                                                        <a type="button" class="btn btn-primary-sm w-100"
                                                            :href="viewAllVolunteers(event.slug)" :loading="loading">
                                                            <i class="fa-solid fa-layer-group me-1"></i>All Volunteer
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #footer v-if="hasMoreActivities">
                                <div class="p-4 text-center">
                                    <button @click="loadMoreActivities" class="btn btn-primary" :disabled="showSpinner">
                                        View More
                                        <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px"
                                            strokeWidth="8" fill="transparent" animationDuration="2s"
                                            aria-label="Custom ProgressSpinner" />
                                    </button>
                                </div>
                            </template>

                            <template #empty v-if="!loading">
                                <div class="text-center no-data-image">
                                    <img src="@/assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                                </div>
                            </template>
                        </DataView>
                        <Dialog v-model:visible="locationDailogVisible" modal :header="this.dailogHrader"
                            :style="{ width: '50vw' }">
                            <div id="mycustommodelcontenet" class="scroll list-group">
                                <div class="border-bottom pb-2 mb-2">
                                    <a v-for="(location, index) in eventLocationData" :key="index" target="_blank"
                                        class="custommlocation-icon d-flex list-group-item list-group-item-action"
                                        :href="'https://www.google.com/maps/search/?api=1&query=' + location.latitude + ',' + location.longitude">
                                        <img src="https://i.ibb.co/HFYVTtw/map.png" alt="milkar.com">{{
            location.address }}
                                    </a>
                                </div>
                            </div>
                        </Dialog>
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getOrganizationActivites, getOrganizationV2, getActivitiesFilters } from "@/api";
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Paginator from 'primevue/paginator';
import Button from 'primevue/button';
import Image from 'primevue/image';
import Tag from 'primevue/tag';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Sidebar from 'primevue/sidebar';
import Dialog from 'primevue/dialog';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Skeleton from 'primevue/skeleton';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import { useToast } from 'primevue/usetoast';
import Menu from "primevue/menu";
import ProgressSpinner from 'primevue/progressspinner';


export default {
    components: {
        // HomeComponent,
        Paginator,
        DataView,
        DataViewLayoutOptions,
        Button,
        Image,
        Tag,
        Chip,
        Divider,
        Card,
        Badge,
        BadgeDirective,
        Sidebar,
        Dialog,
        ListingSkeleton,
        Skeleton,
        Dropdown,
        InputText,
        Calendar,
        AutoComplete,
        MultiSelect,
        useToast,
        Menu,
        ProgressSpinner,
    },
    props: {},
    data() {
        return {
            imageurl: null,
            baseUrl: null,
            user: [],
            events: [],
            image: '',
            eventLocationData: [],
            visible: false,
            pageNo: 1,
            perPage: 12,
            errored: false, // To show error message
            activitiesCount: 0,
            loading: false,
            pageLoading: true,
            selectedProvince: null,
            selectedCity: null,
            selectedEventTypes: [],
            selectedSkills: [],
            selectedCauses: [],
            selectedCities: [],
            organization: [],
            typeOfActivities: [],
            organizationName: null,
            organizationCity: null,
            organizationAddress: null,
            events: [],
            causes: [],
            cities: [],
            dailogHrader: null,
            eventTypes: [],
            provinves: [],
            skills: [],
            activitiesCount: 0,
            eventLocationData: [],
            dates: null,
            activityName: null,
            organizationType: null,
            CheckedInTypeOption: null,
            sortKey: '',
            sortvalue: null,
            sortType: null,
            layout: 'list',
            locationDailogVisible: false,
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            sortOptions: [
                { label: 'Created Date ASC', value: 'created_at', type: 'asc' },
                { label: 'Created Date DESC', value: 'created_at', type: 'desc' },
                { label: 'Start Date ASC', value: 'start_date', type: 'asc' },
                { label: 'Start Date DESC', value: 'start_date', type: 'desc' },
                { label: 'End Date ASC', value: 'end_date', type: 'asc' },
                { label: 'End Date DESC', value: 'end_date', type: 'desc' },
                { label: 'Name ASC', value: 'name', type: 'asc' },
                { label: 'Name DESC', value: 'name', type: 'desc' },
            ],
            socialitems: [
                {
                    label: 'Share on Facebook',
                    icon: 'pi pi-facebook',
                },
                {
                    label: 'Share on Twitter',
                    icon: 'pi pi-twitter',

                },
                {
                    label: 'Copy Link',
                    icon: 'pi pi-copy',

                },
            ],
            showSpinner: false,
            startDate: null,
            endDate: null,
        };
    },
    computed: {
        hasMoreActivities() {
            return this.activitiesCount > this.perPage * this.pageNo;
        },
        isVirtualSelected() {
            return this.CheckedInTypeOption === 'Virtual';
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        const vOrganization = localStorage.getItem("organization");
        const parsedOrganization = JSON.parse(vOrganization);
        this.slug = parsedOrganization.slug;
        try {
            const res = await getOrganizationV2(this.slug);
            const res2 = await getActivitiesFilters();
            this.causes = res2.causes;
            this.allCities = res2.cities;
            this.eventTypes = res2.eventTypes;
            this.provinves = res2.provinves;
            this.skills = res2.skills;
            this.organization = res.organization;
            this.typeOfActivities = res.typeOfActivities;
            this.organizationName = this.organization.user_v2.name;
            this.organizationType = this.organization.type.name;
            this.organizationCity = this.organization.city.name;
        } catch (error) {
            console.error(error);
            this.errored = true;
            this.loading = false;
        } finally {
            this.loading = false;
        }
        try {
            this.pageLoading = true;
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res2 = await getOrganizationActivites(params);
            // console.log(res2);
            this.activitiesCount = res2.eventsCount;
            this.events = res2.organizationEvents.data;
        }
        catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loading = false;
        }
    },

    watch: {
        selectedProvince(newProvince) {
            // Filter cities based on the selected province and update the cities options
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                // If no province is selected, reset the cities options to empty
                this.cities = [];
            }
        },
    },
    methods: {
        async searchActivities() {
            try {
                this.loading = true;
                var check_in_from_anywhere = null;
                if (this.CheckedInTypeOption == 'Virtual') {
                    check_in_from_anywhere = 1
                }
                if (this.CheckedInTypeOption == 'Onsite') {
                    check_in_from_anywhere = 0
                }
                if (this.dates) {
                    this.startDate = this.$formatDate(this.dates[0]);
                    this.endDate = this.$formatDate(this.dates[1]);
                }
                const params = {
                    pageno: 1, // Reset the page number to 1 for a new search
                    check_in_from_anywhere: check_in_from_anywhere,
                    perpage: this.perPage,
                    name: this.activityName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    event_type_ids: this.selectedEventTypes.map((type) => type.id),
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    cause_ids: this.selectedCauses.map((cause) => cause.id),
                    startDate: this.startDate,
                    endDate: this.endDate,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };

                const res = await getOrganizationActivites(params);
                this.events = res.organizationEvents.data;
                this.activitiesCount = res.eventsCount;
                this.loading = false;
                this.visible = false;
            } catch (error) {
                console.error(error);
            }
        },
        async loadMoreActivities() {
            this.showSpinner = true;
            try {
                this.loading = true;
                var check_in_from_anywhere = null;
                if (this.CheckedInTypeOption == 'Virtual') {
                    check_in_from_anywhere = 1
                }
                if (this.CheckedInTypeOption == 'Onsite') {
                    check_in_from_anywhere = 0
                }
                const params = {
                    pageno: this.pageNo + 1,
                    check_in_from_anywhere: check_in_from_anywhere,
                    perpage: this.perPage,
                    name: this.activityName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    event_type_ids: this.selectedEventTypes.map((type) => type.id),
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    cause_ids: this.selectedCauses.map((cause) => cause.id),
                    date_range: this.dates,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };
                const res = await getOrganizationActivites(params);
                const newActivities = res.organizationEvents.data;
                this.events = [...this.events, ...newActivities];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.CheckedInTypeOption = null;
            this.activityName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedEventTypes = [];
            this.selectedSkills = [];
            this.selectedCauses = [];
            this.dates = null;
            this.startDate = null;
            this.endDate = null;
            this.searchActivities();
            this.loading = false;
        },
        async onSortChange(event) {
            this.loading = true;
            this.sortvalue = event.value.value;
            this.sortType = event.value.type;
            var check_in_from_anywhere = null;
            if (this.CheckedInTypeOption == 'Virtual') {
                check_in_from_anywhere = 1
            }
            if (this.CheckedInTypeOption == 'Onsite') {
                check_in_from_anywhere = 0
            }
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage,
                check_in_from_anywhere: check_in_from_anywhere,
                name: this.activityName,
                province_id: this.selectedProvince ? this.selectedProvince.id : null,
                city_id: this.selectedCity ? this.selectedCity.id : null,
                event_type_ids: this.selectedEventTypes.map((type) => type.id),
                skill_ids: this.selectedSkills.map((skill) => skill.id),
                cause_ids: this.selectedCauses.map((cause) => cause.id),
                date_range: this.dates,
                sortBy: this.sortvalue,
                sortType: this.sortType,
            };
            const res = await getOrganizationActivites(params);
            this.events = res.organizationEvents.data;
            this.activitiesCount = res.eventsCount
            this.loading = false;
        },
        handleViewLocationClick(eventLocations, name) {
            this.eventLocationData = eventLocations;
            this.dailogHrader = name;
        },
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        },
        viewAllVolunteers(slug) {
            let url = this.baseUrl + '/organization-dashboard/activity/' + slug + '/volunteers';
            return url;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        toastShow() {
            this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
        },
        dynamicURL(item) {
            if (item.label == 'Share on Facebook') {
                const urlParam = 'https://www.facebook.com/sharer/sharer.php?u=' + this.baseUrl + '/r/' + this.organization.slug;
                window.open(urlParam, '_blank');
            }
            if (item.label == 'Share on Twitter') {
                const urlParam = 'https://twitter.com/intent/tweet?text=' + this.baseUrl + '/r/' + this.organization.slug;
                window.open(urlParam, '_blank');
            }
            if (item.label == 'Copy Link') {
                const copyurl = this.baseUrl + '/organizations/' + this.organization.slug;
                const textarea = document.createElement('textarea');
                textarea.value = copyurl;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            }
        },
    },
};
</script>

<template>
  <section class="dashboard container-fluid py-3">
    <div class="container-xxxl">
      <Toast ref="toast" position="top-right"></Toast>
      <div class="card w-100">
        <div class="card-body p-4 row mx-0 row-gap-3">
          <div class="row row-gap-3 mx-0 px-0">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <img v-if="this.imgDataUrl" :src="this.imgDataUrl" class="img-fluid rounded" />
              <img v-else-if="!organization.logo" src="@/assets/img/org_placeholder.png" class="img-fluid rounded" />
              <Image v-else class="img-fluid rounded" :src="this.image" :alt="organization.slug" preview
                id="uploaded_images" />
              <i class="fa fa-check-circle text-success font-20 fa-check-circl d-none"></i>
              <button for="upload_images" class="btn btn-primary w-100" @click="toggleShow">
                <i class="fa-solid fa-upload pr-1"></i>
                Upload Profile Picture
              </button>
            </div>
            <div class="col-lg-9 col-md-6 col-sm-12 d-flex flex-column row-gap-4">
              <div class="row mx-0">
                <h3>Organization Basic Information</h3>
                <Divider></Divider>
              </div>
              <div class="row mx-0">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Organization Name</label>
                  <input type="text" v-model="organizationName" id="name" name="name" class="form-control"
                    placeholder="Organization Name" :disabled="organizationName !== ''">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Email Address</label>
                  <input type="email" v-model="emailAdress" id="email" name="email" class="form-control"
                    placeholder="Email Address" :disabled="emailAdress !== ''">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label>Phone Number <small>(will not be published)</small><span
                      class="text-danger ml-1">*</span></label>
                  <input type="tel" v-model="phoneNumber" id="phone" name="phone" class="form-control "
                    placeholder="Phone (0300xxxxxxx)" maxlength="11" :disabled="phoneNumber !== ''">
                </div>
              </div>
            </div>
          </div>
          <Accordion :multiple="true" :activeIndex="[0]" class="d-flex flex-column row-gap-2">
            <AccordionTab header="More Information">
              <div class="row mx-0">
                <div class="col-md-6 col-sm-12 form-group">
                  <label>
                    Type of Organization<span class="text-danger ml-1">*</span>
                  </label>
                  <Dropdown v-model="selectedEmploymentStatus" :options="employmentStatuses" filter optionLabel="name"
                    placeholder="Type of organization" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        {{ slotProps.value.name }}
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        {{ slotProps.option.name }}
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedEmploymentStatus"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEmploymentStatus }}</span>
                </div>
                <div class="col-md-6 col-sm-12 form-group">
                  <label>Type of Activity you Organize<span class="text-danger ml-1">*</span></label>
                  <MultiSelect v-model="selectedEventTypes" display="chip" :options="eventTypes" :selectionLimit="4"
                    optionLabel="name" placeholder="Select Event Types" :maxSelectedLabels="100" class="w-100" />
                  <span v-if="this.validationErrors.selectedEventTypes"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedEventTypes }}</span>
                </div>
                <div class="col-md-6 col-sm-12 form-group">
                  <label>Province<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedProvince" :options="provinces" filter optionLabel="name"
                    placeholder="Province" class="w-100" @change="changeProvinces()">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedProvince"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedProvince }}</span>
                </div>
                <div class="col-md-6 col-sm-12 form-group">
                  <label>City<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="selectedCity" :options="provinceCities" :disabled="!selectedProvince" filter
                    optionLabel="name" placeholder="City" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.selectedCity"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.selectedCity }}</span>
                </div>
                <div class="col-md-12 col-sm-12 form-group d-flex flex-column row-gap-2">
                  <!-- <AutoComplete @location-sent="getValue" :userAddress="this.user.address"></AutoComplete> -->
                  <!-- <AutoComplete v-model="address" @filter-sent="updateAddress"></AutoComplete> -->
                  <label for="">Organization Address</label>
                  <!-- <vue-google-autocomplete v-model="address" id="map" classname="form-control"
                      placeholder="Organization Address" @placechanged="onPlaceChanged"
                      :country="['pk']">
                  </vue-google-autocomplete> -->
                  <VueGoogleAutocomplete class="p-0" id="map"
                    v-model="address"
                    :userAddress="address"
                    @location-sent="onLocationSent"></VueGoogleAutocomplete>
                  <div class="d-flex flex-start gap-2 row-gap-2 flex-wrap">
                    <Chip v-model="newAddress" v-if="newAddress" :removable="true"
                      @remove="removeLocation(newAddress)"
                      :label="newAddress" icon="fa-solid fa-location-dot" class="mt-3"
                    />
                    <Chip v-model="selectedLocations" v-if="selectedLocations && !newAddress"
                      :label="selectedLocations" icon="fa-solid fa-location-dot" class="mt-3"
                    />
                    <span v-if="this.validationErrors.selectedLocations"
                        class="text-danger empty-1 text-validation">{{this.validationErrors.selectedLocations }}</span>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <label>About the organization<span class="text-danger ml-1">*</span></label>
                  <textarea class="form-control" v-model="about" rows="10" style="min-height: 110px"
                    placeholder="Describe Your Cause (Limit: 500 Words)"></textarea>
                  <span v-if="this.validationErrors.about"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.about }}</span>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Contact Person Information">
              <div class="row mx-0">
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Contact Person Name<span class="text-danger ml-1">*</span></label>
                  <input type="text" v-model="contactPersonName" id="name" name="name" class="form-control"
                    placeholder="Contact Person Name">
                  <span v-if="this.validationErrors.contactPersonName"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonName }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Email Address<span class="text-danger ml-1">*</span></label>
                  <input type="email" v-model="contactPersonEmail" id="email" name="email" class="form-control"
                    placeholder="Email Address">
                  <span v-if="this.validationErrors.contactPersonEmail"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonEmail }}</span>
                </div>

                <div class="col-md-4 col-sm-12 form-group">
                  <label>Phone Number
                    <span class="text-danger ml-1">*</span>
                  </label>
                  <input type="tel" v-model="contactPersonPhone" id="phone" name="phone" class="form-control "
                    placeholder="Phone (0300xxxxxxx)" maxlength="11">
                  <span v-if="this.validationErrors.contactPersonPhone"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonPhone }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Gender<span class="text-danger ml-1">*</span></label>
                  <div class="flex flex-wrap gap-3 my-3">
                    <div class="flex align-items-center">
                      <RadioButton v-model="gender" inputId="male" name="male" value="male" />
                      <label for="male" class="ml-2">Male</label>
                    </div>
                    <div class="flex align-items-center">
                      <RadioButton v-model="gender" inputId="female" name="female" value="female" />
                      <label for="female" class="ml-2">Female</label>
                    </div>
                  </div>
                  <!-- <Dropdown v-model="selectedEmploymentStatus" :options="employmentStatuses" optionLabel="name"
                    placeholder="Employment Status" class="w-100" /> -->
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>Designation<span class="text-danger ml-1">*</span></label>
                  <input type="text" v-model="contactPersonDesignation" id="designation" name="designation" class="form-control"
                    placeholder="Designation Name">
                  <span v-if="this.validationErrors.contactPersonDesignation"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonDesignation }}</span>
                </div>
                <div class="col-md-6 col-sm-12 form-group">
                  <label>Province<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="contactPersonProvince" :options="provinces" filter optionLabel="name"
                    placeholder="Province" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.contactPersonProvince"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonProvince }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <label>City<span class="text-danger ml-1">*</span></label>
                  <Dropdown v-model="contactPersonCity" :options="provinceCities" :disabled="!selectedProvince" filter
                    optionLabel="name" placeholder="City" class="w-100">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                  <span v-if="this.validationErrors.contactPersonCity"
                    class="text-danger empty-1 text-validation">{{ this.validationErrors.contactPersonCity }}</span>
                </div>
                <div class="col-md-4 col-sm-12 form-group">
                  <!-- <AutoComplete v-model="personAddresss" @filter-sent="updatePersonAddress"></AutoComplete> -->
                  <label for="">Office Address</label>                 
                  <!-- <GoogleAddressAutocomplete apiKey="AIzaSyAJK7B4UfCWSzTWeH-tfPny-OQpzVpfcCM" v-model="personAddresss"
                    @callback="callbackFunction" class="form-control" :country="['pk']" ref="autocompleteInput"
                    placeholder="Office Address" /> -->
                    <VueGoogleAutocomplete class="p-0" id="map"
                    v-model="personAddresss"
                    :userAddress="personAddresss"
                    @location-sent="onLocationSentOffice"></VueGoogleAutocomplete>

                  <Chip v-model="newPersonAddress" v-if="newPersonAddress" :removable="true"
                      @remove="removePersonLocation(newPersonAddress)"
                      :label="newPersonAddress" icon="fa-solid fa-location-dot" class="mt-3"
                    />
                  <Chip v-model="contactPersonAddress" v-if="contactPersonAddress && !newPersonAddress" 
                      :label="contactPersonAddress" icon="fa-solid fa-location-dot" class="mt-3"
                    />
                </div>

              </div>
            </AccordionTab>
            <AccordionTab header="Social Profiles">
              <p class="text-capitalize">
                Link Your Social Profiles for Better Connectivity
              </p>
              <div class="row">
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Facebook</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fa-brands fa-square-facebook"></i>
                    </span>
                    <InputText type="text" v-model="facebook" name="facebook" class="flex-grow-1"
                      placeholder="https://facebook.com/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Twitter</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fa-brands fa-square-twitter"></i>
                    </span>
                    <InputText type="text" v-model="twitter" name="twitter" class="flex-grow-1"
                      placeholder="https://twitter.com/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Linkedin</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-linkedin"></i>
                    </span>
                    <InputText type="text" v-model="linkedin" name="linkedin" class="flex-grow-1"
                      placeholder="https://linkedin.com/in/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Instagram</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-square-instagram"></i>
                    </span>
                    <InputText type="text" v-model="instagram" name="instagram" class="flex-grow-1"
                      placeholder="https://instagram.com/username" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Snapchat</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fa-brands fa-square-snapchat"></i>
                    </span>
                    <InputText type="text" v-model="Snapchat" name="snapchat" class="flex-grow-1"
                      placeholder="https://snapchat.com/username" />
                  </div>
                </div>
              </div>

            </AccordionTab>
            <AccordionTab header="Official Links">
              <p class="text-capitalize">
                Link Your Official app and website for Better Connectivity
              </p>
              <div class="row">
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername" class="text-capitalize">Play Store</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fa-brands fa-google-play"></i>
                    </span>
                    <InputText type="text" v-model="android" class="flex-grow-1"
                      placeholder="www.playstore.com/appurl" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">App Store</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fab fa-app-store-ios"></i>
                    </span>
                    <InputText type="text" v-model="ios" class="flex-grow-1" placeholder="www.appstore.com/appurl" />
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-12">
                  <label for="validationCustomUsername">Website</label>
                  <div class="p-inputgroup flex-1">
                    <span class="p-inputgroup-addon text-primary">
                      <i class="fa-solid fa-globe"></i>
                    </span>
                    <InputText type="text" v-model="website" class="flex-grow-1"
                      placeholder="www.yourwebsite.com/profileurl" />
                  </div>
                </div>
              </div>

            </AccordionTab>
          </Accordion>
          <div class="row">
            <div class="col-md-12 text-center text-md-right">
              <button type="submit" class="btn btn-primary d-inline-flex align-items-center gap-1 save_btn"
                :disabled="showSpinner" @click="updateProfile()">
                <i v-if="!showSpinner" class="fa-solid fa-floppy-disk pr-1"></i> Save Organization Profile
                <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8" fill="transparent"
                  animationDuration="2s" aria-label="Custom ProgressSpinner" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <my-upload field="img" @crop-success="cropSuccess" @crop-upload-fail="cropUploadFail" v-model="show" img-format="png"
    lang-type="en" @change="handleFileSelected"></my-upload>
</template>
<script>
import { getOrganizationDetail, updateOrganizationProfile, getOrganization, getUserDeatails } from "@/api";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from "@/components/AutoComplete";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import GoogleAddressAutocomplete from 'vue3-google-address-autocomplete'
import VueGoogleAutocomplete from "@/components/AutoComplete.vue";
import { GoogleMap } from 'vue3-google-map'
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeflex/primeflex.css';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Chip from 'primevue/chip';
import myUpload from 'vue-image-crop-upload';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';

export default {
  components: {
    // HomeComponent,
    TabView,
    TabPanel,
    Image,
    Calendar,
    RadioButton,
    Dropdown,
    MultiSelect,
    GoogleMap,
    AutoComplete,
    Textarea,
    Button,
    ListingSkeleton,
    Accordion,
    AccordionTab,
    Divider,
    InputText,
    Chip,
    myUpload,
    ProgressSpinner,
    Toast,
    VueGoogleAutocomplete,
    // GoogleAddressAutocomplete,
  },
  data() {
    const maxYear = 2012;
    return {
      selectedLocations: null,
      newAddress : null,
      newPersonAddress : null,
      imageurl: null,
      about: null,
      organization: [],
      user: [],
      image: '',
      loading: true, // To show loading message
      errored: false, // To show error message
      organizationName: null,
      contactPersonName: null,
      contactPersonEmail: null,
      contactPersonPhone: null,
      contactPersonGender: null,
      contactPersonCity: null,
      contactPersonAddress: null,
      emailAdress: null,
      phoneNumber: null,
      maxSelectableDate: new Date(maxYear, 11, 31),
      dateOfBirth: null,
      selectedEmploymentStatus: null,
      selectedEducation: null,
      selectedCity: null,
      selectedProvince: null,
      contactPersonProvince: null,
      contactPersonDesignation: null,
      employmentStatuses: [],
      eventTypes: [],
      skills: [],
      causes: [],
      universities: [],
      education: [],
      educationField: [],
      gender: null,
      provinces: [],
      contactProvinces: [],
      cities: [],
      provinceCities: [],
      selectedEventTypes: [],
      // social models 
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      Snapchat: null,
      address: null,
      personAddresss: null,
      show: false,
      imgDataUrl: null,
      validationErrors: [],
      showSpinner: false,
      android: null,
      ios: null,
      website: null,
    };
  },
  async mounted() {
    this.baseUrl = window.location.origin;
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    try {
      const myUser = localStorage.getItem("organization");
      const parsedUser = JSON.parse(myUser);
      const res = await getOrganizationDetail();
      const organization = await getOrganization(parsedUser.slug);
      const org = organization.organization;
      this.organization = org;
      // console.log(org.addresss);
      this.organizationName = org.user.name;
      this.contactPersonName = org.contact_person_name;
      this.contactPersonEmail = org.contact_person_email;
      this.contactPersonPhone = org.contact_person_phone;
      this.gender = org.contact_person_gender;
      this.contactPersonCity = org.contact_person_city_id;
      this.contactPersonAddress = org.contact_person_address;
      this.contactPersonDesignation = org.contact_person_designation;
      this.selectedLocations = org.address;
      // console.log(this.selectedLocations);
      this.emailAdress = org.user.email;
      this.phoneNumber = org.user.phone;
      this.gender = org.contact_person_gender == 1 ? 'male' : 'female';
      this.about = org.about;
      // this.about = this.about.replace(/<[^>]*>/g, '');
      //asigning  data to socials models 
      this.facebook = org.facebook;
      this.twitter = org.twitter;
      this.linkedin = org.linkedin;
      this.instagram = org.instagram;
      this.Snapchat = org.snapchat;
      this.android = org.android;
      this.ios = org.ios;
      this.website = org.website;
      if(!org.university_id) {
        res.organizationType.splice(res.organizationType.indexOf(4));
      }
      //all the dropdowns & multiselect arrays data
      this.employmentStatuses = res.organizationType;
      this.eventTypes = res.eventType;
      this.provinces = res.provinces;
      this.contactProvinces = res.provinces;
      this.cities = res.cities;
      //select dropdowns & multiselect values
      const selectedOption = this.employmentStatuses.find(status => status.id === org.organization_type_id);
      if (selectedOption) {
        this.selectedEmploymentStatus = selectedOption;
      }
      if (org.event_types) {
        this.selectedEventTypes = org.event_types;
        // console.log(this.selectedEventTypes);
      }
      const selectedProvinceOption = this.provinces.find(status => status.id === org.province_id);
      if (selectedProvinceOption) {
        this.selectedProvince = selectedProvinceOption;
      }
      const selectedProvinceOptionContact = this.contactProvinces.find(status => status.id === org.contact_person_province_id);
      if (selectedProvinceOptionContact) {
        this.contactPersonProvince = selectedProvinceOptionContact;
      }
      const selectedCityOption = this.cities.find(c => c.id === org.city_id);
      if (selectedCityOption) {
        this.selectedCity = selectedCityOption;
      }
      const personselectedCityOption = this.cities.find(c => c.id === org.contact_person_city_id);
      if (personselectedCityOption) {
        this.contactPersonCity = personselectedCityOption;
      }
      this.image = this.imageurl + '/' + this.organization.logo;
    } catch (error) {
      console.error(error);
      this.errored = true;
    } finally {
      this.loading = false;
    }
  },
  watch: {
    selectedProvince(newProvince) {
      // Filter cities based on the selected province and update the cities options
      if (newProvince) {
        this.provinceCities = this.cities.filter((city) => city.province_id === newProvince.id);
      } else {
        // If no province is selected, reset the cities options to empty
        this.cities = [];
      }
    },
    contactPersonProvince(newProvince) {
      // Filter cities based on the selected province and update the cities options
      if (newProvince) {
        this.provinceCities = this.cities.filter((city) => city.province_id === newProvince.id);
      } else {
        // If no province is selected, reset the cities options to empty
        this.cities = [];
      }
    },
    
  },
  methods: {
    changeProvinces() {
      this.selectedCity = null;
    },
    getValue(value) {
      console.log(value);
    },
    toggleShow() {
      this.show = !this.show;
    },
    removeLocation(index) {
      const set = new Set([index]);
      const result2 = Array.from(set).slice(1);
      this.address = '';
      this.newAddress = '';

      // this.selectedLocations.splice(index, 1);
    },
    removePersonLocation(index) {
      const set = new Set([index]);
      const result2 = Array.from(set).slice(1);
      this.personAddresss = '';
      this.newPersonAddress = '';
    },
    // updateAddress(data) {
    //   this.newAddress = data.address;
    //   // this.selectedLocations = data.address;
    //   // You can also access latitude and longitude if needed: data.latitude, data.Longitude
    // },
    onLocationSent(data) {
      this.newAddress = data.address;
      // console.log(this.newAddress);
    },
    onLocationSentOffice(data) {
      this.newPersonAddress = data.address;
    },
    
    // callbackFunction(place, addressData) {
    //   this.newPersonAddress = place.formatted_address;
    // },
    // updatePersonAddress(data) {
    //   this.newPersonAddress = data.address;
    // },
    
    validateData() {
      this.validationErrors = {};
      if (!this.selectedEmploymentStatus) {
        this.validationErrors.selectedEmploymentStatus = 'Type of organization is required';
      }
      // if (!this.selectedEventTypes) {
      //   this.validationErrors.selectedEventTypes = 'Type of activity is required';
      // }
      if (!Array.isArray(this.selectedEventTypes) || this.selectedEventTypes.length === 0) {
        this.validationErrors.selectedEventTypes = 'Type of activity is required';
      }
      if (!this.selectedProvince) {
        this.validationErrors.selectedProvince = 'Province is required';
      }
      if (!this.selectedCity) {
        this.validationErrors.selectedCity = 'City is required';
      }
      // if (!this.newAddress) {
      //   console.log('1');
      //   this.validationErrors.selectedLocations = 'Address is required';
      // }
      if (!this.about) {
        this.validationErrors.about = 'About is required';
      }
      if (!this.contactPersonName) {
        this.validationErrors.contactPersonName = 'Contact person name is required';
      }
      if (!this.contactPersonEmail) {
        this.validationErrors.contactPersonEmail = 'Contact person email is required';
      }
      if (!this.contactPersonPhone) {
        this.validationErrors.contactPersonPhone = 'Contact person phone is required';
      }
      if (!this.contactPersonCity) {
        this.validationErrors.contactPersonCity = 'Contact person city is required';
      }
      if (!this.contactPersonDesignation) {
        this.validationErrors.contactPersonDesignation = 'Contact person designation is required';
      }
      if (!this.contactPersonProvince) {
        this.validationErrors.contactPersonProvince = 'Contact person province is required';
      }      
      if(!this.selectedLocations && !this.newAddress) {
        this.validationErrors.selectedLocations = 'Address is required';
      }
      return Object.keys(this.validationErrors).length === 0;

    },
    async updateProfile() {
      this.showSpinner = true;
      if (!this.validateData()) {
        this.showSpinner = false;
        this.$refs.toast.add({ severity: 'error', summary: 'Oops...', detail: 'Something went wrong', life: 3000 });
        return;
      }
      const params = {
        phone: this.phoneNumber,
        about: this.about,
        contact_person_name: this.contactPersonName,
        contact_person_email: this.contactPersonEmail,
        contact_person_phone: this.contactPersonPhone,
        contact_person_designation: this.contactPersonDesignation,
        contact_person_province_id : this.contactPersonProvince ? this.contactPersonProvince.id : null,
        contact_person_gender: this.gender,
        contact_person_city_id: this.contactPersonCity ? this.contactPersonCity.id : null,
        contact_person_address: this.newPersonAddress ? this.newPersonAddress : this.contactPersonAddress,
        organization_type_id: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.id : null,
        event_types: this.selectedEventTypes ? this.selectedEventTypes.map(type => type.id) : null,
        province_id: this.selectedProvince ? this.selectedProvince.id : null,
        city_id: this.selectedCity ? this.selectedCity.id : null,
        address: this.newAddress ? this.newAddress : this.selectedLocations,
        facebook: this.facebook,
        twitter: this.twitter,
        linkedin: this.linkedin,
        instagram: this.instagram,
        snapchat: this.Snapchat,
        android: this.android,
        ios: this.ios,
        website: this.website,
      };

      if (params.contact_person_gender === 'male') {
        params.contact_person_gender = 1;
      } else if (params.contact_person_gender === 'female') {
        params.contact_person_gender = 2;
      }
      try {
        const res = await updateOrganizationProfile(params);
        if (res.status == 200) {
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Profile has been updated',
            confirmButtonText: 'OK',
            allowOutsideClick: false,                       
          });
          const userDeatil = await getUserDeatails();
          localStorage.setItem('organization', JSON.stringify(userDeatil.data.organization));
          localStorage.setItem('user', JSON.stringify(userDeatil.data.user));
          window.location.href = this.baseUrl + '/organization-dashboard/';
        }
        // console.log(res.data);        
      } catch (error) {
        this.validationErrors = error.response.data.errors;
        // console.log(this.validationErrors);
      } finally {
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    },
    cropSuccess(dataUrl) {
      this.imgDataUrl = dataUrl;
      const result = this.$getFileFormat(dataUrl, this.filename, this.imageType).then(file => {
        this.file = file;
        const path = this.$uploadSingleImage(this.file, this.orgiginalIamge).then(path => {
          const params = {
            slug: this.organization.slug,
            logo: path[0],
            logo_thumb: path[1]
          };
          this.$updateUserProfileImage(params);
        });

      })
    },
    handleFileSelected(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.orgiginalIamge = selectedFile;
        const fileName = selectedFile.name;
        const fileType = selectedFile.type;
        this.filename = fileName
        this.imageType = fileType;
      }
    },
  }
};
</script>

<template>
    <div style="max-width: 600px; margin: 0 auto;" class="email-container">
        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%"
            style="margin: auto; background-color: #ffffff;">
            <tr>
                <td valign="middle" style="padding: 3em 0 2em 0;">
                    <img src="../assets/img/email.png" alt=""
                        style="width: 200px; max-width: 400px; height: auto; margin: auto; display: block;">
                </td>
            </tr><!-- end tr -->
            <tr>
                <td valign="middle" style="padding: 2em 0 4em 0;">
                    <table>
                        <tr>
                            <td>
                                <div class="text" style="padding: 0 2.5em; text-align: center;">
                                    <h2>Verify Your Email Address!</h2>
                                    <p class="mb-0">Before proceeding, please check your email for a verification link.</p>
                                    <p>If you did not receive the email</p>
                                    <p><a class="btn btn-primary" @click="sendVerifyEmail()">Click here to request another</a></p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr><!-- end tr -->
        </table>
    </div>
</template>
<script>
  import axios from "axios";
export default {
    data() {
      return {
        email: null,
      };
    },
    async mounted() {
        const user = localStorage.getItem("user");
        const parsedUser = JSON.parse(user);
        this.email = parsedUser.email;
    },
    methods: {
        async sendVerifyEmail() {
            axios.post(process.env.VUE_APP_BASE_URL +'/api/send-email-verification', { email: this.email }).then(response => {
                if(response.status == 200 ) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Email sent for verification',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,                       
                    });
                }
                // console.log(response);
            }).catch(error => {
                console.error(error.response);
            });
        },
    },
};
</script>
<template>
    <MapComponent></MapComponent>
    <section class="container-fluid py-5 milkar-footer mt-5">
    <div class="col-lg-10 milkar-footer-content">
      <h2 class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-0 text-white">Connecting People, Skills & Good Causes</h2>
      <p class="mb-4 py-0 mb-3 px-0">Download the app with everything for free. </p>
      <ul class="nav justify-content-center justify-content-lg-start app-button">
        <li class="me-2"><a href="https://apps.apple.com/pk/app/milkar/id1565517520"><img class="img-fluid lazy"
              src='../assets/img/appstore.png' alt="milkar.com apple store"></a></li>

        <li><a href="https://play.google.com/store/apps/details?id=org.milkar.pwa"><img class="img-fluid lazy"
              src='../assets/img/googlestore.png' alt="milkar.com playstore"></a></li>
      </ul>
    </div>
    <div class="mobile-image">
      <img class="img-fluid lazy" src="../assets/img/download-app-case.png" alt="milkar.com app download">
    </div>
  </section>
</template>
  
<script>
import MapComponent from "../components/Home/MapComponent";

export default {
    name: "MapView",
    components: {
        MapComponent,
    },
    props: {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
    async mounted() {},
};
</script>
  
<template>
    <section class="container-fluid py-5 mostpopular">
        <div class="container-xxl">
            <div class="row row-gap-3 align-items-center">
                <div class="col-12 px-2">
                    <h2>Most Popular</h2>
                    <p>
                        MilKar serves as an interactive platform for connecting volunteers
                        and organizations to build a better community.
                    </p>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <carousel :itemsToShow="1" :autoplay="2000" :wrap-around="true" class="card">
                        <slide v-for="(popular, index) in  poularEvents.slice(0, Math.ceil(poularEvents.length / 2))"
                            :key="index">
                            <div class="carousel__item card-main">
                                <div class="card-body event-img p-0">
                                    <a :href="baseUrl + '/activities/' + popular.slug">
                                        <img v-if="popular.picture" :src="this.imageurl + '/' + popular.picture"
                                            alt="Slider Image" class="img-fluid">
                                        <img v-else src="@/assets/img/org_placeholder.png" class="img-fluid">

                                        <div class="badge">Activity</div>
                                    </a>
                                </div>
                                <div class="card-footer">
                                    <label class="text-truncate">
                                        {{ popular.event_type.name }}
                                        by
                                        <span class="text-primary text-truncate">
                                            <a href="#">
                                                {{ popular.OwenerDetail[0].name }}
                                            </a>
                                        </span>
                                    </label>
                                    <h4 class="name text-truncate">{{ popular.name }}</h4>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <carousel :itemsToShow="1" :autoplay="2000" :wrap-around="true" class="card">
                        <slide v-for="(popular, index) in poularEvents.slice(Math.ceil(poularEvents.length / 2))"
                            :key="index">
                            <div class="carousel__item card-main">
                                <div class="card-body event-img p-0">
                                    <a :href="baseUrl + '/activities/' + popular.slug">
                                        <img v-if="popular.picture" :src="this.imageurl + '/' + popular.picture"
                                            alt="Slider Image" class="img-fluid">
                                        <img v-else src="@/assets/img/org_placeholder.png" class="img-fluid">

                                        <div class="badge">Activity</div>
                                    </a>

                                </div>
                                <div class="card-footer">
                                    <label class="text-truncate">{{ popular.event_type.name }} by <span
                                            class="text-primary text-truncate"><a href="#">{{ popular.OwenerDetail[0].name
                                            }}</a></span></label>
                                    <h4 class="name text-truncate">{{ popular.name }}</h4>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
                <div v-if="presses.length > 0" class="col-lg-3 col-md-12 col-sm-12">
                    <carousel :itemsToShow="1" :autoplay="2000" :wrap-around="true" class="card">
                        <slide v-for="(press, index) in presses" :key="index">
                            <div class="carousel__item card-main">
                                <div class="card-body event-img p-0">
                                    <a :href="press.link">
                                        <img :src="this.imageurl + '/' + press.banner" alt="Slider Image" class="img-fluid">
                                        <div class="badge">Press</div>
                                    </a>
                                </div>
                                <div class="card-footer">
                                    <label class="text-truncate"> <span class="text-primary text-truncate"></span></label>
                                    <h4 class="name text-truncate">{{ press.title }}</h4>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <carousel :itemsToShow="1" :autoplay="2000" :wrap-around="true" class="card">
                        <slide v-for="(ambassador, index) in ambassadors" :key="index">
                            <div class="carousel__item card-main">
                                <div class="card-body event-img p-0">
                                    <a :href="ambassador.profile_url">
                                        <img v-if="ambassador.picture" :src="this.imageurl + '/' + ambassador.picture"
                                            alt="Slider Image" class="img-fluid">
                                        <img v-else :src="this.$volunteerNoImage(ambassador.volunteer.gender)"
                                            class="img-fluid">
                                        <div class="badge">Ambassador Stories</div>
                                    </a>
                                </div>
                                <div class="card-footer">
                                    <label class="text-truncate">{{ this.$stripHTML(ambassador.about) }}</label>
                                    <h4 class="name text-truncate">{{ ambassador.name }}</h4>
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>

import { getPopularEvents, getPresses, getAmbassadors } from "@/api";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import "primevue/resources/themes/lara-light-indigo/theme.css";

export default {
    name: "HomeView",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: {},
    computed: {

    },
    data() {
        return {
            imageurl: null,
            poularEvents: [],
            presses: [],
            ambassadors: [],
            loading: true,
            loadingPopularEvents: true, // To show loading message
            loadingBlogs: true,
            loadingAmbassadors: true,
            errored: false, // To show error message
            slider: null,
            slider2: null,
            //heat map option are here 
        };
    },
    methods: {

    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            /* eslint-disable */
            this.poularEvents = await getPopularEvents();
            this.loadingPopularEvents = false;

            this.ambassadors = await getAmbassadors();
            // console.log(this.ambassadors);
            this.loadingPopularEvents = false;

            this.presses = await getPresses();
            this.loadingAmbassadors = false;

        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loadingPopularEvents = false;
        }
    },
};
</script>
  
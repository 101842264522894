<template>
    <section v-if="volunteerImages.length > 0">
        <carousel :autoplay="0" :responsiveOptions="responsiveOptions" class="justify-content-start">
            <slide v-for="(image, index) in volunteerImages" :key="index">
                <div class="carousel__item">
                    <div class="event-img p-0 custom-carousel-detail-items">
                        <img class="img-fluid"
                            :style="{ backgroundImage: 'url(' + this.imageurl + '/' + image.image_thumb + ')' }"
                            @click="openGallery(image.imageUrl)" preview>
                    </div>
                    <!-- <div class="card-footer">
                        <label class="text-truncate">Uploaded by: <strong>{{
                            image.volunteer.user.name }}</strong></label>
                    </div> -->
                </div>
            </slide>
            <template #addons>
                <Navigation />
            </template>
        </carousel>
        <Galleria v-model:visible="displayBasic" :value="galleryImages" :responsiveOptions="responsiveOptions"
            :numVisible="9" containerStyle="max-width: 50%" :circular="true" :fullScreen="true" :showItemNavigators="true">
            <template #item="slotProps">
                <div class="card gallery-main-img">
                    <img :src="slotProps.item.imageUrl" :alt="slotProps.item.alt"
                        style="width: 100%; display: block" />
                    <div class="card-footer bg-white rounded-0">
                        <label class="text-truncate">
                            Event Name:
                            <strong>
                                {{ slotProps.item.events.name }}
                            </strong>
                        </label>
                    </div>
                </div>
            </template>
            <template #thumbnail="slotProps">
                <img class="gallery-thumbnail-img" :src="this.imageurl + '/' + slotProps.item.image_thumb"
                    :alt="slotProps.item.alt" style="display: block" />
            </template>
        </Galleria>

    </section>
</template>
<script>
import Galleria from 'primevue/galleria';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { getVolunteerImages } from "@/api";
import Image from 'primevue/image';

export default {
    components: {
        DataView,
        Image,
        Galleria,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            imageurl: null,
            volunteerImages: [],
            loading: false,
            userName: '',
            universityName: '',
            galleryImages: [],
            displayBasic: false,
            cityName: '',
            degree: '',
            field: '',
            rating: 0,
            responsiveOptions: [], // Define your responsive options for Galleria here
            responsiveOptions: ([
                {
                    breakpoint: '1500px',
                    numVisible: 5
                },
                {
                    breakpoint: '1024px',
                    numVisible: 3
                },
                {
                    breakpoint: '768px',
                    numVisible: 2
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ])
        };
    },
    props: {
        volunteerSlug: {
            type: String,
            required: true
        },
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            const params = {
                slug: this.volunteerSlug
            };
            const res = await getVolunteerImages(params);
            this.volunteerImages = res[0].volunteer_event_images;
            // console.log(this.volunteerImages);
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        stripHTML(html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || '';
        },
        openGallery(imageSrc) {
            // When an image in the carousel is clicked, open the gallery and set the images
            this.galleryImages = [...this.volunteerImages];
            const index = this.galleryImages.findIndex((image) => image.imageUrl === imageSrc);
            if (index !== -1) {
                const clickedImage = this.galleryImages.splice(index, 1)[0];
                this.galleryImages.unshift(clickedImage);
            }


            this.displayBasic = true;
        },

    }
}
</script>

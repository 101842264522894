<template>
    <!-- <section class="dashboard container-fluid py-3">
        <div class="d-flex justify-content-between align-items-center">
            <h3>Create SMS Campaign</h3>
        </div>
        <div class="col-lg-12 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
            <label>Campaign Name<span class="text-danger ml-1">*</span></label>
            <InputText v-model="fileCampaignName" placeholder="Campaign name" />
            <span v-if="this.validationErrors.fileCampaignName"
                class="text-danger empty-1 text-validation">{{ this.validationErrors.fileCampaignName }}</span>
        </div>
        <div class="col-lg-12 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
            <label>Campaign Text<span class="text-danger ml-1">*</span></label>
            <InputText v-model="fileCampaignText" placeholder="Campaign Text" />
            <span v-if="this.validationErrors.fileCampaignText"
                class="text-danger empty-1 text-validation">{{ this.validationErrors.fileCampaignText }}</span>
        </div>
        <FileUpload name="volunteer_list" :url="createSmsCampaign" @select="onSelectedFiles, isFileUpload = true"
            @upload="onFileUpload" :multiple="false" accept=".xlsx, .xls" :maxFileSize="1000000">
            <template #empty>
                <p>Drag and drop files to here to upload.</p>
            </template>
</FileUpload>
<input class="file-input" type="file" ref="file" name="file" accept=".xlsx, .xls" @change="onFileChange">
<span v-if="this.validationErrors.file"
    class="text-danger empty-1 text-validation">{{ this.validationErrors.file }}</span>
<div class="text-end">
    <a class="btn btn-primary-sm w-25 d-flex align-items-center gap-2 flex-wrap" @click="createSmsCampaignFile()">
        <i class="pi pi-plus"></i> Create SMS Campaign
    </a>
</div>

</section>
<div class="row mt-5">
    <div class="col-lg-4">
        <div class="card-body">
            <h3 class="">Filter Options</h3>
            <div class="d-flex flex-column align-items-start gap-2 mb-2">
                <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name" placeholder="Province"
                    class="w-100" :disabled="isVirtualSelected" />
                <MultiSelect v-model="selectedCities" :options="cities" optionLabel="name" placeholder="Select Cities"
                    :maxSelectedLabels="3" class="w-100" />

            </div>
            <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                @click="countVolunteers" />
            <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                @click="resetFilters" />
        </div>
        <Sidebar v-model:visible="visible">
            <div class="card-body">
                <h3 class="">Filter Options</h3>
                <div class="d-flex flex-column align-items-start gap-2 mb-2">
                    <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name" placeholder="Province"
                        class="w-100" :disabled="isVirtualSelected" />
                </div>
                <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                    @click="searchActivities" />
                <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                    @click="resetFilters" />
            </div>
        </Sidebar>
    </div>
    <div class="col-lg-8">
        <div class="col-lg-12 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
            <label>Campaign Name<span class="text-danger ml-1">*</span></label>
            <InputText v-model="campaignName" placeholder="Campaign name" />
            <span v-if="this.validationErrors.campaignName"
                class="text-danger empty-1 text-validation">{{ this.validationErrors.campaignName }}</span>
        </div>
        <div class="col-lg-12 col-md-6 col-sm-12 form-group row mx-0 d-flex align-items-top flex-column">
            <label>Campaign Text<span class="text-danger ml-1">*</span></label>
            <InputText v-model="campaignText" placeholder="Campaign Text" />
            <span v-if="this.validationErrors.campaignText"
                class="text-danger empty-1 text-validation">{{ this.validationErrors.campaignText }}</span>
        </div>
        <div>
            <span>Numbers of volunteers: {{ this.volunteersCount }}</span>
        </div>
        <div class="text-end">
            <a class="btn btn-primary-sm w-25 d-flex align-items-center gap-2 flex-wrap" @click="createSmsCampaign()">
                <i class="pi pi-plus"></i> Create SMS Campaign </a>
        </div>
    </div>
</div> -->

    <section class="container px-0 py-5">
        <div class="row mx-0 align-items-start">
            <div class="col-lg-12 mb-3">
                <h3>Create SMS Campaign</h3>
            </div>
            <div
                class="d-flex align-items-start flex-wrap flex-md-nowrap flex-sm-nowrap justify-content-center row-gap-5">
                <div class="flex-auto">
                    <div class="check-smsbox">
                        <h2>Upload Excel File</h2>
                        <p>Please input the SMS campaign text into the designated field and utilize the option to
                            upload a list of volunteers via an Excel file.</p>
                        <div class="row my-4">
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group d-flex align-items-top flex-column">
                                <label>Campaign Name<span class="text-danger ml-1">*</span></label>
                                <InputText v-model="fileCampaignName" placeholder="Campaign name" />
                                <span v-if="this.validationErrors.fileCampaignName"
                                    class="text-danger empty-1 text-validation">
                                    {{ this.validationErrors.fileCampaignName }}
                                </span>

                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 form-group d-flex align-items-top flex-column">
                                <label>Campaign Text<span class="text-danger ml-1">*</span></label>
                                <Textarea v-model="fileCampaignText" rows="5" cols="30" />
                                <!-- <InputText v-model="fileCampaignText" placeholder="Campaign Text" /> -->
                                <span v-if="this.validationErrors.fileCampaignText"
                                    class="text-danger empty-1 text-validation">
                                    {{ this.validationErrors.fileCampaignText }}
                                </span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="custom-uploader">
                                    <input class="file-input" type="file" ref="file" name="file" accept=".xlsx, .xls"
                                        @change="onFileChange">
                                    <span v-if="this.validationErrors.file" class="text-danger empty-1 text-validation">
                                        {{ this.validationErrors.file }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap align-items-center justify-content-between my-3">
                                <span class="fs-7">Volunteer List <span class="fs-8 fw-bold text-muted">( Name, Phone,
                                        XLS/XLS )</span></span>
                                <button class="btn btn-primary-sm flex-sm-grow-0 flex-grow-1" @click="downloadFile"><i
                                        class="pi pi-download fs-7 mr-2"></i>Download
                                    Excel File</button>
                            </div>
                            <div class="col-lg-12">
                                <a class="btn btn-primary-sm w-100" @click="createSmsCampaignFile()">
                                    <i class="pi pi-plus fs-8 mr-2"></i>Create SMS Campaign
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 text-center px-3 mt-auto mb-auto justify-content-center">OR</div>
                <div class="flex-auto">
                    <div class="check-smsbox">
                        <h2>Selective Volunteers List</h2>
                        <p>Kindly draft the SMS campaign text within the designated field and utilize the filter option
                            to
                            organize the list of volunteers accordingly.</p>
                        <div class="row mx-0 my-4">

                            <div class="col-lg-12 col-md-12 col-sm-12 form-group d-flex align-items-top flex-column">
                                <label>Campaign Name<span class="text-danger ml-1">*</span></label>
                                <InputText v-model="campaignName" placeholder="Campaign name" />
                                <span v-if="this.validationErrors.campaignName"
                                    class="text-danger empty-1 text-validation">{{ this.validationErrors.campaignName }}</span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group d-flex align-items-top flex-column">
                                <label>Campaign Text<span class="text-danger ml-1">*</span></label>
                                <Textarea v-model="campaignText" rows="5" cols="30" />
                                <!-- <InputText v-model="campaignText" placeholder="Campaign Text" /> -->
                                <span v-if="this.validationErrors.campaignText"
                                    class="text-danger empty-1 text-validation">{{ this.validationErrors.campaignText }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <lable class="mb-2">Filter Options</lable>
                                <span class="d-flex align-items-center">
                                    <span class="fs-8 mr-1">Volunteers:</span>
                                    <span class="fw-bold">
                                        {{ this.volunteersCount }}
                                    </span>
                                </span>
                            </div>

                            <div class="d-flex flex-wrap align-items-start gap-2 row-gap-4">
                                <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                                    placeholder="Province" class="flex-auto" :disabled="isVirtualSelected" />
                                <MultiSelect v-model="selectedCities" :options="cities" optionLabel="name"
                                    placeholder="Select Cities" :maxSelectedLabels="3" class="flex-auto" />
                            </div>

                            <div class="d-flex align-items-center gap-2 my-3">
                                <Button type="button" class="btn btn-primary-sm d-flex align-items-center flex-auto"
                                    label="Search" x @click="searchActivities" />
                                <Button type="button" class="btn btn-secondary-sm d-flex align-items-center flex-auto"
                                    label="Reset Filter" icon="pi pi-times fs-7" @click="resetFilters" />
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <a class="btn btn-primary-sm w-100" @click="createSmsCampaign()">
                                    <i class="pi pi-plus fs-8 mr-2"></i>Create SMS Campaign
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getActivitiesFilters, postSmsCampaign, postSmsCampaignByCities, getCountVolunteerNumbers } from "@/api";
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';

// import axios from "axios";



export default {
    components: {
        Badge,
        Button,
        InputText,
        FileUpload,
        Dropdown,
        Sidebar,
        MultiSelect,
        Checkbox,
        Textarea,
    },
    data() {

        return {
            validationErrors: [],
            campaignName: null,
            campaignText: null,
            campaignFile: null,
            fileCampaignName: null,
            fileCampaignText: null,
            provinves: [],
            cities: [],
            selectedProvince: null,
            selectedCities: [],
            isFileUpload: false,
            volunteersCount: 0,
            baseUrl: '',
            selectedFile: null,
        }

    },
    async mounted() {
        this.baseUrl = window.location.origin;
        const res2 = await getActivitiesFilters();
        this.allCities = res2.cities;
        this.provinves = res2.provinves;
    },
    watch: {
        selectedProvince(newProvince) {
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                this.cities = [];
            }
        },
    },
    methods: {
        validateData() {
            this.validationErrors = {};
            if (!this.campaignName && !this.isFileUpload) {
                this.validationErrors.campaignName = "Name is required";
            }
            if (!this.campaignText && !this.isFileUpload) {
                this.validationErrors.campaignText = "Text is required";
            }
            if (!this.fileCampaignName && this.isFileUpload) {
                this.validationErrors.fileCampaignName = "Name is required";
            }
            if (!this.fileCampaignText && this.isFileUpload) {
                this.validationErrors.fileCampaignText = "Text is required";
            }
            return Object.keys(this.validationErrors).length === 0;
        },
        async onSelectedFiles(event) {
            alert('will save compain on upload click ');
            if (!this.validateData()) {
                this.showSpinner = false;
                const errorMessages = Object.values(this.validationErrors).map(error => `<li>${error}</li>`).join('');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    confirmButtonColor: "#46B849",
                    html: `<ul>${errorMessages}</ul>`,
                });
                return;
            }
            try {
                const res = await postSmsCampaign(this.fileCampaignName, this.fileCampaignText, event.files[0]);
                if (res.status == 200 || res.status == 201) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'SMS Campaign has been created',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = this.baseUrl + '/organization-dashboard/sms-campaign-list';
                        }
                    });

                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });
                }
            }
            catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            }
        },
        async createSmsCampaign() {
            alert('will save compain');
            if (!this.validateData()) {
                this.showSpinner = false;
                const errorMessages = Object.values(this.validationErrors).map(error => `<li>${error}</li>`).join('');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    confirmButtonColor: "#46B849",
                    html: `<ul>${errorMessages}</ul>`,
                });
                return;
            }
            const params = {
                compaign_name: this.campaignName,
                sms_txt: this.campaignText,
                cities: this.selectedCities,
                provinve: this.selectedProvince
            };
            try {
                const res = await postSmsCampaignByCities(params);
                if (res.status == 200 || res.status == 201) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'SMS Campaign has been created',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = this.baseUrl + '/organization-dashboard/sms-campaign-list';
                        }
                    });
                }
            }
            catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            }
        },
        onFileChange(event) {
            const fileInput = event.target;
            if (fileInput.files.length > 0) {
                this.selectedFile = fileInput.files[0];
                this.isFileUpload = true;
            }
        },
        async createSmsCampaignFile() {
            // alert('will save compain on upload click ');
            // console.log(this.selectedFile);
            if (!this.validateData()) {
                this.showSpinner = false;
                const errorMessages = Object.values(this.validationErrors).map(error => `<li>${error}</li>`).join('');
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    confirmButtonColor: "#46B849",
                    html: `<ul>${errorMessages}</ul>`,
                });
                return;
            }
            try {
                const res = await postSmsCampaign(this.fileCampaignName, this.fileCampaignText, this.selectedFile);
                if (res.status == 200 || res.status == 201) {
                    this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'SMS Campaign has been created',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#46B849",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = this.baseUrl + '/organization-dashboard/sms-campaign-list';
                        }
                    });

                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });
                }
            }
            catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            }
        },
        async countVolunteers() {
            const params = {
                cities: this.selectedCities,
                provinve: this.selectedProvince
            };
            try {
                const res = await getCountVolunteerNumbers(params);
                this.volunteersCount = res.data;
            }
            catch (error) {

            }
        },
        async downloadFile() {
            try {
                // Make a request to the server to get the file
                const response = await fetch('/example.xlsx');

                // Convert the response to blob
                const blob = await response.blob();

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(new Blob([blob]));

                // Create a link element and click it to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'example.xlsx');
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the temporary URL and link element
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }

    }
}
</script>

<template>
    <dropdown-menu class="me-3 notificationDropdownMenu">
        <template #trigger>
            <button class="notification-btn dropdown-toggle me-2" type="button" id="notificationDropdownMenu"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell"></i>

                <span class="vol-unread-notifications quantity" v-if="this.notificationsCount">
                    {{ this.notificationsCount }} </span>
            </button>
        </template>
        <template #header>
            <div>
                <h4 class="widget-title d-inline-flex align-items-center">
                    Notification
                </h4>
                <a class="reloadAllVolNotifications ml-2 pointer" @click="getnotifications">
                    <i class="fas fa-sync-alt pe-auto text-primary refresh-icon" aria-hidden="true"></i>
                </a>

            </div>
            <div class="volMarkAllRequestsAsRead">
                <a class="text-muted font-12 pointer" @click="updateNotifications(false)" style="cursor: pointer">Mark
                    all
                    as read</a>
            </div>
        </template>
        <template #body>
            <ScrollPanel style="width: 100%; max-height: 300px" class="custom-scroll">
                <div v-if="this.notifications && isAuthenticated && role == 0" class="notification-dropdown">
                    <div v-for="(notification, index) in notifications" :key="index">
                        <a v-if="notification.event && notification.type == 1"
                            :href="baseUrl + '/activities/' + notification.event.slug"
                            @click="updateNotifications(notification.id)">
                            <div class="row px-0 mx-0">
                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>
                                <div class="col-8">
                                    <p class="mb-0 fs-7"><strong>
                                            {{ notification.organization ? getName(notification.event, 1) :
                    getName(notification.event, 0) }}
                                        </strong> has added a new activity
                                        <strong>{{ notification.event.name }}</strong>
                                        recommended for you.
                                    </p>

                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                            <!-- end mess__item -->
                        </a>
                        <a v-if="notification.type == 6"
                            :href="this.baseUrl + '/volunteer-dashboard/activity/' + notification.event.slug + '/volunteers/shortlisted'"
                            @click="updateNotifications(notification.id)">
                            <div class="row px-0 mx-0">
                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>

                                <div class="col-8">
                                    <p class="mb-0 fs-7">Your request to <strong>
                                            {{ notification.user.name }}
                                        </strong> has been accepted for <strong>{{ notification.event.name }}</strong>.
                                    </p>
                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                            <!-- end mess__item -->
                        </a>
                        <a v-if="notification.type == 7" :href="baseUrl + '/activities/' + notification.event.slug"
                            @click="updateNotifications(notification.id, notification.event.slug)">
                            <div class="row px-0 mx-0">
                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>
                                <div class="col-8">
                                    <p class="mb-0 fs-7">Your request for the activity <strong>
                                            {{ notification.event.name }}
                                        </strong> has been approved by <strong>{{ notification.organization ?
                    notification.event.organization.name :
                    notification.event.ownervolunteer.name }}</strong>
                                    </p>
                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                        </a>
                        <a v-if="notification.type == 10"
                            :href="this.baseUrl + '/volunteer-dashboard/activity/' + notification.event.slug + '/volunteers/completed'"
                            @click="updateNotifications(notification.id)">
                            <div class="row px-0 mx-0">

                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>
                                <div class="col-8">
                                    <p class="mb-0 fs-7">
                                        <strong>
                                            {{ notification.user.name }}
                                        </strong> has marked
                                        <strong>
                                            {{ notification.event.name }}
                                        </strong>
                                        as complete.
                                    </p>
                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                        </a>
                        <a v-if="notification.type == 12" :href="baseUrl + '/activities/' + notification.event.slug"
                            @click="updateNotifications(notification.id)">
                            <div class="row px-0 mx-0">
                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>
                                <div class="col-8 fs-7">
                                    <p class="mb-0 fs-7">
                                        <span>Review received from </span>
                                    <strong> {{ notification.organization ? notification.event.organization.name :
                                        notification.event.ownervolunteer.name }}</strong>
                                    against activity <strong>{{ notification.event.name }}</strong>
                                    </p>
                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                            <!-- end mess__item -->
                        </a>
                        <a v-if="notification.type == 13" :href="baseUrl + '/activities/' + notification.event.slug"
                            @click="updateNotifications(notification.id)">
                            <div class="row px-0 mx-0">
                                <div class="col-3">
                                    <img v-if="!notification.event.thumbnail_picture"
                                        src="@/assets/img/org_placeholder.png"
                                        class="activity-img-block border-radius img-fluid" />
                                    <img v-else :src="this.imageurl + '/' + notification.event.thumbnail_picture"
                                        alt="abc" class="img-fluid rounded">
                                </div>
                                <div class="col-8 fs-7">
                                    <p class="mb-0 fs-7">Review received from <strong>
                                            {{ notification.user.name }}
                                        </strong> against activity <strong>{{ notification.event.name }}</strong>.
                                    </p>

                                    <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                                </div>
                                <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                    <span class="inactive-available-circle"></span>
                                    <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                                </div>
                            </div>
                            <Divider></Divider>
                            <!-- end mess__item -->
                        </a>
                    </div>
                </div>
                <div v-if="this.notifications.length <= 0 && isAuthenticated && role == 0">
                    <a href="#" class="d-block">
                        <div class="mess__item justify-content-center pb-4">
                            <div class="content">
                                <p class="text-muted mb-0 text-center">No information available</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div v-if="this.notifications && isAuthenticated && role == 1">
                    <a v-for="(notification, index) in notifications" :key="index"
                        :href="baseUrl + '/activities/' + notification.event_slug">
                        <div class="row px-0 mx-0">
                            <div class="col-3">
                                <img v-if="!notification.profile_pic" src="@/assets/img/org_placeholder.png"
                                    class="activity-img-block border-radius img-fluid" />
                                <img v-else :src="this.imageurl + '/' + notification.profile_pic" alt="abc"
                                    class="img-fluid rounded">
                            </div>

                            <div class="col-8 fs-7" v-if="notification.type == 10">
                                <p class="mb-0 fs-7">
                                    <strong>
                                        {{ notification.user_name }}
                                    </strong> has marked
                                    <strong>
                                        {{ notification.event_name }}
                                    </strong>
                                    as complete.
                                </p>

                                <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                            </div>
                            <div class="col-8 fs-7" v-if="notification.type == 13">
                                <p class="mb-0 fs-7">Review received from <strong>
                                        {{ notification.user_name }}
                                    </strong> against activity <strong>{{ notification.event_name }}</strong>.
                                </p>

                                <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                            </div>
                            <div class="col-8 fs-7" v-if="notification.type == 6">
                                <p class="mb-0 fs-7">Your request to <strong>
                                        {{ notification.user_name }}
                                    </strong> has been accepted for <strong>{{ notification.event_name }}</strong>.
                                </p>

                                <span class="fs-8 text-primary">{{ notification.updated_at }}</span>
                            </div>
                            <div v-if="!notification.is_read" class="col-1 px-0 mt-1">
                                <span class="inactive-available-circle"></span>
                                <!-- <i class="fs-7 fas fa-circle text-danger"></i> -->
                            </div>

                        </div>
                        <Divider></Divider>
                    </a>
                </div>
                <p v-if="isLoading" class="org-notifications-loading text-center mt-2"> <i
                        class="fas fa-circle-notch fa-spin"></i> Loading</p>
                <!-- <div v-if="this.notifications.length <= 0 && isAuthenticated && !isLoading">
                    <a href="#" class="d-block">
                        <div class="mess__item justify-content-center pb-4">
                            <div class="content">
                                <p class="text-muted mb-0 text-center">No information available</p>
                            </div>
                        </div>
                    </a>
                </div> -->
            </ScrollPanel>
        </template>
        <template #footer v-if="!isLoading && this.lastPage > this.pageNo">
            <div class="my-3 text-center">
                <a @click="loadMore($event)" class="fs-7 cursor-pointer text-primary">Load More</a>
            </div>
        </template>
    </dropdown-menu>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu'
import { loadNotifications, readNotifications } from "@/api";
import Divider from 'primevue/divider';
import ScrollPanel from 'primevue/scrollpanel';

export default
    {
        components: {
            DropdownMenu,
            Divider,
            ScrollPanel
        },
        data() {
            return {
                pageNo: 1,
                perPage: 10,
                notifications: [],
                notificationsCount: null,
                userID: null,
                isLoading: false,
                lastPage: 1,
            }
        },
        props: ['isAuthenticated', 'role'],
        async mounted() {
            this.baseUrl = window.location.origin;
            this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
            const user = JSON.parse(localStorage.getItem('user'));
            this.userID = user.id;
            this.getnotifications();

        },
        methods: {
            getURL(url) {

            },
            async getnotifications() {
                this.isLoading = true;
                this.notifications = [];
                this.pageNo = 1;
                const params = {
                    pageno: this.pageNo,
                    perpage: this.perPage
                };
                try {
                    const responce = await loadNotifications(params);
                    this.lastPage = responce.notifications.last_page;
                    this.notifications = responce.notifications.data;
                    this.notificationsCount = responce.notificationsCount;
                    this.isLoading = false;
                }
                catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            getName(event, role) {
                var name = '';
                if (role == 1) {
                    if (event.organizationsCsv) {
                        name = event.organizationsCsv;
                        return name;
                    }
                    else {
                        return '';
                    }
                }
                else if (role == 0) {
                    name = event.ownervolunteer ? event.ownervolunteer.name : '';
                    return name;
                }
            },
            async loadMore(event) {
                event.stopPropagation(); // Stop event propagation
                this.pageNo++;
                this.isLoading = true;
                try {
                    const params = {
                        pageno: this.pageNo,
                        perpage: this.perPage
                    };
                    const responce = await loadNotifications(params);
                    this.notifications = [...this.notifications, ...responce.notifications.data];
                    this.isLoading = false;
                } catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            async updateNotifications(id, slug) {
                this.isLoading = true;
                try {
                    var params = {};
                    if (id) {
                        params = {
                            notificationID: id
                        };
                    }
                    const responce = await readNotifications(params);
                    if (responce.status == 200 || responce.status == 201) {
                        window.location.href = this.baseUrl + "/activities/" + slug;
                    }
                    this.getnotifications();
                    this.isLoading = false;
                } catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            }
        }
    }
</script>
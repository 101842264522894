<template>
    <div class="col-lg-3">
        <div class="card w-100 side-filter-sticky">
            <div class="card-body">
                <h3 class="">Filter Options</h3>
                <div class="d-flex flex-column align-items-start gap-2 mb-2">
                    <InputText v-model="name" type="text" placeholder="Name" class="w-100" />
                    <InputText v-model="address" type="text" placeholder="Address" class="w-100" />
                </div>
                <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                    @click="searchInstitutes" />
                <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                    @click="resetInstitutes" />
            </div>
        </div>
    </div>
</template>
<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
    emits: ['filter-sent'],
    components: {
        InputText,
        Button,
    },
    props: {
        isTabChnaged: String,
    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            name: '',
            address: '',
            imageurl: null,
            baseUrl: null,
            oldtab: null,
        };
    },
    watch: {
        isTabChnaged(newVal) {
            this.name = '';
            this.address = '';
        },
    },
    methods: {
        searchInstitutes() {
            const data = {
                name: this.name,
                address: this.address,
            }
            this.$emit('filter-sent', data);
        },
        resetInstitutes() {
            this.name = '';
            this.address = '';
            const data = {
                name: this.name,
                address: this.address,
            }
            this.$emit('filter-sent', data);
        },
    },
}
</script>

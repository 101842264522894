import { createRouter, createWebHistory } from "vue-router";
import { createApp } from 'vue';
import HomeView from "../views/HomeView.vue";
import Map from "../views/Map.vue";
import VolunteerDashboard from "../views/Volunteer/DashboardView.vue";
import OrganizationDashboard from "../views/Organization/DashboardView.vue";
import OrganizationUpdateProfile from "../views/Organization/UpdateProfile.vue";
import SmsCampaigns from "../views/Organization/SmsCampaigns.vue";
import CreateSmsCampaign from "../views/Organization/CreateSmsCampaign.vue";
import UpdateProfile from "../views/Volunteer/UpdateProfile.vue";
import OurTeam from "../views/OurTeam.vue";
import OurAmbassadors from "../views/OurAmbassadors.vue";
import Organizations from "../views/Organizations.vue";
import Institutes from "../views/Institutes.vue";
import Volunteers from "../views/Volunteers.vue";
import VolunteerDetail from "../views/VolunteerDetail.vue";
import OrganizationDetail from "../views/OrganizationDetail.vue";
import InstituteDetail from "../views/InstituteDetail.vue";
import OurProjects from "../views/OurProjects.vue";
import Champions from "../views/Champions.vue";
import Press from "../views/Press.vue";
import Blog from "../views/Blog.vue";
import ContactUs from "../views/ContactUs.vue";
import Register from "../auth/RegisterComponent.vue";
import Login from "../auth/LoginComponent.vue";
import CommunityGuidelines from "../views/CommunityGuidelines.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import { authGuard } from "./gaurds";
import CreateCause from "../views/Volunteer/CreateCause.vue";
import UpdateCause from "../views/Volunteer/UpdateCause.vue";
import CreateActivity from "../views/Organization/CreateActivity.vue";
import UpdateActivity from "../views/Organization/UpdateActivity.vue";
import ActivityVolunteers from "../views/Volunteer/ActivityVolunteers.vue";
import OrganizationActivityVolunteers from "../views/Organization/ActivityVolunteers.vue";
import Verification from "../auth/VerificationComponent.vue";
import Verified from "../auth/VerifiedComponent.vue";
import CompleteProfile from "../auth/CompleteProfile.vue";
import ForgotPassword from "../auth/ForgotPassword.vue";
import ResetPassword from "../auth/ResetPassword.vue";
import VerifyPassword from "../auth/VerifyPassword.vue";
import Offline from "../components/OfflineComponent.vue";
import NotFound from '@/components/PageNotFound.vue';

const routes = [
  { 
    path: '/offline',
    name: 'offline',
    component: Offline 
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
  {
    path: "/volunteer-dashboard/update-settings",
    name: "update-profile",
    component: UpdateProfile,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard",
    name: "volunteer-dashboard",
    component: VolunteerDashboard,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard/:tabSelected",
    name: "volunteer-dashboard-tabs",
    component: VolunteerDashboard,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard/create-activity",
    name: "create-cause",
    component: CreateCause,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard/activity/:slug/update-event",
    name: "volunteer-activity-update",
    component: UpdateCause,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/verification",
    name: "organization-verify",
    component: Verification,
    meta: { requiresAuth: true },
  },
  {
    path: "/verified/:token",
    name: "verfied",
    component: Verified,
    meta: { requiresAuth: true },
  },
  {
    path: "/completeprofile",
    name: "completeprofile",
    component: CompleteProfile,
    meta: { requiresAuth: true },
  },

  {
    path: "/organization-dashboard",
    name: "organization-dashboard",
    component: OrganizationDashboard,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/:tabSelected",
    name: "organization-dashboard-tabs",
    component: OrganizationDashboard,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/update-settings",
    name: "organization-update-profile",
    component: OrganizationUpdateProfile,
    meta: { requiresAuth: true },
    // beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/create-activity",
    name: "create-activity",
    component: CreateActivity,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/sms-campaign-list",
    name: "sms-campaign-list",
    component: SmsCampaigns,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/create-sms-campaign",
    name: "create-sms-campaign",
    component: CreateSmsCampaign,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/activity/:slug/update-event",
    name: "activity-update",
    component: UpdateActivity,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard/activity/:slug/volunteers",
    name: "volunteer-activity-dashboard",
    component: ActivityVolunteers,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/volunteer-dashboard/activity/:slug/volunteers/:tabSelected",
    name: "owner-volunteer-request",
    component: ActivityVolunteers,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/activity/:slug/volunteers",
    name: "organization-activity-dashboard",
    component: OrganizationActivityVolunteers,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/organization-dashboard/activity/:slug/volunteers/:tabSelected",
    name: "organization-volunteer-request",
    component: OrganizationActivityVolunteers,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: authGuard,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/map",
    name: "map",
    component: Map,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      requiresGuest: true,
    },
    component: Login,
  },
  {
    path: "/login/:isActive",
    name: "login-active",
    meta: {
      requiresGuest: true,
    },
    component: Login,
  },

  {
    path: "/Register",
    name: "register",
    meta: {
      requiresGuest: true,
    },
    component: Register,
  },
  {
    path: "/r/:rcode",
    name: "refer",
    meta: {
      requiresGuest: true,
    },
    component: Register,
  },
  {
    path: "/team",
    name: "OurTeam",
    meta: {
      requiresGuest: true,
    },
    component: OurTeam,
  },
  {
    path: "/volunteers",
    name: "volunteers",
    meta: {
      requiresGuest: true,
    },
    component: Volunteers,
  },
  {
    path: "/volunteers/:slug",
    name: "volunteerDetail",
    props: true,
    meta: {
      requiresGuest: true,
    },
    component: VolunteerDetail,
  },
  {
    path: "/ambassadors",
    name: "OurAmbassadors",
    meta: {
      requiresGuest: true,
    },
    component: OurAmbassadors,
  },
  {
    path: "/organizations",
    name: "organizations",
    meta: {
      requiresGuest: true,
    },
    component: Organizations,
  },
  {
    path: "/organizations/:slug",
    name: "OrganizationDetail",
    props: true,
    meta: {
      requiresGuest: true,
    },
    component: OrganizationDetail,
  },
  {
    path: "/institutes",
    name: "institutes",
    meta: {
      requiresGuest: true,
    },
    component: Institutes,
  },
  {
    path: "/institutes/:slug",
    name: "InstituteDetail",
    props: true,
    meta: {
      requiresGuest: true,
    },
    component: InstituteDetail,
  },

  {
    path: "/activities",
    name: "activities",

    component: () =>
      import(
        /* webpackChunkName: "activities" */ "../views/ActivitiesView.vue"
      ),
  },
  {
    path: "/activities/:slug",
    name: "ActivityDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "activities" */ "../views/ActivityDetail.vue"
      ),
  },
  {
    path: "/blogs/:slug",
    name: "BlogDetail",
    props: true,
    component: () =>
      import(/* webpackChunkName: "activities" */ "../views/BlogDetail.vue"),
  },
  {
    path: "/api/social-login/google/callback",
    name: "SocialLogin",
    props: true,
    component: () =>
      import(/* webpackChunkName: "activities" */ "../auth/SocialLogin.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      requiresGuest: true,
    },
    component: OurProjects,
  },
  {
    path: "/milkar-champions",
    name: "milkar-champions",
    meta: {
      requiresGuest: true,
    },
    component: Champions,
  },
  {
    path: "/press",
    name: "press",
    meta: {
      requiresGuest: true,
    },
    component: Press,
  },
  {
    path: "/blogs",
    name: "blog",
    meta: {
      requiresGuest: true,
    },
    component: Blog,
  },
  {
    path: "/blogs/category/:slug",
    name: "blog-category",
    meta: {
      requiresGuest: true,
    },
    component: Blog,
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      requiresGuest: true,
    },
    component: ContactUs,
  },
  {
    path: "/community-guidelines",
    name: "CommunityGuidelines",
    component: CommunityGuidelines,
  },
  {
    path: "/privacy",
    name: "PrivacyView",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "TermsView",
    component: Terms,
  },
  {
    path: "/password/forgot",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/password/reset/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/password/verify",
    name: "VerifyPassword",
    component: VerifyPassword,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  var user = localStorage.getItem("user");
  user = JSON.parse(user);
  const isAuthenticated  = token && user ? true : false;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      next({ name: "login" });
    } else {
      if (user.role == 1)
      {
        const org = JSON.parse(localStorage.getItem('organization'));
        if (org && org.is_completed && user.is_email_verified)
        {
          next();
        }
        else if(to.name === "organization-verify" && user.is_email_verified  )
        {
          next("/completeprofile");
        }
        if(to.name === "completeprofile" && org && !user.is_email_verified && !org.is_completed )
        {
          next("/verification");
        }
        else if (to.name === "completeprofile"  && org && user.is_email_verified && org.is_completed)
        {
          window.location.href = "/organization-dashboard"
        }
        else if (to.name === "organization-verify" && user.is_email_verified && org.is_completed)
        {
          window.location.href = "/organization-dashboard"
        }
      }
    }
  } else {
    // For routes that do not require authentication
    next();
  }
  const isOnline = navigator.onLine; // Check if the user is online
  if (!isOnline && to.path !== '/offline') {
    next('/offline'); // Navigate to the offline page if offline
  } else if (isOnline && to.path === '/offline') {
    // If the user was offline and navigated to the offline page,
    // and now online, redirect to the next route (e.g., '/')
    next('/');
  } else {
    document.title = to.meta.title || 'Milkar.com - Connecting people, skills and good causes'; // Set the tab title
    next(); // Continue with navigation
  }
});

// Listen for the online event to redirect when the user gets online
window.addEventListener('online', () => {
  if (router.currentRoute.value.path === '/offline') {
    // If the current route is the offline page and the user comes online,
    // redirect to the next route (e.g., '/')
    router.push('/');
  }
});


export default router;

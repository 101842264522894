<template>
    <section class="dashboard container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-12">
                    <div class="card flex justify-content-center">
                        <Breadcrumb :home="home" :model="items" class="text-capitalize">
                            <template #item="{ label, item, props }">
                                <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
                                    <a :href="routerProps.href" v-bind="props.action">
                                        <span v-bind="props.icon" class="text-primary" />
                                        <span v-bind="props.label">{{ label }}</span>
                                    </a>
                                </router-link>
                                <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                                    <span v-if="item.icon" v-bind="props.icon" />
                                    <span v-bind="props.label">{{ label }}</span>
                                </a>
                            </template>
                        </Breadcrumb>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card w-100">
                        <div class="card-body">
                            <div class="col-lg-12 px-0 text-left">
                                <div class="text-center">
                                    <img v-if="!activity.picture" src="@/assets/img/org_placeholder.png"
                                        class="border-radius img-fluid" width="100" shape="circle" />
                                    <Image v-else class="border-radius img-fluid" preview
                                        :src="this.imageurl + '/' + activity.picture" :alt="activity.name" width="100"
                                        shape="circle" />
                                </div>
                                <h4 class="mt-2 mt-4">{{ activity.name }}</h4>
                                <p class="font-15 mb-2 fs-7">
                                    {{ activity.event_type && activity.event_type.name }} by
                                    <span class="contact-info">
                                        <a href="" class="text-primary">{{ this.ownerName }}</a>
                                    </span>
                                </p>
                                <div class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <span class="badge-counter-text">Volunteers Engaged:
                                            <span v-if="activity.registeredVolunteersCount > 0"
                                                class="counter">{{ activity.registeredVolunteersCount }}</span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>

                                        <span class="badge-counter-text">Volunteers Required:
                                            <span v-if="activity.totalVolunteersRequired > 0"
                                                class="counter">{{ activity.totalVolunteersRequired }}</span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>

                                    </div>
                                </div>
                                <div class="d-flex justify-content-around flex-wrap mt-2 mb-2">
                                    <div class="col-lg-6 d-flex flex-column text-left px-0">
                                        <small class="text-black-50">Time</small>
                                        <p class="fs-8 mb-0 fw-bold">{{ activity.formattedStartTime }} - {{
                                            activity.formattedEndTime }}</p>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column text-left px-0">
                                        <small class="text-black-50">Date</small>
                                        <p class="fs-8 mb-0 fw-bold">{{ activity.formattedStartDate }} - {{
                                            activity.formattedEndDate }}</p>
                                    </div>
                                </div>
                                <div class="mb-2 p-0 mt-3 text-left">
                                    <small class="text-black-50">Venues</small>
                                    {{ thisactivityLocationCount }}
                                    <div v-if="this.activityLocationCount > 0">
                                        <div class="border-bottom pb-2 mb-2"
                                            v-for="(location, index) in activity.EventLocations" :key="index">
                                            <a :href="'https://www.google.com/maps/search/?api=1&query=' + location.latitude + ',' + location.longitude"
                                                title="" target="_blank"
                                                class="text-left fs-7 bg-light border rounded py-1 px-2 text-truncate-1 text-primary">
                                                <i class="pi pi-map-marker fs-6 mr-1"></i>
                                                {{ location.address }}
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="this.activityLocationCount <= 0 && activity.check_in_from_anywhere != 1">
                                        No Location Added
                                    </div>
                                    <div v-else-if="activity.check_in_from_anywhere == 1">
                                        Virtual
                                    </div>
                                </div>
                                <div class="mb-2 p-0 text-left" v-if="activity.hashtag">
                                    <small class="text-black-50">Cause Hashtag</small>
                                    <div class="border-bottom pb-2 mb-2 mb-2 d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                        <a :href="'https://twitter.com/hashtag/' + activity.hashtag" title=""
                                            target="_blank"
                                            class="text-left fs-7 py-1 px-2 text-truncate-1 badge-custom d-xl-inline-block">
                                            <i class="pi pi-hashtag text-primary fs-6 mr-1"></i>
                                            {{ this.activity.hashtag }}
                                        </a>
                                    </div>
                                </div>
                                <div class="mb-2 p-0 text-left" v-if="this.activityTags && this.activityTags.length > 0">
                                    <small class="text-black-50">Additional Cause Hashtags</small>
                                    <div class="border-bottom pb-2 mb-2 mb-2 d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                        <span v-for="(tag, index) in this.activityTags" :key="index"
                                            class="text-left fs-7 py-1 px-2 text-truncate-1 badge-custom d-xl-inline-block">
                                            <i class="pi pi-hashtag text-primary fs-6 mr-1"></i>
                                            <a class="color-blue" data-toggle="tooltip" data-placement="top"
                                                :title="tag.tag_name" :href="'https://twitter.com/hashtag/' + tag.tag_name"
                                                target="_blank">{{ tag.tag_name }}</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
                            <TabView :scrollable="true" v-model:activeIndex="active">
                                <TabPanel>
                                    <template #header>
                                        <span>Requests</span>
                                        <Badge class="ml-2 bg-primary">{{ this.requestedVolunteersCount }}</Badge>
                                    </template>
                                    <RequestsComponent v-if="this.active == 0" :slug="this.slug"></RequestsComponent>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>Recommended</span>
                                        <Badge class="ml-2 bg-primary">{{ this.recommendedVolunteersCount }}</Badge>
                                    </template>
                                    <RecommendedComponent v-if="this.active == 1" :slug="this.slug"></RecommendedComponent>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>Shortlisted</span>
                                        <Badge class="ml-2 bg-primary">{{ this.shortListedVolunteersCount }}</Badge>
                                    </template>
                                    <ShortlistedComponent v-if="this.active == 2" :slug="this.slug"></ShortlistedComponent>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>Completed</span>
                                        <Badge class="ml-2 bg-primary">{{ this.completedVolunteersCount }}</Badge>
                                    </template>
                                    <CompletedComponent v-if="this.active == 3" :slug="this.slug"></CompletedComponent>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>Response Awaited</span>
                                        <Badge class="ml-2 bg-primary">{{ this.pendingVolunteersCount }}</Badge>
                                    </template>
                                    <ResponseAwaitedComponent v-if="this.active == 4" :slug="this.slug">
                                    </ResponseAwaitedComponent>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { getActivity } from "@/api";
import { getEventDashboardVolunteersRequestsCount } from "@/api";
import { getEventDashboardVolunteersRecommendedCount } from "@/api";
import { getEventDashboardVolunteersShortlistedCount } from "@/api";
import { getEventDashboardVolunteersCompletedCount } from "@/api";
import { getEventDashboardVolunteersPendingsCount } from "@/api";
import Image from 'primevue/image';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import RequestsComponent from "@/components/CauseDashboard/Requests";
import RecommendedComponent from "@/components/CauseDashboard/Recommended";
import ShortlistedComponent from "@/components/CauseDashboard/Shortlisted";
import CompletedComponent from "@/components/CauseDashboard/Completed";
import ResponseAwaitedComponent from "@/components/CauseDashboard/ResponseAwaited";
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabMenu from 'primevue/tabmenu';
import Badge from 'primevue/badge';
import Sidebar from 'primevue/sidebar';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Breadcrumb from 'primevue/breadcrumb';

export default {
    components: {
        Image,
        Button,
        Textarea,
        ListingSkeleton,
        Divider,
        InputText,
        Calendar,
        RadioButton,
        Dropdown,
        MultiSelect,
        TabView,
        TabPanel,
        TabMenu,
        Badge,
        Sidebar,
        DataView,
        DataViewLayoutOptions,
        Breadcrumb,
        RequestsComponent,
        CompletedComponent,
        RecommendedComponent,
        ShortlistedComponent,
        ResponseAwaitedComponent,

    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            visible: false,
            layout: 'list',
            imageurl: null,
            activity: [],
            avgRating: null,
            ownerName: null,
            activityLocationCount: 0,
            slug: '',
            active: 0,
            activityTags: [],
            allvolunteerlist: ['data'],
            requestedVolunteersCount: null,
            recommendedVolunteersCount: null,
            shortListedVolunteersCount: null,
            completedVolunteersCount: null,
            pendingVolunteersCount: null,
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ]
        }
    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        const userId = this.$route.params.tabSelected;
        if (userId == 'requests') {
            this.active = 0;
        }
        try {
            this.loading = true;
            const res = await getActivity(this.slug);
            this.activity = res.activity;
            console.log(this.activity);
            this.ownerName = this.activity.OwenerDetail[0].name
            this.registeredVolunteers = res.registeredVolunteers;
            this.activityTags = res.additionalTags;
            this.activityLocationCount = this.activity.EventLocations.length
            const requestedVolunteersCount = await getEventDashboardVolunteersRequestsCount(this.slug);
            this.requestedVolunteersCount = requestedVolunteersCount;
            const recommendedVolunteersCount = await getEventDashboardVolunteersRecommendedCount(this.slug);
            this.recommendedVolunteersCount = recommendedVolunteersCount;
            const shortListedVolunteersCount = await getEventDashboardVolunteersShortlistedCount(this.slug);
            this.shortListedVolunteersCount = shortListedVolunteersCount;
            const completedVolunteersCount = await getEventDashboardVolunteersCompletedCount(this.slug);
            this.completedVolunteersCount = completedVolunteersCount;
            const pendingVolunteersCount = await getEventDashboardVolunteersPendingsCount(this.slug);
            this.pendingVolunteersCount = pendingVolunteersCount;
            this.loading = false;
        } catch (error) {
            console.error(error);
        }
    },
    created() {
        this.slug = this.$route.params.slug;
    },
}
</script>
<template>
  <section>
    <div class="container-fluid px-0">
      <Toast ref="toast" position="top-right"></Toast>
      <section class="login-reg-sec">
        <div class="box">
          <div class="square" style="--i:0;"></div>
          <div class="square" style="--i:1;"></div>
          <div class="square" style="--i:2;"></div>
          <div class="square" style="--i:3;"></div>
          <div class="square" style="--i:4;"></div>
          <div class="square" style="--i:5;"></div>
          <div class="card vw-40">
            <div class="card-body">
              <div class="p-dataview px-0">
                <div class="d-flex justify-content-center mb-2">
                  <a href="/" class="text-center">
                    <img src="../assets/img/logo.png" alt="logo" class="img-fluid w-50 my-3" />
                  </a>
                </div>
                <div class="row mx-0 text-center">
                  <h1 class="mb-3">Register As </h1>
                </div>
                <tabs @changed="tabChanged">
                  <tab name="Volunteer" id="Volunteer">
                    <div id="volunteer" class="row mx-0">
                      <div id="volunteerRegistration" class="row mx-0 form-signin px-0">
                        <div class="col-md-12 align-self-center text-center text-md-right mb-3 mt-3">
                          <div class="d-flex flex-row justify-content-center">
                            <RadioButton v-model="volunteerType" value="individual" inputId="individual" name="individual"
                              id="individual_type" />
                            <label for="individual" class="radio pr-3 pr-md-3 font-14-mb mx-2">Individual</label>
                            <RadioButton v-model="volunteerType" class=" mx-2" value="student" inputId="student" name="student"
                              id="student_type" />
                            <label for="student" class="radio font-14-mb">Student</label>
                          </div>
                        </div>

                        <div v-if="volunteerType === 'student'" class="form-group col-lg-12 col-md-12 col-sm-12">
                          <Dropdown v-model="selectedUniversity" :options="universities" filter optionLabel="name"
                            placeholder="Select a University" class="w-100">
                            <template #value="slotProps">
                              <div v-if="slotProps.value" class="flex align-items-center">
                                {{ slotProps.value.name }}
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>
                            <template #option="slotProps">
                              <div class="flex align-items-center white-space-break white-space-normal">
                                {{ slotProps.option.name }}
                              </div>
                            </template>
                          </Dropdown>

                          <div class="d-flex justify-content-between fs-7 mt-2">
                            <span v-if="this.validationErrors.selectedUniversity"
                              class="text-danger empty-1 text-validation">
                              {{ this.validationErrors.selectedUniversity }}
                            </span>
                            <span class="text-right fs-7 ml-auto">
                              If not found <a label="Show" class="text-primary cursor-pointer" icon="pi pi-external-link"
                                @click="regStudent = true">
                                click here
                              </a>
                            </span>
                            <Dialog v-model:visible="regStudent" modal header="Educational Institute"
                              :style="{ width: '30vw' }">
                              <form>
                                <div class="form-group text-left">
                                  <label>Institute Name</label>
                                  <input v-model="instituteName" type="text" class="form-control" name="name"
                                    placeholder="Enter Institute Name" />
                                </div>
                                <div class="form-group text-left">
                                  <label>Short Name</label>
                                  <input v-model="instituteShortName" type="text" class="form-control" name="short_name"
                                    placeholder="Enter Short Name" />
                                </div>
                                <div class="form-group text-left">
                                  <label>Type</label>
                                  <div class="flex justify-content-center">
                                    <Dropdown v-model="instituteSelectedType" :options="instituteSelectedTypeValues"
                                      optionLabel="name" placeholder="Select a Type" class="w-full" />
                                  </div>
                                </div>
                                <div class="form-group text-right">
                                  <input type="submit" value="Add" class="btn btn-primary" />
                                </div>
                              </form>
                            </Dialog>
                          </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 text-justify">
                          <input type="text" id="name" v-model="name" class="vol-input form-control"
                            placeholder="Volunteer name" />
                          <span v-if="this.validationErrors.name" class="text-danger empty-1 text-validation"> {{
                            this.validationErrors.name }}
                          </span>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 text-justify">
                          <input type="email" v-model="email" id="validationServer01" class="vol-input form-control"
                            placeholder="Email address" />
                          <span v-if="this.validationErrors.email" class="text-danger empty-1 text-validation"> {{
                            this.validationErrors.email }}
                          </span>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 text-justify">
                          <input type="tel" v-model="phone" class="vol-input form-control"
                            placeholder="Phone (0300xxxxxxx)" maxlength="11" tabindex="3"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                          <span v-if="this.validationErrors.phone" class="text-danger empty-1 text-validation"> {{
                            this.validationErrors.phone }}
                          </span>
                        </div>
                        <div class="form-group col-md-6 text-justify">
                          <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                            class="w-100 text-left" />
                          <span v-if="this.validationErrors.selectedCity" class="text-danger empty-1 text-validation">
                            {{
                              this.validationErrors.selectedCity }}
                          </span>
                        </div>
                        <div class="form-group col-md-6 d-flex align-items-center justify-content-center">
                          <div class="flex flex-wrap gap-3">
                            <div class="flex align-items-center">
                              <RadioButton v-model="gender" inputId="male" name="male" value="male" />
                              <label for="male" class="ml-2">Male</label>
                            </div>
                            <div class="flex align-items-center">
                              <RadioButton v-model="gender" inputId="female" name="female" value="female" />
                              <label for="female" class="ml-2">Female</label>
                            </div>
                            <div class="flex align-items-center">
                              <RadioButton v-model="gender" inputId="other" name="other" value="other" />
                              <label for="other" class="ml-2">Other</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <small>(Min age must be 10 years)</small>
                          <Calendar v-model="date" :maxDate="maxSelectableDate" placeholder="Date of Birth"
                            class="w-100" />
                          <span v-if="this.validationErrors.date" class="text-danger empty-1 text-validation"> {{
                            this.validationErrors.date }}
                          </span>
                        </div>
                        <div class="form-group col-md-6 ">
                          <Password v-model="password" toggleMask placeholder="Password" autocomplete="off" />
                          <span v-if="this.validationErrors.password" class="text-danger empty-1 text-validation">
                            {{
                              this.validationErrors.password }}
                          </span>
                        </div>
                        <div class="form-group col-md-6">
                          <input placeholder="Referral Code (optional)" id="rcode" v-model="rcode" type="text"
                            class="vol-input form-control"  :disabled="true"/>
                        </div>
                        <input name="activity_id" type="hidden" value="" />
                        <input type="hidden" id="role" name="role" value="0" />
                        <div class="col-lg-12">
                          <button @click="handleRegister" id="register-volunteer-btn" class="btn btn-primary w-100"
                            type="submit" tabindex="12" data-style="zoom-in" :disabled="showSpinner">
                            Register
                            <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                              fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                          </button>
                        </div>

                      </div>
                      <div class="mx-0 d-flex align-items-center gap-2 flex-sm-nowrap flex-wrap">
                        <button @click="socialLogin('google')" class=" flex-grow-1 btn btn-secondary">
                          <span class="google_wait"></span>
                          <i class="fab fa-google pr-1"></i> Google
                        </button>
                        <form action="https://stage.milkar.com/social-login/facebook" id="form-fb-signin"
                          class="flex-grow-1">
                          <button @click="socialLogin('facebook')" class="btn btn-secondary w-100">
                            <span class="fb_wait"></span>
                            <i class="fab fa-facebook-f pr-1"></i>
                            Facebook
                          </button>
                        </form>
                      </div>

                    </div>
                    <div class="footer mt-3 text-center">
                      <span class="font-12-mb">Already have an account?
                        <a href="/login" class="text-primary">Login here</a></span>
                    </div>
                  </tab>

                  <tab name="Organization" id="organization">
                    <div id="organization" class="drivers-main tab-pane p-0" role="tabpanel">
                      <div id="organizationRegistration" class="form-signin">
                        <div class="col-md-12 align-self-center text-center text-md-right mb-3 mt-3">
                          <div class="d-flex flex-row justify-content-center">
                            <RadioButton v-model="organizationType" value="organization" inputId="organization_type"
                              id="individual_type" />
                            <label for="organization_type" class="radio pr-3 pr-md-3 font-14-mb mx-2">Organization</label>
                            <RadioButton v-model="organizationType" class=" mx-2" value="educationalInstitute"
                              inputId="educationalInstitute" id="organization_type" />
                            <label for="educationalInstitute" class="radio font-14-mb">Educational Institute</label>
                          </div>
                        </div>
                        <div class="row mx-0">
                          <div v-if="organizationType === 'educationalInstitute'" class="form-group col-lg-12">
                            <Dropdown v-model="selectedInstitute" :options="universities" filter optionLabel="name"
                              placeholder="Select a University" class="w-100">
                              <template #value="slotProps">
                                <div v-if="slotProps.value" class="flex align-items-center">
                                  <div>{{ slotProps.value.name }}</div>
                                </div>
                                <span v-else>
                                  {{ slotProps.placeholder }}
                                </span>
                              </template>
                              <template #option="slotProps">
                                <div class="flex align-items-center white-space-break white-space-normal">
                                  <div>{{ slotProps.option.name }}</div>
                                </div>
                              </template>
                            </Dropdown>
                            <span v-if="this.validationErrors.selectedInstitute"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.selectedInstitute }}
                            </span>
                            <div class="text-right fs-7 mt-2">
                              If not found <a label="Show" class="text-primary cursor-pointer" icon="pi pi-external-link"
                                @click="regAddEducationalInstitute = true">
                                click here
                              </a>
                              <Dialog v-model:visible="regAddEducationalInstitute" modal header="Educational Institute"
                                :style="{ width: '30vw' }">
                                <form>
                                  <div class="form-group text-left">
                                    <label>Institute Name</label>
                                    <input v-model="instituteName" type="text" class="form-control" name="name"
                                      placeholder="Enter Institute Name" />
                                      <span v-if="this.validationErrors.instituteName" class="text-danger empty-1 text-validation">
                                        {{this.validationErrors.instituteName }}
                                      </span>
                                  </div>
                                  <div class="form-group text-left">
                                    <label>Short Name</label>
                                    <input v-model="instituteShortName" type="text" class="form-control" name="short_name"
                                      placeholder="Enter Short Name" />
                                      <span v-if="this.validationErrors.instituteShortName" class="text-danger empty-1 text-validation">
                                        {{this.validationErrors.instituteShortName }}
                                      </span>
                                  </div>
                                  <div class="form-group text-left">
                                    <label>Type</label>
                                    <div class="flex justify-content-center">
                                      <Dropdown v-model="instituteSelectedType" :options="instituteSelectedTypeValues"
                                        optionLabel="name" placeholder="Select a Type" class="w-full" />
                                    </div>
                                    <span v-if="this.validationErrors.instituteSelectedType" class="text-danger empty-1 text-validation">
                                      {{this.validationErrors.instituteSelectedType }}
                                    </span>
                                  </div>
                                  <div class="form-group text-right">
                                    <button @click.prevent="registerInstitute" class="btn btn-primary">
                                      Submit
                                      <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                                        fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                                    </button>
                                    <!-- <input type="submit" value="Add" class="btn btn-primary" /> -->
                                  </div>
                                </form>
                              </Dialog>
                            </div>
                          </div>
                        </div>
                        <div class="row mx-0">
                          <div class="form-group orgnmaefiled" v-if="organizationType === 'organization'">
                            <input type="text" v-model="organizationName" id="name_org" name="name"
                              class="org-input form-control" placeholder="Organization Name" required="" autofocus="" />
                            <span v-if="this.validationErrors.organizationName"
                              class="text-danger empty-1 text-validation">
                              {{
                                this.validationErrors.organizationName }}
                            </span>
                          </div>
                          <div class="form-group">
                            <input type="email" v-model="organizationEmail" name="email" id="email_org"
                              class="org-input form-control" placeholder="Email address" autocomplete="email"
                              required="" />
                            <span v-if="this.validationErrors.organizationEmail"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.organizationEmail }}
                            </span>
                          </div>
                          <div class="form-group">
                            <input type="tel" v-model="organizationPhone" id="phone_org" name="phone"
                              class="org-input form-control" placeholder="Phone (0300xxxxxxx)" maxlength="11"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              required="" />
                            <span v-if="this.validationErrors.organizationPhone"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.organizationPhone }}
                            </span>
                          </div>
                          <div class="form-group">
                            <Password class="d-grid" v-model="organizationPassword" toggleMask placeholder="Password" />
                            <span v-if="this.validationErrors.organizationPassword"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.organizationPassword }}
                            </span>
                          </div>
                          <div class="form-group">
                            <Password class="d-grid" v-model="organizationConfirmPassword" toggleMask
                              placeholder="Confirm password" />
                            <span v-if="this.validationErrors.organizationConfirmPassword"
                              class="text-danger empty-1 text-validation"> {{
                                this.validationErrors.organizationConfirmPassword }}
                            </span>
                          </div>
                          <div class="col-lg-12">
                            <button @click="handleRegister" id="register-volunteer-btn" class="btn btn-primary w-100"
                              type="submit" tabindex="12" data-style="zoom-in" :disabled="showSpinner">
                              Register
                              <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                                fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                            </button>
                          </div>
                          <input type="hidden" id="role" name="role" value="1" />

                        </div>
                      </div>
                    </div>
                    <div class="footer mt-3 text-center">
                      <span class="font-12-mb">Already have an account?
                        <a href="/login" class="text-primary">Login here</a></span>
                    </div>
                  </tab>
                </tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </section>
</template>
<script>
import vSelect from "vue-select";
import { getCities } from "@/api";
import { socialLogins } from "@/api";
import { getUniversitiesList, registerEducationalOrg } from "@/api";
import { Tabs, Tab } from 'vue3-tabs-component';
import 'primeflex/primeflex.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import { mapActions } from 'vuex';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';
import Toast from 'primevue/toast';

export default {
  props: {

  },
  components: {
    vSelect,
    Tabs,
    Tab,
    InputText,
    Calendar,
    Button,
    Dropdown,
    RadioButton,
    Dialog,
    ProgressSpinner,
    Password,
    Toast,

  },
  data() {
    // const currentYear = new Date().getFullYear();
    // const maxYear = 2012;
    const currentDate = new Date();
    const maxSelectableDate = new Date();
    maxSelectableDate.setFullYear(currentDate.getFullYear() - 10);
    return {
      cities: [],
      date: null,
      maxSelectableDate,
      selectedCity: null,
      selectedInstitute: null,
      selectedUniversity: null,
      gender: "male",
      volunteerType: 'individual',
      organizationType: 'organization',
      name: '',
      email: '',
      phone: '',
      password: '',
      rcode: '',
      role: 0,
      universities: [],
      organizationName: '',
      organizationEmail: '',
      organizationPhone: '',
      organizationPassword: '',
      organizationConfirmPassword: '',
      regStudent: false,
      regAddEducationalInstitute: false,
      selectedIndex: 0,
      selectedTab: null,
      instituteSelectedType: null,
      instituteSelectedTypeValues: [
        { id: 1, name: 'University' },
        { id: 2, name: 'College' },
        { id: 3, name: 'School' },
      ],
      validationErrors: [],
      showSpinner: false,
      instituteName: null,
      instituteShortName: null,
    };
  },
  async mounted() {
      const currentPath = this.$route.params.rcode;
      // alert(currentPath);
      // Check if the path matches the pattern (adjust the pattern as needed)
      if (currentPath) {
        // Redirect to the register user path
        this.$router.push('/register');
        this.rcode = currentPath;
      }
    this.baseUrl = window.location.origin;
    this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
    // console.log(this.selectedIndex);
    try {
      this.loading = true;
      const res = await getCities();
      const res2 = await getUniversitiesList();
      this.cities = res;
      this.universities = res2;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions(['register']),
    validateData() {
      // console.log(this.selectedIndex);
      this.validationErrors = {};
      if (this.volunteerType === 'individual' && this.selectedIndex === 0) {
        if (!this.email || this.email.trim() === "") {
          this.validationErrors.email = 'Email is required';
        } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
          this.validationErrors.email = 'Please enter valid email';
        }
        if (!this.name || this.name.trim() === "") {
          this.validationErrors.name = 'Name is required';
        }
        if (!this.phone || this.phone.trim() === "") {
          this.validationErrors.phone = 'Phone is required';
        }
        const phoneNumber = this.phone.replace(/\D/g, '');
        if (phoneNumber.length !== 11) {
          this.validationErrors.phone = 'Phone number must be 11 digits long';
        }
        if (!this.selectedCity) {
          this.validationErrors.selectedCity = 'City is required';
        }
        if (!this.date) {
          this.validationErrors.date = 'Date of birth is required';
        }
        if (!this.password || this.password.trim() === "") {
          this.validationErrors.password = 'Password is required';
        } else if (this.password.length < 6) {
          this.validationErrors.password = 'Password should be at least 6 characters long';
        }
      } else if (this.volunteerType === 'student') {
        if (!this.selectedUniversity) {
          this.validationErrors.selectedUniversity = 'Please select university';
        }
        if (!this.email || this.email.trim() === "") {
          this.validationErrors.email = 'Email is required';
        } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
          this.validationErrors.email = 'Please enter valid email';
        }
        if (!this.name || this.name.trim() === "") {
          this.validationErrors.name = 'Name is required';
        }
        const phoneNumber = this.phone.replace(/\D/g, '');
        if (phoneNumber.length !== 11) {
          this.validationErrors.phone = 'Phone number must be 11 digits long';
        }
        if (!this.phone || this.phone.trim() === "") {
          this.validationErrors.phone = 'Phone is required';
        }
        if (!this.selectedCity) {
          this.validationErrors.selectedCity = 'City is required';
        }
        if (!this.date) {
          this.validationErrors.date = 'Date of birth is required';
        }
        if (!this.password || this.password.trim() === "") {
          this.validationErrors.password = 'Password is required';
        } else if (this.password.length < 6) {
          this.validationErrors.password = 'Password should be at least 6 characters long';
        }
      }

      if (this.organizationType === 'educationalInstitute' && this.selectedIndex === 1) {
        if (!this.selectedInstitute) {
          this.validationErrors.selectedInstitute = 'University is required';
        }
        if (this.selectedInstitute) {
          this.organizationName = this.selectedInstitute.name;
        }
        if (!this.organizationName || this.organizationName.trim() === "") {
          this.validationErrors.organizationName = 'Name is required';
        }
        if (!this.organizationEmail || this.organizationEmail.trim() === "") {
          this.validationErrors.organizationEmail = 'Email is required';
        } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.organizationEmail)) {
          this.validationErrors.organizationEmail = 'Please enter valid email';
        }
        if (!this.organizationPassword || this.organizationPassword.trim() === "") {
          this.validationErrors.organizationPassword = 'Password is required';
        } else if (this.organizationPassword.length < 6) {
          this.validationErrors.organizationPassword = 'Password should be at least 6 characters long';
        } else if (!this.organizationConfirmPassword) {
          this.validationErrors.organizationConfirmPassword = 'Confirm password is required';
        } else if (this.organizationPassword !== this.organizationConfirmPassword) {
          this.validationErrors.organizationConfirmPassword = 'Password do not match';
        }
        const phoneNumber = this.organizationPhone.replace(/\D/g, '');
        if (phoneNumber.length !== 11) {
          this.validationErrors.organizationPhone = 'Phone number must be 11 digits long';
        }
        if (!this.organizationPhone || this.organizationPhone.trim() === "") {
          this.validationErrors.organizationPhone = 'Phone is required';
        }
      }
      if (this.organizationType === 'organization' && this.selectedIndex === 1) {
        if (!this.organizationName || this.organizationName.trim() === "") {
          this.validationErrors.organizationName = 'Name is required';
        }
        if (!this.organizationEmail || this.organizationEmail.trim() === "") {
          this.validationErrors.organizationEmail = 'Email is required';
        } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.organizationEmail)) {
          this.validationErrors.organizationEmail = 'Please enter valid email';
        }
        if (!this.organizationPassword || this.organizationPassword.trim() === "") {
          this.validationErrors.organizationPassword = 'Password is required';
        } else if (this.organizationPassword.length < 6) {
          this.validationErrors.organizationPassword = 'Password should be at least 6 characters long';
        } else if (!this.organizationConfirmPassword) {
          this.validationErrors.organizationConfirmPassword = 'Confirm password is required';
        } else if (this.organizationPassword !== this.organizationConfirmPassword) {
          this.validationErrors.organizationConfirmPassword = 'Password do not match';
        }
        const phoneNumber = this.organizationPhone.replace(/\D/g, '');
        if (phoneNumber.length !== 11) {
          this.validationErrors.organizationPhone = 'Phone number must be 11 digits long';
        }
        if (!this.organizationPhone || this.organizationPhone.trim() === "") {
          this.validationErrors.organizationPhone = 'Phone is required';
        }
        if (!this.organizationType) {
          this.validationErrors.organizationType = 'Type is required';

        }
      }
      return Object.keys(this.validationErrors).length === 0;

    },
    validateEducational(){
      if (this.organizationType === 'educationalInstitute' && this.regAddEducationalInstitute) {
        if (!this.instituteName || this.instituteName.trim() === "") {
          this.validationErrors.instituteName = 'Name is required';
        }
        if (!this.instituteShortName || this.instituteShortName.trim() === "") {
          this.validationErrors.instituteShortName = 'Short Name is required';
        }
        if (!this.instituteSelectedType) {
          this.validationErrors.instituteSelectedType = 'Type is required';
        }

      }
      return Object.keys(this.validationErrors).length === 0;

    },
    async handleRegister() {
      this.showSpinner = true;
      if (!this.validateData()) {
        this.showSpinner = false;
        // const errorMessages = Object.values(this.validationErrors).map(error => `<li>${error}</li>`).join('');
        // this.$swal.fire({
        //   icon: 'error',
        //   title: 'Oops...',
        //   text: 'Something went wrong!',
        //   html: `<ul>${errorMessages}</ul>`,
        // });
        return;
      }
      if(this.gender == 'male') {
        this.gender = 'm';
      } else if(this.gender == 'female') {
        this.gender = 'f';
      } else {
        this.gender = 'o';
      }

      let ind = parseInt(this.selectedIndex);
      try {
        const cityid = this.selectedCity ? this.selectedCity.id : null;
        const user = {
          name: this.name,
          email: this.email,
          password: this.password,
          role: this.role,
          phone: this.phone,
          gender: this.gender,
          dob: this.date,
          rcode: this.rcode,
          city_id: cityid,
          volunteer_type: this.volunteerType,
          university_id: this.selectedUniversity ? this.selectedUniversity.id : null,
          is_cp_email_sent: 0,
        };
        const organization = {
          name: this.organizationName,
          email: this.organizationEmail,
          password: this.organizationPassword,
          phone: this.organizationPhone,
          organization_type: this.organizationType,
          role: 1,
          university_id: this.selectedInstitute ? this.selectedInstitute.id : null,
        };
        let redirect = true;

        if (!ind) {
          const response = await this.register(user);
          if (response && (response.email || response.phone)) {
            redirect = false;
            if (response.email) {
              this.validationErrors.email = response.email[0];
            }
            if (response.phone) {
              this.validationErrors.phone = response.phone[0];
            }
          }
        } else {
          const response = await this.register(organization);
          if (response && (response.email || response.phone)) {
            redirect = false;
            if (response.email) {
              this.validationErrors.organizationEmail = response.email[0];
            }
            if (response.phone) {
              this.validationErrors.organizationPhone = response.phone[0];
            }
          }
        }

        if (redirect) {
          const userRole = this.$store.state.user.role;
          if (userRole === 0) {
            this.$router.push('/volunteer-dashboard');
          } else if (userRole === 1) {
            this.$router.push('/organization-dashboard');
          } else {
            console.error('Unknown user role');
          }
        }

        // if (!ind) {
        //   const response = await this.register(user);
        //   // console.log(response);
        //   if(response.email) {
        //     this.validationErrors.email = response.email[0];
        //   }
        //   if(response.phone) {
        //     this.validationErrors.phone = response.phone[0];
        //   }
        // }
        // else {
        //   const response = await this.register(organization);
        //   // console.log(response);
        //   if(response.email) {
        //     this.validationErrors.organizationEmail = response.email[0];
        //   }
        //   if(response.phone) {
        //     this.validationErrors.organizationPhone = response.phone[0];
        //   }
        // }
        // const item = localStorage.getItem('user');
        // console.log(item);
        // const userRole = this.$store.state.user.role;
        // console.log('role', userRole);
        // if (userRole === 0) {
        //   this.$router.push('/volunteer-dashboard');
        // }
        // else if (userRole === 1) {
        //   this.$router.push('/organization-dashboard');
        // }
        // else {
        //   // Handle unknown role or other cases
        //   console.error('Unknown user role');
        // }
      } catch (error) {
        console.log(error);
        // Handle login failure, show error message, etc.
      } finally {
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    },
    async socialLogin(provider) {
      try {
        const response = await socialLogins(provider);
        window.location = response.data;
      } catch (error) {
        console.error('Google login error', error);
      }
    },
    async registerInstitute() {
      this.showSpinner = true;
      if (!this.validateEducational()) {
        this.showSpinner = false;
        return;
      }
      const params = {
        name: this.instituteName,
        short_name: this.instituteShortName,
        type_id: this.instituteSelectedType.id,
      }

      try{
        const res = await registerEducationalOrg(params);
        if (res.status == 200) {
          this.regAddEducationalInstitute = false;
          this.$refs.toast.add({ severity: 'success', summary: 'Success...', detail: res.data.message, life: 3000 });
          const newInstitute = {
            id: res.data.newUniversity.id,
            name: res.data.newUniversity.name,
          };
          this.universities.push(newInstitute);
          this.selectedInstitute = newInstitute;
        }

      }catch (error) {
        console.log(error);
        if (error.response && error.response.status == 409) {
          this.$refs.toast.add({ severity: 'info', summary: 'Oops...', detail: error.response.data.error, life: 3000 });
        }
      } finally {
        setTimeout(() => {
          this.showSpinner = false;
        }, 1500);
      }
    },
    tabChanged(selectedTab) {
      this.selectedIndex = parseInt(selectedTab.tab.index);
    },
  },
};
</script>
<style>
@import "vue-select/dist/vue-select.css";
</style>


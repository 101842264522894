<template>
  <div class="loader-main d-flex justify-content-center d-none">
    <div class="spinner-border loader" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <Header
    v-if="this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'ForgotPassword' && this.$route.name !== 'offline'">
  </Header>
  <!-- <div class="body-custom-scroll"> -->

  <router-view />

  <Footer
    v-if="this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'ForgotPassword' && this.$route.name !== 'offline'">
  </Footer>
  <!-- </div> -->
</template>

<script>
//importing bootstrap 5 Modules
import Header from "./components/HeaderComponent";
import Footer from "./components/FooterComponent";
import { mapGetters } from 'vuex';
import { useStore } from 'vuex';
// import store from "./store";
import { mapActions } from 'vuex';
import { saveUserLocation } from "@/api";
import { updateActivityTime, getLastActivityTime } from './eventBus';

export default {
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  data() {
    return {

    }
  },
  setup() {
    const store = useStore();
    const token = localStorage.getItem("token");

    if (token) {
      store.commit('setAuthenticated', true);
    }
    // ...
  },
  async mounted() {

    if (this.isAuthenticated) {
      this.$updateActivityTime = updateActivityTime;
      this.$getLastActivityTime = getLastActivityTime;

      const handleActivity = () => {
        this.$updateActivityTime(); // Update the last activity time
      };
      // Add event listeners
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
      window.addEventListener('change', handleActivity);
      window.addEventListener('click', handleActivity);
      window.addEventListener('load', handleActivity);
      window.addEventListener('scroll', handleActivity);
      // window.addEventListener('unload', () => {
      //   alert('Jep');
      //   // this.logout();
      //   // Clear the relevant local storage value
      //   // localStorage.removeItem('token');
      //   // localStorage.removeItem('user');
      //   // localStorage.removeItem('volunteer');
      //   // localStorage.removeItem('organization');
      //   // localStorage.removeItem('yourLocalStorageKey');
      // });
      const interval = setInterval(() => {  
      const lastActivityTime = getLastActivityTime();
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivityTime;

      // If inactive time exceeds 30 minutes (30 * 60 * 1000 milliseconds)
        if (inactiveTime >= 15 * 60 * 1000) {
          // Logout the user
          this.logout();
          // Clear the interval
          clearInterval(interval);
          // console.log('User logged out due to inactivity');
        }
      }, 60000); // Check every minute

      const user = localStorage.getItem("user");
      const parsedUser = JSON.parse(user);
      const userrole = parsedUser.role;
      const pathname = window.location.pathname;
      if (userrole == 0) {
        if (pathname === '/' || pathname === '') {
          this.$router.push('/volunteer-dashboard');
        }
      }
      else if (userrole == 1) {
        if (pathname === '/' || pathname === '') {
          this.$router.push('/organization-dashboard');
        }
      }
      else {
        this.$router.push('/');
      }
    }
  },
  // beforeUnmount() {
  //   if (this.isAuthenticated) {
  //     window.removeEventListener('mousemove', handleActivity);
  //     window.removeEventListener('keydown', handleActivity);
  //     window.removeEventListener('change', handleActivity);
  //     window.removeEventListener('click', handleActivity);
  //     window.removeEventListener('load', handleActivity);
  //     window.removeEventListener('scroll', handleActivity);

  //   }
  // },
  methods: {
      ...mapActions(['logout']),
    },
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
</style>

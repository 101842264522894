<template>
    <div>
        <!-- <label class="font-12-mb">Residential Address <small>(will not be published)</small><span
                class="text-red">*</span></label> -->
        <input 
            type="text" 
            v-model="residentialAddress" 
            @input="initializeAutocomplete" 
            ref="autocompleteInput" 
            class="form-control" 
            :disabled="disabled" 
        />
        <!-- {{ residentialAddress }} -->
    </div>
</template>

<script>
export default {
    name: 'AutocompleteComponent',
    props: ['userAddress', 'disabled'],
    emits: ['location-sent', 'update:userAddress'],
    data() {
        return {
            residentialAddress: this.userAddress || '',
            lat: null,
            lng: null,
            autocomplete: null,
        };
    },
    watch: {
        userAddress(newAddress) {
            this.residentialAddress = newAddress;
        }
    },
    mounted() {
        this.initializeAutocomplete();
    },
    methods: {
        initializeAutocomplete() {
            if (!this.autocomplete && this.$refs.autocompleteInput) {
                this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, {
                    componentRestrictions: { country: 'pk' },
                });

                this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
            }
        },
        handlePlaceChanged() {
            const place = this.autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                this.lat = place.geometry.location.lat();
                this.lng = place.geometry.location.lng();
            }
            this.residentialAddress = place.formatted_address; // Update the address
            let city = '';
            let province = '';

            place.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (component.types.includes('administrative_area_level_1')) {
                    province = component.long_name;
                }
            });
            // Emit the new address and coordinates
            const data = {
                address: this.residentialAddress,
                latitude: this.lat,
                longitude: this.lng,
                city: city,
                province: province,
            };
            this.$emit('location-sent', data);
            this.$emit('update:userAddress', this.residentialAddress); // Update the parent component
        },
    },
};
</script>

<!-- ForgotPassword.vue -->
<template>
  <!-- <div>
    <h2>Forgot Password</h2>
    <form @submit.prevent="submitForm">
      <label>Email:</label>
      <input v-model="email" type="email" required>
      <button type="submit">Submit</button>
    </form>
  </div> -->
  <section class="login-reg-sec">
    <div class="box">
      <div class="square" style="--i:0;"></div>
      <div class="square" style="--i:1;"></div>
      <div class="square" style="--i:2;"></div>
      <div class="square" style="--i:3;"></div>
      <div class="square" style="--i:4;"></div>
      <div class="square" style="--i:5;"></div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-center mb-2">
            <a href="/" class="text-center">
              <img src="../assets/img/logo.png" alt="logo" class="img-fluid w-50 my-3" />
            </a>
          </div>
          <div class="row mx-0 text-center">
            <h1 class="mb-3">Forgot Password</h1>
          </div>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <input type="email" class="form-control" autocomplete="email" placeholder="Email Address" v-model="email"
                required />
            </div>
            <div class="form-group mb-0">
              <button id="login-btn" class="btn btn-primary w-100" type="submit" tabindex="12" data-style="zoom-in">
                <i class="fa-solid fa-arrow-right-to-bracket pr-1"></i>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ForgotPassword } from "@/api";

export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await ForgotPassword(this.email);
        if (response.status == 200) {
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'We have e-mailed your password reset link!',
            confirmButtonText: 'Ok',
            confirmButtonColor: "#46B849",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        if (error) {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Email does not exist',
            confirmButtonText: 'Ok',
            confirmButtonColor: "#46B849",
            allowOutsideClick: false,
          })
        }
      }
    },
  },
};
</script>
<style>
@import "../assets/css/login-register.css";
</style>

<template>
      <div class="container mt-5">

<div class="row justify-content-center">

    <div class="col-lg-8 mb-5">
        <div class=WordSection1>
            <h3 class="pb-4 font-weight-bold text-center">Privacy Policy</h3>
            
            <p class=MsoNormal><span style='line-height:115%;color:black'>This Privacy
            Policy describes our policies and procedures on the collection, use and
            disclosure of your information when you use the Service and tells you about your
            privacy rights and how the law protects you.</span></p>
            
            <p class=MsoNormal><span style='line-height:115%;color:black'>We use Your
            Personal data to provide and improve the Service. By using the Service, You
            agree to the collection and use of information in accordance with this Privacy
            Policy.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Interpretation and Definitions</span></p>
            
            </div>
            
            <h2><span style='color:black'>Interpretation</span></h2>
            
            <p class=MsoNormal><span style='color:black'>The words of which the initial
            letter is capitalized have meanings defined under the following conditions. The
            following definitions shall have the same meaning regardless of whether they
            appear in singular or in plural.</span></p>
            
            <h2><span style='color:black'>Definitions</span></h2>
            
            <p class=MsoNormal><span style='color:black'>For the purposes of this Privacy
            Policy:</span></p>
            
            <p class=MsoListParagraphCxSpFirst><b><span style='color:black'>Account</span></b><span
            style='color:black'> means a unique account created for You to access our
            Service or parts of our Service.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Affiliate</span></b><span
            style='color:black'> means an entity that controls, is controlled by or is
            under common control with a party, where &quot;control&quot; means ownership of
            50% or more of the shares, equity interest or other securities entitled to vote
            for election of directors or other managing authority.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Application</span></b><span
            style='color:black'> means the software program provided by the Company
            downloaded by You on any electronic device, named <b>MILKAR</b></span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Company</span></b><span
            style='color:black'> (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Interlink
            Multimedia Private Limited</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Country</span></b><span
            style='color:black'> refers to: Pakistan</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Device</span></b><span
            style='color:black'> means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Personal Data</span></b><span
            style='color:black'> is any information that relates to an identified or
            identifiable individual.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Service</span></b><span
            style='color:black'> refers to the Application.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Service
            Provider</span></b><span style='color:black'> means any natural or legal person
            who processes the data on behalf of the Company. It refers to third-party
            companies or individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services related to
            the Service or to assist the Company in analyzing how the Service is used.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Third-party
            Social Media Service</span></b><span style='color:black'> refers to any website
            or any social network website through which a User can log in or create an
            account to use the Service.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>Usage Data</span></b><span
            style='color:black'> refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself (for example,
            the duration of a page visit).</span></p>
            
            <p class=MsoListParagraphCxSpLast><b><span style='color:black'>You</span></b><span
            style='color:black'> means the individual accessing or using the Service, or
            the company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Collecting and Using Your Personal Data</span></p>
            
            </div>
            
            <h2><span style='color:black'>Types of Data Collected</span></h2>
            
            <h3><span style='color:black'>Personal Data</span></h3>
            
            <p class=MsoNormal><span style='color:black'>While using Our Service, We may
            ask You to provide Us with certain personally identifiable information that can
            be used to contact or identify You. Personally identifiable information may
            include, but is not limited to:</span></p>
            
            <p class=MsoListParagraphCxSpFirst><span style='color:black'>First name and
            last name</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><span style='color:black'>Phone number</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><span style='color:black'>Address, State,
            Province, ZIP/Postal code, City</span></p>
            
            <p class=MsoListParagraphCxSpLast><span style='color:black'>Usage Data</span></p>
            
            <h3><span style='color:black'>Usage Data</span></h3>
            
            <p class=MsoNormal><span style='color:black'>Usage Data is collected
            automatically when using the Service.</span></p>
            
            <p class=MsoNormal><span style='color:black'>Usage Data may include information
            such as Your Device's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that You visit, the time and
            date of Your visit, the time spent on those pages, unique device identifiers
            and other diagnostic data.</span></p>
            
            <p class=MsoNormal><span style='color:black'>When You access the Service by or
            through a mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use, Your mobile
            device unique ID, the IP address of Your mobile device, Your mobile operating
            system, the type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.</span></p>
            
            <p class=MsoNormal><span style='color:black'>We may also collect information
            that Your browser sends whenever You visit our Service or when You access the
            Service by or through a mobile device.</span></p>
            
            <h3><span style='color:black'>Information from Third-Party Social Media
            Services</span></h3>
            
            <p class=MsoNormal><span style='color:black'>The Company allows You to create
            an account and log in to use the Service through the following Third-party
            Social Media Services:</span></p>
            
            <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Google</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Facebook</span></p>
            
            <p class=MsoListBulletCxSpLast><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Apple</span></p>
            
            <p class=MsoNormal><span style='color:black'>If You decide to register through
            or otherwise grant us access to a Third-Party Social Media Service, We may
            collect Personal data that is already associated with Your Third-Party Social
            Media Service's account, such as Your name, Your email address, Your activities
            or Your contact list associated with that account.</span></p>
            
            <p class=MsoNormal><span style='color:black'>You may also have the option of
            sharing additional information with the Company through Your Third-Party Social
            Media Service's account. If You choose to provide such information and Personal
            Data, during registration or otherwise, You are giving the Company permission
            to use, share, and store it in a manner consistent with this Privacy Policy.</span></p>
            
            <h3><span style='color:black'>Information Collected while Using the Application</span></h3>
            
            <p class=MsoNormal><span style='color:black'>While using Our Application, in
            order to provide features of Our Application, We may collect, with Your prior
            permission:</span></p>
            
            <p class=MsoListParagraphCxSpFirst><span style='color:black'>Information regarding
            your location</span></p>
            
            <p class=MsoListParagraphCxSpLast><span style='color:black'>Pictures and other
            information from your Device's camera and photo library</span></p>
            
            <p class=MsoNormal><span style='color:black'>We use this information to provide
            features of Our Service, to improve and customize Our Service. The information
            may be uploaded to the Company's servers and/or a Service Provider's server or
            it may be simply stored on Your device.</span></p>
            
            <p class=MsoNormal><span style='color:black'>You can enable or disable access
            to this information at any time, through Your Device settings.</span></p>
            
            <h2><span style='color:black'>Use of Your Personal Data</span></h2>
            
            <p class=MsoNormal><span style='color:black'>The Company may use Personal Data
            for the following purposes:</span></p>
            
            <p class=MsoListParagraphCxSpFirst><b><span style='color:black'>To provide and
            maintain our Service</span></b><span style='color:black'>, including to monitor
            the usage of our Service.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>To manage Your
            Account:</span></b><span style='color:black'> to manage Your registration as a
            user of the Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You as a
            registered user.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>For the
            performance of a contract:</span></b><span style='color:black'> the
            development, compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other contract with Us
            through the Service.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>To contact
            You:</span></b><span style='color:black'> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication, such as a
            mobile application's push notifications regarding updates or informative
            communications related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for their
            implementation.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>To provide You</span></b><span
            style='color:black'> with news, special offers and general information about
            other goods, services and events which we offer that are similar to those that
            you have already purchased or enquired about unless You have opted not to
            receive such information.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>To manage Your
            requests:</span></b><span style='color:black'> To attend and manage Your
            requests to Us.</span></p>
            
            <p class=MsoListParagraphCxSpMiddle><b><span style='color:black'>For business
            transfers:</span></b><span style='color:black'> We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets, whether as
            a going concern or as part of bankruptcy, liquidation, or similar proceeding,
            in which Personal Data held by Us about our Service users is among the assets
            transferred.</span></p>
            
            <p class=MsoListParagraphCxSpLast><b><span style='color:black'>For other
            purposes</span></b><span style='color:black'>: We may use Your information for
            other purposes, such as data analysis, identifying usage trends, determining
            the effectiveness of our promotional campaigns and to evaluate and improve our
            Service, products, services, marketing and your experience.</span></p>
            
            <p class=MsoNormal><span style='color:black'>We may share Your personal
            information in the following situations:</span></p>
            
            <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>With Service Providers:</span></b><span
            style='color:black'> We may share Your personal information with Service
            Providers to monitor and analyze the use of our Service, to contact You.</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>For business transfers:</span></b><span
            style='color:black'> We may share or transfer Your personal information in
            connection with, or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of Our business to another
            company.</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>With Affiliates:</span></b><span
            style='color:black'> We may share Your information with Our affiliates, in
            which case we will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries, joint venture
            partners or other companies that We control or that are under common control
            with Us.</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>With business partners:</span></b><span
            style='color:black'> We may share Your information with Our business partners
            to offer You certain products, services or promotions.</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>With other users:</span></b><span
            style='color:black'> when You share personal information or otherwise interact
            in the public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside. If You interact with other users
            or register through a Third-Party Social Media Service, Your contacts on the
            Third-Party Social Media Service may see Your name, profile, pictures and
            description of Your activity. Similarly, other users will be able to view
            descriptions of Your activity, communicate with You and view Your profile.</span></p>
            
            <p class=MsoListBulletCxSpLast><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><b><span style='color:black'>With Your consent</span></b><span
            style='color:black'>: We may disclose Your personal information for any other
            purpose with Your consent.</span></p>
            
            <h2><span style='color:black'>Retention of Your Personal Data</span></h2>
            
            <p class=MsoNormal><span style='color:black'>The Company will retain Your
            Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary
            to comply with our legal obligations (for example, if we are required to retain
            your data to comply with applicable laws), resolve disputes, and enforce our
            legal agreements and policies.</span></p>
            
            <p class=MsoNormal><span style='color:black'>The Company will also retain Usage
            Data for internal analysis purposes. Usage Data is generally retained for a
            shorter period of time, except when this data is used to strengthen the
            security or to improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.</span></p>
            
            <h2><span style='color:black'>Transfer of Your Personal Data</span></h2>
            
            <p class=MsoNormal><span style='color:black'>Your information, including
            Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that
            this information may be transferred to — and maintained on — computers located
            outside of Your state, province, country or other governmental jurisdiction
            where the data protection laws may differ than those from Your jurisdiction.</span></p>
            
            <p class=MsoNormal><span style='color:black'>Your consent to this Privacy
            Policy followed by Your submission of such information represents Your
            agreement to that transfer.</span></p>
            
            <p class=MsoNormal><span style='color:black'>The Company will take all steps
            reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will
            take place to an organization or a country unless there are adequate controls
            in place including the security of Your data and other personal information.</span></p>
            
            <h2><span style='color:black'>User Rights and Data Deletion</span></h2>
            
            <p class=MsoNormal><span style='color:black'>If you are concerned about your
            data, you have the right to request access to the personal data which we may
            hold or process about you. You have the right to require us to correct any
            inaccuracies in your data free of charge. At any stage you also have the right
            to ask us to stop using your personal data for direct marketing purposes. If
            so, we will inform you of the fee before processing your request.</span></p>
            
            <p class=MsoNormal><span style='color:black'>You may communicate the withdrawal
            of your consent to the continued use, disclosure, storing and/or processing of
            your personal data by contacting our customer services, subject to the conditions
            and/or limitations imposed by applicable laws or regulations. Please note that
            if you communicate your withdrawal of your consent to our use, disclosure,
            storing or processing of your personal data for the purposes and in the manner
            as stated above or exercise your other rights as available under applicable
            local laws, we may not be in a position to continue to provide the Services to
            you or perform any contract we have with you, and we will not be liable in the
            event that we do not continue to provide the Services to, or perform our
            contract with you. Our legal rights and remedies are expressly reserved in such
            an event.</span></p>
            
            <p class=MsoNormal><span style='color:black'>As a <b>MILKAR</b> user, you have
            the right to ask us to delete your data held by us. In the event you wish to
            have your data deleted, you may contact our customer services at <a href="<?php echo e(route('contact')); ?>">Contact Us</a>. Our customer service agent will reach out to
            you to assist you with the process. It will take 10 working days from the
            receipt of your Deletion Request to delete your data. </span></p>
            
            <p class=MsoNormal><span style='color:black'>&nbsp;</span></p>
            
            <h2><span style='color:black'>Disclosure of Your Personal Data</span></h2>
            
            <h3><span style='color:black'>Business Transactions</span></h3>
            
            <p class=MsoNormal><span style='color:black'>If the Company is involved in a
            merger, acquisition or asset sale, Your Personal Data may be transferred. We
            will provide notice before Your Personal Data is transferred and becomes
            subject to a different Privacy Policy.</span></p>
            
            <h3><span style='color:black'>Law enforcement</span></h3>
            
            <p class=MsoNormal><span style='color:black'>Under certain circumstances, the
            Company may be required to disclose Your Personal Data if required to do so by
            law or in response to valid requests by public authorities (e.g. a court or a
            government agency).</span></p>
            
            <h3><span style='color:black'>Other legal requirements</span></h3>
            
            <p class=MsoNormal><span style='color:black'>The Company may disclose Your
            Personal Data in the good faith belief that such action is necessary to:</span></p>
            
            <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Comply with a legal obligation</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Protect and defend the rights or
            property of the Company</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Prevent or investigate possible
            wrongdoing in connection with the Service</span></p>
            
            <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Protect the personal safety of Users
            of the Service or the public</span></p>
            
            <p class=MsoListBulletCxSpLast><span style='font-family:Symbol;color:black'>·<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
            dir=LTR></span><span style='color:black'>Protect against legal liability</span></p>
            
            <h2><span style='color:black'>Security of Your Personal Data</span></h2>
            
            <p class=MsoNormal><span style='color:black'>The security of Your Personal Data
            is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to
            use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Children's Privacy</span></p>
            
            </div>
            
            <p class=MsoNormal><span style='color:black'>Our Service does not address
            anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian
            and You are aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data from anyone
            under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.</span></p>
            
            <p class=MsoNormal><span style='color:black'>If We need to rely on consent as a
            legal basis for processing Your information and Your country requires consent
            from a parent, We may require Your parent's consent before We collect and use
            that information.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Links to Other Websites</span></p>
            
            </div>
            
            <p class=MsoNormal><span style='color:black'>Our Service may contain links to
            other websites that are not operated by Us. If You click on a third party link,
            You will be directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.</span></p>
            
            <p class=MsoNormal><span style='color:black'>We have no control over and assume
            no responsibility for the content, privacy policies or practices of any third
            party sites or services.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Changes to this Privacy Policy</span></p>
            
            </div>
            
            <p class=MsoNormal><span style='color:black'>We may update Our Privacy Policy
            from time to time. We will notify You of any changes by posting the new Privacy
            Policy on this page.</span></p>
            
            <p class=MsoNormal><span style='color:black'>We will let You know via email
            and/or a prominent notice on Our Service, prior to the change becoming
            effective and update the &quot;Last updated&quot; date at the top of this
            Privacy Policy.</span></p>
            
            <p class=MsoNormal><span style='color:black'>You are advised to review this
            Privacy Policy periodically for any changes. Changes to this Privacy Policy are
            effective when they are posted on this page.</span></p>
            
            <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm'>
            
            <p class=MsoTitle><span style='font-family:"Times New Roman","serif";
            color:black'>Contact Us</span></p>
            
            </div>
            
            <p class=MsoNormal><span style='color:black'>If you have any questions about
            this Privacy Policy, You can <a href="<?php echo e(route('contact')); ?>">Contact Us</a>.</span></p>
            </div>
    </div>
</div>
</div>
</template>
<script>

export default {
    name: "PrivacyView",
    components: {
    },
    props: {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
    async mounted() {},
};
</script>
  
import { createApp } from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { volunteerDataApi, organizationDataApi  } from "@/api";
import router from "@/router"; // Import the router instance



const app = createApp();
app.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: null,
  },
  mutations: {
    setAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    setUser(state, user) {
      state.user = user;
    },
    clearAuthenticated(state) {
      state.isAuthenticated = false;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
  },
  actions: {
    async updateProvinceId({ commit }, id) {
      localStorage.setItem('provinceId', id);
    },
    async login({ commit }, credentials) {
        // Make the login API request
        const response = await axios.post(process.env.VUE_APP_BASE_URL +'/api/login', credentials);
        
        // Handle the response and update the store accordingly
        if (response.status === 200) {
          const { token, user } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          commit('setAuthenticated', true);
          commit('setUser', user);
          if(user.role == 0)
          {
            await volunteerDataApi();
          }
          if(user.role == 1)
          {
            await organizationDataApi();
          }
        } else {
          // Handle unsuccessful login, show error message, etc.
        }
    },
    async socialLogin({ commit }, { token, user }) {
      // Handle the redirected data and update the store accordingly
      if (token && user) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        commit('setAuthenticated', true);
        commit('setUser', user);
        if (user.role === 0) {
          await volunteerDataApi();
          window.location = '/volunteer-dashboard'
        }
      } else {
        // Handle the case where token or user information is missing
      }
    },

    async logout({ commit }) {
      // Clear authentication data and reset store state
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('volunteer');
      localStorage.removeItem('organization');
      try {
        // Make the logout API request
        await axios.post(process.env.VUE_APP_BASE_URL + '/api/logout');

        
        delete axios.defaults.headers.common['Authorization'];
        commit('clearAuthenticated');
        commit('clearUser');

        // Redirect to login page  appropriate route
        router.push('/');
      } catch (error) {
        console.log(error);
        // Handle logout failure, show error message, etc.
      }
    },
    async register({ commit }, userData) {
      try {
        const response = await axios.post(process.env.VUE_APP_BASE_URL +'/api/register', userData);
        if (response.status === 200) {
          const { token, user } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          commit('setAuthenticated', true);
          commit('setUser', user);
          if (response.data.user.role == 0)
          {
            await volunteerDataApi();
          }
          if (response.data.user.role == 1)
          {
            await organizationDataApi();
          }
        } else {
          return response;
        }
      } catch (error) {
        // Handle the error, e.g., show an error message
        // console.error(error.response.data);
        return error.response.data.errors;
      }
    }
  },
});
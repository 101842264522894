<template>
  <footer class="main-footer">
    <div class="row">
      <div class="col-md-8">
        <span>
          Copyright © -- Milkar.com -
          <a class="text-white" href="/privacy">Privacy - </a>
          <a class="text-white" href="/terms">Terms - </a>
          <a class="text-white" href="/community-guidelines">Community Guidelines</a>
        </span>
      </div>
      <div class="col-md-4 text-center text-lg-right">
        <div class="footer-socials">
          <a href="https://facebook.com/milkarpakistan" class="fab fa-facebook" target="_blank"></a>
          <a href="https://twitter.com/milkarpakistan" class="fab fa-twitter" target="_blank"></a>
          <a href="https://linkedin.com/company/milkarpakistan" class="fab fa-linkedin" target="_blank"></a>
          <a href="https://instagram.com/milkarpakistan" class="fab fa-instagram" target="_blank"></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<template>
    <section class="volunteer-dashboard container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <div class="col-lg-3">
                    <div class="card w-100">
                        <div class="card-body">
                            <div class="col-lg-12 px-0 text-left d-flex flex-column row-gap-2">
                                <div class="text-center mb-3">
                                    <img v-if="!organization.logo" src="@/assets/img/org_placeholder.png"
                                        class="border-radius img-fluid" />
                                    <Image v-else class="border-radius img-fluid" preview
                                        :src="this.imageurl + '/' + organization.logo" :alt="organization.user.name" />
                                </div>
                                <div class="">
                                    <a href="">
                                        <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1">
                                            {{ this.organizationName }}
                                        </h3>
                                    </a>
                                </div>

                                <div
                                    class="row mx-0 flex-wrap w-100 justify-content-center card card-section-right mb-2">
                                    <div class="badge-counter-box d-flex flex-column row-gap-2 px-0">
                                        <span v-if="organization.university_id" class="badge-counter-text">Students:
                                            <span v-if="organization.studentsCount > 0"
                                                class="counter">{{ organization.studentsCount }}</span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">
                                            Activity Organized:
                                            <span v-if="organization.eventsCount > 0"
                                                class="counter">{{ organization.eventsCount }}</span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                        <span class="badge-counter-text">Volunteers Engaged:
                                            <span v-if="organization.volunteersEngagedCount > 0"
                                                class="counter">{{ organization.volunteersEngagedCount }}</span>
                                            <span v-else class="counter fs-7 text-muted" v-tooltip.bottom="'Not Added'">
                                                N/A
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Type</label>
                                        <p v-if="this.organizationType" class="fs-bold mb-0">{{ this.organizationType }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">City</label>
                                        <p v-if="this.organizationCity" class="fs-bold mb-0">{{ this.organizationCity }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-12 px-0">
                                        <label class="fs-8 text-muted">Address</label>
                                        <p v-if="organization.address" class="fs-bold mb-0">{{ organization.address }}
                                        </p>
                                        <p v-else class="fs-bold mb-0">N/A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
                            <div class="col-lg-8 d-flex align-content-center gap-2 justify-content-end flex-wrap ml-auto">
                                <IconField iconPosition="left" class="col-lg-4 col-md-12 col-sm-12 flex-auto">
                                    <InputIcon class="pi pi-search"> </InputIcon>
                                    <InputText v-model="filters['global'].value" placeholder="Search" class="w-100" />
                                </IconField>
                                <a href="/organization-dashboard/create-sms-campaign"
                                    class="btn btn-primary col-lg-2 col-md-12 col-sm-12 flex-auto d-flex align-items-center gap-2 my-0">
                                    <i class="fa fa-plus"></i> Create Campaign
                                </a>
                            </div>
                            <div class="col-lg-12 d-flex flex-column">
                                <h3 class="mb-1 me-3 text-muted">Campaign List</h3>
                                <p class="fs-8 text-muted mb-0">All SMS campaign are shown here.</p>
                            </div>

                            <DataTable v-model:filters="filters" :value="this.campiagnList" paginator :rows="10"
                                dataKey="id" :loading="loading" :globalFilterFields="['name']" sortField="id" :sortOrder="-1">
                                <template #empty> No data found. </template>

                                <template #loading> Loading customers data. Please wait. </template>
                                <Column header="#" headerStyle="width:2.5rem" style="vertical-align: top" field="id" sortable>
                                </Column>
                                <Column field="name" header="Campaign Name"
                                    style="min-width: 11rem; vertical-align: top" class="">

                                    <template #body="{ data }">
                                        <span class="campaign-detail custom-scroll">
                                            {{ data.name }}
                                        </span>
                                        <span class="opacity-75  fs-9 d-flex flex-column border-top mt-2">
                                            <span class="fs-10 text-muted">Create at</span>
                                            {{ formattedCreatedAt(data.created_at) }}
                                        </span>
                                    </template>
                                </Column>
                                <Column field="body" header="SMS Text" style="min-width: 12rem; vertical-align: top"
                                    class="">

                                    <template #body="{ data }">
                                        <span class="campaign-detail custom-scroll">
                                            {{ data.body }}
                                        </span>
                                    </template>
                                </Column>
                                <Column field="status" header="Status" style="min-width: 6rem">

                                    <template #body="{ data }">
                                        <div v-if="data.status == 'inactive'">
                                            <span class="p-tag p-tag-warning">Pending</span>
                                        </div>
                                        <div v-if="data.status == 'done'">
                                            <span class="p-tag p-tag-success">Sent</span>
                                        </div>
                                        <div v-if="data.status == 'processing'">
                                            <span class="p-tag p-tag-info">processing</span>
                                        </div>
                                        <div v-if="data.status == 'active'">
                                            <Badge class="p-tag" severity="success">Active </Badge>
                                        </div>
                                        <div v-if="data.status == ''">
                                            <Badge class="p-tag" severity="danger">Declined </Badge>
                                        </div>
                                    </template>
                                </Column>
                                <Column field="status" header="Count" style="min-width: 6rem">

                                    <template #body="{ data }">
                                        {{ data.willSendCount }}
                                    </template>
                                </Column>
                                <!-- <Column field="status" header="Created At" style="min-width: 12rem">

                                    <template #body="{ data }">
                                        {{ data.created_at }}
                                    </template>
                                </Column> -->
                                <Column field="status" header="Action" style="min-width: 6rem">

                                    <template #body="{ data }">
                                        <Button v-if="data.status !== 'done'" icon="pi pi-trash" rounded
                                            @click="this.delete(data.id)" severity="danger" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getSmsCampaignList, getOrganization, deleteSmsCompain } from "@/api";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';
import { FilterMatchMode } from 'primevue/api';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Image from 'primevue/image';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import ListingSkeleton from "@/components/Skeletons/ListingSkeleton";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";


export default {
    components: {
        DataTable,
        Column,
        ColumnGroup,
        Row,
        Badge,
        Button,
        Image,
        BadgeDirective,
        Divider,
        Card,
        Dialog,
        ListingSkeleton,
        Dropdown,
        MultiSelect,
        ProgressSpinner,
        InputText,
        IconField,
        InputIcon,

    },
    data() {

        return {
            filters: null,
            campiagnList: null,
            organization: [],
            campiagndropdownList: [],
            selectedCampaignList: null,
            organizationName: null,
            organizationCity: null,
            organizationAddress: null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                representative: { value: null, matchMode: FilterMatchMode.IN },
                status: { value: null, matchMode: FilterMatchMode.EQUALS },
                verified: { value: null, matchMode: FilterMatchMode.EQUALS }
            },
            loading: false,
        }

    },
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        const vOrganization = localStorage.getItem("organization");
        const parsedOrganization = JSON.parse(vOrganization);
        this.slug = parsedOrganization.slug;
        try {
            const res = await getSmsCampaignList();
            this.campiagnList = res.data.smsCampaignLists;
            const res2 = await getOrganization(this.slug);
            this.organization = res2.organization;
            this.organizationName = this.organization.user.name;
            this.organizationType = this.organization.type.name;
            this.organizationCity = this.organization.city.name;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        formattedCreatedAt(created_at) {
            const date = new Date(created_at);
            return date.toLocaleString();
        },
        async delete(id) {
            try {
                const res = await deleteSmsCompain(id);
                this.$swal.fire({
                    title: 'Success!',
                    text: "SMS campaign deleted successfully.",
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#46B849",
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } catch (error) {
                console.error(error);
            }
        }
    },
}
</script>

<template>
    <section class="container-fluid py-2 mt-5">
        <div class="container-xxl">
            <div class="row row-gap-3 align-items-center">
                <div class="activities-listing text-center mb-3 position-relative">
                    <div class="pl-3 back-icon d-block d-sm-none text-left">
                        <a id="back" class="color-black"><i class="fas fa-arrow-left pr-3"></i></a>
                    </div>
                    <h1 class="name">Our Ambassadors</h1>
                </div>
                <div class="row team-boxed team-fixed-size mx-0">
                    <div v-for="(ambassador, index) in OurAmbassadors" :key="index"
                        class="item d-flex card m-2 rounded overflow-hidden">
                        <div v-if="ambassador.picture" class="card-header t-img-block img-fluid"
                            :style="{ 'background-image': 'url(' + imageurl + '/' + ambassador.picture + ')' }"
                            :alt="ambassador.name">
                        </div>
                        <img v-else :src="this.$volunteerNoImage(ambassador.volunteer.gender)"
                            class="t-img-block img-fluid">
                        <div class="card-body">
                            <h3 class="name font-14-mb curosr-pointer open_details text-truncate-2"
                                :data-description="ambassador.description" :data-name="ambassador.name"
                                :data-image="ambassador.picture_thumb">{{ ambassador.name }}</h3>
                            <div class="title ambassador-detail custom-scroll pr-2">
                                {{ this.$stripHTML(ambassador.about) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="hasMoreAmbassador" class="pb-5 mb-3 text-center">
        <button @click="loadMoreAmbassador" class="btn btn-primary" :disabled="showSpinner">
            View More
            <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8" fill="transparent"
                animationDuration="2s" aria-label="Custom ProgressSpinner" />

        </button>
    </div>
</template>

<script>
import { getOurAmbassadors } from "@/api";
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: "HomeView",
    components: {
        ProgressSpinner,
    },
    props: {},
    data() {
        return {
            imageurl: null,
            OurAmbassadors: [],
            loadingOurAmbassadors: true, // To show loading message
            errored: false, // To show error message
            //heat map option are here 
            ambassadorCount: 0,
            pageNo: 1,
            perPage: 12,
            showSpinner: false,
        };
    },
    computed: {
        hasMoreAmbassador() {
            return this.ambassadorCount > this.perPage * this.pageNo;
        },
    },
    async mounted() {
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            /* eslint-disable */
            const params = {
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const res = await getOurAmbassadors(params);
            this.OurAmbassadors = res.ambassadors.data;
            this.ambassadorCount = res.ambassadorsTotal;
            this.loadingOurAmbassadors = false;
        } catch (error) {
            console.error(error);
            this.errored = true;
        } finally {
            this.loadingOurAmbassadors = false;
        }
    },
    methods: {
        async loadMoreAmbassador() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    pageno: this.pageNo + 1,
                    perpage: this.perPage,
                };
                const res = await getOurAmbassadors(params);
                const newActivities = res.ambassadors.data;
                this.OurAmbassadors = [...this.OurAmbassadors, ...newActivities];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
    },
}
</script>

<template>
    <simpleListingSkeleton v-if="pageLoading"></simpleListingSkeleton>
    <section class="dashboard container-fluid py-3">
        <div class="container-xxxl">
            <div class="row row-gap-3">
                <InsitutesFilters @filter-sent="getValue" :isTabChnaged="ifTabChanged"></InsitutesFilters>
                <div class="col-lg-9">
                    <div class="card w-100">
                        <div class="card-body p-dataview row mx-0 row-gap-4 pb-5">
                            <div class="row justify-content-between flex-wrap row-gap-2 mx-0 px-0">
                                <div
                                    class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                    <h3 class="mb-0 me-3">Partner Institutes</h3>
                                </div>
                            </div>
                            <TabView :scrollable="true">
                                <TabPanel>
                                    <template #header>
                                        <span>All</span>

                                    </template>
                                    <AllInstitutes :filtered-data="filteredData">
                                    </AllInstitutes>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>University</span>

                                    </template>
                                    <Universities :filtered-data="filteredData">
                                    </Universities>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>College</span>

                                    </template>
                                    <Colleges :filtered-data="filteredData"></Colleges>
                                </TabPanel>
                                <TabPanel>
                                    <template #header>
                                        <span>School</span>
                                    </template>
                                    <Schools :filtered-data="filteredData"></Schools>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Skeleton from 'primevue/skeleton';
import AllInstitutes from "../components/Organizations/AllInstitutes";
import Colleges from "../components/Organizations/Colleges";
import Schools from "../components/Organizations/Schools";
import Universities from "../components/Organizations/Universities";
import InsitutesFilters from "../components/Organizations/InsitutesFilters";
import { Tabs, Tab } from 'vue3-tabs-component';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabMenu from 'primevue/tabmenu';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Chip from 'primevue/chip';
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';

export default {
    name: "Institutes",
    components: {
        Skeleton,
        Tabs,
        Tab,
        InsitutesFilters,
        AllInstitutes,
        Colleges,
        Schools,
        Universities,
        currentTab: 'All',
        ifTabChanged: '',
        TabView,
        TabPanel,
        TabMenu,
        Badge,
        BadgeDirective,
        DataView,
        DataViewLayoutOptions,
        Chip,
        Dropdown,
        Sidebar,
        Button,
    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            imageurl: null,
            baseUrl: null,
            filteredData: [],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ]
        };
    },
    methods: {
        tabChanged(selectedTab) {
            this.ifTabChanged = selectedTab.tab.name;
            this.currentTab = selectedTab.tab.name;
        },
        getValue(value) {
            this.filteredData = value;
        },
    },
    async mounted() {
    },
}
</script>
